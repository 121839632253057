(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === elm$core$Basics$EQ ? 0 : ord === elm$core$Basics$LT ? -1 : 1;
	}));
});



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = elm$core$Set$toList(x);
		y = elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = elm$core$Dict$toList(x);
		y = elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = elm$core$Dict$toList(x);
		y = elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? elm$core$Basics$LT : n ? elm$core$Basics$GT : elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File === 'function' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[94m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.eE.b9 === region.fd.b9)
	{
		return 'on line ' + region.eE.b9;
	}
	return 'on lines ' + region.eE.b9 + ' through ' + region.fd.b9;
}



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return word
		? elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? elm$core$Maybe$Nothing
		: elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? elm$core$Maybe$Just(n) : elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




/**_UNUSED/
function _Json_errorToString(error)
{
	return elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? elm$core$Result$Ok(value)
		: (value instanceof String)
			? elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return elm$core$Result$Err(A2(elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return (elm$core$Result$isOk(result)) ? result : elm$core$Result$Err(A2(elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return (elm$core$Result$isOk(result)) ? result : elm$core$Result$Err(A2(elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!elm$core$Result$isOk(result))
					{
						return elm$core$Result$Err(A2(elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return elm$core$Result$Ok(elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if (elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return elm$core$Result$Err(elm$json$Json$Decode$OneOf(elm$core$List$reverse(errors)));

		case 1:
			return elm$core$Result$Err(A2(elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!elm$core$Result$isOk(result))
		{
			return elm$core$Result$Err(A2(elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2(elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return elm$core$Result$Err(A2(elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.hS,
		impl.jv,
		impl.i7,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_dispatchEffects(managers, result.b, subscriptions(model));
	}

	_Platform_dispatchEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				p: bag.n,
				q: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.q)
		{
			x = temp.p(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		r: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		r: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.hu.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done(elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done(elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.hu.b, xhr)); });
		elm$core$Maybe$isJust(request.cr) && _Http_track(router, xhr, request.cr.a);

		try {
			xhr.open(request.a9, request.jw, true);
		} catch (e) {
			return done(elm$http$Http$BadUrl_(request.jw));
		}

		_Http_configureRequest(xhr, request);

		request.bT.a && xhr.setRequestHeader('Content-Type', request.bT.a);
		xhr.send(request.bT.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.hG; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.cm.a || 0;
	xhr.responseType = request.hu.d;
	xhr.withCredentials = request.bn;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? elm$http$Http$GoodStatus_ : elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		jw: xhr.responseURL,
		gh: xhr.status,
		i2: xhr.statusText,
		hG: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return elm$core$Dict$empty;
	}

	var headers = elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3(elm$core$Dict$update, key, function(oldValue) {
				return elm$core$Maybe$Just(elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2(elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, elm$http$Http$Sending({
			iU: event.loaded,
			ew: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2(elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, elm$http$Http$Receiving({
			iB: event.loaded,
			ew: event.lengthComputable ? elm$core$Maybe$Just(event.total) : elm$core$Maybe$Nothing
		}))));
	});
}

function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return elm$core$Maybe$Nothing;
	}
}



// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2(elm$json$Json$Decode$map, func, handler.a)
				:
			A3(elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		aS: func(record.aS),
		eF: record.eF,
		eq: record.eq
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.aS;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.eF;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.eq) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.hS,
		impl.jv,
		impl.i7,
		function(sendToApp, initialModel) {
			var view = impl.jx;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.hS,
		impl.jv,
		impl.i7,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.ch && impl.ch(sendToApp)
			var view = impl.jx;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.bT);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.gn) && (_VirtualDom_doc.title = title = doc.gn);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.ip;
	var onUrlRequest = impl.iq;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		ch: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.f$ === next.f$
							&& curr.fs === next.fs
							&& curr.fX.a === next.fX.a
						)
							? elm$browser$Browser$Internal(next)
							: elm$browser$Browser$External(href)
					));
				}
			});
		},
		hS: function(flags)
		{
			return A3(impl.hS, flags, _Browser_getUrl(), key);
		},
		jx: impl.jx,
		jv: impl.jv,
		i7: impl.i7
	});
}

function _Browser_getUrl()
{
	return elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return elm$core$Result$isOk(result) ? elm$core$Maybe$Just(result.a) : elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { hI: 'hidden', g7: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { hI: 'mozHidden', g7: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { hI: 'msHidden', g7: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { hI: 'webkitHidden', g7: 'webkitvisibilitychange' }
		: { hI: 'hidden', g7: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail(elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		f9: _Browser_getScene(),
		gv: {
			eR: _Browser_window.pageXOffset,
			eS: _Browser_window.pageYOffset,
			gy: _Browser_doc.documentElement.clientWidth,
			fp: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		gy: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		fp: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			f9: {
				gy: node.scrollWidth,
				fp: node.scrollHeight
			},
			gv: {
				eR: node.scrollLeft,
				eS: node.scrollTop,
				gy: node.clientWidth,
				fp: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			f9: _Browser_getScene(),
			gv: {
				eR: x,
				eS: y,
				gy: _Browser_doc.documentElement.clientWidth,
				fp: _Browser_doc.documentElement.clientHeight
			},
			hq: {
				eR: x + rect.left,
				eS: y + rect.top,
				gy: rect.width,
				fp: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2(elm$core$Task$perform, elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2(elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}
var author$project$Main$ChangedUrl = function (a) {
	return {$: 0, a: a};
};
var author$project$Main$ClickedLink = function (a) {
	return {$: 1, a: a};
};
var author$project$Main$Initialize = function (a) {
	return {$: 8, a: a};
};
var author$project$Main$InitializeMsg = function (a) {
	return {$: 10, a: a};
};
var elm$core$Basics$False = 1;
var elm$core$Basics$True = 0;
var elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var elm$core$Basics$EQ = 1;
var elm$core$Basics$GT = 2;
var elm$core$Basics$LT = 0;
var elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3(elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var elm$core$List$cons = _List_cons;
var elm$core$Dict$toList = function (dict) {
	return A3(
		elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var elm$core$Dict$keys = function (dict) {
	return A3(
		elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2(elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var elm$core$Set$toList = function (_n0) {
	var dict = _n0;
	return elm$core$Dict$keys(dict);
};
var elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var elm$core$Array$foldr = F3(
	function (func, baseCase, _n0) {
		var tree = _n0.c;
		var tail = _n0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3(elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3(elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			elm$core$Elm$JsArray$foldr,
			helper,
			A3(elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var elm$core$Array$toList = function (array) {
	return A3(elm$core$Array$foldr, elm$core$List$cons, _List_Nil, array);
};
var elm$core$Array$branchFactor = 32;
var elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var elm$core$Basics$ceiling = _Basics_ceiling;
var elm$core$Basics$fdiv = _Basics_fdiv;
var elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var elm$core$Basics$toFloat = _Basics_toFloat;
var elm$core$Array$shiftStep = elm$core$Basics$ceiling(
	A2(elm$core$Basics$logBase, 2, elm$core$Array$branchFactor));
var elm$core$Elm$JsArray$empty = _JsArray_empty;
var elm$core$Array$empty = A4(elm$core$Array$Array_elm_builtin, 0, elm$core$Array$shiftStep, elm$core$Elm$JsArray$empty, elm$core$Elm$JsArray$empty);
var elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var elm$core$List$reverse = function (list) {
	return A3(elm$core$List$foldl, elm$core$List$cons, _List_Nil, list);
};
var elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _n0 = A2(elm$core$Elm$JsArray$initializeFromList, elm$core$Array$branchFactor, nodes);
			var node = _n0.a;
			var remainingNodes = _n0.b;
			var newAcc = A2(
				elm$core$List$cons,
				elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var elm$core$Basics$eq = _Utils_equal;
var elm$core$Tuple$first = function (_n0) {
	var x = _n0.a;
	return x;
};
var elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = elm$core$Basics$ceiling(nodeListSize / elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2(elm$core$Elm$JsArray$initializeFromList, elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2(elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var elm$core$Basics$add = _Basics_add;
var elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var elm$core$Basics$floor = _Basics_floor;
var elm$core$Basics$gt = _Utils_gt;
var elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var elm$core$Basics$mul = _Basics_mul;
var elm$core$Basics$sub = _Basics_sub;
var elm$core$Elm$JsArray$length = _JsArray_length;
var elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.p) {
			return A4(
				elm$core$Array$Array_elm_builtin,
				elm$core$Elm$JsArray$length(builder.s),
				elm$core$Array$shiftStep,
				elm$core$Elm$JsArray$empty,
				builder.s);
		} else {
			var treeLen = builder.p * elm$core$Array$branchFactor;
			var depth = elm$core$Basics$floor(
				A2(elm$core$Basics$logBase, elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? elm$core$List$reverse(builder.t) : builder.t;
			var tree = A2(elm$core$Array$treeFromBuilder, correctNodeList, builder.p);
			return A4(
				elm$core$Array$Array_elm_builtin,
				elm$core$Elm$JsArray$length(builder.s) + treeLen,
				A2(elm$core$Basics$max, 5, depth * elm$core$Array$shiftStep),
				tree,
				builder.s);
		}
	});
var elm$core$Basics$idiv = _Basics_idiv;
var elm$core$Basics$lt = _Utils_lt;
var elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					elm$core$Array$builderToArray,
					false,
					{t: nodeList, p: (len / elm$core$Array$branchFactor) | 0, s: tail});
			} else {
				var leaf = elm$core$Array$Leaf(
					A3(elm$core$Elm$JsArray$initialize, elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2(elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var elm$core$Basics$le = _Utils_le;
var elm$core$Basics$remainderBy = _Basics_remainderBy;
var elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return elm$core$Array$empty;
		} else {
			var tailLen = len % elm$core$Array$branchFactor;
			var tail = A3(elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - elm$core$Array$branchFactor;
			return A5(elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var elm$core$Maybe$Nothing = {$: 1};
var elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var elm$core$Basics$and = _Basics_and;
var elm$core$Basics$append = _Utils_append;
var elm$core$Basics$or = _Basics_or;
var elm$core$Char$toCode = _Char_toCode;
var elm$core$Char$isLower = function (_char) {
	var code = elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var elm$core$Char$isUpper = function (_char) {
	var code = elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var elm$core$Char$isAlpha = function (_char) {
	return elm$core$Char$isLower(_char) || elm$core$Char$isUpper(_char);
};
var elm$core$Char$isDigit = function (_char) {
	var code = elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var elm$core$Char$isAlphaNum = function (_char) {
	return elm$core$Char$isLower(_char) || (elm$core$Char$isUpper(_char) || elm$core$Char$isDigit(_char));
};
var elm$core$List$length = function (xs) {
	return A3(
		elm$core$List$foldl,
		F2(
			function (_n0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var elm$core$List$map2 = _List_map2;
var elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2(elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var elm$core$List$range = F2(
	function (lo, hi) {
		return A3(elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			elm$core$List$map2,
			f,
			A2(
				elm$core$List$range,
				0,
				elm$core$List$length(xs) - 1),
			xs);
	});
var elm$core$String$all = _String_all;
var elm$core$String$fromInt = _String_fromNumber;
var elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var elm$core$String$uncons = _String_uncons;
var elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var elm$json$Json$Decode$indent = function (str) {
	return A2(
		elm$core$String$join,
		'\n    ',
		A2(elm$core$String$split, '\n', str));
};
var elm$json$Json$Encode$encode = _Json_encode;
var elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + (elm$core$String$fromInt(i + 1) + (') ' + elm$json$Json$Decode$indent(
			elm$json$Json$Decode$errorToString(error))));
	});
var elm$json$Json$Decode$errorToString = function (error) {
	return A2(elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _n1 = elm$core$String$uncons(f);
						if (_n1.$ === 1) {
							return false;
						} else {
							var _n2 = _n1.a;
							var _char = _n2.a;
							var rest = _n2.b;
							return elm$core$Char$isAlpha(_char) && A2(elm$core$String$all, elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2(elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + (elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2(elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									elm$core$String$join,
									'',
									elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										elm$core$String$join,
										'',
										elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + (elm$core$String$fromInt(
								elm$core$List$length(errors)) + ' ways:'));
							return A2(
								elm$core$String$join,
								'\n\n',
								A2(
									elm$core$List$cons,
									introduction,
									A2(elm$core$List$indexedMap, elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								elm$core$String$join,
								'',
								elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + (elm$json$Json$Decode$indent(
						A2(elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var elm$core$Platform$Cmd$batch = _Platform_batch;
var author$project$Extra$Cmd$withExtraCmd = F2(
	function (cmd2, _n0) {
		var m = _n0.a;
		var cmd1 = _n0.b;
		return _Utils_Tuple2(
			m,
			elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[cmd1, cmd2])));
	});
var author$project$Main$Blueprint = function (a) {
	return {$: 6, a: a};
};
var author$project$Main$BlueprintMsg = function (a) {
	return {$: 9, a: a};
};
var author$project$Main$Blueprints = function (a) {
	return {$: 7, a: a};
};
var author$project$Main$BlueprintsMsg = function (a) {
	return {$: 8, a: a};
};
var author$project$Main$Campaign = function (a) {
	return {$: 1, a: a};
};
var author$project$Main$CampaignMsg = function (a) {
	return {$: 2, a: a};
};
var author$project$Main$Campaigns = function (a) {
	return {$: 2, a: a};
};
var author$project$Main$CampaignsMsg = function (a) {
	return {$: 3, a: a};
};
var author$project$Main$Credits = function (a) {
	return {$: 3, a: a};
};
var author$project$Main$CreditsMsg = function (a) {
	return {$: 4, a: a};
};
var author$project$Main$Draft = function (a) {
	return {$: 5, a: a};
};
var author$project$Main$DraftMsg = function (a) {
	return {$: 6, a: a};
};
var author$project$Main$Execution = function (a) {
	return {$: 4, a: a};
};
var author$project$Main$ExecutionMsg = function (a) {
	return {$: 5, a: a};
};
var author$project$Main$Home = function (a) {
	return {$: 0, a: a};
};
var author$project$Main$HomeMsg = function (a) {
	return {$: 7, a: a};
};
var author$project$Main$NotFound = function (a) {
	return {$: 9, a: a};
};
var author$project$Main$NotFoundMsg = function (a) {
	return {$: 11, a: a};
};
var elm$core$Platform$Cmd$map = _Platform_map;
var author$project$Main$updateWith = F3(
	function (modelMap, cmdMap, _n0) {
		var model = _n0.a;
		var cmd = _n0.b;
		return _Utils_Tuple2(
			modelMap(model),
			A2(elm$core$Platform$Cmd$map, cmdMap, cmd));
	});
var elm$core$Array$length = function (_n0) {
	var len = _n0.a;
	return len;
};
var author$project$Data$Board$height = function (board) {
	return elm$core$Array$length(board);
};
var elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var elm$core$Array$bitMask = 4294967295 >>> (32 - elm$core$Array$shiftStep);
var elm$core$Bitwise$and = _Bitwise_and;
var elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = elm$core$Array$bitMask & (index >>> shift);
			var _n0 = A2(elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_n0.$) {
				var subTree = _n0.a;
				var $temp$shift = shift - elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _n0.a;
				return A2(elm$core$Elm$JsArray$unsafeGet, elm$core$Array$bitMask & index, values);
			}
		}
	});
var elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var elm$core$Basics$ge = _Utils_ge;
var elm$core$Array$get = F2(
	function (index, _n0) {
		var len = _n0.a;
		var startShift = _n0.b;
		var tree = _n0.c;
		var tail = _n0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			elm$core$Array$tailIndex(len)) > -1) ? elm$core$Maybe$Just(
			A2(elm$core$Elm$JsArray$unsafeGet, elm$core$Array$bitMask & index, tail)) : elm$core$Maybe$Just(
			A3(elm$core$Array$getHelp, startShift, index, tree)));
	});
var elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return elm$core$Maybe$Just(
				f(value));
		} else {
			return elm$core$Maybe$Nothing;
		}
	});
var elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var author$project$Data$Board$width = function (board) {
	return A2(
		elm$core$Maybe$withDefault,
		0,
		A2(
			elm$core$Maybe$map,
			elm$core$Array$length,
			A2(elm$core$Array$get, 0, board)));
};
var author$project$Data$Instruction$NoOp = {$: 0};
var author$project$Data$InstructionTool$JustInstruction = function (a) {
	return {$: 0, a: a};
};
var author$project$Data$Direction$Left = 0;
var author$project$Data$Direction$Right = 2;
var author$project$Data$Instruction$Abs = {$: 8};
var author$project$Data$Instruction$Add = {$: 12};
var author$project$Data$Instruction$And = {$: 18};
var author$project$Data$Instruction$CompareLessThan = {$: 17};
var author$project$Data$Instruction$Decrement = {$: 11};
var author$project$Data$Instruction$Divide = {$: 15};
var author$project$Data$Instruction$Duplicate = {$: 5};
var author$project$Data$Instruction$Equals = {$: 16};
var author$project$Data$Instruction$Increment = {$: 10};
var author$project$Data$Instruction$JumpForward = {$: 4};
var author$project$Data$Instruction$Multiply = {$: 14};
var author$project$Data$Instruction$Negate = {$: 7};
var author$project$Data$Instruction$Not = {$: 9};
var author$project$Data$Instruction$Or = {$: 19};
var author$project$Data$Instruction$PopFromStack = {$: 3};
var author$project$Data$Instruction$Print = {$: 22};
var author$project$Data$Instruction$Read = {$: 21};
var author$project$Data$Instruction$SendToBottom = {$: 25};
var author$project$Data$Instruction$Subtract = {$: 13};
var author$project$Data$Instruction$Swap = {$: 6};
var author$project$Data$Instruction$Terminate = {$: 24};
var author$project$Data$Instruction$XOr = {$: 20};
var author$project$Data$Instruction$allSimple = _List_fromArray(
	[author$project$Data$Instruction$NoOp, author$project$Data$Instruction$PopFromStack, author$project$Data$Instruction$JumpForward, author$project$Data$Instruction$Duplicate, author$project$Data$Instruction$Swap, author$project$Data$Instruction$Negate, author$project$Data$Instruction$Abs, author$project$Data$Instruction$Not, author$project$Data$Instruction$Increment, author$project$Data$Instruction$Decrement, author$project$Data$Instruction$Add, author$project$Data$Instruction$Subtract, author$project$Data$Instruction$Multiply, author$project$Data$Instruction$Divide, author$project$Data$Instruction$Equals, author$project$Data$Instruction$CompareLessThan, author$project$Data$Instruction$And, author$project$Data$Instruction$Or, author$project$Data$Instruction$XOr, author$project$Data$Instruction$Read, author$project$Data$Instruction$Print, author$project$Data$Instruction$Terminate, author$project$Data$Instruction$SendToBottom]);
var author$project$Data$InstructionTool$BranchAnyDirection = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var author$project$Data$InstructionTool$ChangeAnyDirection = function (a) {
	return {$: 1, a: a};
};
var author$project$Data$InstructionTool$Exception = function (a) {
	return {$: 4, a: a};
};
var elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							elm$core$List$foldl,
							fn,
							acc,
							elm$core$List$reverse(r4)) : A4(elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4(elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3(elm$core$List$foldr, elm$core$List$cons, ys, xs);
		}
	});
var elm$core$List$concat = function (lists) {
	return A3(elm$core$List$foldr, elm$core$List$append, _List_Nil, lists);
};
var elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var author$project$Data$InstructionTool$all = elm$core$List$concat(
	_List_fromArray(
		[
			A2(elm$core$List$map, author$project$Data$InstructionTool$JustInstruction, author$project$Data$Instruction$allSimple),
			_List_fromArray(
			[
				author$project$Data$InstructionTool$ChangeAnyDirection(0),
				A2(author$project$Data$InstructionTool$BranchAnyDirection, 0, 2),
				author$project$Data$InstructionTool$Exception('')
			])
		]));
var author$project$Data$Int16$toString = function (_n0) {
	var a = _n0;
	return elm$core$String$fromInt(a);
};
var author$project$Data$Level$localStorageKey = function (levelId) {
	return A2(
		elm$core$String$join,
		'.',
		_List_fromArray(
			['levels', levelId]));
};
var elm$json$Json$Encode$string = _Json_wrap;
var author$project$Ports$LocalStorage$storageGetItem = _Platform_outgoingPort('storageGetItem', elm$json$Json$Encode$string);
var author$project$Data$Level$loadFromLocalStorage = function (levelId) {
	return author$project$Ports$LocalStorage$storageGetItem(
		author$project$Data$Level$localStorageKey(levelId));
};
var author$project$Data$Cache$getDict = function (_n0) {
	var dict = _n0;
	return dict;
};
var elm$core$Basics$compare = _Utils_compare;
var elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _n1 = A2(elm$core$Basics$compare, targetKey, key);
				switch (_n1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var krisajenkins$remotedata$RemoteData$NotAsked = {$: 0};
var author$project$Data$Cache$get = F2(
	function (key, cache) {
		return A2(
			elm$core$Maybe$withDefault,
			krisajenkins$remotedata$RemoteData$NotAsked,
			A2(
				elm$core$Dict$get,
				key,
				author$project$Data$Cache$getDict(cache)));
	});
var author$project$Data$Session$getLevel = F2(
	function (levelId, session) {
		return A2(author$project$Data$Cache$get, levelId, session.as);
	});
var author$project$Data$Cache$Cache = elm$core$Basics$identity;
var elm$core$Basics$identity = function (x) {
	return x;
};
var elm$core$Dict$Black = 1;
var elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var elm$core$Dict$Red = 0;
var elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _n1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _n3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5(elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5(elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5(elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _n5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _n6 = left.d;
				var _n7 = _n6.a;
				var llK = _n6.b;
				var llV = _n6.c;
				var llLeft = _n6.d;
				var llRight = _n6.e;
				var lRight = left.e;
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5(elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5(elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5(elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5(elm$core$Dict$RBNode_elm_builtin, 0, key, value, elm$core$Dict$RBEmpty_elm_builtin, elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _n1 = A2(elm$core$Basics$compare, key, nKey);
			switch (_n1) {
				case 0:
					return A5(
						elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3(elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5(elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3(elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _n0 = A3(elm$core$Dict$insertHelp, key, value, dict);
		if ((_n0.$ === -1) && (!_n0.a)) {
			var _n1 = _n0.a;
			var k = _n0.b;
			var v = _n0.c;
			var l = _n0.d;
			var r = _n0.e;
			return A5(elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _n0;
			return x;
		}
	});
var author$project$Data$Cache$insertInternal = F3(
	function (key, webData, cache) {
		return A3(
			elm$core$Dict$insert,
			key,
			webData,
			author$project$Data$Cache$getDict(cache));
	});
var krisajenkins$remotedata$RemoteData$Loading = {$: 1};
var author$project$Data$Cache$loading = function (key) {
	return A2(author$project$Data$Cache$insertInternal, key, krisajenkins$remotedata$RemoteData$Loading);
};
var author$project$Data$Session$setLevelCache = F2(
	function (session, cache) {
		return _Utils_update(
			session,
			{as: cache});
	});
var author$project$Data$Session$levelLoading = F2(
	function (levelId, session) {
		return A2(
			author$project$Data$Session$setLevelCache,
			session,
			A2(author$project$Data$Cache$loading, levelId, session.as));
	});
var author$project$Data$Suite$empty = {hU: _List_Nil, iu: _List_Nil};
var elm$core$Elm$JsArray$foldl = _JsArray_foldl;
var elm$core$Array$foldl = F3(
	function (func, baseCase, _n0) {
		var tree = _n0.c;
		var tail = _n0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3(elm$core$Elm$JsArray$foldl, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3(elm$core$Elm$JsArray$foldl, func, acc, values);
				}
			});
		return A3(
			elm$core$Elm$JsArray$foldl,
			func,
			A3(elm$core$Elm$JsArray$foldl, helper, baseCase, tree),
			tail);
	});
var elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var author$project$Extra$Array$member = function (value) {
	return A2(
		elm$core$Array$foldl,
		A2(
			elm$core$Basics$composeL,
			elm$core$Basics$or,
			elm$core$Basics$eq(value)),
		false);
};
var author$project$Extra$Cmd$bind = F2(
	function (_function, _n0) {
		var value = _n0.a;
		var cmd = _n0.b;
		return A2(
			author$project$Extra$Cmd$withExtraCmd,
			cmd,
			_function(value));
	});
var elm$core$Platform$Cmd$none = elm$core$Platform$Cmd$batch(_List_Nil);
var author$project$Extra$Cmd$noCmd = function (value) {
	return _Utils_Tuple2(value, elm$core$Platform$Cmd$none);
};
var elm_community$basics_extra$Basics$Extra$flip = F3(
	function (f, b, a) {
		return A2(f, a, b);
	});
var author$project$Extra$Cmd$fold = F2(
	function (functions, model) {
		return A3(
			elm$core$List$foldl,
			elm_community$basics_extra$Basics$Extra$flip(elm$core$Basics$apR),
			author$project$Extra$Cmd$noCmd(model),
			A2(elm$core$List$map, author$project$Extra$Cmd$bind, functions));
	});
var author$project$Page$Blueprint$setSession = F2(
	function (model, session) {
		return _Utils_update(
			model,
			{f: session});
	});
var elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _n0 = A2(elm$core$Elm$JsArray$initializeFromList, elm$core$Array$branchFactor, list);
			var jsArray = _n0.a;
			var remainingItems = _n0.b;
			if (_Utils_cmp(
				elm$core$Elm$JsArray$length(jsArray),
				elm$core$Array$branchFactor) < 0) {
				return A2(
					elm$core$Array$builderToArray,
					true,
					{t: nodeList, p: nodeListSize, s: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					elm$core$List$cons,
					elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return elm$core$Array$empty;
	} else {
		return A3(elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var elm$core$Basics$neq = _Utils_notEqual;
var elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2(elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return elm$core$Maybe$Just(x);
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var author$project$Page$Blueprint$load = function () {
	var loadLevel = function (model) {
		var _n0 = A2(author$project$Data$Session$getLevel, model.a8, model.f);
		switch (_n0.$) {
			case 0:
				return _Utils_Tuple2(
					A2(
						author$project$Page$Blueprint$setSession,
						model,
						A2(author$project$Data$Session$levelLoading, model.a8, model.f)),
					author$project$Data$Level$loadFromLocalStorage(model.a8));
			case 3:
				var level = _n0.a;
				return A2(
					elm$core$Maybe$withDefault,
					false,
					A2(
						elm$core$Maybe$map,
						elm$core$Basics$eq(level.b6),
						model.c_)) ? author$project$Extra$Cmd$noCmd(model) : _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aq: elm$core$Array$fromList(
								A2(
									elm$core$List$map,
									function (tool) {
										return _Utils_Tuple2(
											tool,
											A2(author$project$Extra$Array$member, tool, level.aO));
									},
									A2(
										elm$core$List$filter,
										elm$core$Basics$neq(
											author$project$Data$InstructionTool$JustInstruction(author$project$Data$Instruction$NoOp)),
										author$project$Data$InstructionTool$all))),
							fp: elm$core$String$fromInt(
								author$project$Data$Board$height(level.hT)),
							hU: A2(
								elm$core$String$join,
								',',
								A2(
									elm$core$List$map,
									author$project$Data$Int16$toString,
									A2(
										elm$core$Maybe$withDefault,
										author$project$Data$Suite$empty,
										elm$core$List$head(level.bM)).hU)),
							a8: level.b6,
							c_: elm$core$Maybe$Just(level.b6),
							iu: A2(
								elm$core$String$join,
								',',
								A2(
									elm$core$List$map,
									author$project$Data$Int16$toString,
									A2(
										elm$core$Maybe$withDefault,
										author$project$Data$Suite$empty,
										elm$core$List$head(level.bM)).iu)),
							gy: elm$core$String$fromInt(
								author$project$Data$Board$width(level.hT))
						}),
					elm$core$Platform$Cmd$none);
			default:
				return author$project$Extra$Cmd$noCmd(model);
		}
	};
	return author$project$Extra$Cmd$fold(
		_List_fromArray(
			[loadLevel]));
}();
var author$project$Api$GCP$get = {eT: elm$core$Maybe$Nothing, bT: elm$core$Maybe$Nothing, a9: 'GET', da: _List_Nil, dc: _List_Nil, cm: elm$core$Maybe$Nothing, cr: elm$core$Maybe$Nothing};
var author$project$Data$AccessToken$toString = function (_n0) {
	var accessToken = _n0;
	return accessToken;
};
var elm$core$Dict$empty = elm$core$Dict$RBEmpty_elm_builtin;
var elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _n1 = dict.d;
			var lClr = _n1.a;
			var lK = _n1.b;
			var lV = _n1.c;
			var lLeft = _n1.d;
			var lRight = _n1.e;
			var _n2 = dict.e;
			var rClr = _n2.a;
			var rK = _n2.b;
			var rV = _n2.c;
			var rLeft = _n2.d;
			var _n3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _n2.e;
			return A5(
				elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5(elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5(elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _n4 = dict.d;
			var lClr = _n4.a;
			var lK = _n4.b;
			var lV = _n4.c;
			var lLeft = _n4.d;
			var lRight = _n4.e;
			var _n5 = dict.e;
			var rClr = _n5.a;
			var rK = _n5.b;
			var rV = _n5.c;
			var rLeft = _n5.d;
			var rRight = _n5.e;
			if (clr === 1) {
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5(elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5(elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5(elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5(elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _n1 = dict.d;
			var lClr = _n1.a;
			var lK = _n1.b;
			var lV = _n1.c;
			var _n2 = _n1.d;
			var _n3 = _n2.a;
			var llK = _n2.b;
			var llV = _n2.c;
			var llLeft = _n2.d;
			var llRight = _n2.e;
			var lRight = _n1.e;
			var _n4 = dict.e;
			var rClr = _n4.a;
			var rK = _n4.b;
			var rV = _n4.c;
			var rLeft = _n4.d;
			var rRight = _n4.e;
			return A5(
				elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5(elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5(elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _n5 = dict.d;
			var lClr = _n5.a;
			var lK = _n5.b;
			var lV = _n5.c;
			var lLeft = _n5.d;
			var lRight = _n5.e;
			var _n6 = dict.e;
			var rClr = _n6.a;
			var rK = _n6.b;
			var rV = _n6.c;
			var rLeft = _n6.d;
			var rRight = _n6.e;
			if (clr === 1) {
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5(elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5(elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5(elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5(elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _n1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5(elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_n2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _n3 = right.a;
							var _n4 = right.d;
							var _n5 = _n4.a;
							return elm$core$Dict$moveRedRight(dict);
						} else {
							break _n2$2;
						}
					} else {
						var _n6 = right.a;
						var _n7 = right.d;
						return elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _n2$2;
				}
			}
			return dict;
		}
	});
var elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _n3 = lLeft.a;
				return A5(
					elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					elm$core$Dict$removeMin(left),
					right);
			} else {
				var _n4 = elm$core$Dict$moveRedLeft(dict);
				if (_n4.$ === -1) {
					var nColor = _n4.a;
					var nKey = _n4.b;
					var nValue = _n4.c;
					var nLeft = _n4.d;
					var nRight = _n4.e;
					return A5(
						elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _n4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _n6 = lLeft.a;
						return A5(
							elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2(elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _n7 = elm$core$Dict$moveRedLeft(dict);
						if (_n7.$ === -1) {
							var nColor = _n7.a;
							var nKey = _n7.b;
							var nValue = _n7.c;
							var nLeft = _n7.d;
							var nRight = _n7.e;
							return A5(
								elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2(elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2(elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7(elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _n1 = elm$core$Dict$getMin(right);
				if (_n1.$ === -1) {
					var minKey = _n1.b;
					var minValue = _n1.c;
					return A5(
						elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						elm$core$Dict$removeMin(right));
				} else {
					return elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2(elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var elm$core$Dict$remove = F2(
	function (key, dict) {
		var _n0 = A2(elm$core$Dict$removeHelp, key, dict);
		if ((_n0.$ === -1) && (!_n0.a)) {
			var _n1 = _n0.a;
			var k = _n0.b;
			var v = _n0.c;
			var l = _n0.d;
			var r = _n0.e;
			return A5(elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _n0;
			return x;
		}
	});
var elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _n0 = alter(
			A2(elm$core$Dict$get, targetKey, dictionary));
		if (!_n0.$) {
			var value = _n0.a;
			return A3(elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2(elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var elm$core$Platform$sendToApp = _Platform_sendToApp;
var elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return elm$core$Result$Err(e);
		}
	});
var elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var elm$http$Http$NetworkError_ = {$: 2};
var elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var elm$http$Http$Timeout_ = {$: 1};
var elm$http$Http$emptyBody = _Http_emptyBody;
var elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var elm$http$Http$header = elm$http$Http$Header;
var elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2(elm$json$Json$Encode$encode, 0, value));
};
var elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var elm$core$Task$succeed = _Scheduler_succeed;
var elm$http$Http$State = F2(
	function (reqs, subs) {
		return {f2: reqs, gj: subs};
	});
var elm$http$Http$init = elm$core$Task$succeed(
	A2(elm$http$Http$State, elm$core$Dict$empty, _List_Nil));
var elm$core$Task$andThen = _Scheduler_andThen;
var elm$core$Process$kill = _Scheduler_kill;
var elm$core$Process$spawn = _Scheduler_spawn;
var elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _n2 = A2(elm$core$Dict$get, tracker, reqs);
					if (_n2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _n2.a;
						return A2(
							elm$core$Task$andThen,
							function (_n3) {
								return A3(
									elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2(elm$core$Dict$remove, tracker, reqs));
							},
							elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						elm$core$Task$andThen,
						function (pid) {
							var _n4 = req.cr;
							if (_n4.$ === 1) {
								return A3(elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _n4.a;
								return A3(
									elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3(elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			elm$core$Task$andThen,
			function (reqs) {
				return elm$core$Task$succeed(
					A2(elm$http$Http$State, reqs, subs));
			},
			A3(elm$http$Http$updateReqs, router, cmds, state.f2));
	});
var elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _n0 = f(mx);
		if (!_n0.$) {
			var x = _n0.a;
			return A2(elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			elm$core$List$foldr,
			elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			elm$core$Task$andThen,
			function (a) {
				return A2(
					elm$core$Task$andThen,
					function (b) {
						return elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var elm$core$Task$sequence = function (tasks) {
	return A3(
		elm$core$List$foldr,
		elm$core$Task$map2(elm$core$List$cons),
		elm$core$Task$succeed(_List_Nil),
		tasks);
};
var elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _n0) {
		var actualTracker = _n0.a;
		var toMsg = _n0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? elm$core$Maybe$Just(
			A2(
				elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : elm$core$Maybe$Nothing;
	});
var elm$http$Http$onSelfMsg = F3(
	function (router, _n0, state) {
		var tracker = _n0.a;
		var progress = _n0.b;
		return A2(
			elm$core$Task$andThen,
			function (_n1) {
				return elm$core$Task$succeed(state);
			},
			elm$core$Task$sequence(
				A2(
					elm$core$List$filterMap,
					A3(elm$http$Http$maybeSend, router, tracker, progress),
					state.gj)));
	});
var elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return elm$http$Http$Request(
				{
					bn: r.bn,
					bT: r.bT,
					hu: A2(_Http_mapExpect, func, r.hu),
					hG: r.hG,
					a9: r.a9,
					cm: r.cm,
					cr: r.cr,
					jw: r.jw
				});
		}
	});
var elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var elm$http$Http$subMap = F2(
	function (func, _n0) {
		var tracker = _n0.a;
		var toMsg = _n0.b;
		return A2(
			elm$http$Http$MySub,
			tracker,
			A2(elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager(elm$http$Http$init, elm$http$Http$onEffects, elm$http$Http$onSelfMsg, elm$http$Http$cmdMap, elm$http$Http$subMap);
var elm$http$Http$command = _Platform_leaf('Http');
var elm$http$Http$subscription = _Platform_leaf('Http');
var elm$http$Http$request = function (r) {
	return elm$http$Http$command(
		elm$http$Http$Request(
			{bn: false, bT: r.bT, hu: r.hu, hG: r.hG, a9: r.a9, cm: r.cm, cr: r.cr, jw: r.jw}));
};
var elm$url$Url$Builder$toQueryPair = function (_n0) {
	var key = _n0.a;
	var value = _n0.b;
	return key + ('=' + value);
};
var elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			elm$core$String$join,
			'&',
			A2(elm$core$List$map, elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var elm$url$Url$Builder$crossOrigin = F3(
	function (prePath, pathSegments, parameters) {
		return prePath + ('/' + (A2(elm$core$String$join, '/', pathSegments) + elm$url$Url$Builder$toQuery(parameters)));
	});
var elm_community$maybe_extra$Maybe$Extra$foldrValues = F2(
	function (item, list) {
		if (item.$ === 1) {
			return list;
		} else {
			var v = item.a;
			return A2(elm$core$List$cons, v, list);
		}
	});
var elm_community$maybe_extra$Maybe$Extra$values = A2(elm$core$List$foldr, elm_community$maybe_extra$Maybe$Extra$foldrValues, _List_Nil);
var author$project$Api$GCP$request = F2(
	function (expect, builder) {
		var url = A3(elm$url$Url$Builder$crossOrigin, 'https://us-central1-luminous-cubist-234816.cloudfunctions.net', builder.da, builder.dc);
		var headers = function () {
			var authorizationHeader = function () {
				var _n0 = builder.eT;
				if (!_n0.$) {
					var accessToken = _n0.a;
					return elm$core$Maybe$Just(
						A2(
							elm$http$Http$header,
							'Authorization',
							'Bearer ' + author$project$Data$AccessToken$toString(accessToken)));
				} else {
					return elm$core$Maybe$Nothing;
				}
			}();
			return elm_community$maybe_extra$Maybe$Extra$values(
				_List_fromArray(
					[authorizationHeader]));
		}();
		var body = A2(
			elm$core$Maybe$withDefault,
			elm$http$Http$emptyBody,
			A2(elm$core$Maybe$map, elm$http$Http$jsonBody, builder.bT));
		return elm$http$Http$request(
			{bT: body, hu: expect, hG: headers, a9: builder.a9, cm: builder.cm, cr: builder.cr, jw: url});
	});
var author$project$Api$GCP$withAccessToken = F2(
	function (accessToken, builder) {
		return _Utils_update(
			builder,
			{
				eT: elm$core$Maybe$Just(accessToken)
			});
	});
var author$project$Api$GCP$withPath = F2(
	function (path, builder) {
		return _Utils_update(
			builder,
			{da: path});
	});
var author$project$Data$Blueprint$path = _List_fromArray(
	['blueprints']);
var author$project$Data$GetError$InvalidAccessToken = function (a) {
	return {$: 1, a: a};
};
var author$project$Data$GetError$NetworkError = {$: 0};
var author$project$Data$GetError$Other = function (a) {
	return {$: 2, a: a};
};
var elm$json$Json$Decode$decodeString = _Json_runOnString;
var author$project$Data$GetError$result = F2(
	function (decoder, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return elm$core$Result$Err(
					author$project$Data$GetError$Other('Bad url: ' + url));
			case 1:
				return elm$core$Result$Err(author$project$Data$GetError$NetworkError);
			case 2:
				return elm$core$Result$Err(author$project$Data$GetError$NetworkError);
			case 3:
				var metadata = response.a;
				var body = response.b;
				var _n1 = metadata.gh;
				if (_n1 === 403) {
					return elm$core$Result$Err(
						author$project$Data$GetError$InvalidAccessToken(body));
				} else {
					var statusCode = _n1;
					return elm$core$Result$Err(
						author$project$Data$GetError$Other(
							'Status ' + (elm$core$String$fromInt(statusCode) + (': ' + body))));
				}
			default:
				var metadata = response.a;
				var body = response.b;
				var _n2 = A2(elm$json$Json$Decode$decodeString, decoder, body);
				if (!_n2.$) {
					var value = _n2.a;
					return elm$core$Result$Ok(value);
				} else {
					var err = _n2.a;
					return elm$core$Result$Err(
						author$project$Data$GetError$Other(
							'Bad response: ' + elm$json$Json$Decode$errorToString(err)));
				}
		}
	});
var elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			elm$core$Basics$identity,
			A2(elm$core$Basics$composeR, toResult, toMsg));
	});
var author$project$Data$GetError$expect = F2(
	function (decoder, toMsg) {
		return A2(
			elm$http$Http$expectStringResponse,
			toMsg,
			author$project$Data$GetError$result(decoder));
	});
var elm$core$Array$repeat = F2(
	function (n, e) {
		return A2(
			elm$core$Array$initialize,
			n,
			function (_n0) {
				return e;
			});
	});
var author$project$Data$Board$empty = F2(
	function (boardWidth, boardHeight) {
		return A2(
			elm$core$Array$repeat,
			boardHeight,
			A2(elm$core$Array$repeat, boardWidth, author$project$Data$Instruction$NoOp));
	});
var elm$core$Elm$JsArray$unsafeSet = _JsArray_unsafeSet;
var elm$core$Array$setHelp = F4(
	function (shift, index, value, tree) {
		var pos = elm$core$Array$bitMask & (index >>> shift);
		var _n0 = A2(elm$core$Elm$JsArray$unsafeGet, pos, tree);
		if (!_n0.$) {
			var subTree = _n0.a;
			var newSub = A4(elm$core$Array$setHelp, shift - elm$core$Array$shiftStep, index, value, subTree);
			return A3(
				elm$core$Elm$JsArray$unsafeSet,
				pos,
				elm$core$Array$SubTree(newSub),
				tree);
		} else {
			var values = _n0.a;
			var newLeaf = A3(elm$core$Elm$JsArray$unsafeSet, elm$core$Array$bitMask & index, value, values);
			return A3(
				elm$core$Elm$JsArray$unsafeSet,
				pos,
				elm$core$Array$Leaf(newLeaf),
				tree);
		}
	});
var elm$core$Array$set = F3(
	function (index, value, array) {
		var len = array.a;
		var startShift = array.b;
		var tree = array.c;
		var tail = array.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? array : ((_Utils_cmp(
			index,
			elm$core$Array$tailIndex(len)) > -1) ? A4(
			elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			tree,
			A3(elm$core$Elm$JsArray$unsafeSet, elm$core$Array$bitMask & index, value, tail)) : A4(
			elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			A4(elm$core$Array$setHelp, startShift, index, value, tree),
			tail));
	});
var author$project$Data$Board$set = F3(
	function (_n0, instruction, board) {
		var x = _n0.eR;
		var y = _n0.eS;
		var _n1 = A2(elm$core$Array$get, y, board);
		if (!_n1.$) {
			var row = _n1.a;
			return A3(
				elm$core$Array$set,
				y,
				A3(elm$core$Array$set, x, instruction, row),
				board);
		} else {
			return board;
		}
	});
var author$project$Data$Board$withInstructions = F2(
	function (boardInstructions, board) {
		var setInstruction = function (_n0) {
			var position = _n0.ep;
			var instruction = _n0.cU;
			return A2(author$project$Data$Board$set, position, instruction);
		};
		return A3(elm$core$List$foldl, setInstruction, board, boardInstructions);
	});
var author$project$Data$Direction$Down = 3;
var author$project$Data$Direction$Up = 1;
var elm$json$Json$Decode$andThen = _Json_andThen;
var elm$json$Json$Decode$fail = _Json_fail;
var elm$json$Json$Decode$string = _Json_decodeString;
var elm$json$Json$Decode$succeed = _Json_succeed;
var author$project$Data$Direction$decoder = function () {
	var stringToDirection = function (stringValue) {
		switch (stringValue) {
			case 'Left':
				return elm$json$Json$Decode$succeed(0);
			case 'Up':
				return elm$json$Json$Decode$succeed(1);
			case 'Right':
				return elm$json$Json$Decode$succeed(2);
			case 'Down':
				return elm$json$Json$Decode$succeed(3);
			default:
				return elm$json$Json$Decode$fail('\'' + (stringValue + '\' could not be mapped to a Direction'));
		}
	};
	return A2(elm$json$Json$Decode$andThen, stringToDirection, elm$json$Json$Decode$string);
}();
var author$project$Data$Instruction$Branch = F2(
	function (a, b) {
		return {$: 23, a: a, b: b};
	});
var author$project$Data$Instruction$ChangeDirection = function (a) {
	return {$: 1, a: a};
};
var author$project$Data$Instruction$Exception = function (a) {
	return {$: 26, a: a};
};
var author$project$Data$Instruction$PushToStack = function (a) {
	return {$: 2, a: a};
};
var author$project$Data$Int16$Int16 = elm$core$Basics$identity;
var elm$core$Basics$modBy = _Basics_modBy;
var elm$core$Basics$pow = _Basics_pow;
var author$project$Data$Int16$constructor = function (_int) {
	return A3(
		elm_community$basics_extra$Basics$Extra$flip,
		elm$core$Basics$sub,
		A2(elm$core$Basics$pow, 2, 15),
		A2(
			elm$core$Basics$modBy,
			A2(elm$core$Basics$pow, 2, 16),
			A2(elm$core$Basics$pow, 2, 15) + _int));
};
var elm$json$Json$Decode$int = _Json_decodeInt;
var elm$json$Json$Decode$map = _Json_map1;
var author$project$Data$Int16$decoder = A2(elm$json$Json$Decode$map, author$project$Data$Int16$constructor, elm$json$Json$Decode$int);
var elm$json$Json$Decode$field = _Json_decodeField;
var author$project$Data$Instruction$decoder = function () {
	var tagToDecoder = function (tag) {
		switch (tag) {
			case 'NoOp':
				return elm$json$Json$Decode$succeed(author$project$Data$Instruction$NoOp);
			case 'ChangeDirection':
				return A2(
					elm$json$Json$Decode$map,
					author$project$Data$Instruction$ChangeDirection,
					A2(elm$json$Json$Decode$field, 'direction', author$project$Data$Direction$decoder));
			case 'PushToStack':
				return A2(
					elm$json$Json$Decode$map,
					author$project$Data$Instruction$PushToStack,
					A2(elm$json$Json$Decode$field, 'value', author$project$Data$Int16$decoder));
			case 'PopFromStack':
				return elm$json$Json$Decode$succeed(author$project$Data$Instruction$PopFromStack);
			case 'JumpForward':
				return elm$json$Json$Decode$succeed(author$project$Data$Instruction$JumpForward);
			case 'Jump':
				return elm$json$Json$Decode$succeed(author$project$Data$Instruction$JumpForward);
			case 'Duplicate':
				return elm$json$Json$Decode$succeed(author$project$Data$Instruction$Duplicate);
			case 'Swap':
				return elm$json$Json$Decode$succeed(author$project$Data$Instruction$Swap);
			case 'Negate':
				return elm$json$Json$Decode$succeed(author$project$Data$Instruction$Negate);
			case 'Abs':
				return elm$json$Json$Decode$succeed(author$project$Data$Instruction$Abs);
			case 'Not':
				return elm$json$Json$Decode$succeed(author$project$Data$Instruction$Not);
			case 'Increment':
				return elm$json$Json$Decode$succeed(author$project$Data$Instruction$Increment);
			case 'Decrement':
				return elm$json$Json$Decode$succeed(author$project$Data$Instruction$Decrement);
			case 'Add':
				return elm$json$Json$Decode$succeed(author$project$Data$Instruction$Add);
			case 'Subtract':
				return elm$json$Json$Decode$succeed(author$project$Data$Instruction$Subtract);
			case 'Multiply':
				return elm$json$Json$Decode$succeed(author$project$Data$Instruction$Multiply);
			case 'Divide':
				return elm$json$Json$Decode$succeed(author$project$Data$Instruction$Divide);
			case 'Equals':
				return elm$json$Json$Decode$succeed(author$project$Data$Instruction$Equals);
			case 'CompareLessThan':
				return elm$json$Json$Decode$succeed(author$project$Data$Instruction$CompareLessThan);
			case 'And':
				return elm$json$Json$Decode$succeed(author$project$Data$Instruction$And);
			case 'Or':
				return elm$json$Json$Decode$succeed(author$project$Data$Instruction$Or);
			case 'XOr':
				return elm$json$Json$Decode$succeed(author$project$Data$Instruction$XOr);
			case 'Read':
				return elm$json$Json$Decode$succeed(author$project$Data$Instruction$Read);
			case 'Print':
				return elm$json$Json$Decode$succeed(author$project$Data$Instruction$Print);
			case 'Branch':
				return A2(
					elm$json$Json$Decode$andThen,
					function (trueDirection) {
						return A2(
							elm$json$Json$Decode$andThen,
							function (falseDirection) {
								return elm$json$Json$Decode$succeed(
									A2(author$project$Data$Instruction$Branch, trueDirection, falseDirection));
							},
							A2(elm$json$Json$Decode$field, 'falseDirection', author$project$Data$Direction$decoder));
					},
					A2(elm$json$Json$Decode$field, 'trueDirection', author$project$Data$Direction$decoder));
			case 'Terminate':
				return elm$json$Json$Decode$succeed(author$project$Data$Instruction$Terminate);
			case 'SendToBottom':
				return elm$json$Json$Decode$succeed(author$project$Data$Instruction$SendToBottom);
			case 'Exception':
				return A2(
					elm$json$Json$Decode$map,
					author$project$Data$Instruction$Exception,
					A2(elm$json$Json$Decode$field, 'exceptionMessage', elm$json$Json$Decode$string));
			default:
				return elm$json$Json$Decode$fail('Unknown instruction tag: ' + tag);
		}
	};
	return A2(
		elm$json$Json$Decode$andThen,
		tagToDecoder,
		A2(elm$json$Json$Decode$field, 'tag', elm$json$Json$Decode$string));
}();
var author$project$Data$Position$decoder = A2(
	elm$json$Json$Decode$andThen,
	function (x) {
		return A2(
			elm$json$Json$Decode$andThen,
			function (y) {
				return elm$json$Json$Decode$succeed(
					{eR: x, eS: y});
			},
			A2(elm$json$Json$Decode$field, 'y', elm$json$Json$Decode$int));
	},
	A2(elm$json$Json$Decode$field, 'x', elm$json$Json$Decode$int));
var author$project$Data$BoardInstruction$decoder = A2(
	elm$json$Json$Decode$andThen,
	function (position) {
		return A2(
			elm$json$Json$Decode$andThen,
			function (instruction) {
				return elm$json$Json$Decode$succeed(
					{cU: instruction, ep: position});
			},
			A2(elm$json$Json$Decode$field, 'instruction', author$project$Data$Instruction$decoder));
	},
	A2(elm$json$Json$Decode$field, 'position', author$project$Data$Position$decoder));
var elm$core$Basics$not = _Basics_not;
var elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			elm$core$List$any,
			A2(elm$core$Basics$composeL, elm$core$Basics$not, isOkay),
			list);
	});
var elm$json$Json$Decode$list = _Json_decodeList;
var author$project$Data$Board$decoder = function () {
	var verifyBoard = function (board) {
		var maybeWidth = function (widths) {
			if (!widths.b) {
				return elm$core$Maybe$Just(0);
			} else {
				var rowWidth = widths.a;
				var tail = widths.b;
				return A2(
					elm$core$List$all,
					elm$core$Basics$eq(rowWidth),
					tail) ? elm$core$Maybe$Just(rowWidth) : elm$core$Maybe$Nothing;
			}
		}(
			A2(
				elm$core$List$map,
				elm$core$Array$length,
				elm$core$Array$toList(board)));
		var boardHeight = elm$core$Array$length(board);
		var _n0 = _Utils_Tuple2(maybeWidth, boardHeight);
		if (_n0.a.$ === 1) {
			var _n1 = _n0.a;
			return elm$json$Json$Decode$fail('All rows must have the same length');
		} else {
			if (!_n0.b) {
				return elm$json$Json$Decode$fail('Board cannot be empty');
			} else {
				if (!_n0.a.a) {
					return elm$json$Json$Decode$fail('Board cannot be empty');
				} else {
					return elm$json$Json$Decode$succeed(board);
				}
			}
		}
	};
	return A2(
		elm$json$Json$Decode$andThen,
		function (decodedWidth) {
			return A2(
				elm$json$Json$Decode$andThen,
				function (decodedHeight) {
					return A2(
						elm$json$Json$Decode$andThen,
						function (decodedBoardInstructions) {
							return verifyBoard(
								A2(
									author$project$Data$Board$withInstructions,
									decodedBoardInstructions,
									A2(author$project$Data$Board$empty, decodedWidth, decodedHeight)));
						},
						A2(
							elm$json$Json$Decode$field,
							'instructions',
							elm$json$Json$Decode$list(author$project$Data$BoardInstruction$decoder)));
				},
				A2(elm$json$Json$Decode$field, 'height', elm$json$Json$Decode$int));
		},
		A2(elm$json$Json$Decode$field, 'width', elm$json$Json$Decode$int));
}();
var author$project$Data$CampaignId$decoder = elm$json$Json$Decode$string;
var author$project$Data$InstructionTool$PushValueToStack = function (a) {
	return {$: 3, a: a};
};
var author$project$Data$InstructionTool$decoder = function () {
	var tagToDecoder = function (tag) {
		switch (tag) {
			case 'JustInstruction':
				return A2(
					elm$json$Json$Decode$map,
					author$project$Data$InstructionTool$JustInstruction,
					A2(elm$json$Json$Decode$field, 'instruction', author$project$Data$Instruction$decoder));
			case 'ChangeAnyDirection':
				return elm$json$Json$Decode$succeed(
					author$project$Data$InstructionTool$ChangeAnyDirection(0));
			case 'BranchAnyDirection':
				return elm$json$Json$Decode$succeed(
					A2(author$project$Data$InstructionTool$BranchAnyDirection, 0, 2));
			case 'PushValueToStack':
				return elm$json$Json$Decode$succeed(
					author$project$Data$InstructionTool$PushValueToStack('0'));
			case 'Exception':
				return elm$json$Json$Decode$succeed(
					author$project$Data$InstructionTool$Exception(''));
			default:
				return elm$json$Json$Decode$fail('Unknown instruction tool tag: ' + tag);
		}
	};
	return A2(
		elm$json$Json$Decode$andThen,
		tagToDecoder,
		A2(elm$json$Json$Decode$field, 'tag', elm$json$Json$Decode$string));
}();
var author$project$Data$Suite$decoder = A2(
	elm$json$Json$Decode$andThen,
	function (input) {
		return A2(
			elm$json$Json$Decode$andThen,
			function (output) {
				return elm$json$Json$Decode$succeed(
					{hU: input, iu: output});
			},
			A2(
				elm$json$Json$Decode$field,
				'output',
				elm$json$Json$Decode$list(author$project$Data$Int16$decoder)));
	},
	A2(
		elm$json$Json$Decode$field,
		'input',
		elm$json$Json$Decode$list(author$project$Data$Int16$decoder)));
var elm$json$Json$Decode$array = _Json_decodeArray;
var author$project$Data$Level$decoder = function () {
	var v2 = A2(
		elm$json$Json$Decode$andThen,
		function (id) {
			return A2(
				elm$json$Json$Decode$andThen,
				function (index) {
					return A2(
						elm$json$Json$Decode$andThen,
						function (campaignId) {
							return A2(
								elm$json$Json$Decode$andThen,
								function (name) {
									return A2(
										elm$json$Json$Decode$andThen,
										function (description) {
											return A2(
												elm$json$Json$Decode$andThen,
												function (suites) {
													return A2(
														elm$json$Json$Decode$andThen,
														function (initialBoard) {
															return A2(
																elm$json$Json$Decode$andThen,
																function (instructionTools) {
																	return elm$json$Json$Decode$succeed(
																		{bW: campaignId, bq: description, b6: id, b7: index, hT: initialBoard, aO: instructionTools, bA: name, bM: suites});
																},
																A2(
																	elm$json$Json$Decode$field,
																	'instructionTools',
																	elm$json$Json$Decode$array(author$project$Data$InstructionTool$decoder)));
														},
														A2(elm$json$Json$Decode$field, 'initialBoard', author$project$Data$Board$decoder));
												},
												A2(
													elm$json$Json$Decode$field,
													'suites',
													elm$json$Json$Decode$list(author$project$Data$Suite$decoder)));
										},
										A2(
											elm$json$Json$Decode$field,
											'description',
											elm$json$Json$Decode$list(elm$json$Json$Decode$string)));
								},
								A2(elm$json$Json$Decode$field, 'name', elm$json$Json$Decode$string));
						},
						A2(elm$json$Json$Decode$field, 'campaignId', author$project$Data$CampaignId$decoder));
				},
				A2(elm$json$Json$Decode$field, 'index', elm$json$Json$Decode$int));
		},
		A2(elm$json$Json$Decode$field, 'id', elm$json$Json$Decode$string));
	var v1 = A2(
		elm$json$Json$Decode$andThen,
		function (id) {
			return A2(
				elm$json$Json$Decode$andThen,
				function (index) {
					return A2(
						elm$json$Json$Decode$andThen,
						function (campaignId) {
							return A2(
								elm$json$Json$Decode$andThen,
								function (name) {
									return A2(
										elm$json$Json$Decode$andThen,
										function (description) {
											return A2(
												elm$json$Json$Decode$andThen,
												function (io) {
													return A2(
														elm$json$Json$Decode$andThen,
														function (initialBoard) {
															return A2(
																elm$json$Json$Decode$andThen,
																function (instructionTools) {
																	return elm$json$Json$Decode$succeed(
																		{
																			bW: campaignId,
																			bq: description,
																			b6: id,
																			b7: index,
																			hT: initialBoard,
																			aO: instructionTools,
																			bA: name,
																			bM: _List_fromArray(
																				[io])
																		});
																},
																A2(
																	elm$json$Json$Decode$field,
																	'instructionTools',
																	elm$json$Json$Decode$array(author$project$Data$InstructionTool$decoder)));
														},
														A2(elm$json$Json$Decode$field, 'initialBoard', author$project$Data$Board$decoder));
												},
												A2(elm$json$Json$Decode$field, 'io', author$project$Data$Suite$decoder));
										},
										A2(
											elm$json$Json$Decode$field,
											'description',
											elm$json$Json$Decode$list(elm$json$Json$Decode$string)));
								},
								A2(elm$json$Json$Decode$field, 'name', elm$json$Json$Decode$string));
						},
						A2(elm$json$Json$Decode$field, 'campaignId', author$project$Data$CampaignId$decoder));
				},
				A2(elm$json$Json$Decode$field, 'index', elm$json$Json$Decode$int));
		},
		A2(elm$json$Json$Decode$field, 'id', elm$json$Json$Decode$string));
	return A2(
		elm$json$Json$Decode$andThen,
		function (version) {
			switch (version) {
				case 1:
					return v1;
				case 2:
					return v2;
				default:
					return elm$json$Json$Decode$fail(
						'Unknown level decoder version: ' + elm$core$String$fromInt(version));
			}
		},
		A2(elm$json$Json$Decode$field, 'version', elm$json$Json$Decode$int));
}();
var author$project$Data$Blueprint$loadAllFromServer = F2(
	function (accessToken, toMsg) {
		return A2(
			author$project$Api$GCP$request,
			A2(
				author$project$Data$GetError$expect,
				elm$json$Json$Decode$list(author$project$Data$Level$decoder),
				toMsg),
			A2(
				author$project$Api$GCP$withAccessToken,
				accessToken,
				A2(author$project$Api$GCP$withPath, author$project$Data$Blueprint$path, author$project$Api$GCP$get)));
	});
var krisajenkins$remotedata$RemoteData$isNotAsked = function (data) {
	if (!data.$) {
		return true;
	} else {
		return false;
	}
};
var author$project$Data$Cache$isNotAsked = F2(
	function (key, cache) {
		return krisajenkins$remotedata$RemoteData$isNotAsked(
			A2(author$project$Data$Cache$get, key, cache));
	});
var author$project$Data$Campaign$empty = function (campaignId) {
	return {b6: campaignId, aQ: _List_Nil};
};
var author$project$Data$Campaign$localStorageKey = function (campaignId) {
	return A2(
		elm$core$String$join,
		'.',
		_List_fromArray(
			['campaigns', campaignId]));
};
var author$project$Data$Campaign$loadFromLocalStorage = function (campaignId) {
	return author$project$Ports$LocalStorage$storageGetItem(
		author$project$Data$Campaign$localStorageKey(campaignId));
};
var author$project$Data$CampaignId$encode = elm$json$Json$Encode$string;
var author$project$Data$LevelId$encode = elm$json$Json$Encode$string;
var elm$json$Json$Encode$int = _Json_wrap;
var elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			elm$core$List$foldl,
			F2(
				function (_n0, obj) {
					var k = _n0.a;
					var v = _n0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var author$project$Data$Campaign$encode = function (campaign) {
	return elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'version',
				elm$json$Json$Encode$int(1)),
				_Utils_Tuple2(
				'id',
				author$project$Data$CampaignId$encode(campaign.b6)),
				_Utils_Tuple2(
				'levelIds',
				A2(elm$json$Json$Encode$list, author$project$Data$LevelId$encode, campaign.aQ))
			]));
};
var author$project$Ports$LocalStorage$storageSetItem = _Platform_outgoingPort(
	'storageSetItem',
	function ($) {
		var a = $.a;
		var b = $.b;
		return A2(
			elm$json$Json$Encode$list,
			elm$core$Basics$identity,
			_List_fromArray(
				[
					elm$json$Json$Encode$string(a),
					elm$core$Basics$identity(b)
				]));
	});
var author$project$Data$Campaign$saveToLocalStorage = function (campaign) {
	return author$project$Ports$LocalStorage$storageSetItem(
		_Utils_Tuple2(
			author$project$Data$Campaign$localStorageKey(campaign.b6),
			author$project$Data$Campaign$encode(campaign)));
};
var author$project$Data$CampaignId$blueprints = 'blueprints';
var author$project$Data$Session$setCampaignCache = F2(
	function (session, cache) {
		return _Utils_update(
			session,
			{aG: cache});
	});
var author$project$Data$Session$campaignLoading = F2(
	function (campaignId, session) {
		return A2(
			author$project$Data$Session$setCampaignCache,
			session,
			A2(author$project$Data$Cache$loading, campaignId, session.aG));
	});
var author$project$Data$User$getToken = function (user) {
	return user.eT;
};
var author$project$Data$Session$getAccessToken = A2(
	elm$core$Basics$composeR,
	function ($) {
		return $.bQ;
	},
	author$project$Data$User$getToken);
var krisajenkins$remotedata$RemoteData$Success = function (a) {
	return {$: 3, a: a};
};
var author$project$Data$Cache$withValue = F2(
	function (key, value) {
		return A2(
			author$project$Data$Cache$insertInternal,
			key,
			krisajenkins$remotedata$RemoteData$Success(value));
	});
var author$project$Data$Session$withCampaign = F2(
	function (campaign, session) {
		return A2(
			author$project$Data$Session$setCampaignCache,
			session,
			A3(author$project$Data$Cache$withValue, campaign.b6, campaign, session.aG));
	});
var author$project$Page$Blueprints$GotLoadBlueprintsResponse = function (a) {
	return {$: 6, a: a};
};
var author$project$Page$Blueprints$campaignId = author$project$Data$CampaignId$blueprints;
var author$project$Page$Blueprints$setSession = F2(
	function (model, session) {
		return _Utils_update(
			model,
			{f: session});
	});
var author$project$Page$Blueprints$load = function (model) {
	var _n0 = A2(author$project$Data$Cache$get, author$project$Data$CampaignId$blueprints, model.f.aG);
	switch (_n0.$) {
		case 0:
			var _n1 = author$project$Data$Session$getAccessToken(model.f);
			if (!_n1.$) {
				var accessToken = _n1.a;
				return _Utils_Tuple2(
					A2(
						author$project$Page$Blueprints$setSession,
						model,
						A2(author$project$Data$Session$campaignLoading, author$project$Page$Blueprints$campaignId, model.f)),
					A2(author$project$Data$Blueprint$loadAllFromServer, accessToken, author$project$Page$Blueprints$GotLoadBlueprintsResponse));
			} else {
				return _Utils_Tuple2(
					A2(
						author$project$Page$Blueprints$setSession,
						model,
						A2(author$project$Data$Session$campaignLoading, author$project$Page$Blueprints$campaignId, model.f)),
					author$project$Data$Campaign$loadFromLocalStorage(author$project$Page$Blueprints$campaignId));
			}
		case 2:
			var error = _n0.a;
			var campaign = author$project$Data$Campaign$empty(author$project$Page$Blueprints$campaignId);
			return _Utils_Tuple2(
				A2(
					author$project$Page$Blueprints$setSession,
					model,
					A2(author$project$Data$Session$withCampaign, campaign, model.f)),
				author$project$Data$Campaign$saveToLocalStorage(campaign));
		case 3:
			var campaign = _n0.a;
			var notAskedLevelIds = A2(
				elm$core$List$filter,
				A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Data$Cache$isNotAsked, model.f.as),
				campaign.aQ);
			return _Utils_Tuple2(
				A2(
					author$project$Page$Blueprints$setSession,
					model,
					A3(elm$core$List$foldl, author$project$Data$Session$levelLoading, model.f, notAskedLevelIds)),
				elm$core$Platform$Cmd$batch(
					A2(elm$core$List$map, author$project$Data$Level$loadFromLocalStorage, notAskedLevelIds)));
		default:
			return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
	}
};
var author$project$Api$GCP$withQueryParameters = F2(
	function (queryParameters, builder) {
		return _Utils_update(
			builder,
			{dc: queryParameters});
	});
var elm$url$Url$percentEncode = _Url_percentEncode;
var elm$url$Url$Builder$QueryParameter = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var elm$url$Url$Builder$string = F2(
	function (key, value) {
		return A2(
			elm$url$Url$Builder$QueryParameter,
			elm$url$Url$percentEncode(key),
			elm$url$Url$percentEncode(value));
	});
var author$project$Data$Level$loadFromServerByCampaignId = F2(
	function (toMsg, campaignId) {
		return A2(
			author$project$Api$GCP$request,
			A2(
				author$project$Data$GetError$expect,
				elm$json$Json$Decode$list(author$project$Data$Level$decoder),
				toMsg),
			A2(
				author$project$Api$GCP$withQueryParameters,
				_List_fromArray(
					[
						A2(elm$url$Url$Builder$string, 'campaignId', campaignId)
					]),
				A2(
					author$project$Api$GCP$withPath,
					_List_fromArray(
						['levels']),
					author$project$Api$GCP$get)));
	});
var author$project$Data$Session$withCampaignCache = F2(
	function (cache, session) {
		return _Utils_update(
			session,
			{aG: cache});
	});
var author$project$SessionUpdate$GotLoadLevelsByCampaignIdResponse = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var author$project$Loaders$loadCampaignByCampaignId = F2(
	function (campaignId, session) {
		var _n0 = A2(author$project$Data$Cache$get, campaignId, session.aG);
		if (!_n0.$) {
			var loadCampaignRemotely = A2(
				author$project$Data$Level$loadFromServerByCampaignId,
				author$project$SessionUpdate$GotLoadLevelsByCampaignIdResponse(campaignId),
				campaignId);
			return _Utils_Tuple2(
				A3(
					elm_community$basics_extra$Basics$Extra$flip,
					author$project$Data$Session$withCampaignCache,
					session,
					A2(author$project$Data$Cache$loading, campaignId, session.aG)),
				loadCampaignRemotely);
		} else {
			return _Utils_Tuple2(session, elm$core$Platform$Cmd$none);
		}
	});
var author$project$Data$DraftId$decoder = elm$json$Json$Decode$string;
var author$project$Data$History$singleton = function (a) {
	return {x: a, y: _List_Nil, u: _List_Nil};
};
var author$project$Data$LevelId$decoder = elm$json$Json$Decode$string;
var author$project$Data$Draft$decoder = function () {
	var v1 = A2(
		elm$json$Json$Decode$andThen,
		function (id) {
			return A2(
				elm$json$Json$Decode$andThen,
				function (levelId) {
					return A2(
						elm$json$Json$Decode$andThen,
						function (board) {
							return elm$json$Json$Decode$succeed(
								{
									G: author$project$Data$History$singleton(board),
									b6: id,
									a8: levelId
								});
						},
						A2(elm$json$Json$Decode$field, 'board', author$project$Data$Board$decoder));
				},
				A2(elm$json$Json$Decode$field, 'levelId', author$project$Data$LevelId$decoder));
		},
		A2(elm$json$Json$Decode$field, 'id', author$project$Data$DraftId$decoder));
	return A2(
		elm$json$Json$Decode$andThen,
		function (version) {
			if (version === 1) {
				return v1;
			} else {
				return elm$json$Json$Decode$fail(
					'Unknown draft decoder version ' + elm$core$String$fromInt(version));
			}
		},
		A2(elm$json$Json$Decode$field, 'version', elm$json$Json$Decode$int));
}();
var author$project$Data$Draft$loadFromServerByLevelId = F3(
	function (toMsg, accessToken, levelId) {
		return A2(
			author$project$Api$GCP$request,
			A2(
				author$project$Data$GetError$expect,
				elm$json$Json$Decode$list(author$project$Data$Draft$decoder),
				toMsg),
			A2(
				author$project$Api$GCP$withAccessToken,
				accessToken,
				A2(
					author$project$Api$GCP$withQueryParameters,
					_List_fromArray(
						[
							A2(elm$url$Url$Builder$string, 'levelId', levelId)
						]),
					A2(
						author$project$Api$GCP$withPath,
						_List_fromArray(
							['drafts']),
						author$project$Api$GCP$get))));
	});
var author$project$Data$DraftBook$localStorageKey = function (levelId) {
	return A2(
		elm$core$String$join,
		'.',
		_List_fromArray(
			['levels', levelId, 'draftBook']));
};
var author$project$Data$DraftBook$loadFromLocalStorage = function (levelId) {
	var key = author$project$Data$DraftBook$localStorageKey(levelId);
	return author$project$Ports$LocalStorage$storageGetItem(key);
};
var author$project$Data$Session$withDraftBookCache = F2(
	function (cache, session) {
		return _Utils_update(
			session,
			{aJ: cache});
	});
var author$project$SessionUpdate$GotLoadDraftsByLevelIdResponse = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var author$project$Loaders$loadDraftBookByLevelId = F2(
	function (levelId, session) {
		var _n0 = A2(author$project$Data$Cache$get, levelId, session.aJ);
		if (!_n0.$) {
			return _Utils_Tuple2(
				A3(
					elm_community$basics_extra$Basics$Extra$flip,
					author$project$Data$Session$withDraftBookCache,
					session,
					A2(author$project$Data$Cache$loading, levelId, session.aJ)),
				function () {
					var _n1 = author$project$Data$Session$getAccessToken(session);
					if (!_n1.$) {
						var accessToken = _n1.a;
						return A3(
							author$project$Data$Draft$loadFromServerByLevelId,
							author$project$SessionUpdate$GotLoadDraftsByLevelIdResponse(levelId),
							accessToken,
							levelId);
					} else {
						return author$project$Data$DraftBook$loadFromLocalStorage(levelId);
					}
				}());
		} else {
			return _Utils_Tuple2(session, elm$core$Platform$Cmd$none);
		}
	});
var author$project$Data$DraftId$toString = function (id) {
	return id;
};
var author$project$Data$Draft$localStorageKey = function (draftId) {
	return A2(
		elm$core$String$join,
		'.',
		_List_fromArray(
			[
				'drafts',
				author$project$Data$DraftId$toString(draftId)
			]));
};
var author$project$Data$Draft$loadFromLocalStorage = function (draftId) {
	var key = author$project$Data$Draft$localStorageKey(draftId);
	return author$project$Ports$LocalStorage$storageGetItem(key);
};
var author$project$Data$RemoteCache$withLocalLoading = F2(
	function (key, cache) {
		return _Utils_update(
			cache,
			{
				aj: A2(author$project$Data$Cache$loading, key, cache.aj)
			});
	});
var author$project$Data$Session$withDraftCache = F2(
	function (cache, session) {
		return _Utils_update(
			session,
			{dC: cache});
	});
var author$project$Loaders$loadDraftsByDraftIds = F2(
	function (draftIds, session) {
		var notAskedDraftIds = A2(
			elm$core$List$filter,
			A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Data$Cache$isNotAsked, session.dC.aj),
			draftIds);
		return _Utils_Tuple2(
			A3(
				elm_community$basics_extra$Basics$Extra$flip,
				author$project$Data$Session$withDraftCache,
				session,
				A3(elm$core$List$foldl, author$project$Data$RemoteCache$withLocalLoading, session.dC, notAskedDraftIds)),
			elm$core$Platform$Cmd$batch(
				A2(elm$core$List$map, author$project$Data$Draft$loadFromLocalStorage, notAskedDraftIds)));
	});
var krisajenkins$remotedata$RemoteData$Failure = function (a) {
	return {$: 2, a: a};
};
var krisajenkins$remotedata$RemoteData$map = F2(
	function (f, data) {
		switch (data.$) {
			case 3:
				var value = data.a;
				return krisajenkins$remotedata$RemoteData$Success(
					f(value));
			case 1:
				return krisajenkins$remotedata$RemoteData$Loading;
			case 0:
				return krisajenkins$remotedata$RemoteData$NotAsked;
			default:
				var error = data.a;
				return krisajenkins$remotedata$RemoteData$Failure(error);
		}
	});
var krisajenkins$remotedata$RemoteData$withDefault = F2(
	function (_default, data) {
		if (data.$ === 3) {
			var x = data.a;
			return x;
		} else {
			return _default;
		}
	});
var krisajenkins$remotedata$RemoteData$toMaybe = A2(
	elm$core$Basics$composeR,
	krisajenkins$remotedata$RemoteData$map(elm$core$Maybe$Just),
	krisajenkins$remotedata$RemoteData$withDefault(elm$core$Maybe$Nothing));
var author$project$Loaders$loadDraftsByLevelId = F2(
	function (levelId, session) {
		return A2(
			author$project$Extra$Cmd$fold,
			_List_fromArray(
				[
					author$project$Loaders$loadDraftBookByLevelId(levelId),
					function (s) {
					var _n0 = krisajenkins$remotedata$RemoteData$toMaybe(
						A2(author$project$Data$Cache$get, levelId, s.aJ));
					if (!_n0.$) {
						var draftIds = _n0.a.aK;
						return A2(
							author$project$Loaders$loadDraftsByDraftIds,
							elm$core$Set$toList(draftIds),
							s);
					} else {
						return _Utils_Tuple2(s, elm$core$Platform$Cmd$none);
					}
				}
				]),
			session);
	});
var elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var elm$json$Json$Decode$index = _Json_decodeIndex;
var elm$json$Json$Decode$value = _Json_decodeValue;
var author$project$Extra$Decode$tuple = F2(
	function (aDecoder, bDecoder) {
		return A2(
			elm$json$Json$Decode$andThen,
			function (length) {
				if (length === 2) {
					return A2(
						elm$json$Json$Decode$andThen,
						function (a) {
							return A2(
								elm$json$Json$Decode$andThen,
								A2(
									elm$core$Basics$composeR,
									elm$core$Tuple$pair(a),
									elm$json$Json$Decode$succeed),
								A2(elm$json$Json$Decode$index, 1, bDecoder));
						},
						A2(elm$json$Json$Decode$index, 0, aDecoder));
				} else {
					return elm$json$Json$Decode$fail(
						'Invalid length: expected 2 but was ' + elm$core$String$fromInt(length));
				}
			},
			A2(
				elm$json$Json$Decode$map,
				elm$core$List$length,
				elm$json$Json$Decode$list(elm$json$Json$Decode$value)));
	});
var elm$core$Dict$fromList = function (assocs) {
	return A3(
		elm$core$List$foldl,
		F2(
			function (_n0, dict) {
				var key = _n0.a;
				var value = _n0.b;
				return A3(elm$core$Dict$insert, key, value, dict);
			}),
		elm$core$Dict$empty,
		assocs);
};
var author$project$Data$HighScore$decoder = function () {
	var intIntDictDecoder = A2(
		elm$json$Json$Decode$map,
		elm$core$Dict$fromList,
		elm$json$Json$Decode$list(
			A2(author$project$Extra$Decode$tuple, elm$json$Json$Decode$int, elm$json$Json$Decode$int)));
	var v1 = A2(
		elm$json$Json$Decode$andThen,
		function (numberOfSteps) {
			return A2(
				elm$json$Json$Decode$andThen,
				function (numberOfInstructions) {
					return A2(
						elm$json$Json$Decode$andThen,
						function (levelId) {
							return elm$json$Json$Decode$succeed(
								{a8: levelId, bc: numberOfInstructions, bd: numberOfSteps});
						},
						A2(elm$json$Json$Decode$field, 'levelId', author$project$Data$LevelId$decoder));
				},
				A2(elm$json$Json$Decode$field, 'numberOfInstructions', intIntDictDecoder));
		},
		A2(elm$json$Json$Decode$field, 'numberOfSteps', intIntDictDecoder));
	return A2(
		elm$json$Json$Decode$andThen,
		function (version) {
			if (version === 1) {
				return v1;
			} else {
				return elm$json$Json$Decode$fail(
					'Unknown high score decoder version ' + elm$core$String$fromInt(version));
			}
		},
		A2(elm$json$Json$Decode$field, 'version', elm$json$Json$Decode$int));
}();
var author$project$Data$HighScore$loadFromServer = F2(
	function (levelId, toMsg) {
		return A2(
			author$project$Api$GCP$request,
			A2(author$project$Data$GetError$expect, author$project$Data$HighScore$decoder, toMsg),
			A2(
				author$project$Api$GCP$withQueryParameters,
				_List_fromArray(
					[
						A2(elm$url$Url$Builder$string, 'levelId', levelId)
					]),
				A2(
					author$project$Api$GCP$withPath,
					_List_fromArray(
						['highScores']),
					author$project$Api$GCP$get)));
	});
var author$project$Data$Session$withHighScoreCache = F2(
	function (cache, session) {
		return _Utils_update(
			session,
			{U: cache});
	});
var author$project$SessionUpdate$GotLoadHighScoreResponse = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var author$project$Loaders$loadHighScoreByLevelId = F2(
	function (levelId, session) {
		var _n0 = A2(author$project$Data$Cache$get, levelId, session.U);
		if (!_n0.$) {
			return _Utils_Tuple2(
				A3(
					elm_community$basics_extra$Basics$Extra$flip,
					author$project$Data$Session$withHighScoreCache,
					session,
					A2(author$project$Data$Cache$loading, levelId, session.U)),
				A2(
					author$project$Data$HighScore$loadFromServer,
					levelId,
					author$project$SessionUpdate$GotLoadHighScoreResponse(levelId)));
		} else {
			return _Utils_Tuple2(session, elm$core$Platform$Cmd$none);
		}
	});
var author$project$Data$Level$loadFromServer = F2(
	function (toMsg, levelId) {
		return A2(
			author$project$Api$GCP$request,
			A2(author$project$Data$GetError$expect, author$project$Data$Level$decoder, toMsg),
			A2(
				author$project$Api$GCP$withQueryParameters,
				_List_fromArray(
					[
						A2(elm$url$Url$Builder$string, 'levelId', levelId)
					]),
				A2(
					author$project$Api$GCP$withPath,
					_List_fromArray(
						['levels']),
					author$project$Api$GCP$get)));
	});
var author$project$Data$Session$getCampaign = F2(
	function (campaignId, session) {
		return A2(author$project$Data$Cache$get, campaignId, session.aG);
	});
var author$project$Data$Session$withLevelCache = F2(
	function (cache, session) {
		return _Utils_update(
			session,
			{as: cache});
	});
var author$project$SessionUpdate$GotLoadLevelByLevelIdResponse = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var author$project$Loaders$loadLevelsByCampaignId = F2(
	function (campaignId, session) {
		var _n0 = krisajenkins$remotedata$RemoteData$toMaybe(
			A2(author$project$Data$Session$getCampaign, campaignId, session));
		if (!_n0.$) {
			var campaign = _n0.a;
			var notAskedLevelIds = A2(
				elm$core$List$filter,
				A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Data$Cache$isNotAsked, session.as),
				campaign.aQ);
			return _Utils_Tuple2(
				A3(
					elm_community$basics_extra$Basics$Extra$flip,
					author$project$Data$Session$withLevelCache,
					session,
					A3(elm$core$List$foldl, author$project$Data$Cache$loading, session.as, notAskedLevelIds)),
				elm$core$Platform$Cmd$batch(
					A2(
						elm$core$List$map,
						function (levelId) {
							return A2(
								author$project$Data$Level$loadFromServer,
								author$project$SessionUpdate$GotLoadLevelByLevelIdResponse(levelId),
								levelId);
						},
						notAskedLevelIds)));
		} else {
			return _Utils_Tuple2(session, elm$core$Platform$Cmd$none);
		}
	});
var author$project$Data$Session$withSolutionBookCache = F2(
	function (cache, session) {
		return _Utils_update(
			session,
			{aZ: cache});
	});
var author$project$Data$Score$decoder = A2(
	elm$json$Json$Decode$andThen,
	function (numberOfSteps) {
		return A2(
			elm$json$Json$Decode$andThen,
			function (numberOfInstructions) {
				return elm$json$Json$Decode$succeed(
					{bc: numberOfInstructions, bd: numberOfSteps});
			},
			A2(elm$json$Json$Decode$field, 'numberOfInstructions', elm$json$Json$Decode$int));
	},
	A2(elm$json$Json$Decode$field, 'numberOfSteps', elm$json$Json$Decode$int));
var author$project$Data$SolutionId$decoder = elm$json$Json$Decode$string;
var author$project$Data$Solution$decoder = function () {
	var v1 = A2(
		elm$json$Json$Decode$andThen,
		function (id) {
			return A2(
				elm$json$Json$Decode$andThen,
				function (levelId) {
					return A2(
						elm$json$Json$Decode$andThen,
						function (score) {
							return A2(
								elm$json$Json$Decode$andThen,
								function (board) {
									return elm$json$Json$Decode$succeed(
										{cy: board, b6: id, a8: levelId, df: score});
								},
								A2(elm$json$Json$Decode$field, 'board', author$project$Data$Board$decoder));
						},
						A2(elm$json$Json$Decode$field, 'score', author$project$Data$Score$decoder));
				},
				A2(elm$json$Json$Decode$field, 'levelId', author$project$Data$LevelId$decoder));
		},
		A2(elm$json$Json$Decode$field, 'id', author$project$Data$SolutionId$decoder));
	return A2(
		elm$json$Json$Decode$andThen,
		function (version) {
			if (version === 1) {
				return v1;
			} else {
				return elm$json$Json$Decode$fail(
					'Unknown version: ' + elm$core$String$fromInt(version));
			}
		},
		A2(elm$json$Json$Decode$field, 'version', elm$json$Json$Decode$int));
}();
var author$project$Data$Solution$loadFromServerByLevelIds = F3(
	function (toMsg, accessToken, levelIds) {
		return A2(
			author$project$Api$GCP$request,
			A2(
				author$project$Data$GetError$expect,
				elm$json$Json$Decode$list(author$project$Data$Solution$decoder),
				toMsg),
			A2(
				author$project$Api$GCP$withAccessToken,
				accessToken,
				A2(
					author$project$Api$GCP$withQueryParameters,
					_List_fromArray(
						[
							A2(
							elm$url$Url$Builder$string,
							'levelIds',
							A2(elm$core$String$join, ',', levelIds))
						]),
					A2(
						author$project$Api$GCP$withPath,
						_List_fromArray(
							['solutions']),
						author$project$Api$GCP$get))));
	});
var author$project$Data$SolutionBook$localStorageKey = function (levelId) {
	return A2(
		elm$core$String$join,
		'.',
		_List_fromArray(
			['levels', levelId, 'solutionBook']));
};
var author$project$Data$SolutionBook$loadFromLocalStorage = function (levelId) {
	var key = author$project$Data$SolutionBook$localStorageKey(levelId);
	return author$project$Ports$LocalStorage$storageGetItem(key);
};
var author$project$SessionUpdate$GotLoadSolutionsByLevelIdsResponse = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var author$project$Loaders$loadSolutionBooksByLevelIds = F2(
	function (levelIds, session) {
		var _n0 = author$project$Data$Session$getAccessToken(session);
		if (!_n0.$) {
			var accessToken = _n0.a;
			return _Utils_Tuple2(
				A3(
					elm_community$basics_extra$Basics$Extra$flip,
					author$project$Data$Session$withSolutionBookCache,
					session,
					A3(elm$core$List$foldl, author$project$Data$Cache$loading, session.aZ, levelIds)),
				elm$core$List$isEmpty(levelIds) ? elm$core$Platform$Cmd$none : A3(
					author$project$Data$Solution$loadFromServerByLevelIds,
					author$project$SessionUpdate$GotLoadSolutionsByLevelIdsResponse(levelIds),
					accessToken,
					levelIds));
		} else {
			return _Utils_Tuple2(
				A3(
					elm_community$basics_extra$Basics$Extra$flip,
					author$project$Data$Session$withSolutionBookCache,
					session,
					A3(elm$core$List$foldl, author$project$Data$Cache$loading, session.aZ, levelIds)),
				elm$core$Platform$Cmd$batch(
					A2(elm$core$List$map, author$project$Data$SolutionBook$loadFromLocalStorage, levelIds)));
		}
	});
var author$project$Data$RemoteCache$withActualLoading = F2(
	function (key, cache) {
		return _Utils_update(
			cache,
			{
				ae: A2(author$project$Data$Cache$loading, key, cache.ae)
			});
	});
var author$project$Data$Session$withSolutionCache = F2(
	function (cache, session) {
		return _Utils_update(
			session,
			{ex: cache});
	});
var author$project$Data$Solution$localStorageKey = function (solutionId) {
	return A2(
		elm$core$String$join,
		'.',
		_List_fromArray(
			['solutions', solutionId]));
};
var author$project$Data$Solution$loadFromLocalStorage = function (solutionId) {
	return author$project$Ports$LocalStorage$storageGetItem(
		author$project$Data$Solution$localStorageKey(solutionId));
};
var author$project$Data$GetError$maybeResult = F2(
	function (decoder, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return elm$core$Result$Err(
					author$project$Data$GetError$Other('Bad url: ' + url));
			case 1:
				return elm$core$Result$Err(author$project$Data$GetError$NetworkError);
			case 2:
				return elm$core$Result$Err(author$project$Data$GetError$NetworkError);
			case 3:
				var metadata = response.a;
				var body = response.b;
				var _n1 = metadata.gh;
				switch (_n1) {
					case 404:
						return elm$core$Result$Ok(elm$core$Maybe$Nothing);
					case 403:
						return elm$core$Result$Err(
							author$project$Data$GetError$InvalidAccessToken(body));
					default:
						var statusCode = _n1;
						return elm$core$Result$Err(
							author$project$Data$GetError$Other(
								'Status ' + (elm$core$String$fromInt(statusCode) + (': ' + body))));
				}
			default:
				var body = response.b;
				var _n2 = A2(elm$json$Json$Decode$decodeString, decoder, body);
				if (!_n2.$) {
					var value = _n2.a;
					return elm$core$Result$Ok(
						elm$core$Maybe$Just(value));
				} else {
					var err = _n2.a;
					return elm$core$Result$Err(
						author$project$Data$GetError$Other(
							'Bad response: ' + elm$json$Json$Decode$errorToString(err)));
				}
		}
	});
var author$project$Data$GetError$expectMaybe = F2(
	function (decoder, toMsg) {
		return A2(
			elm$http$Http$expectStringResponse,
			toMsg,
			author$project$Data$GetError$maybeResult(decoder));
	});
var author$project$Data$Solution$loadFromServerBySolutionId = F3(
	function (toMsg, accessToken, solutionId) {
		return A2(
			author$project$Api$GCP$request,
			A2(author$project$Data$GetError$expectMaybe, author$project$Data$Solution$decoder, toMsg),
			A2(
				author$project$Api$GCP$withAccessToken,
				accessToken,
				A2(
					author$project$Api$GCP$withQueryParameters,
					_List_fromArray(
						[
							A2(elm$url$Url$Builder$string, 'solutionId', solutionId)
						]),
					A2(
						author$project$Api$GCP$withPath,
						_List_fromArray(
							['solutions']),
						author$project$Api$GCP$get))));
	});
var author$project$SessionUpdate$GotLoadSolutionsBySolutionIdResponse = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var author$project$Loaders$loadSolutionsBySolutionIds = F2(
	function (solutionIds, session) {
		var _n0 = author$project$Data$Session$getAccessToken(session);
		if (!_n0.$) {
			var accessToken = _n0.a;
			var notAskedSolutionIds = A2(
				elm$core$List$filter,
				A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Data$Cache$isNotAsked, session.ex.ae),
				solutionIds);
			return _Utils_Tuple2(
				A3(
					elm_community$basics_extra$Basics$Extra$flip,
					author$project$Data$Session$withSolutionCache,
					session,
					A3(elm$core$List$foldl, author$project$Data$RemoteCache$withActualLoading, session.ex, notAskedSolutionIds)),
				elm$core$Platform$Cmd$batch(
					A2(
						elm$core$List$map,
						function (solutionId) {
							return A3(
								author$project$Data$Solution$loadFromServerBySolutionId,
								author$project$SessionUpdate$GotLoadSolutionsBySolutionIdResponse(solutionId),
								accessToken,
								solutionId);
						},
						notAskedSolutionIds)));
		} else {
			var notAskedSolutionIds = A2(
				elm$core$List$filter,
				A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Data$Cache$isNotAsked, session.ex.aj),
				solutionIds);
			return _Utils_Tuple2(
				A3(
					elm_community$basics_extra$Basics$Extra$flip,
					author$project$Data$Session$withSolutionCache,
					session,
					A3(elm$core$List$foldl, author$project$Data$RemoteCache$withLocalLoading, session.ex, notAskedSolutionIds)),
				elm$core$Platform$Cmd$batch(
					A2(elm$core$List$map, author$project$Data$Solution$loadFromLocalStorage, notAskedSolutionIds)));
		}
	});
var elm$core$List$concatMap = F2(
	function (f, list) {
		return elm$core$List$concat(
			A2(elm$core$List$map, f, list));
	});
var author$project$Loaders$loadSolutionsByCampaignId = F2(
	function (campaignId, session) {
		var _n0 = A2(author$project$Data$Cache$get, campaignId, session.aG);
		if (_n0.$ === 3) {
			var campaign = _n0.a;
			var notAskedSolutionIds = A2(
				elm$core$List$concatMap,
				A2(
					elm$core$Basics$composeR,
					function ($) {
						return $.iY;
					},
					elm$core$Set$toList),
				A2(
					elm$core$List$filterMap,
					krisajenkins$remotedata$RemoteData$toMaybe,
					A2(
						elm$core$List$map,
						A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Data$Cache$get, session.aZ),
						campaign.aQ)));
			var notAskedLevelIds = A2(
				elm$core$List$filter,
				A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Data$Cache$isNotAsked, session.aZ),
				campaign.aQ);
			return A2(
				author$project$Extra$Cmd$fold,
				_List_fromArray(
					[
						author$project$Loaders$loadSolutionBooksByLevelIds(notAskedLevelIds),
						author$project$Loaders$loadSolutionsBySolutionIds(notAskedSolutionIds)
					]),
				session);
		} else {
			return _Utils_Tuple2(session, elm$core$Platform$Cmd$none);
		}
	});
var author$project$Page$Campaign$SessionMsg = function (a) {
	return {$: 1, a: a};
};
var author$project$Page$Campaign$withSession = F2(
	function (session, model) {
		return _Utils_update(
			model,
			{f: session});
	});
var elm$core$Tuple$mapBoth = F3(
	function (funcA, funcB, _n0) {
		var x = _n0.a;
		var y = _n0.b;
		return _Utils_Tuple2(
			funcA(x),
			funcB(y));
	});
var author$project$Page$Campaign$load = function () {
	var loadSolutions = function (model) {
		return A3(
			elm$core$Tuple$mapBoth,
			A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Page$Campaign$withSession, model),
			elm$core$Platform$Cmd$map(author$project$Page$Campaign$SessionMsg),
			A2(author$project$Loaders$loadSolutionsByCampaignId, model.bW, model.f));
	};
	var loadSelectedLevelHighScore = function (model) {
		var _n1 = model.aY;
		if (!_n1.$) {
			var levelId = _n1.a;
			return A3(
				elm$core$Tuple$mapBoth,
				A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Page$Campaign$withSession, model),
				elm$core$Platform$Cmd$map(author$project$Page$Campaign$SessionMsg),
				A2(author$project$Loaders$loadHighScoreByLevelId, levelId, model.f));
		} else {
			return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
		}
	};
	var loadLevels = function (model) {
		return A3(
			elm$core$Tuple$mapBoth,
			A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Page$Campaign$withSession, model),
			elm$core$Platform$Cmd$map(author$project$Page$Campaign$SessionMsg),
			A2(author$project$Loaders$loadLevelsByCampaignId, model.bW, model.f));
	};
	var loadDraftsBySelectedLevelId = function (model) {
		var _n0 = model.aY;
		if (!_n0.$) {
			var levelId = _n0.a;
			return A3(
				elm$core$Tuple$mapBoth,
				A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Page$Campaign$withSession, model),
				elm$core$Platform$Cmd$map(author$project$Page$Campaign$SessionMsg),
				A2(author$project$Loaders$loadDraftsByLevelId, levelId, model.f));
		} else {
			return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
		}
	};
	var loadCampaign = function (model) {
		return A3(
			elm$core$Tuple$mapBoth,
			A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Page$Campaign$withSession, model),
			elm$core$Platform$Cmd$map(author$project$Page$Campaign$SessionMsg),
			A2(author$project$Loaders$loadCampaignByCampaignId, model.bW, model.f));
	};
	return author$project$Extra$Cmd$fold(
		_List_fromArray(
			[loadCampaign, loadLevels, loadSolutions, loadDraftsBySelectedLevelId, loadSelectedLevelHighScore]));
}();
var author$project$Data$CampaignId$labyrinth = 'labyrinth';
var author$project$Data$CampaignId$standard = 'standard';
var author$project$Data$CampaignId$all = _List_fromArray(
	[author$project$Data$CampaignId$standard, author$project$Data$CampaignId$labyrinth]);
var author$project$Loaders$loadCampaignsByCampaignIds = function (campaignIds) {
	return author$project$Extra$Cmd$fold(
		A2(elm$core$List$map, author$project$Loaders$loadCampaignByCampaignId, campaignIds));
};
var author$project$Loaders$loadSolutionsByCampaignIds = function (campaignIds) {
	return author$project$Extra$Cmd$fold(
		A2(elm$core$List$map, author$project$Loaders$loadSolutionsByCampaignId, campaignIds));
};
var author$project$Page$Campaigns$SessionMsg = function (a) {
	return {$: 1, a: a};
};
var author$project$Page$Campaigns$withSession = F2(
	function (session, model) {
		return _Utils_update(
			model,
			{f: session});
	});
var author$project$Page$Campaigns$load = function () {
	var loadSolutions = function (model) {
		return A3(
			elm$core$Tuple$mapBoth,
			A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Page$Campaigns$withSession, model),
			elm$core$Platform$Cmd$map(author$project$Page$Campaigns$SessionMsg),
			A2(author$project$Loaders$loadSolutionsByCampaignIds, author$project$Data$CampaignId$all, model.f));
	};
	var loadCampaigns = function (model) {
		return A3(
			elm$core$Tuple$mapBoth,
			A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Page$Campaigns$withSession, model),
			elm$core$Platform$Cmd$map(author$project$Page$Campaigns$SessionMsg),
			A2(author$project$Loaders$loadCampaignsByCampaignIds, author$project$Data$CampaignId$all, model.f));
	};
	return author$project$Extra$Cmd$fold(
		_List_fromArray(
			[loadCampaigns, loadSolutions]));
}();
var author$project$Page$Credits$load = A2(elm_community$basics_extra$Basics$Extra$flip, elm$core$Tuple$pair, elm$core$Platform$Cmd$none);
var author$project$Data$Draft$loadFromServer = F3(
	function (toMsg, accessToken, draftId) {
		return A2(
			author$project$Api$GCP$request,
			A2(author$project$Data$GetError$expectMaybe, author$project$Data$Draft$decoder, toMsg),
			A2(
				author$project$Api$GCP$withAccessToken,
				accessToken,
				A2(
					author$project$Api$GCP$withQueryParameters,
					_List_fromArray(
						[
							A2(elm$url$Url$Builder$string, 'draftId', draftId)
						]),
					A2(
						author$project$Api$GCP$withPath,
						_List_fromArray(
							['drafts']),
						author$project$Api$GCP$get))));
	});
var author$project$Page$Draft$SessionMsg = function (a) {
	return {$: 1, a: a};
};
var author$project$Page$Draft$withSession = F2(
	function (session, model) {
		return _Utils_update(
			model,
			{f: session});
	});
var author$project$SessionUpdate$GotLoadDraftByDraftIdResponse = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var elm_community$maybe_extra$Maybe$Extra$isNothing = function (m) {
	if (m.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var elm_community$maybe_extra$Maybe$Extra$join = function (mx) {
	if (!mx.$) {
		var x = mx.a;
		return x;
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var krisajenkins$remotedata$RemoteData$isFailure = function (data) {
	if (data.$ === 2) {
		var x = data.a;
		return true;
	} else {
		return false;
	}
};
var author$project$Page$Draft$load = function () {
	var loadLocalDraft = function (model) {
		var _n4 = A2(author$project$Data$Cache$get, model.o, model.f.dC.aj);
		if (!_n4.$) {
			return (elm_community$maybe_extra$Maybe$Extra$isNothing(
				author$project$Data$Session$getAccessToken(model.f)) || krisajenkins$remotedata$RemoteData$isFailure(
				A2(author$project$Data$Cache$get, model.o, model.f.dC.ae))) ? _Utils_Tuple2(
				A3(
					elm_community$basics_extra$Basics$Extra$flip,
					author$project$Page$Draft$withSession,
					model,
					A3(
						elm_community$basics_extra$Basics$Extra$flip,
						author$project$Data$Session$withDraftCache,
						model.f,
						A2(author$project$Data$RemoteCache$withLocalLoading, model.o, model.f.dC))),
				author$project$Data$Draft$loadFromLocalStorage(model.o)) : author$project$Extra$Cmd$noCmd(model);
		} else {
			return author$project$Extra$Cmd$noCmd(model);
		}
	};
	var loadLevel = function (model) {
		var _n2 = elm_community$maybe_extra$Maybe$Extra$join(
			krisajenkins$remotedata$RemoteData$toMaybe(
				A2(author$project$Data$Cache$get, model.o, model.f.dC.aj)));
		if (!_n2.$) {
			var draft = _n2.a;
			var _n3 = A2(author$project$Data$Cache$get, draft.a8, model.f.as);
			if (!_n3.$) {
				return _Utils_Tuple2(
					A3(
						elm_community$basics_extra$Basics$Extra$flip,
						author$project$Page$Draft$withSession,
						model,
						A3(
							elm_community$basics_extra$Basics$Extra$flip,
							author$project$Data$Session$withLevelCache,
							model.f,
							A2(author$project$Data$Cache$loading, draft.a8, model.f.as))),
					A2(
						author$project$Data$Level$loadFromServer,
						A2(
							elm$core$Basics$composeL,
							author$project$Page$Draft$SessionMsg,
							author$project$SessionUpdate$GotLoadLevelByLevelIdResponse(draft.a8)),
						draft.a8));
			} else {
				return author$project$Extra$Cmd$noCmd(model);
			}
		} else {
			return author$project$Extra$Cmd$noCmd(model);
		}
	};
	var loadActualDraft = function (model) {
		var _n0 = _Utils_Tuple2(
			author$project$Data$Session$getAccessToken(model.f),
			A2(author$project$Data$Cache$get, model.o, model.f.dC.ae));
		if ((!_n0.a.$) && (!_n0.b.$)) {
			var accessToken = _n0.a.a;
			var _n1 = _n0.b;
			return _Utils_Tuple2(
				A3(
					elm_community$basics_extra$Basics$Extra$flip,
					author$project$Page$Draft$withSession,
					model,
					A3(
						elm_community$basics_extra$Basics$Extra$flip,
						author$project$Data$Session$withDraftCache,
						model.f,
						A2(author$project$Data$RemoteCache$withActualLoading, model.o, model.f.dC))),
				A3(
					author$project$Data$Draft$loadFromServer,
					A2(
						elm$core$Basics$composeL,
						author$project$Page$Draft$SessionMsg,
						author$project$SessionUpdate$GotLoadDraftByDraftIdResponse(model.o)),
					accessToken,
					model.o));
		} else {
			return author$project$Extra$Cmd$noCmd(model);
		}
	};
	return author$project$Extra$Cmd$fold(
		_List_fromArray(
			[loadActualDraft, loadLocalDraft, loadLevel]));
}();
var author$project$Page$Execution$SessionMsg = function (a) {
	return {$: 1, a: a};
};
var author$project$Data$History$current = function ($) {
	return $.x;
};
var author$project$Data$History$fromList = function (list) {
	if (list.b) {
		var head = list.a;
		var tail = list.b;
		return elm$core$Maybe$Just(
			{x: head, y: tail, u: _List_Nil});
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var author$project$Data$History$map = F2(
	function (_function, history) {
		return {
			x: _function(history.x),
			y: A2(elm$core$List$map, _function, history.y),
			u: A2(elm$core$List$map, _function, history.u)
		};
	});
var author$project$Page$Execution$initialExecutionStep = F2(
	function (board, input) {
		return {
			cy: board,
			b2: elm$core$Maybe$Nothing,
			hU: input,
			aN: {
				hm: 2,
				ep: {eR: 0, eS: 0}
			},
			iu: _List_Nil,
			k: _List_Nil,
			cj: 0,
			di: false
		};
	});
var author$project$Page$Execution$initialExecution = F2(
	function (level, draft) {
		var board = author$project$Data$History$current(draft.G);
		var executionSuite = function (suite) {
			return {
				r: author$project$Data$History$singleton(
					A2(author$project$Page$Execution$initialExecutionStep, board, suite.hU)),
				cO: suite.iu
			};
		};
		var suites = A2(
			author$project$Data$History$map,
			executionSuite,
			A2(
				elm$core$Maybe$withDefault,
				author$project$Data$History$singleton(author$project$Data$Suite$empty),
				author$project$Data$History$fromList(level.bM)));
		return {l: suites, bv: level};
	});
var author$project$Page$Execution$withSession = F2(
	function (session, model) {
		return _Utils_update(
			model,
			{f: session});
	});
var author$project$Page$Execution$load = function () {
	var loadLocalDraft = function (model) {
		var _n6 = A2(author$project$Data$Cache$get, model.o, model.f.dC.aj);
		if (!_n6.$) {
			return (elm_community$maybe_extra$Maybe$Extra$isNothing(
				author$project$Data$Session$getAccessToken(model.f)) || krisajenkins$remotedata$RemoteData$isFailure(
				A2(author$project$Data$Cache$get, model.o, model.f.dC.ae))) ? _Utils_Tuple2(
				A3(
					elm_community$basics_extra$Basics$Extra$flip,
					author$project$Page$Execution$withSession,
					model,
					A3(
						elm_community$basics_extra$Basics$Extra$flip,
						author$project$Data$Session$withDraftCache,
						model.f,
						A2(author$project$Data$RemoteCache$withLocalLoading, model.o, model.f.dC))),
				author$project$Data$Draft$loadFromLocalStorage(model.o)) : author$project$Extra$Cmd$noCmd(model);
		} else {
			return author$project$Extra$Cmd$noCmd(model);
		}
	};
	var loadLevel = function (model) {
		var _n4 = elm_community$maybe_extra$Maybe$Extra$join(
			krisajenkins$remotedata$RemoteData$toMaybe(
				A2(author$project$Data$Cache$get, model.o, model.f.dC.aj)));
		if (!_n4.$) {
			var draft = _n4.a;
			var _n5 = A2(author$project$Data$Cache$get, draft.a8, model.f.as);
			if (!_n5.$) {
				return _Utils_Tuple2(
					A3(
						elm_community$basics_extra$Basics$Extra$flip,
						author$project$Page$Execution$withSession,
						model,
						A3(
							elm_community$basics_extra$Basics$Extra$flip,
							author$project$Data$Session$withLevelCache,
							model.f,
							A2(author$project$Data$Cache$loading, draft.a8, model.f.as))),
					A2(
						author$project$Data$Level$loadFromServer,
						A2(
							elm$core$Basics$composeL,
							author$project$Page$Execution$SessionMsg,
							author$project$SessionUpdate$GotLoadLevelByLevelIdResponse(draft.a8)),
						draft.a8));
			} else {
				return author$project$Extra$Cmd$noCmd(model);
			}
		} else {
			return author$project$Extra$Cmd$noCmd(model);
		}
	};
	var loadActualDraft = function (model) {
		var _n2 = _Utils_Tuple2(
			author$project$Data$Session$getAccessToken(model.f),
			A2(author$project$Data$Cache$get, model.o, model.f.dC.ae));
		if ((!_n2.a.$) && (!_n2.b.$)) {
			var accessToken = _n2.a.a;
			var _n3 = _n2.b;
			return _Utils_Tuple2(
				A3(
					elm_community$basics_extra$Basics$Extra$flip,
					author$project$Page$Execution$withSession,
					model,
					A3(
						elm_community$basics_extra$Basics$Extra$flip,
						author$project$Data$Session$withDraftCache,
						model.f,
						A2(author$project$Data$RemoteCache$withActualLoading, model.o, model.f.dC))),
				A3(
					author$project$Data$Draft$loadFromServer,
					A2(
						elm$core$Basics$composeL,
						author$project$Page$Execution$SessionMsg,
						author$project$SessionUpdate$GotLoadDraftByDraftIdResponse(model.o)),
					accessToken,
					model.o));
		} else {
			return author$project$Extra$Cmd$noCmd(model);
		}
	};
	var initializeExecution = function (model) {
		var _n0 = elm_community$maybe_extra$Maybe$Extra$join(
			krisajenkins$remotedata$RemoteData$toMaybe(
				A2(author$project$Data$Cache$get, model.o, model.f.dC.aj)));
		if (!_n0.$) {
			var draft = _n0.a;
			var _n1 = A2(author$project$Data$Cache$get, draft.a8, model.f.as);
			if (_n1.$ === 3) {
				var level = _n1.a;
				return A2(
					elm$core$Maybe$withDefault,
					false,
					A2(
						elm$core$Maybe$map,
						elm$core$Basics$eq(level.b6),
						model.c_)) ? _Utils_Tuple2(model, elm$core$Platform$Cmd$none) : _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ar: elm$core$Maybe$Just(
								A2(author$project$Page$Execution$initialExecution, level, draft)),
							c_: elm$core$Maybe$Just(level.b6)
						}),
					elm$core$Platform$Cmd$none);
			} else {
				return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
			}
		} else {
			return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
		}
	};
	return author$project$Extra$Cmd$fold(
		_List_fromArray(
			[loadActualDraft, loadLocalDraft, loadLevel, initializeExecution]));
}();
var author$project$Page$Home$load = author$project$Extra$Cmd$noCmd;
var author$project$Data$AccessToken$encode = function (accessToken) {
	return elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'version',
				elm$json$Json$Encode$int(1)),
				_Utils_Tuple2(
				'accessToken',
				elm$json$Json$Encode$string(
					author$project$Data$AccessToken$toString(accessToken)))
			]));
};
var author$project$Data$AccessToken$localStorageKey = 'accessToken';
var author$project$Data$AccessToken$saveToLocalStorage = function (campaign) {
	return author$project$Ports$LocalStorage$storageSetItem(
		_Utils_Tuple2(
			author$project$Data$AccessToken$localStorageKey,
			author$project$Data$AccessToken$encode(campaign)));
};
var elm$core$Basics$always = F2(
	function (a, _n0) {
		return a;
	});
var elm$core$Dict$map = F2(
	function (func, dict) {
		if (dict.$ === -2) {
			return elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			return A5(
				elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				A2(func, key, value),
				A2(elm$core$Dict$map, func, left),
				A2(elm$core$Dict$map, func, right));
		}
	});
var krisajenkins$remotedata$RemoteData$succeed = krisajenkins$remotedata$RemoteData$Success;
var author$project$Data$Cache$fromValueDict = function (dict) {
	return A2(
		elm$core$Dict$map,
		elm$core$Basics$always(krisajenkins$remotedata$RemoteData$succeed),
		dict);
};
var author$project$Data$Draft$eq = F2(
	function (draft1, draft2) {
		return _Utils_eq(draft1.b6, draft2.b6) && (_Utils_eq(draft1.a8, draft2.a8) && _Utils_eq(
			author$project$Data$History$current(draft1.G),
			author$project$Data$History$current(draft2.G)));
	});
var elm$core$Elm$JsArray$indexedMap = _JsArray_indexedMap;
var elm$core$Array$indexedMap = F2(
	function (func, _n0) {
		var len = _n0.a;
		var tree = _n0.c;
		var tail = _n0.d;
		var initialBuilder = {
			t: _List_Nil,
			p: 0,
			s: A3(
				elm$core$Elm$JsArray$indexedMap,
				func,
				elm$core$Array$tailIndex(len),
				tail)
		};
		var helper = F2(
			function (node, builder) {
				if (!node.$) {
					var subTree = node.a;
					return A3(elm$core$Elm$JsArray$foldl, helper, builder, subTree);
				} else {
					var leaf = node.a;
					var offset = builder.p * elm$core$Array$branchFactor;
					var mappedLeaf = elm$core$Array$Leaf(
						A3(elm$core$Elm$JsArray$indexedMap, func, offset, leaf));
					return {
						t: A2(elm$core$List$cons, mappedLeaf, builder.t),
						p: builder.p + 1,
						s: builder.s
					};
				}
			});
		return A2(
			elm$core$Array$builderToArray,
			true,
			A3(elm$core$Elm$JsArray$foldl, helper, initialBuilder, tree));
	});
var author$project$Data$Board$instructions = function (board) {
	return elm$core$List$concat(
		elm$core$Array$toList(
			A2(
				elm$core$Array$indexedMap,
				F2(
					function (y, row) {
						return elm$core$Array$toList(
							A2(
								elm$core$Array$indexedMap,
								F2(
									function (x, instruction) {
										return {
											cU: instruction,
											ep: {eR: x, eS: y}
										};
									}),
								row));
					}),
				board)));
};
var author$project$Data$Direction$encode = function (direction) {
	switch (direction) {
		case 0:
			return elm$json$Json$Encode$string('Left');
		case 1:
			return elm$json$Json$Encode$string('Up');
		case 2:
			return elm$json$Json$Encode$string('Right');
		default:
			return elm$json$Json$Encode$string('Down');
	}
};
var author$project$Data$Int16$encode = function (_n0) {
	var _int = _n0;
	return elm$json$Json$Encode$int(_int);
};
var author$project$Data$Instruction$encode = function (instruction) {
	switch (instruction.$) {
		case 0:
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('NoOp'))
					]));
		case 1:
			var direction = instruction.a;
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('ChangeDirection')),
						_Utils_Tuple2(
						'direction',
						author$project$Data$Direction$encode(direction))
					]));
		case 2:
			var value = instruction.a;
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('PushToStack')),
						_Utils_Tuple2(
						'value',
						author$project$Data$Int16$encode(value))
					]));
		case 3:
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('PopFromStack'))
					]));
		case 4:
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('JumpForward'))
					]));
		case 5:
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('Duplicate'))
					]));
		case 6:
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('Swap'))
					]));
		case 7:
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('Negate'))
					]));
		case 8:
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('Abs'))
					]));
		case 9:
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('Not'))
					]));
		case 10:
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('Increment'))
					]));
		case 11:
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('Decrement'))
					]));
		case 12:
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('Add'))
					]));
		case 13:
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('Subtract'))
					]));
		case 14:
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('Multiply'))
					]));
		case 15:
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('Divide'))
					]));
		case 16:
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('Equals'))
					]));
		case 17:
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('CompareLessThan'))
					]));
		case 18:
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('And'))
					]));
		case 19:
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('Or'))
					]));
		case 20:
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('XOr'))
					]));
		case 21:
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('Read'))
					]));
		case 22:
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('Print'))
					]));
		case 23:
			var trueDirection = instruction.a;
			var falseDirection = instruction.b;
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('Branch')),
						_Utils_Tuple2(
						'trueDirection',
						author$project$Data$Direction$encode(trueDirection)),
						_Utils_Tuple2(
						'falseDirection',
						author$project$Data$Direction$encode(falseDirection))
					]));
		case 24:
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('Terminate'))
					]));
		case 25:
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('SendToBottom'))
					]));
		default:
			var exceptionMessage = instruction.a;
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('Exception')),
						_Utils_Tuple2(
						'exceptionMessage',
						elm$json$Json$Encode$string(exceptionMessage))
					]));
	}
};
var author$project$Data$Position$encode = function (position) {
	return elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'x',
				elm$json$Json$Encode$int(position.eR)),
				_Utils_Tuple2(
				'y',
				elm$json$Json$Encode$int(position.eS))
			]));
};
var author$project$Data$BoardInstruction$encode = function (boardInstruction) {
	return elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'position',
				author$project$Data$Position$encode(boardInstruction.ep)),
				_Utils_Tuple2(
				'instruction',
				author$project$Data$Instruction$encode(boardInstruction.cU))
			]));
};
var author$project$Data$Board$encode = function (board) {
	return elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'width',
				elm$json$Json$Encode$int(
					author$project$Data$Board$width(board))),
				_Utils_Tuple2(
				'height',
				elm$json$Json$Encode$int(
					author$project$Data$Board$height(board))),
				_Utils_Tuple2(
				'instructions',
				A2(
					elm$json$Json$Encode$list,
					author$project$Data$BoardInstruction$encode,
					A2(
						elm$core$List$filter,
						A2(
							elm$core$Basics$composeR,
							function ($) {
								return $.cU;
							},
							elm$core$Basics$neq(author$project$Data$Instruction$NoOp)),
						author$project$Data$Board$instructions(board))))
			]));
};
var author$project$Data$DraftId$encode = A2(elm$core$Basics$composeR, author$project$Data$DraftId$toString, elm$json$Json$Encode$string);
var author$project$Data$Draft$encode = function (draft) {
	return elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'version',
				elm$json$Json$Encode$int(1)),
				_Utils_Tuple2(
				'id',
				author$project$Data$DraftId$encode(draft.b6)),
				_Utils_Tuple2(
				'levelId',
				author$project$Data$LevelId$encode(draft.a8)),
				_Utils_Tuple2(
				'board',
				author$project$Data$Board$encode(
					author$project$Data$History$current(draft.G)))
			]));
};
var author$project$Data$Draft$remoteKey = function (draftId) {
	return A2(
		elm$core$String$join,
		'.',
		_List_fromArray(
			[
				author$project$Data$Draft$localStorageKey(draftId),
				'remote'
			]));
};
var author$project$Data$Draft$saveRemoteToLocalStorage = function (draft) {
	var value = author$project$Data$Draft$encode(draft);
	var key = author$project$Data$Draft$remoteKey(draft.b6);
	return author$project$Ports$LocalStorage$storageSetItem(
		_Utils_Tuple2(key, value));
};
var author$project$Ports$LocalStorage$storagePushToSet = _Platform_outgoingPort(
	'storagePushToSet',
	function ($) {
		var a = $.a;
		var b = $.b;
		return A2(
			elm$json$Json$Encode$list,
			elm$core$Basics$identity,
			_List_fromArray(
				[
					elm$json$Json$Encode$string(a),
					elm$core$Basics$identity(b)
				]));
	});
var author$project$Data$DraftBook$saveToLocalStorage = F2(
	function (draftId, levelId) {
		var value = author$project$Data$DraftId$encode(draftId);
		var key = author$project$Data$DraftBook$localStorageKey(levelId);
		return author$project$Ports$LocalStorage$storagePushToSet(
			_Utils_Tuple2(key, value));
	});
var author$project$Data$Draft$saveToLocalStorage = function (draft) {
	var value = author$project$Data$Draft$encode(draft);
	var key = author$project$Data$Draft$localStorageKey(draft.b6);
	return elm$core$Platform$Cmd$batch(
		_List_fromArray(
			[
				author$project$Ports$LocalStorage$storageSetItem(
				_Utils_Tuple2(key, value)),
				A2(author$project$Data$DraftBook$saveToLocalStorage, draft.b6, draft.a8)
			]));
};
var author$project$Data$Session$withUser = F2(
	function (user, session) {
		return _Utils_update(
			session,
			{bQ: user});
	});
var author$project$Api$GCP$post = _Utils_update(
	author$project$Api$GCP$get,
	{a9: 'POST'});
var author$project$Api$GCP$withBody = F2(
	function (body, builder) {
		return _Utils_update(
			builder,
			{
				bT: elm$core$Maybe$Just(body)
			});
	});
var author$project$Data$Score$encode = function (score) {
	return elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'numberOfSteps',
				elm$json$Json$Encode$int(score.bd)),
				_Utils_Tuple2(
				'numberOfInstructions',
				elm$json$Json$Encode$int(score.bc))
			]));
};
var author$project$Data$SolutionId$encode = elm$json$Json$Encode$string;
var author$project$Data$Solution$encode = function (solution) {
	return elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'version',
				elm$json$Json$Encode$int(1)),
				_Utils_Tuple2(
				'id',
				author$project$Data$SolutionId$encode(solution.b6)),
				_Utils_Tuple2(
				'levelId',
				author$project$Data$LevelId$encode(solution.a8)),
				_Utils_Tuple2(
				'score',
				author$project$Data$Score$encode(solution.df)),
				_Utils_Tuple2(
				'board',
				author$project$Data$Board$encode(solution.cy))
			]));
};
var author$project$Data$EndpointResult$BadRequest = function (a) {
	return {$: 4, a: a};
};
var author$project$Data$EndpointResult$ConflictingId = {$: 1};
var author$project$Data$EndpointResult$Duplicate = {$: 2};
var author$project$Data$EndpointResult$Forbidden = function (a) {
	return {$: 6, a: a};
};
var author$project$Data$EndpointResult$InternalServerError = function (a) {
	return {$: 7, a: a};
};
var author$project$Data$EndpointResult$InvalidAccessToken = function (a) {
	return {$: 5, a: a};
};
var author$project$Data$EndpointResult$NotFound = {$: 3};
var author$project$Data$EndpointResult$Ok = {$: 0};
var author$project$Data$EndpointResult$decoder = function () {
	var messagesDecoder = function (f) {
		return A2(
			elm$json$Json$Decode$andThen,
			f,
			A2(
				elm$json$Json$Decode$field,
				'messages',
				elm$json$Json$Decode$list(elm$json$Json$Decode$string)));
	};
	return A2(
		elm$json$Json$Decode$andThen,
		function (string) {
			switch (string) {
				case 'Ok':
					return elm$json$Json$Decode$succeed(author$project$Data$EndpointResult$Ok);
				case 'ConflictingId':
					return elm$json$Json$Decode$succeed(author$project$Data$EndpointResult$ConflictingId);
				case 'Duplicate':
					return elm$json$Json$Decode$succeed(author$project$Data$EndpointResult$Duplicate);
				case 'NotFound':
					return elm$json$Json$Decode$succeed(author$project$Data$EndpointResult$NotFound);
				case 'BadRequest':
					return messagesDecoder(
						A2(elm$core$Basics$composeL, elm$json$Json$Decode$succeed, author$project$Data$EndpointResult$BadRequest));
				case 'InvalidAccessToken':
					return messagesDecoder(
						A2(elm$core$Basics$composeL, elm$json$Json$Decode$succeed, author$project$Data$EndpointResult$InvalidAccessToken));
				case 'Forbidden':
					return messagesDecoder(
						A2(elm$core$Basics$composeL, elm$json$Json$Decode$succeed, author$project$Data$EndpointResult$Forbidden));
				case 'InternalServerError':
					return messagesDecoder(
						A2(elm$core$Basics$composeL, elm$json$Json$Decode$succeed, author$project$Data$EndpointResult$InternalServerError));
				default:
					var other = string;
					return elm$json$Json$Decode$fail('Unknown tag: ' + other);
			}
		},
		A2(elm$json$Json$Decode$field, 'tag', elm$json$Json$Decode$string));
}();
var author$project$Data$EndpointResult$toString = function (endpointResult) {
	switch (endpointResult.$) {
		case 0:
			return 'Ok';
		case 1:
			return 'Conflicting id';
		case 2:
			return 'Duplicate';
		case 3:
			return 'Not found';
		case 4:
			var messages = endpointResult.a;
			return A2(elm$core$String$join, '\n', messages);
		case 5:
			var messages = endpointResult.a;
			return A2(elm$core$String$join, '\n', messages);
		case 6:
			var messages = endpointResult.a;
			return A2(elm$core$String$join, '\n', messages);
		default:
			var messages = endpointResult.a;
			return A2(elm$core$String$join, '\n', messages);
	}
};
var author$project$Data$SubmitSolutionError$ConflictingId = {$: 3};
var author$project$Data$SubmitSolutionError$Duplicate = {$: 2};
var author$project$Data$SubmitSolutionError$InvalidAccessToken = function (a) {
	return {$: 1, a: a};
};
var author$project$Data$SubmitSolutionError$NetworkError = {$: 0};
var author$project$Data$SubmitSolutionError$Other = function (a) {
	return {$: 4, a: a};
};
var author$project$Data$SubmitSolutionError$fromResponse = function (response) {
	switch (response.$) {
		case 0:
			var string = response.a;
			return elm$core$Result$Err(
				author$project$Data$SubmitSolutionError$Other('Bad url: ' + string));
		case 1:
			return elm$core$Result$Err(
				author$project$Data$SubmitSolutionError$Other('Request timed out'));
		case 2:
			return elm$core$Result$Err(author$project$Data$SubmitSolutionError$NetworkError);
		case 3:
			var metadata = response.a;
			var body = response.b;
			var _n1 = A2(elm$json$Json$Decode$decodeString, author$project$Data$EndpointResult$decoder, body);
			if (!_n1.$) {
				switch (_n1.a.$) {
					case 1:
						var _n2 = _n1.a;
						return elm$core$Result$Err(author$project$Data$SubmitSolutionError$ConflictingId);
					case 2:
						var _n3 = _n1.a;
						return elm$core$Result$Err(author$project$Data$SubmitSolutionError$Duplicate);
					case 5:
						var messages = _n1.a.a;
						return elm$core$Result$Err(
							author$project$Data$SubmitSolutionError$InvalidAccessToken(
								A2(elm$core$String$join, '\n', messages)));
					default:
						var other = _n1.a;
						return elm$core$Result$Err(
							author$project$Data$SubmitSolutionError$Other(
								author$project$Data$EndpointResult$toString(other)));
				}
			} else {
				return elm$core$Result$Err(
					author$project$Data$SubmitSolutionError$Other(
						'Status ' + (elm$core$String$fromInt(metadata.gh) + (': ' + body))));
			}
		default:
			return elm$core$Result$Ok(0);
	}
};
var author$project$Extra$Result$getError = function (result) {
	if (!result.$) {
		return elm$core$Maybe$Nothing;
	} else {
		var error = result.a;
		return elm$core$Maybe$Just(error);
	}
};
var author$project$Data$SubmitSolutionError$expect = function (toMsg) {
	return A2(
		elm$http$Http$expectStringResponse,
		A2(elm$core$Basics$composeR, author$project$Extra$Result$getError, toMsg),
		author$project$Data$SubmitSolutionError$fromResponse);
};
var author$project$Data$Solution$saveToServer = F3(
	function (toMsg, accessToken, solution) {
		return A2(
			author$project$Api$GCP$request,
			author$project$Data$SubmitSolutionError$expect(toMsg),
			A2(
				author$project$Api$GCP$withBody,
				author$project$Data$Solution$encode(solution),
				A2(
					author$project$Api$GCP$withAccessToken,
					accessToken,
					A2(
						author$project$Api$GCP$withPath,
						_List_fromArray(
							['solutions']),
						author$project$Api$GCP$post))));
	});
var author$project$Data$User$authorizedUser = F2(
	function (accessToken, userInfo) {
		return {
			eT: elm$core$Maybe$Just(accessToken),
			cu: elm$core$Maybe$Just(userInfo)
		};
	});
var author$project$Data$User$withUserInfo = F2(
	function (userInfo, user) {
		return _Utils_update(
			user,
			{cu: userInfo});
	});
var elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return elm$core$Maybe$Nothing;
		}
	});
var elm$json$Json$Decode$oneOf = _Json_oneOf;
var elm$json$Json$Decode$maybe = function (decoder) {
	return elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(elm$json$Json$Decode$map, elm$core$Maybe$Just, decoder),
				elm$json$Json$Decode$succeed(elm$core$Maybe$Nothing)
			]));
};
var elm$core$String$length = _String_length;
var elm$core$String$slice = _String_slice;
var elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			elm$core$String$slice,
			n,
			elm$core$String$length(string),
			string);
	});
var elm$core$String$startsWith = _String_startsWith;
var elm$url$Url$Http = 0;
var elm$url$Url$Https = 1;
var elm$core$String$indexes = _String_indexes;
var elm$core$String$isEmpty = function (string) {
	return string === '';
};
var elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(elm$core$String$slice, 0, n, string);
	});
var elm$core$String$contains = _String_contains;
var elm$core$String$toInt = _String_toInt;
var elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {dE: fragment, fs: host, da: path, fX: port_, f$: protocol, f0: query};
	});
var elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if (elm$core$String$isEmpty(str) || A2(elm$core$String$contains, '@', str)) {
			return elm$core$Maybe$Nothing;
		} else {
			var _n0 = A2(elm$core$String$indexes, ':', str);
			if (!_n0.b) {
				return elm$core$Maybe$Just(
					A6(elm$url$Url$Url, protocol, str, elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_n0.b.b) {
					var i = _n0.a;
					var _n1 = elm$core$String$toInt(
						A2(elm$core$String$dropLeft, i + 1, str));
					if (_n1.$ === 1) {
						return elm$core$Maybe$Nothing;
					} else {
						var port_ = _n1;
						return elm$core$Maybe$Just(
							A6(
								elm$url$Url$Url,
								protocol,
								A2(elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return elm$core$Maybe$Nothing;
				}
			}
		}
	});
var elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if (elm$core$String$isEmpty(str)) {
			return elm$core$Maybe$Nothing;
		} else {
			var _n0 = A2(elm$core$String$indexes, '/', str);
			if (!_n0.b) {
				return A5(elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _n0.a;
				return A5(
					elm$url$Url$chompBeforePath,
					protocol,
					A2(elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2(elm$core$String$left, i, str));
			}
		}
	});
var elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if (elm$core$String$isEmpty(str)) {
			return elm$core$Maybe$Nothing;
		} else {
			var _n0 = A2(elm$core$String$indexes, '?', str);
			if (!_n0.b) {
				return A4(elm$url$Url$chompBeforeQuery, protocol, elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _n0.a;
				return A4(
					elm$url$Url$chompBeforeQuery,
					protocol,
					elm$core$Maybe$Just(
						A2(elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2(elm$core$String$left, i, str));
			}
		}
	});
var elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if (elm$core$String$isEmpty(str)) {
			return elm$core$Maybe$Nothing;
		} else {
			var _n0 = A2(elm$core$String$indexes, '#', str);
			if (!_n0.b) {
				return A3(elm$url$Url$chompBeforeFragment, protocol, elm$core$Maybe$Nothing, str);
			} else {
				var i = _n0.a;
				return A3(
					elm$url$Url$chompBeforeFragment,
					protocol,
					elm$core$Maybe$Just(
						A2(elm$core$String$dropLeft, i + 1, str)),
					A2(elm$core$String$left, i, str));
			}
		}
	});
var elm$url$Url$fromString = function (str) {
	return A2(elm$core$String$startsWith, 'http://', str) ? A2(
		elm$url$Url$chompAfterProtocol,
		0,
		A2(elm$core$String$dropLeft, 7, str)) : (A2(elm$core$String$startsWith, 'https://', str) ? A2(
		elm$url$Url$chompAfterProtocol,
		1,
		A2(elm$core$String$dropLeft, 8, str)) : elm$core$Maybe$Nothing);
};
var author$project$Data$UserInfo$decoder = function () {
	var updatedAtDecode = F8(
		function (sub, givenName, picture, familyName, nickname, name, locale, updatedAt) {
			return elm$json$Json$Decode$succeed(
				{cP: familyName, cQ: givenName, d0: locale, bA: name, c5: nickname, eo: picture, dh: sub, eM: updatedAt});
		});
	var localeDecode = F7(
		function (sub, givenName, picture, familyName, nickname, name, locale) {
			return A2(
				elm$json$Json$Decode$andThen,
				A7(updatedAtDecode, sub, givenName, picture, familyName, nickname, name, locale),
				elm$json$Json$Decode$maybe(
					A2(elm$json$Json$Decode$field, 'updated_at', elm$json$Json$Decode$string)));
		});
	var nameDecode = F6(
		function (sub, givenName, picture, familyName, nickname, name) {
			return A2(
				elm$json$Json$Decode$andThen,
				A6(localeDecode, sub, givenName, picture, familyName, nickname, name),
				elm$json$Json$Decode$maybe(
					A2(elm$json$Json$Decode$field, 'locale', elm$json$Json$Decode$string)));
		});
	var nicknameDecode = F5(
		function (sub, givenName, picture, familyName, nickname) {
			return A2(
				elm$json$Json$Decode$andThen,
				A5(nameDecode, sub, givenName, picture, familyName, nickname),
				elm$json$Json$Decode$maybe(
					A2(elm$json$Json$Decode$field, 'name', elm$json$Json$Decode$string)));
		});
	var familyNameDecode = F4(
		function (sub, givenName, picture, familyName) {
			return A2(
				elm$json$Json$Decode$andThen,
				A4(nicknameDecode, sub, givenName, picture, familyName),
				elm$json$Json$Decode$maybe(
					A2(elm$json$Json$Decode$field, 'nickname', elm$json$Json$Decode$string)));
		});
	var pictureDecode = F3(
		function (sub, givenName, picture) {
			return A2(
				elm$json$Json$Decode$andThen,
				A3(familyNameDecode, sub, givenName, picture),
				elm$json$Json$Decode$maybe(
					A2(elm$json$Json$Decode$field, 'family_name', elm$json$Json$Decode$string)));
		});
	var givenNameDecode = F2(
		function (sub, givenName) {
			return A2(
				elm$json$Json$Decode$andThen,
				A2(pictureDecode, sub, givenName),
				A2(
					elm$json$Json$Decode$map,
					elm$core$Maybe$andThen(elm$url$Url$fromString),
					elm$json$Json$Decode$maybe(
						A2(elm$json$Json$Decode$field, 'picture', elm$json$Json$Decode$string))));
		});
	var subDecode = function (sub) {
		return A2(
			elm$json$Json$Decode$andThen,
			givenNameDecode(sub),
			elm$json$Json$Decode$maybe(
				A2(elm$json$Json$Decode$field, 'given_name', elm$json$Json$Decode$string)));
	};
	return A2(
		elm$json$Json$Decode$andThen,
		subDecode,
		A2(elm$json$Json$Decode$field, 'sub', elm$json$Json$Decode$string));
}();
var author$project$Data$UserInfo$loadFromServer = F2(
	function (accessToken, toMsg) {
		return A2(
			author$project$Api$GCP$request,
			A2(author$project$Data$GetError$expect, author$project$Data$UserInfo$decoder, toMsg),
			A2(
				author$project$Api$GCP$withAccessToken,
				accessToken,
				A2(
					author$project$Api$GCP$withPath,
					_List_fromArray(
						['userInfo']),
					author$project$Api$GCP$get)));
	});
var elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + elm$core$String$fromInt(port_));
		}
	});
var elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var elm$url$Url$toString = function (url) {
	var http = function () {
		var _n0 = url.f$;
		if (!_n0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		elm$url$Url$addPrefixed,
		'#',
		url.dE,
		A3(
			elm$url$Url$addPrefixed,
			'?',
			url.f0,
			_Utils_ap(
				A2(
					elm$url$Url$addPort,
					url.fX,
					_Utils_ap(http, url.fs)),
				url.da)));
};
var elm$json$Json$Encode$null = _Json_encodeNull;
var elm_community$json_extra$Json$Encode$Extra$maybe = function (encoder) {
	return A2(
		elm$core$Basics$composeR,
		elm$core$Maybe$map(encoder),
		elm$core$Maybe$withDefault(elm$json$Json$Encode$null));
};
var author$project$Data$UserInfo$encode = function (userInfo) {
	return elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'sub',
				elm$json$Json$Encode$string(userInfo.dh)),
				_Utils_Tuple2(
				'given_name',
				A2(elm_community$json_extra$Json$Encode$Extra$maybe, elm$json$Json$Encode$string, userInfo.cQ)),
				_Utils_Tuple2(
				'family_name',
				A2(elm_community$json_extra$Json$Encode$Extra$maybe, elm$json$Json$Encode$string, userInfo.cP)),
				_Utils_Tuple2(
				'nickname',
				A2(elm_community$json_extra$Json$Encode$Extra$maybe, elm$json$Json$Encode$string, userInfo.c5)),
				_Utils_Tuple2(
				'name',
				A2(elm_community$json_extra$Json$Encode$Extra$maybe, elm$json$Json$Encode$string, userInfo.bA)),
				_Utils_Tuple2(
				'picture',
				A2(
					elm_community$json_extra$Json$Encode$Extra$maybe,
					elm$json$Json$Encode$string,
					A2(elm$core$Maybe$map, elm$url$Url$toString, userInfo.eo))),
				_Utils_Tuple2(
				'locale',
				A2(elm_community$json_extra$Json$Encode$Extra$maybe, elm$json$Json$Encode$string, userInfo.d0)),
				_Utils_Tuple2(
				'updated_at',
				A2(elm_community$json_extra$Json$Encode$Extra$maybe, elm$json$Json$Encode$string, userInfo.eM))
			]));
};
var author$project$Data$UserInfo$localStorageKey = 'userInfo';
var author$project$Data$UserInfo$saveToLocalStorage = function (campaign) {
	return author$project$Ports$LocalStorage$storageSetItem(
		_Utils_Tuple2(
			author$project$Data$UserInfo$localStorageKey,
			author$project$Data$UserInfo$encode(campaign)));
};
var author$project$Page$Initialize$GotDraftLoadResponse = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var author$project$Page$Initialize$GotSolutionSaveResponse = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var author$project$Page$Initialize$GotUserInfoResponse = function (a) {
	return {$: 1, a: a};
};
var author$project$Page$Initialize$Saving = function (a) {
	return {$: 0, a: a};
};
var author$project$Page$Initialize$isSaving = function (saving) {
	if (!saving.$) {
		return true;
	} else {
		return false;
	}
};
var author$project$Page$Initialize$withActualDrafts = F2(
	function (actualDrafts, model) {
		return _Utils_update(
			model,
			{C: actualDrafts});
	});
var author$project$Route$toString = function (route) {
	var pieces = function () {
		switch (route.$) {
			case 0:
				return _List_Nil;
			case 1:
				if (route.b.$ === 1) {
					var campaignId = route.a;
					var _n1 = route.b;
					return _List_fromArray(
						['campaign', campaignId]);
				} else {
					var campaignId = route.a;
					var levelId = route.b.a;
					return _List_fromArray(
						['campaign', campaignId, 'level', levelId]);
				}
			case 2:
				return _List_fromArray(
					['campaigns']);
			case 7:
				return _List_fromArray(
					['credits']);
			case 3:
				var draftId = route.a;
				return _List_fromArray(
					[
						'drafts',
						author$project$Data$DraftId$toString(draftId)
					]);
			case 4:
				var draftId = route.a;
				return _List_fromArray(
					[
						'drafts',
						author$project$Data$DraftId$toString(draftId),
						'execute'
					]);
			case 5:
				if (route.a.$ === 1) {
					var _n2 = route.a;
					return _List_fromArray(
						['blueprints']);
				} else {
					var levelId = route.a.a;
					return _List_fromArray(
						['blueprints', levelId]);
				}
			case 6:
				var levelId = route.a;
				return _List_fromArray(
					['blueprints', levelId, 'edit']);
			default:
				return _List_fromArray(
					['notFound']);
		}
	}();
	return '/#' + A2(elm$core$String$join, '/', pieces);
};
var elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var elm$browser$Browser$Dom$NotFound = elm$core$Basics$identity;
var elm$core$Basics$never = function (_n0) {
	never:
	while (true) {
		var nvr = _n0;
		var $temp$_n0 = nvr;
		_n0 = $temp$_n0;
		continue never;
	}
};
var elm$core$Task$Perform = elm$core$Basics$identity;
var elm$core$Task$init = elm$core$Task$succeed(0);
var elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			elm$core$Task$andThen,
			function (a) {
				return elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var elm$core$Task$spawnCmd = F2(
	function (router, _n0) {
		var task = _n0;
		return _Scheduler_spawn(
			A2(
				elm$core$Task$andThen,
				elm$core$Platform$sendToApp(router),
				task));
	});
var elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			elm$core$Task$map,
			function (_n0) {
				return 0;
			},
			elm$core$Task$sequence(
				A2(
					elm$core$List$map,
					elm$core$Task$spawnCmd(router),
					commands)));
	});
var elm$core$Task$onSelfMsg = F3(
	function (_n0, _n1, _n2) {
		return elm$core$Task$succeed(0);
	});
var elm$core$Task$cmdMap = F2(
	function (tagger, _n0) {
		var task = _n0;
		return A2(elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager(elm$core$Task$init, elm$core$Task$onEffects, elm$core$Task$onSelfMsg, elm$core$Task$cmdMap);
var elm$core$Task$command = _Platform_leaf('Task');
var elm$core$Task$perform = F2(
	function (toMessage, task) {
		return elm$core$Task$command(
			A2(elm$core$Task$map, toMessage, task));
	});
var elm$json$Json$Decode$map2 = _Json_map2;
var elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var elm$browser$Browser$Navigation$replaceUrl = _Browser_replaceUrl;
var author$project$Route$replaceUrl = F2(
	function (key, route) {
		return A2(
			elm$browser$Browser$Navigation$replaceUrl,
			key,
			author$project$Route$toString(route));
	});
var elm$core$Dict$member = F2(
	function (key, dict) {
		var _n0 = A2(elm$core$Dict$get, key, dict);
		if (!_n0.$) {
			return true;
		} else {
			return false;
		}
	});
var elm$core$Dict$values = function (dict) {
	return A3(
		elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2(elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var elm_community$maybe_extra$Maybe$Extra$filter = F2(
	function (f, m) {
		var _n0 = A2(elm$core$Maybe$map, f, m);
		if ((!_n0.$) && _n0.a) {
			return m;
		} else {
			return elm$core$Maybe$Nothing;
		}
	});
var elm_community$maybe_extra$Maybe$Extra$isJust = function (m) {
	if (m.$ === 1) {
		return false;
	} else {
		return true;
	}
};
var author$project$Page$Initialize$load = function () {
	var publishSolutions = function (model) {
		var _n5 = model.m;
		if (_n5.$ === 3) {
			var accessToken = _n5.a.eT;
			var unpublishedSolutions = A2(
				elm$core$List$filter,
				A2(
					elm$core$Basics$composeR,
					function ($) {
						return $.b6;
					},
					A2(
						elm$core$Basics$composeR,
						A2(elm_community$basics_extra$Basics$Extra$flip, elm$core$Dict$member, model.al),
						elm$core$Basics$not)),
				A2(
					elm$core$List$filter,
					A2(
						elm$core$Basics$composeR,
						function ($) {
							return $.b6;
						},
						A2(
							elm$core$Basics$composeR,
							A2(elm_community$basics_extra$Basics$Extra$flip, elm$core$Dict$member, model.a4),
							elm$core$Basics$not)),
					elm$core$Dict$values(model.at)));
			var savingSolutions = A3(
				elm$core$List$foldl,
				function (solution) {
					return A2(
						elm$core$Dict$insert,
						solution.b6,
						author$project$Page$Initialize$Saving(solution));
				},
				model.al,
				unpublishedSolutions);
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{al: savingSolutions}),
				elm$core$Platform$Cmd$batch(
					A2(
						elm$core$List$map,
						function (solution) {
							return A3(
								author$project$Data$Solution$saveToServer,
								author$project$Page$Initialize$GotSolutionSaveResponse(solution),
								accessToken,
								solution);
						},
						unpublishedSolutions)));
		} else {
			return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
		}
	};
	var loadUserData = function (model) {
		var _n3 = _Utils_Tuple2(model.m, model.aE);
		if ((_n3.a.$ === 2) && (!_n3.b.$)) {
			var accessToken = _n3.a.a;
			var _n4 = _n3.b;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{aE: krisajenkins$remotedata$RemoteData$Loading}),
				elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							A2(author$project$Data$UserInfo$loadFromServer, accessToken, author$project$Page$Initialize$GotUserInfoResponse)
						])));
		} else {
			return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
		}
	};
	var loadDrafts = function (model) {
		var _n2 = model.m;
		if (_n2.$ === 3) {
			var accessToken = _n2.a.eT;
			var loadingDraftIds = A2(
				elm$core$List$filterMap,
				function (localDraft) {
					return krisajenkins$remotedata$RemoteData$isNotAsked(
						A2(author$project$Data$Cache$get, localDraft.b6, model.C)) ? (elm_community$maybe_extra$Maybe$Extra$isJust(
						A2(
							elm_community$maybe_extra$Maybe$Extra$filter,
							author$project$Data$Draft$eq(localDraft),
							A2(elm$core$Dict$get, localDraft.b6, model.n))) ? elm$core$Maybe$Nothing : elm$core$Maybe$Just(localDraft.b6)) : elm$core$Maybe$Nothing;
				},
				elm$core$Dict$values(model.j));
			return _Utils_Tuple2(
				A3(
					elm_community$basics_extra$Basics$Extra$flip,
					author$project$Page$Initialize$withActualDrafts,
					model,
					A3(elm$core$List$foldl, author$project$Data$Cache$loading, model.C, loadingDraftIds)),
				elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							elm$core$Platform$Cmd$batch(
							A2(
								elm$core$List$map,
								function (draftId) {
									return A3(
										author$project$Data$Draft$loadFromServer,
										author$project$Page$Initialize$GotDraftLoadResponse(draftId),
										accessToken,
										draftId);
								},
								loadingDraftIds))
						])));
		} else {
			return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
		}
	};
	var finish = function (model) {
		var _n0 = model.m;
		switch (_n0.$) {
			case 0:
				var user = A2(author$project$Data$User$withUserInfo, model.a5, model.f.bQ);
				var session = A2(author$project$Data$Session$withUser, user, model.f);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{f: session}),
					A2(author$project$Route$replaceUrl, model.f.fA, model.aX));
			case 1:
				return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
			case 2:
				return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
			default:
				var accessToken = _n0.a.eT;
				var userInfo = _n0.a.cu;
				var hasSavingSolutions = !elm$core$List$isEmpty(
					A2(
						elm$core$List$filter,
						author$project$Page$Initialize$isSaving,
						elm$core$Dict$values(model.al)));
				var hasSavingDrafts = !elm$core$List$isEmpty(
					A2(
						elm$core$List$filter,
						author$project$Page$Initialize$isSaving,
						elm$core$Dict$values(model.aa)));
				var hasDraftConflicts = !elm$core$List$isEmpty(
					A2(
						elm$core$List$filter,
						function (_n1) {
							var local = _n1.a;
							var expected = _n1.b;
							return !_Utils_eq(local, expected);
						},
						A2(
							elm$core$List$map,
							function (draftId) {
								return _Utils_Tuple2(
									A2(elm$core$Dict$get, draftId, model.j),
									A2(elm$core$Dict$get, draftId, model.n));
							},
							elm$core$Dict$keys(model.j))));
				var done = !A2(
					elm$core$List$any,
					elm$core$Basics$identity,
					_List_fromArray(
						[hasDraftConflicts, hasSavingDrafts, hasSavingSolutions]));
				if (done) {
					var user = A2(author$project$Data$User$authorizedUser, accessToken, userInfo);
					var saveDraftsLocallyCmd = elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								elm$core$Platform$Cmd$batch(
								A2(
									elm$core$List$map,
									author$project$Data$Draft$saveToLocalStorage,
									elm$core$Dict$values(model.j))),
								elm$core$Platform$Cmd$batch(
								A2(
									elm$core$List$map,
									author$project$Data$Draft$saveRemoteToLocalStorage,
									elm$core$Dict$values(model.n)))
							]));
					var draftCache = {
						ae: model.C,
						ai: author$project$Data$Cache$fromValueDict(
							A2(
								elm$core$Dict$map,
								elm$core$Basics$always(elm$core$Maybe$Just),
								model.n)),
						aj: author$project$Data$Cache$fromValueDict(
							A2(
								elm$core$Dict$map,
								elm$core$Basics$always(elm$core$Maybe$Just),
								model.j))
					};
					var session = A2(
						author$project$Data$Session$withDraftCache,
						draftCache,
						A2(author$project$Data$Session$withUser, user, model.f));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{f: session}),
						elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									author$project$Data$AccessToken$saveToLocalStorage(accessToken),
									author$project$Data$UserInfo$saveToLocalStorage(userInfo),
									saveDraftsLocallyCmd,
									A2(author$project$Route$replaceUrl, model.f.fA, model.aX)
								])));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
		}
	};
	return author$project$Extra$Cmd$fold(
		_List_fromArray(
			[loadUserData, loadDrafts, publishSolutions, finish]));
}();
var author$project$Page$NotFound$load = function (model) {
	return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
};
var author$project$Main$load = function (_n0) {
	var mainModel = _n0.a;
	var cmd = _n0.b;
	return A2(
		author$project$Extra$Cmd$withExtraCmd,
		cmd,
		function () {
			switch (mainModel.$) {
				case 0:
					var model = mainModel.a;
					return A3(
						author$project$Main$updateWith,
						author$project$Main$Home,
						author$project$Main$HomeMsg,
						author$project$Page$Home$load(model));
				case 1:
					var model = mainModel.a;
					return A3(
						author$project$Main$updateWith,
						author$project$Main$Campaign,
						author$project$Main$CampaignMsg,
						author$project$Page$Campaign$load(model));
				case 2:
					var model = mainModel.a;
					return A3(
						author$project$Main$updateWith,
						author$project$Main$Campaigns,
						author$project$Main$CampaignsMsg,
						author$project$Page$Campaigns$load(model));
				case 3:
					var model = mainModel.a;
					return A3(
						author$project$Main$updateWith,
						author$project$Main$Credits,
						author$project$Main$CreditsMsg,
						author$project$Page$Credits$load(model));
				case 4:
					var model = mainModel.a;
					return A3(
						author$project$Main$updateWith,
						author$project$Main$Execution,
						author$project$Main$ExecutionMsg,
						author$project$Page$Execution$load(model));
				case 5:
					var model = mainModel.a;
					return A3(
						author$project$Main$updateWith,
						author$project$Main$Draft,
						author$project$Main$DraftMsg,
						author$project$Page$Draft$load(model));
				case 6:
					var model = mainModel.a;
					return A3(
						author$project$Main$updateWith,
						author$project$Main$Blueprint,
						author$project$Main$BlueprintMsg,
						author$project$Page$Blueprint$load(model));
				case 7:
					var model = mainModel.a;
					return A3(
						author$project$Main$updateWith,
						author$project$Main$Blueprints,
						author$project$Main$BlueprintsMsg,
						author$project$Page$Blueprints$load(model));
				case 8:
					var model = mainModel.a;
					return A3(
						author$project$Main$updateWith,
						author$project$Main$Initialize,
						author$project$Main$InitializeMsg,
						author$project$Page$Initialize$load(model));
				default:
					var model = mainModel.a;
					return A3(
						author$project$Main$updateWith,
						author$project$Main$NotFound,
						author$project$Main$NotFoundMsg,
						author$project$Page$NotFound$load(model));
			}
		}());
};
var author$project$Api$Auth0$stateDecoder = function () {
	var routeDecoder = A2(
		elm$json$Json$Decode$map,
		elm$core$Maybe$withDefault(''),
		elm$json$Json$Decode$maybe(
			A2(elm$json$Json$Decode$field, 'route', elm$json$Json$Decode$string)));
	return A2(
		elm$json$Json$Decode$andThen,
		function (route) {
			return elm$json$Json$Decode$succeed(
				{aX: route});
		},
		routeDecoder);
}();
var author$project$Data$AccessToken$AccessToken = elm$core$Basics$identity;
var author$project$Data$AccessToken$fromString = elm$core$Basics$identity;
var author$project$Route$Home = {$: 0};
var elm$url$Url$Parser$Parser = elm$core$Basics$identity;
var elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {a6: frag, bf: params, a1: unvisited, ay: value, bk: visited};
	});
var elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_n0) {
			var visited = _n0.bk;
			var unvisited = _n0.a1;
			var params = _n0.bf;
			var frag = _n0.a6;
			var value = _n0.ay;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _n2 = stringToSomething(next);
				if (!_n2.$) {
					var nextValue = _n2.a;
					return _List_fromArray(
						[
							A5(
							elm$url$Url$Parser$State,
							A2(elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var author$project$Data$CampaignId$urlParser = A2(
	elm$url$Url$Parser$custom,
	'CAMPAIGN ID',
	function (str) {
		return elm$core$Maybe$Just(str);
	});
var author$project$Data$DraftId$urlParser = A2(
	elm$url$Url$Parser$custom,
	'DRAFT ID',
	function (str) {
		return elm$core$Maybe$Just(str);
	});
var author$project$Data$LevelId$urlParser = A2(
	elm$url$Url$Parser$custom,
	'DRAFT ID',
	function (str) {
		return elm$core$Maybe$Just(str);
	});
var author$project$Route$Blueprint = function (a) {
	return {$: 6, a: a};
};
var author$project$Route$Blueprints = function (a) {
	return {$: 5, a: a};
};
var author$project$Route$Campaign = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var author$project$Route$Campaigns = {$: 2};
var author$project$Route$Credits = {$: 7};
var author$project$Route$EditDraft = function (a) {
	return {$: 3, a: a};
};
var author$project$Route$ExecuteDraft = function (a) {
	return {$: 4, a: a};
};
var elm$url$Url$Parser$mapState = F2(
	function (func, _n0) {
		var visited = _n0.bk;
		var unvisited = _n0.a1;
		var params = _n0.bf;
		var frag = _n0.a6;
		var value = _n0.ay;
		return A5(
			elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var elm$url$Url$Parser$map = F2(
	function (subValue, _n0) {
		var parseArg = _n0;
		return function (_n1) {
			var visited = _n1.bk;
			var unvisited = _n1.a1;
			var params = _n1.bf;
			var frag = _n1.a6;
			var value = _n1.ay;
			return A2(
				elm$core$List$map,
				elm$url$Url$Parser$mapState(value),
				parseArg(
					A5(elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			elm$core$List$concatMap,
			function (_n0) {
				var parser = _n0;
				return parser(state);
			},
			parsers);
	};
};
var elm$url$Url$Parser$s = function (str) {
	return function (_n0) {
		var visited = _n0.bk;
		var unvisited = _n0.a1;
		var params = _n0.bf;
		var frag = _n0.a6;
		var value = _n0.ay;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					elm$url$Url$Parser$State,
					A2(elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var elm$url$Url$Parser$slash = F2(
	function (_n0, _n1) {
		var parseBefore = _n0;
		var parseAfter = _n1;
		return function (state) {
			return A2(
				elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var author$project$Route$parser = elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2(elm$url$Url$Parser$map, author$project$Route$Home, elm$url$Url$Parser$top),
			A2(
			elm$url$Url$Parser$map,
			A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Route$Campaign, elm$core$Maybe$Nothing),
			A2(
				elm$url$Url$Parser$slash,
				elm$url$Url$Parser$s('campaign'),
				author$project$Data$CampaignId$urlParser)),
			A2(
			elm$url$Url$Parser$map,
			author$project$Route$Campaigns,
			elm$url$Url$Parser$s('campaigns')),
			A2(
			elm$url$Url$Parser$map,
			F2(
				function (campaignId, levelId) {
					return A2(
						author$project$Route$Campaign,
						campaignId,
						elm$core$Maybe$Just(levelId));
				}),
			A2(
				elm$url$Url$Parser$slash,
				elm$url$Url$Parser$s('campaign'),
				A2(
					elm$url$Url$Parser$slash,
					author$project$Data$CampaignId$urlParser,
					A2(
						elm$url$Url$Parser$slash,
						elm$url$Url$Parser$s('level'),
						author$project$Data$LevelId$urlParser)))),
			A2(
			elm$url$Url$Parser$map,
			author$project$Route$EditDraft,
			A2(
				elm$url$Url$Parser$slash,
				elm$url$Url$Parser$s('drafts'),
				author$project$Data$DraftId$urlParser)),
			A2(
			elm$url$Url$Parser$map,
			author$project$Route$ExecuteDraft,
			A2(
				elm$url$Url$Parser$slash,
				elm$url$Url$Parser$s('drafts'),
				A2(
					elm$url$Url$Parser$slash,
					author$project$Data$DraftId$urlParser,
					elm$url$Url$Parser$s('execute')))),
			A2(
			elm$url$Url$Parser$map,
			author$project$Route$Blueprints(elm$core$Maybe$Nothing),
			elm$url$Url$Parser$s('blueprints')),
			A2(
			elm$url$Url$Parser$map,
			A2(elm$core$Basics$composeL, author$project$Route$Blueprints, elm$core$Maybe$Just),
			A2(
				elm$url$Url$Parser$slash,
				elm$url$Url$Parser$s('blueprints'),
				author$project$Data$LevelId$urlParser)),
			A2(
			elm$url$Url$Parser$map,
			author$project$Route$Blueprint,
			A2(
				elm$url$Url$Parser$slash,
				elm$url$Url$Parser$s('blueprints'),
				A2(
					elm$url$Url$Parser$slash,
					author$project$Data$LevelId$urlParser,
					elm$url$Url$Parser$s('edit')))),
			A2(
			elm$url$Url$Parser$map,
			author$project$Route$Credits,
			elm$url$Url$Parser$s('credits'))
		]));
var elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _n1 = state.a1;
			if (!_n1.b) {
				return elm$core$Maybe$Just(state.ay);
			} else {
				if ((_n1.a === '') && (!_n1.b.b)) {
					return elm$core$Maybe$Just(state.ay);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				elm$core$List$cons,
				segment,
				elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var elm$url$Url$Parser$preparePath = function (path) {
	var _n0 = A2(elm$core$String$split, '/', path);
	if (_n0.b && (_n0.a === '')) {
		var segments = _n0.b;
		return elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _n0;
		return elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var elm$url$Url$percentDecode = _Url_percentDecode;
var elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return elm$core$Maybe$Just(
				A2(elm$core$List$cons, value, list));
		}
	});
var elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _n0 = A2(elm$core$String$split, '=', segment);
		if ((_n0.b && _n0.b.b) && (!_n0.b.b.b)) {
			var rawKey = _n0.a;
			var _n1 = _n0.b;
			var rawValue = _n1.a;
			var _n2 = elm$url$Url$percentDecode(rawKey);
			if (_n2.$ === 1) {
				return dict;
			} else {
				var key = _n2.a;
				var _n3 = elm$url$Url$percentDecode(rawValue);
				if (_n3.$ === 1) {
					return dict;
				} else {
					var value = _n3.a;
					return A3(
						elm$core$Dict$update,
						key,
						elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			elm$core$List$foldr,
			elm$url$Url$Parser$addParam,
			elm$core$Dict$empty,
			A2(elm$core$String$split, '&', qry));
	}
};
var elm$url$Url$Parser$parse = F2(
	function (_n0, url) {
		var parser = _n0;
		return elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					elm$url$Url$Parser$State,
					_List_Nil,
					elm$url$Url$Parser$preparePath(url.da),
					elm$url$Url$Parser$prepareQuery(url.f0),
					url.dE,
					elm$core$Basics$identity)));
	});
var author$project$Route$fromUrl = function (url) {
	return A2(
		elm$url$Url$Parser$parse,
		author$project$Route$parser,
		_Utils_update(
			url,
			{
				dE: elm$core$Maybe$Nothing,
				da: A2(elm$core$Maybe$withDefault, '', url.dE)
			}));
};
var elm$core$Result$toMaybe = function (result) {
	if (!result.$) {
		var v = result.a;
		return elm$core$Maybe$Just(v);
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var author$project$Api$Auth0$loginResponseFromUrl = function (url) {
	var fragmentParameters = elm$core$Dict$fromList(
		elm_community$maybe_extra$Maybe$Extra$values(
			A2(
				elm$core$List$map,
				function (list) {
					if ((list.b && list.b.b) && (!list.b.b.b)) {
						var key = list.a;
						var _n2 = list.b;
						var value = _n2.a;
						return elm$core$Maybe$Just(
							_Utils_Tuple2(key, value));
					} else {
						return elm$core$Maybe$Nothing;
					}
				},
				A2(
					elm$core$List$map,
					elm$core$String$split('='),
					A2(
						elm$core$String$split,
						'&',
						A2(elm$core$Maybe$withDefault, '', url.dE))))));
	var maybeAccessToken = A2(
		elm$core$Maybe$map,
		author$project$Data$AccessToken$fromString,
		A2(elm$core$Dict$get, 'access_token', fragmentParameters));
	var maybeExpiresIn = A2(
		elm$core$Maybe$andThen,
		elm$core$String$toInt,
		A2(elm$core$Dict$get, 'expires_in', fragmentParameters));
	var state = A2(
		elm$core$Maybe$withDefault,
		{aX: ''},
		A2(
			elm$core$Maybe$andThen,
			A2(
				elm$core$Basics$composeR,
				elm$json$Json$Decode$decodeString(author$project$Api$Auth0$stateDecoder),
				elm$core$Result$toMaybe),
			A2(
				elm$core$Maybe$andThen,
				elm$url$Url$percentDecode,
				A2(elm$core$Dict$get, 'state', fragmentParameters))));
	var route = A2(
		elm$core$Maybe$withDefault,
		author$project$Route$Home,
		author$project$Route$fromUrl(
			_Utils_update(
				url,
				{
					dE: elm$core$Maybe$Just(state.aX)
				})));
	var _n0 = _Utils_Tuple2(maybeAccessToken, maybeExpiresIn);
	if ((!_n0.a.$) && (!_n0.b.$)) {
		var accessToken = _n0.a.a;
		var expiresIn = _n0.b.a;
		return elm$core$Maybe$Just(
			{eT: accessToken, fi: expiresIn, aX: route});
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var author$project$Data$AccessToken$decoder = A2(
	elm$json$Json$Decode$andThen,
	function (version) {
		if (version === 1) {
			return A2(
				elm$json$Json$Decode$andThen,
				function (accessToken) {
					return elm$json$Json$Decode$succeed(
						author$project$Data$AccessToken$fromString(accessToken));
				},
				A2(elm$json$Json$Decode$field, 'accessToken', elm$json$Json$Decode$string));
		} else {
			return elm$json$Json$Decode$fail(
				'Unknown version: ' + elm$core$String$fromInt(version));
		}
	},
	A2(elm$json$Json$Decode$field, 'version', elm$json$Json$Decode$int));
var author$project$Data$RequestResult$constructor = F2(
	function (request, result) {
		return {iD: request, iE: result};
	});
var elm$json$Json$Decode$decodeValue = _Json_run;
var elm$json$Json$Decode$null = _Json_decodeNull;
var elm$json$Json$Decode$nullable = function (decoder) {
	return elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				elm$json$Json$Decode$null(elm$core$Maybe$Nothing),
				A2(elm$json$Json$Decode$map, elm$core$Maybe$Just, decoder)
			]));
};
var author$project$Data$AccessToken$localStorageResponse = function (_n0) {
	var key = _n0.a;
	var value = _n0.b;
	return _Utils_eq(key, author$project$Data$AccessToken$localStorageKey) ? elm$core$Maybe$Just(
		A2(
			author$project$Data$RequestResult$constructor,
			'accessToken',
			A2(
				elm$json$Json$Decode$decodeValue,
				elm$json$Json$Decode$nullable(author$project$Data$AccessToken$decoder),
				value))) : elm$core$Maybe$Nothing;
};
var author$project$Data$Cache$empty = elm$core$Dict$empty;
var author$project$Data$Draft$localRemoteStorageResponse = function (_n0) {
	var key = _n0.a;
	var value = _n0.b;
	var _n1 = A2(elm$core$String$split, '.', key);
	if (((((_n1.b && (_n1.a === 'drafts')) && _n1.b.b) && _n1.b.b.b) && (_n1.b.b.a === 'remote')) && (!_n1.b.b.b.b)) {
		var _n2 = _n1.b;
		var draftId = _n2.a;
		var _n3 = _n2.b;
		return elm$core$Maybe$Just(
			A2(
				author$project$Data$RequestResult$constructor,
				draftId,
				A2(
					elm$json$Json$Decode$decodeValue,
					elm$json$Json$Decode$nullable(author$project$Data$Draft$decoder),
					value)));
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var author$project$Data$Draft$localStorageResponse = function (_n0) {
	var key = _n0.a;
	var value = _n0.b;
	var _n1 = A2(elm$core$String$split, '.', key);
	if (((_n1.b && (_n1.a === 'drafts')) && _n1.b.b) && (!_n1.b.b.b)) {
		var _n2 = _n1.b;
		var draftId = _n2.a;
		return elm$core$Maybe$Just(
			A2(
				author$project$Data$RequestResult$constructor,
				draftId,
				A2(
					elm$json$Json$Decode$decodeValue,
					elm$json$Json$Decode$nullable(author$project$Data$Draft$decoder),
					value)));
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var elm$core$Set$Set_elm_builtin = elm$core$Basics$identity;
var elm$core$Set$empty = elm$core$Dict$empty;
var author$project$Data$DraftBook$empty = function (levelId) {
	return {aK: elm$core$Set$empty, a8: levelId};
};
var elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3(elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3(elm$core$Dict$foldl, elm$core$Dict$insert, t2, t1);
	});
var elm$core$Set$union = F2(
	function (_n0, _n1) {
		var dict1 = _n0;
		var dict2 = _n1;
		return A2(elm$core$Dict$union, dict1, dict2);
	});
var author$project$Data$DraftBook$withDraftIds = F2(
	function (draftIds, draftBook) {
		return _Utils_update(
			draftBook,
			{
				aK: A2(elm$core$Set$union, draftBook.aK, draftIds)
			});
	});
var elm$core$Set$insert = F2(
	function (key, _n0) {
		var dict = _n0;
		return A3(elm$core$Dict$insert, key, 0, dict);
	});
var elm$core$Set$fromList = function (list) {
	return A3(elm$core$List$foldl, elm$core$Set$insert, elm$core$Set$empty, list);
};
var author$project$Extra$Decode$set = function (decoder) {
	return A2(
		elm$json$Json$Decode$map,
		elm$core$Set$fromList,
		elm$json$Json$Decode$list(decoder));
};
var author$project$Data$DraftBook$localStorageResponse = function (_n0) {
	var key = _n0.a;
	var value = _n0.b;
	var _n1 = A2(elm$core$String$split, '.', key);
	if (((((_n1.b && (_n1.a === 'levels')) && _n1.b.b) && _n1.b.b.b) && (_n1.b.b.a === 'draftBook')) && (!_n1.b.b.b.b)) {
		var _n2 = _n1.b;
		var levelId = _n2.a;
		var _n3 = _n2.b;
		var localStorageDecoder = A2(
			elm$json$Json$Decode$map,
			elm$core$Maybe$withDefault(
				author$project$Data$DraftBook$empty(levelId)),
			elm$json$Json$Decode$nullable(
				A2(
					elm$json$Json$Decode$map,
					A2(
						elm_community$basics_extra$Basics$Extra$flip,
						author$project$Data$DraftBook$withDraftIds,
						author$project$Data$DraftBook$empty(levelId)),
					author$project$Extra$Decode$set(author$project$Data$DraftId$decoder))));
		return elm$core$Maybe$Just(
			A2(
				author$project$Data$RequestResult$constructor,
				levelId,
				A2(elm$json$Json$Decode$decodeValue, localStorageDecoder, value)));
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var author$project$Data$RequestResult$extractMaybe = function (_n0) {
	var request = _n0.iD;
	var result = _n0.iE;
	if (!result.$) {
		if (!result.a.$) {
			var value = result.a.a;
			return elm$core$Maybe$Just(
				{
					iD: request,
					iE: elm$core$Result$Ok(value)
				});
		} else {
			var _n2 = result.a;
			return elm$core$Maybe$Nothing;
		}
	} else {
		var error = result.a;
		return elm$core$Maybe$Just(
			{
				iD: request,
				iE: elm$core$Result$Err(error)
			});
	}
};
var author$project$Data$RequestResult$split = function (list) {
	if (list.b) {
		var request = list.a.iD;
		var result = list.a.iE;
		var tail = list.b;
		var _n1 = author$project$Data$RequestResult$split(tail);
		var values = _n1.a;
		var errors = _n1.b;
		if (!result.$) {
			var value = result.a;
			return _Utils_Tuple2(
				A2(
					elm$core$List$cons,
					_Utils_Tuple2(request, value),
					values),
				errors);
		} else {
			var error = result.a;
			return _Utils_Tuple2(
				values,
				A2(
					elm$core$List$cons,
					_Utils_Tuple2(request, error),
					errors));
		}
	} else {
		return _Utils_Tuple2(_List_Nil, _List_Nil);
	}
};
var author$project$Data$RemoteCache$empty = {ae: author$project$Data$Cache$empty, ai: author$project$Data$Cache$empty, aj: author$project$Data$Cache$empty};
var author$project$Data$User$guest = {eT: elm$core$Maybe$Nothing, cu: elm$core$Maybe$Nothing};
var author$project$Data$Session$init = F2(
	function (key, url) {
		return {aG: author$project$Data$Cache$empty, aJ: author$project$Data$Cache$empty, dC: author$project$Data$RemoteCache$empty, U: author$project$Data$Cache$empty, fA: key, as: author$project$Data$Cache$empty, aZ: author$project$Data$Cache$empty, ex: author$project$Data$RemoteCache$empty, jw: url, bQ: author$project$Data$User$guest};
	});
var author$project$Data$Solution$localRemoteStorageResponse = function (_n0) {
	var key = _n0.a;
	var value = _n0.b;
	var _n1 = A2(elm$core$String$split, '.', key);
	if (((((_n1.b && (_n1.a === 'solutions')) && _n1.b.b) && _n1.b.b.b) && (_n1.b.b.a === 'remote')) && (!_n1.b.b.b.b)) {
		var _n2 = _n1.b;
		var solutionId = _n2.a;
		var _n3 = _n2.b;
		return elm$core$Maybe$Just(
			A2(
				author$project$Data$RequestResult$constructor,
				solutionId,
				A2(
					elm$json$Json$Decode$decodeValue,
					elm$json$Json$Decode$nullable(author$project$Data$Solution$decoder),
					value)));
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var author$project$Data$Solution$localStorageResponse = function (_n0) {
	var key = _n0.a;
	var value = _n0.b;
	var _n1 = A2(elm$core$String$split, '.', key);
	if (((_n1.b && (_n1.a === 'solutions')) && _n1.b.b) && (!_n1.b.b.b)) {
		var _n2 = _n1.b;
		var solutionId = _n2.a;
		return elm$core$Maybe$Just(
			A2(
				author$project$Data$RequestResult$constructor,
				solutionId,
				A2(
					elm$json$Json$Decode$decodeValue,
					elm$json$Json$Decode$nullable(author$project$Data$Solution$decoder),
					value)));
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var author$project$Data$SolutionBook$empty = function (levelId) {
	return {a8: levelId, iY: elm$core$Set$empty};
};
var author$project$Data$SolutionBook$withSolutionIds = F2(
	function (solutionIds, levelSolutions) {
		return _Utils_update(
			levelSolutions,
			{
				iY: A2(elm$core$Set$union, levelSolutions.iY, solutionIds)
			});
	});
var author$project$Data$SolutionBook$localStorageResponse = function (_n0) {
	var key = _n0.a;
	var value = _n0.b;
	var _n1 = A2(elm$core$String$split, '.', key);
	if (((((_n1.b && (_n1.a === 'levels')) && _n1.b.b) && _n1.b.b.b) && (_n1.b.b.a === 'solutionBook')) && (!_n1.b.b.b.b)) {
		var _n2 = _n1.b;
		var levelId = _n2.a;
		var _n3 = _n2.b;
		var localStorageDecoder = A2(
			elm$json$Json$Decode$map,
			elm$core$Maybe$withDefault(
				author$project$Data$SolutionBook$empty(levelId)),
			elm$json$Json$Decode$nullable(
				A2(
					elm$json$Json$Decode$map,
					A2(
						elm_community$basics_extra$Basics$Extra$flip,
						author$project$Data$SolutionBook$withSolutionIds,
						author$project$Data$SolutionBook$empty(levelId)),
					author$project$Extra$Decode$set(author$project$Data$SolutionId$decoder))));
		return elm$core$Maybe$Just(
			A2(
				author$project$Data$RequestResult$constructor,
				levelId,
				A2(elm$json$Json$Decode$decodeValue, localStorageDecoder, value)));
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var author$project$Data$UserInfo$localStorageResponse = function (_n0) {
	var key = _n0.a;
	var value = _n0.b;
	return _Utils_eq(key, author$project$Data$UserInfo$localStorageKey) ? elm$core$Maybe$Just(
		A2(
			author$project$Data$RequestResult$constructor,
			'userInfo',
			A2(
				elm$json$Json$Decode$decodeValue,
				elm$json$Json$Decode$nullable(author$project$Data$UserInfo$decoder),
				value))) : elm$core$Maybe$Nothing;
};
var author$project$Page$Initialize$Missing = {$: 0};
var author$project$Page$Initialize$Verifying = function (a) {
	return {$: 2, a: a};
};
var author$project$Ports$Console$error = _Platform_outgoingPort('error', elm$core$Basics$identity);
var author$project$Ports$Console$errorString = A2(elm$core$Basics$composeR, elm$json$Json$Encode$string, author$project$Ports$Console$error);
var elm$core$Tuple$second = function (_n0) {
	var y = _n0.b;
	return y;
};
var author$project$Page$Initialize$init = function (_n0) {
	var navigationKey = _n0.ib;
	var localStorageEntries = _n0.c$;
	var url = _n0.jw;
	var expectedUserInfoResult = A2(
		elm$core$Maybe$map,
		function ($) {
			return $.iE;
		},
		A2(
			elm$core$Maybe$andThen,
			author$project$Data$RequestResult$extractMaybe,
			elm$core$List$head(
				A2(elm$core$List$filterMap, author$project$Data$UserInfo$localStorageResponse, localStorageEntries))));
	var _n1 = author$project$Data$RequestResult$split(
		A2(
			elm$core$List$filterMap,
			author$project$Data$RequestResult$extractMaybe,
			A2(elm$core$List$filterMap, author$project$Data$Solution$localStorageResponse, localStorageEntries)));
	var localSolutions = _n1.a;
	var localSolutionErrors = _n1.b;
	var _n2 = author$project$Data$RequestResult$split(
		A2(elm$core$List$filterMap, author$project$Data$SolutionBook$localStorageResponse, localStorageEntries));
	var localSolutionBooks = _n2.a;
	var localSolutionBookErrors = _n2.b;
	var _n3 = author$project$Data$RequestResult$split(
		A2(
			elm$core$List$filterMap,
			author$project$Data$RequestResult$extractMaybe,
			A2(elm$core$List$filterMap, author$project$Data$Draft$localStorageResponse, localStorageEntries)));
	var localDrafts = _n3.a;
	var localDraftErrors = _n3.b;
	var _n4 = author$project$Data$RequestResult$split(
		A2(elm$core$List$filterMap, author$project$Data$DraftBook$localStorageResponse, localStorageEntries));
	var localDraftBooks = _n4.a;
	var localDraftBookErrors = _n4.b;
	var _n5 = author$project$Data$RequestResult$split(
		A2(
			elm$core$List$filterMap,
			author$project$Data$RequestResult$extractMaybe,
			A2(elm$core$List$filterMap, author$project$Data$Solution$localRemoteStorageResponse, localStorageEntries)));
	var expectedSolutions = _n5.a;
	var expectedSolutionErrors = _n5.b;
	var _n6 = author$project$Data$RequestResult$split(
		A2(
			elm$core$List$filterMap,
			author$project$Data$RequestResult$extractMaybe,
			A2(elm$core$List$filterMap, author$project$Data$Draft$localRemoteStorageResponse, localStorageEntries)));
	var expectedDrafts = _n6.a;
	var expectedDraftErrors = _n6.b;
	var _n7 = function () {
		var _n8 = author$project$Api$Auth0$loginResponseFromUrl(url);
		if (!_n8.$) {
			var loginResponse = _n8.a;
			return _Utils_Tuple3(
				loginResponse.aX,
				elm$core$Maybe$Just(loginResponse.eT),
				elm$core$Platform$Cmd$none);
		} else {
			var accessTokenResult = A2(
				elm$core$Maybe$map,
				function ($) {
					return $.iE;
				},
				elm$core$List$head(
					A2(elm$core$List$filterMap, author$project$Data$AccessToken$localStorageResponse, localStorageEntries)));
			return _Utils_Tuple3(
				A2(
					elm$core$Maybe$withDefault,
					author$project$Route$Home,
					author$project$Route$fromUrl(url)),
				elm_community$maybe_extra$Maybe$Extra$join(
					A2(elm$core$Maybe$andThen, elm$core$Result$toMaybe, accessTokenResult)),
				function () {
					if ((!accessTokenResult.$) && (accessTokenResult.a.$ === 1)) {
						var error = accessTokenResult.a.a;
						return author$project$Ports$Console$errorString(
							elm$json$Json$Decode$errorToString(error));
					} else {
						return elm$core$Platform$Cmd$none;
					}
				}());
		}
	}();
	var route = _n7.a;
	var accessToken = _n7.b;
	var accessTokenCmd = _n7.c;
	var cmd = elm$core$Platform$Cmd$batch(
		_List_fromArray(
			[
				accessTokenCmd,
				A2(
				elm$core$Maybe$withDefault,
				elm$core$Platform$Cmd$none,
				A2(
					elm$core$Maybe$map,
					author$project$Ports$Console$errorString,
					A2(
						elm$core$Maybe$map,
						elm$json$Json$Decode$errorToString,
						A2(elm$core$Maybe$andThen, author$project$Extra$Result$getError, expectedUserInfoResult)))),
				elm$core$Platform$Cmd$batch(
				A2(
					elm$core$List$map,
					author$project$Ports$Console$errorString,
					A2(
						elm$core$List$map,
						elm$json$Json$Decode$errorToString,
						A2(elm$core$List$map, elm$core$Tuple$second, localDraftErrors)))),
				elm$core$Platform$Cmd$batch(
				A2(
					elm$core$List$map,
					author$project$Ports$Console$errorString,
					A2(
						elm$core$List$map,
						elm$json$Json$Decode$errorToString,
						A2(elm$core$List$map, elm$core$Tuple$second, expectedDraftErrors)))),
				elm$core$Platform$Cmd$batch(
				A2(
					elm$core$List$map,
					author$project$Ports$Console$errorString,
					A2(
						elm$core$List$map,
						elm$json$Json$Decode$errorToString,
						A2(elm$core$List$map, elm$core$Tuple$second, localDraftBookErrors)))),
				elm$core$Platform$Cmd$batch(
				A2(
					elm$core$List$map,
					author$project$Ports$Console$errorString,
					A2(
						elm$core$List$map,
						elm$json$Json$Decode$errorToString,
						A2(elm$core$List$map, elm$core$Tuple$second, localSolutionErrors)))),
				elm$core$Platform$Cmd$batch(
				A2(
					elm$core$List$map,
					author$project$Ports$Console$errorString,
					A2(
						elm$core$List$map,
						elm$json$Json$Decode$errorToString,
						A2(elm$core$List$map, elm$core$Tuple$second, expectedSolutionErrors)))),
				elm$core$Platform$Cmd$batch(
				A2(
					elm$core$List$map,
					author$project$Ports$Console$errorString,
					A2(
						elm$core$List$map,
						elm$json$Json$Decode$errorToString,
						A2(elm$core$List$map, elm$core$Tuple$second, localSolutionBookErrors))))
			]));
	var model = {
		m: A2(
			elm$core$Maybe$withDefault,
			author$project$Page$Initialize$Missing,
			A2(elm$core$Maybe$map, author$project$Page$Initialize$Verifying, accessToken)),
		C: author$project$Data$Cache$empty,
		bm: author$project$Data$Cache$empty,
		aE: krisajenkins$remotedata$RemoteData$NotAsked,
		n: elm$core$Dict$fromList(expectedDrafts),
		a4: elm$core$Dict$fromList(expectedSolutions),
		a5: A2(elm$core$Maybe$andThen, elm$core$Result$toMaybe, expectedUserInfoResult),
		d$: elm$core$Dict$fromList(localDraftBooks),
		j: elm$core$Dict$fromList(localDrafts),
		aR: elm$core$Dict$fromList(localSolutionBooks),
		at: elm$core$Dict$fromList(localSolutions),
		aX: route,
		aa: elm$core$Dict$empty,
		al: elm$core$Dict$empty,
		f: A2(author$project$Data$Session$init, navigationKey, url)
	};
	return _Utils_Tuple2(model, cmd);
};
var author$project$Main$init = F3(
	function (flags, url, key) {
		return author$project$Main$load(
			A3(
				author$project$Main$updateWith,
				author$project$Main$Initialize,
				author$project$Main$InitializeMsg,
				author$project$Page$Initialize$init(
					{c$: flags.c$, ib: key, jw: url})));
	});
var author$project$Main$LocalStorageResponse = function (a) {
	return {$: 12, a: a};
};
var elm$core$Platform$Sub$batch = _Platform_batch;
var elm$core$Platform$Sub$none = elm$core$Platform$Sub$batch(_List_Nil);
var author$project$Page$Blueprint$subscriptions = function (model) {
	return elm$core$Platform$Sub$none;
};
var author$project$Page$Blueprints$subscriptions = elm$core$Basics$always(elm$core$Platform$Sub$none);
var author$project$Page$Campaign$subscriptions = elm$core$Basics$always(elm$core$Platform$Sub$none);
var author$project$Page$Campaigns$subscriptions = function (model) {
	return elm$core$Platform$Sub$none;
};
var author$project$Page$Credits$subscriptions = function (model) {
	return elm$core$Platform$Sub$none;
};
var author$project$Page$Draft$subscriptions = elm$core$Basics$always(elm$core$Platform$Sub$none);
var author$project$Page$Execution$InternalMsg = function (a) {
	return {$: 0, a: a};
};
var author$project$Page$Execution$Tick = {$: 9};
var elm$browser$Browser$AnimationManager$Time = function (a) {
	return {$: 0, a: a};
};
var elm$browser$Browser$AnimationManager$State = F3(
	function (subs, request, oldTime) {
		return {d8: oldTime, iD: request, gj: subs};
	});
var elm$browser$Browser$AnimationManager$init = elm$core$Task$succeed(
	A3(elm$browser$Browser$AnimationManager$State, _List_Nil, elm$core$Maybe$Nothing, 0));
var elm$browser$Browser$AnimationManager$now = _Browser_now(0);
var elm$browser$Browser$AnimationManager$rAF = _Browser_rAF(0);
var elm$browser$Browser$AnimationManager$onEffects = F3(
	function (router, subs, _n0) {
		var request = _n0.iD;
		var oldTime = _n0.d8;
		var _n1 = _Utils_Tuple2(request, subs);
		if (_n1.a.$ === 1) {
			if (!_n1.b.b) {
				var _n2 = _n1.a;
				return elm$browser$Browser$AnimationManager$init;
			} else {
				var _n4 = _n1.a;
				return A2(
					elm$core$Task$andThen,
					function (pid) {
						return A2(
							elm$core$Task$andThen,
							function (time) {
								return elm$core$Task$succeed(
									A3(
										elm$browser$Browser$AnimationManager$State,
										subs,
										elm$core$Maybe$Just(pid),
										time));
							},
							elm$browser$Browser$AnimationManager$now);
					},
					elm$core$Process$spawn(
						A2(
							elm$core$Task$andThen,
							elm$core$Platform$sendToSelf(router),
							elm$browser$Browser$AnimationManager$rAF)));
			}
		} else {
			if (!_n1.b.b) {
				var pid = _n1.a.a;
				return A2(
					elm$core$Task$andThen,
					function (_n3) {
						return elm$browser$Browser$AnimationManager$init;
					},
					elm$core$Process$kill(pid));
			} else {
				return elm$core$Task$succeed(
					A3(elm$browser$Browser$AnimationManager$State, subs, request, oldTime));
			}
		}
	});
var elm$time$Time$Posix = elm$core$Basics$identity;
var elm$time$Time$millisToPosix = elm$core$Basics$identity;
var elm$browser$Browser$AnimationManager$onSelfMsg = F3(
	function (router, newTime, _n0) {
		var subs = _n0.gj;
		var oldTime = _n0.d8;
		var send = function (sub) {
			if (!sub.$) {
				var tagger = sub.a;
				return A2(
					elm$core$Platform$sendToApp,
					router,
					tagger(
						elm$time$Time$millisToPosix(newTime)));
			} else {
				var tagger = sub.a;
				return A2(
					elm$core$Platform$sendToApp,
					router,
					tagger(newTime - oldTime));
			}
		};
		return A2(
			elm$core$Task$andThen,
			function (pid) {
				return A2(
					elm$core$Task$andThen,
					function (_n1) {
						return elm$core$Task$succeed(
							A3(
								elm$browser$Browser$AnimationManager$State,
								subs,
								elm$core$Maybe$Just(pid),
								newTime));
					},
					elm$core$Task$sequence(
						A2(elm$core$List$map, send, subs)));
			},
			elm$core$Process$spawn(
				A2(
					elm$core$Task$andThen,
					elm$core$Platform$sendToSelf(router),
					elm$browser$Browser$AnimationManager$rAF)));
	});
var elm$browser$Browser$AnimationManager$Delta = function (a) {
	return {$: 1, a: a};
};
var elm$browser$Browser$AnimationManager$subMap = F2(
	function (func, sub) {
		if (!sub.$) {
			var tagger = sub.a;
			return elm$browser$Browser$AnimationManager$Time(
				A2(elm$core$Basics$composeL, func, tagger));
		} else {
			var tagger = sub.a;
			return elm$browser$Browser$AnimationManager$Delta(
				A2(elm$core$Basics$composeL, func, tagger));
		}
	});
_Platform_effectManagers['Browser.AnimationManager'] = _Platform_createManager(elm$browser$Browser$AnimationManager$init, elm$browser$Browser$AnimationManager$onEffects, elm$browser$Browser$AnimationManager$onSelfMsg, 0, elm$browser$Browser$AnimationManager$subMap);
var elm$browser$Browser$AnimationManager$subscription = _Platform_leaf('Browser.AnimationManager');
var elm$browser$Browser$AnimationManager$onAnimationFrame = function (tagger) {
	return elm$browser$Browser$AnimationManager$subscription(
		elm$browser$Browser$AnimationManager$Time(tagger));
};
var elm$browser$Browser$Events$onAnimationFrame = elm$browser$Browser$AnimationManager$onAnimationFrame;
var elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var elm$time$Time$State = F2(
	function (taggers, processes) {
		return {f_: processes, gl: taggers};
	});
var elm$time$Time$init = elm$core$Task$succeed(
	A2(elm$time$Time$State, elm$core$Dict$empty, elm$core$Dict$empty));
var elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _n0) {
				stepState:
				while (true) {
					var list = _n0.a;
					var result = _n0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _n2 = list.a;
						var lKey = _n2.a;
						var lValue = _n2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_n0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_n0 = $temp$_n0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _n3 = A3(
			elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _n3.a;
		var intermediateResult = _n3.b;
		return A3(
			elm$core$List$foldl,
			F2(
				function (_n4, result) {
					var k = _n4.a;
					var v = _n4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var elm$time$Time$addMySub = F2(
	function (_n0, state) {
		var interval = _n0.a;
		var tagger = _n0.b;
		var _n1 = A2(elm$core$Dict$get, interval, state);
		if (_n1.$ === 1) {
			return A3(
				elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _n1.a;
			return A3(
				elm$core$Dict$insert,
				interval,
				A2(elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var elm$time$Time$customZone = elm$time$Time$Zone;
var elm$time$Time$setInterval = _Time_setInterval;
var elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = elm$core$Process$spawn(
				A2(
					elm$time$Time$setInterval,
					interval,
					A2(elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					elm$time$Time$spawnHelp,
					router,
					rest,
					A3(elm$core$Dict$insert, interval, id, processes));
			};
			return A2(elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var elm$time$Time$onEffects = F3(
	function (router, subs, _n0) {
		var processes = _n0.f_;
		var rightStep = F3(
			function (_n6, id, _n7) {
				var spawns = _n7.a;
				var existing = _n7.b;
				var kills = _n7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						elm$core$Task$andThen,
						function (_n5) {
							return kills;
						},
						elm$core$Process$kill(id)));
			});
		var newTaggers = A3(elm$core$List$foldl, elm$time$Time$addMySub, elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _n4) {
				var spawns = _n4.a;
				var existing = _n4.b;
				var kills = _n4.c;
				return _Utils_Tuple3(
					A2(elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _n3) {
				var spawns = _n3.a;
				var existing = _n3.b;
				var kills = _n3.c;
				return _Utils_Tuple3(
					spawns,
					A3(elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _n1 = A6(
			elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				elm$core$Dict$empty,
				elm$core$Task$succeed(0)));
		var spawnList = _n1.a;
		var existingDict = _n1.b;
		var killTask = _n1.c;
		return A2(
			elm$core$Task$andThen,
			function (newProcesses) {
				return elm$core$Task$succeed(
					A2(elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				elm$core$Task$andThen,
				function (_n2) {
					return A3(elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var elm$time$Time$now = _Time_now(elm$time$Time$millisToPosix);
var elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _n0 = A2(elm$core$Dict$get, interval, state.gl);
		if (_n0.$ === 1) {
			return elm$core$Task$succeed(state);
		} else {
			var taggers = _n0.a;
			var tellTaggers = function (time) {
				return elm$core$Task$sequence(
					A2(
						elm$core$List$map,
						function (tagger) {
							return A2(
								elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				elm$core$Task$andThen,
				function (_n1) {
					return elm$core$Task$succeed(state);
				},
				A2(elm$core$Task$andThen, tellTaggers, elm$time$Time$now));
		}
	});
var elm$time$Time$subMap = F2(
	function (f, _n0) {
		var interval = _n0.a;
		var tagger = _n0.b;
		return A2(
			elm$time$Time$Every,
			interval,
			A2(elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager(elm$time$Time$init, elm$time$Time$onEffects, elm$time$Time$onSelfMsg, 0, elm$time$Time$subMap);
var elm$time$Time$subscription = _Platform_leaf('Time');
var elm$time$Time$every = F2(
	function (interval, tagger) {
		return elm$time$Time$subscription(
			A2(elm$time$Time$Every, interval, tagger));
	});
var author$project$Page$Execution$subscriptions = function (model) {
	if (elm_community$maybe_extra$Maybe$Extra$isJust(model.ar)) {
		var _n0 = model.B;
		switch (_n0) {
			case 0:
				return elm$core$Platform$Sub$none;
			case 1:
				return A2(
					elm$time$Time$every,
					250,
					elm$core$Basics$always(
						author$project$Page$Execution$InternalMsg(author$project$Page$Execution$Tick)));
			default:
				return elm$browser$Browser$Events$onAnimationFrame(
					elm$core$Basics$always(
						author$project$Page$Execution$InternalMsg(author$project$Page$Execution$Tick)));
		}
	} else {
		return elm$core$Platform$Sub$none;
	}
};
var author$project$Page$Initialize$subscriptions = elm$core$Basics$always(elm$core$Platform$Sub$none);
var author$project$Page$NotFound$subscriptions = function (model) {
	return elm$core$Platform$Sub$none;
};
var author$project$Ports$LocalStorage$storageGetItemResponse = _Platform_incomingPort(
	'storageGetItemResponse',
	A2(
		elm$json$Json$Decode$andThen,
		function (x0) {
			return A2(
				elm$json$Json$Decode$andThen,
				function (x1) {
					return elm$json$Json$Decode$succeed(
						_Utils_Tuple2(x0, x1));
				},
				A2(elm$json$Json$Decode$index, 1, elm$json$Json$Decode$value));
		},
		A2(elm$json$Json$Decode$index, 0, elm$json$Json$Decode$string)));
var elm$core$Platform$Sub$map = _Platform_map;
var author$project$Main$subscriptions = function (model) {
	var specificSubscriptions = function () {
		switch (model.$) {
			case 0:
				return elm$core$Platform$Sub$none;
			case 1:
				var mdl = model.a;
				return A2(
					elm$core$Platform$Sub$map,
					author$project$Main$CampaignMsg,
					author$project$Page$Campaign$subscriptions(mdl));
			case 2:
				var mdl = model.a;
				return A2(
					elm$core$Platform$Sub$map,
					author$project$Main$CampaignsMsg,
					author$project$Page$Campaigns$subscriptions(mdl));
			case 3:
				var mdl = model.a;
				return A2(
					elm$core$Platform$Sub$map,
					author$project$Main$CreditsMsg,
					author$project$Page$Credits$subscriptions(mdl));
			case 4:
				var mdl = model.a;
				return A2(
					elm$core$Platform$Sub$map,
					author$project$Main$ExecutionMsg,
					author$project$Page$Execution$subscriptions(mdl));
			case 5:
				var mdl = model.a;
				return A2(
					elm$core$Platform$Sub$map,
					author$project$Main$DraftMsg,
					author$project$Page$Draft$subscriptions(mdl));
			case 7:
				var mdl = model.a;
				return A2(
					elm$core$Platform$Sub$map,
					author$project$Main$BlueprintsMsg,
					author$project$Page$Blueprints$subscriptions(mdl));
			case 6:
				var mdl = model.a;
				return A2(
					elm$core$Platform$Sub$map,
					author$project$Main$BlueprintMsg,
					author$project$Page$Blueprint$subscriptions(mdl));
			case 8:
				var mdl = model.a;
				return A2(
					elm$core$Platform$Sub$map,
					author$project$Main$InitializeMsg,
					author$project$Page$Initialize$subscriptions(mdl));
			default:
				var mdl = model.a;
				return A2(
					elm$core$Platform$Sub$map,
					author$project$Main$NotFoundMsg,
					author$project$Page$NotFound$subscriptions(mdl));
		}
	}();
	var localStorageSubscriptions = author$project$Ports$LocalStorage$storageGetItemResponse(author$project$Main$LocalStorageResponse);
	return elm$core$Platform$Sub$batch(
		_List_fromArray(
			[specificSubscriptions, localStorageSubscriptions]));
};
var author$project$Api$Auth0$clientId = 'QnLYsQ4CDaqcGViA43t90z6lo7L77JK6';
var author$project$Api$Auth0$prePath = 'https://dev-253xzd4c.eu.auth0.com';
var author$project$Environment$Production = 1;
var author$project$Environment$environment = 1;
var author$project$Api$Auth0$redirectUri = function () {
	var _n0 = author$project$Environment$environment;
	if (!_n0) {
		return 'http://localhost:3000';
	} else {
		return 'https://efng.simonolander.com';
	}
}();
var author$project$Api$Auth0$returnTo = author$project$Api$Auth0$redirectUri;
var author$project$Api$Auth0$logout = A3(
	elm$url$Url$Builder$crossOrigin,
	author$project$Api$Auth0$prePath,
	_List_fromArray(
		['v2', 'logout']),
	_List_fromArray(
		[
			A2(elm$url$Url$Builder$string, 'client_id', author$project$Api$Auth0$clientId),
			A2(elm$url$Url$Builder$string, 'returnTo', author$project$Api$Auth0$returnTo)
		]));
var author$project$Data$Session$withUrl = F2(
	function (url, session) {
		return _Utils_update(
			session,
			{jw: url});
	});
var author$project$Page$Blueprint$init = F2(
	function (levelId, session) {
		var model = {
			aq: elm$core$Array$empty,
			ff: elm$core$Maybe$Nothing,
			fp: '',
			hU: '',
			aO: elm$core$Array$fromList(author$project$Data$InstructionTool$all),
			a8: levelId,
			c_: elm$core$Maybe$Nothing,
			iu: '',
			cg: elm$core$Maybe$Nothing,
			f: session,
			gy: ''
		};
		return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
	});
var author$project$Page$Blueprints$init = F2(
	function (selectedLevelId, session) {
		var model = {ff: elm$core$Maybe$Nothing, aY: selectedLevelId, f: session};
		return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
	});
var author$project$Page$Campaign$init = F3(
	function (campaignId, selectedLevelId, session) {
		var model = {bW: campaignId, ff: elm$core$Maybe$Nothing, aY: selectedLevelId, f: session};
		return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
	});
var author$project$Page$Campaigns$init = function (session) {
	return _Utils_Tuple2(
		{f: session},
		elm$core$Platform$Cmd$none);
};
var author$project$Page$Credits$init = function (session) {
	return _Utils_Tuple2(
		{f: session},
		elm$core$Platform$Cmd$none);
};
var author$project$Page$Draft$Editing = {$: 0};
var author$project$Page$Draft$init = F2(
	function (draftId, session) {
		var model = {o: draftId, ff: elm$core$Maybe$Nothing, cg: elm$core$Maybe$Nothing, f: session, B: author$project$Page$Draft$Editing};
		return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
	});
var author$project$Page$Execution$Paused = 0;
var author$project$Page$Execution$init = F2(
	function (draftId, session) {
		var model = {o: draftId, ar: elm$core$Maybe$Nothing, c_: elm$core$Maybe$Nothing, f: session, B: 0};
		return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
	});
var author$project$Page$Home$init = function (session) {
	return _Utils_Tuple2(
		{f: session},
		elm$core$Platform$Cmd$none);
};
var author$project$Page$NotFound$init = function (session) {
	return _Utils_Tuple2(
		{f: session},
		elm$core$Platform$Cmd$none);
};
var author$project$Main$changeUrl = F2(
	function (url, oldSession) {
		var session = A2(author$project$Data$Session$withUrl, url, oldSession);
		return author$project$Main$load(
			function () {
				var _n0 = author$project$Route$fromUrl(url);
				if (_n0.$ === 1) {
					return A3(
						author$project$Main$updateWith,
						author$project$Main$NotFound,
						author$project$Main$NotFoundMsg,
						author$project$Page$NotFound$init(session));
				} else {
					switch (_n0.a.$) {
						case 0:
							var _n1 = _n0.a;
							return A3(
								author$project$Main$updateWith,
								author$project$Main$Home,
								author$project$Main$HomeMsg,
								author$project$Page$Home$init(session));
						case 1:
							var _n2 = _n0.a;
							var campaignId = _n2.a;
							var maybeLevelId = _n2.b;
							return A3(
								author$project$Main$updateWith,
								author$project$Main$Campaign,
								author$project$Main$CampaignMsg,
								A3(author$project$Page$Campaign$init, campaignId, maybeLevelId, session));
						case 2:
							var _n3 = _n0.a;
							return A3(
								author$project$Main$updateWith,
								author$project$Main$Campaigns,
								author$project$Main$CampaignsMsg,
								author$project$Page$Campaigns$init(session));
						case 3:
							var draftId = _n0.a.a;
							return A3(
								author$project$Main$updateWith,
								author$project$Main$Draft,
								author$project$Main$DraftMsg,
								A2(author$project$Page$Draft$init, draftId, session));
						case 4:
							var draftId = _n0.a.a;
							return A3(
								author$project$Main$updateWith,
								author$project$Main$Execution,
								author$project$Main$ExecutionMsg,
								A2(author$project$Page$Execution$init, draftId, session));
						case 5:
							var maybeLevelId = _n0.a.a;
							return A3(
								author$project$Main$updateWith,
								author$project$Main$Blueprints,
								author$project$Main$BlueprintsMsg,
								A2(author$project$Page$Blueprints$init, maybeLevelId, session));
						case 6:
							var levelId = _n0.a.a;
							return A3(
								author$project$Main$updateWith,
								author$project$Main$Blueprint,
								author$project$Main$BlueprintMsg,
								A2(author$project$Page$Blueprint$init, levelId, session));
						case 7:
							var _n4 = _n0.a;
							return A3(
								author$project$Main$updateWith,
								author$project$Main$Credits,
								author$project$Main$CreditsMsg,
								author$project$Page$Credits$init(session));
						default:
							var _n5 = _n0.a;
							return A3(
								author$project$Main$updateWith,
								author$project$Main$NotFound,
								author$project$Main$NotFoundMsg,
								author$project$Page$NotFound$init(session));
					}
				}
			}());
	});
var author$project$Page$Blueprint$getSession = function (_n0) {
	var session = _n0.f;
	return session;
};
var author$project$Page$Blueprints$getSession = function (_n0) {
	var session = _n0.f;
	return session;
};
var author$project$Page$Draft$getSession = function (_n0) {
	var session = _n0.f;
	return session;
};
var author$project$Main$getSession = function (model) {
	switch (model.$) {
		case 0:
			var mdl = model.a;
			return mdl.f;
		case 1:
			var mdl = model.a;
			return mdl.f;
		case 2:
			var mdl = model.a;
			return mdl.f;
		case 3:
			var mdl = model.a;
			return mdl.f;
		case 4:
			var mdl = model.a;
			return mdl.f;
		case 5:
			var mdl = model.a;
			return author$project$Page$Draft$getSession(mdl);
		case 7:
			var mdl = model.a;
			return author$project$Page$Blueprints$getSession(mdl);
		case 6:
			var mdl = model.a;
			return author$project$Page$Blueprint$getSession(mdl);
		case 8:
			var mdl = model.a;
			return mdl.f;
		default:
			var mdl = model.a;
			return mdl.f;
	}
};
var author$project$Data$Cache$withError = F2(
	function (key, error) {
		return A2(
			author$project$Data$Cache$insertInternal,
			key,
			krisajenkins$remotedata$RemoteData$Failure(error));
	});
var author$project$Data$Campaign$decoder = A2(
	elm$json$Json$Decode$andThen,
	function (version) {
		if (version === 1) {
			return A2(
				elm$json$Json$Decode$andThen,
				function (id) {
					return A2(
						elm$json$Json$Decode$andThen,
						function (levelIds) {
							return elm$json$Json$Decode$succeed(
								{b6: id, aQ: levelIds});
						},
						A2(
							elm$json$Json$Decode$field,
							'levelIds',
							elm$json$Json$Decode$list(author$project$Data$LevelId$decoder)));
				},
				A2(elm$json$Json$Decode$field, 'id', elm$json$Json$Decode$string));
		} else {
			return elm$json$Json$Decode$fail(
				'Unknown version: ' + elm$core$String$fromInt(version));
		}
	},
	A2(elm$json$Json$Decode$field, 'version', elm$json$Json$Decode$int));
var author$project$Data$Campaign$localStorageResponse = function (_n0) {
	var key = _n0.a;
	var value = _n0.b;
	var _n1 = A2(elm$core$String$split, '.', key);
	if (((_n1.b && (_n1.a === 'campaigns')) && _n1.b.b) && (!_n1.b.b.b)) {
		var _n2 = _n1.b;
		var campaignId = _n2.a;
		return elm$core$Maybe$Just(
			A2(
				author$project$Data$RequestResult$constructor,
				campaignId,
				A2(
					elm$json$Json$Decode$decodeValue,
					elm$json$Json$Decode$nullable(author$project$Data$Campaign$decoder),
					value)));
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var author$project$Data$Level$localStorageResponse = function (_n0) {
	var key = _n0.a;
	var value = _n0.b;
	var _n1 = A2(elm$core$String$split, '.', key);
	if (((_n1.b && (_n1.a === 'levels')) && _n1.b.b) && (!_n1.b.b.b)) {
		var _n2 = _n1.b;
		var levelId = _n2.a;
		return elm$core$Maybe$Just(
			A2(
				author$project$Data$RequestResult$constructor,
				levelId,
				A2(
					elm$json$Json$Decode$decodeValue,
					elm$json$Json$Decode$nullable(author$project$Data$Level$decoder),
					value)));
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var krisajenkins$remotedata$RemoteData$fromResult = function (result) {
	if (result.$ === 1) {
		var e = result.a;
		return krisajenkins$remotedata$RemoteData$Failure(e);
	} else {
		var x = result.a;
		return krisajenkins$remotedata$RemoteData$Success(x);
	}
};
var author$project$Data$Cache$withResult = F3(
	function (key, result, cache) {
		return A3(
			author$project$Data$Cache$insertInternal,
			key,
			krisajenkins$remotedata$RemoteData$fromResult(result),
			cache);
	});
var author$project$Data$RemoteCache$withLocalResult = F3(
	function (key, result, cache) {
		return _Utils_update(
			cache,
			{
				aj: A3(author$project$Data$Cache$withResult, key, result, cache.aj)
			});
	});
var author$project$Data$RemoteCache$withLocalValue = F3(
	function (key, value, cache) {
		return _Utils_update(
			cache,
			{
				aj: A3(author$project$Data$Cache$withValue, key, value, cache.aj)
			});
	});
var author$project$Data$RequestResult$badBody = A2(elm$core$Basics$composeR, elm$json$Json$Decode$errorToString, author$project$Data$GetError$Other);
var author$project$Data$Session$campaignError = F3(
	function (campaignId, error, session) {
		return A2(
			author$project$Data$Session$setCampaignCache,
			session,
			A3(author$project$Data$Cache$withError, campaignId, error, session.aG));
	});
var author$project$Data$Session$setDraftBookCache = F2(
	function (session, cache) {
		return _Utils_update(
			session,
			{aJ: cache});
	});
var author$project$Data$Session$draftBookError = F3(
	function (levelId, error, session) {
		return A2(
			author$project$Data$Session$setDraftBookCache,
			session,
			A3(author$project$Data$Cache$withError, levelId, error, session.aJ));
	});
var author$project$Data$Session$levelError = F3(
	function (levelId, error, session) {
		return A2(
			author$project$Data$Session$setLevelCache,
			session,
			A3(author$project$Data$Cache$withError, levelId, error, session.as));
	});
var author$project$Data$Session$setSolutionBookCache = F2(
	function (session, cache) {
		return _Utils_update(
			session,
			{aZ: cache});
	});
var author$project$Data$Session$solutionBookError = F3(
	function (levelId, error, session) {
		return A2(
			author$project$Data$Session$setSolutionBookCache,
			session,
			A3(author$project$Data$Cache$withError, levelId, error, session.aZ));
	});
var author$project$Data$Cache$map = F3(
	function (key, _function, cache) {
		return A3(
			elm_community$basics_extra$Basics$Extra$flip,
			author$project$Data$Cache$insertInternal(key),
			cache,
			A2(
				krisajenkins$remotedata$RemoteData$map,
				_function,
				A2(author$project$Data$Cache$get, key, cache)));
	});
var author$project$Data$Cache$withDefault = F3(
	function (key, _default, cache) {
		return A3(
			elm_community$basics_extra$Basics$Extra$flip,
			author$project$Data$Cache$withValue(key),
			cache,
			A2(
				krisajenkins$remotedata$RemoteData$withDefault,
				_default,
				A2(author$project$Data$Cache$get, key, cache)));
	});
var author$project$Data$Session$withDraftBook = F2(
	function (draftBook, session) {
		return A2(
			author$project$Data$Session$setDraftBookCache,
			session,
			A3(
				author$project$Data$Cache$map,
				draftBook.a8,
				author$project$Data$DraftBook$withDraftIds(draftBook.aK),
				A3(author$project$Data$Cache$withDefault, draftBook.a8, draftBook, session.aJ)));
	});
var author$project$Data$Session$withLevel = F2(
	function (level, session) {
		return A2(
			author$project$Data$Session$setLevelCache,
			session,
			A3(author$project$Data$Cache$withValue, level.b6, level, session.as));
	});
var author$project$Data$Session$withSolutionBook = F2(
	function (solutionBook, session) {
		return A2(
			author$project$Data$Session$setSolutionBookCache,
			session,
			A3(
				author$project$Data$Cache$map,
				solutionBook.a8,
				author$project$Data$SolutionBook$withSolutionIds(solutionBook.iY),
				A3(author$project$Data$Cache$withDefault, solutionBook.a8, solutionBook, session.aZ)));
	});
var author$project$Main$localStorageResponseUpdate = F2(
	function (response, mainModel) {
		var onSingle = F2(
			function (_n8, _n9) {
				var name = _n8.bA;
				var success = _n8.a_;
				var notFound = _n8.cb;
				var failure = _n8.aM;
				var transform = _n8.a0;
				var mdl = _n9.a;
				var cmd = _n9.b;
				var _n5 = transform(response);
				if (!_n5.$) {
					var request = _n5.a.iD;
					var result = _n5.a.iE;
					if (!result.$) {
						if (!result.a.$) {
							var campaign = result.a.a;
							return _Utils_Tuple2(
								_Utils_update(
									mdl,
									{
										f: A2(success, campaign, mdl.f)
									}),
								cmd);
						} else {
							var _n7 = result.a;
							var errorMessage = '1d2c9ff5    ' + (name + (' ' + (request + ' not found')));
							return _Utils_Tuple2(
								_Utils_update(
									mdl,
									{
										f: A2(notFound, request, mdl.f)
									}),
								elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											cmd,
											author$project$Ports$Console$errorString(errorMessage)
										])));
						}
					} else {
						var error = result.a;
						return _Utils_Tuple2(
							_Utils_update(
								mdl,
								{
									f: A3(failure, request, error, mdl.f)
								}),
							elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										cmd,
										author$project$Ports$Console$errorString(
										elm$json$Json$Decode$errorToString(error))
									])));
					}
				} else {
					return _Utils_Tuple2(mdl, cmd);
				}
			});
		var onSolution = function () {
			var notFound = F2(
				function (solutionId, session) {
					return A3(
						elm_community$basics_extra$Basics$Extra$flip,
						author$project$Data$Session$withSolutionCache,
						session,
						A3(author$project$Data$RemoteCache$withLocalValue, solutionId, elm$core$Maybe$Nothing, session.ex));
				});
			return onSingle(
				{
					aM: F3(
						function (solutionId, error, session) {
							return A3(
								elm_community$basics_extra$Basics$Extra$flip,
								author$project$Data$Session$withSolutionCache,
								session,
								A3(
									author$project$Data$RemoteCache$withLocalResult,
									solutionId,
									elm$core$Result$Err(error),
									session.ex));
						}),
					bA: 'Solution',
					cb: notFound,
					a_: F2(
						function (solution, session) {
							return A3(
								elm_community$basics_extra$Basics$Extra$flip,
								author$project$Data$Session$withSolutionCache,
								session,
								A3(
									author$project$Data$RemoteCache$withLocalValue,
									solution.b6,
									elm$core$Maybe$Just(solution),
									session.ex));
						}),
					a0: author$project$Data$Solution$localStorageResponse
				});
		}();
		var onLevel = onSingle(
			{
				aM: F3(
					function (levelId, error, session) {
						return A3(
							elm_community$basics_extra$Basics$Extra$flip,
							author$project$Data$Session$withLevelCache,
							session,
							A3(
								author$project$Data$Cache$withError,
								levelId,
								author$project$Data$GetError$Other(
									elm$json$Json$Decode$errorToString(error)),
								session.as));
					}),
				bA: 'Level',
				cb: F2(
					function (id, session) {
						return A3(
							author$project$Data$Session$levelError,
							id,
							author$project$Data$GetError$Other('Not found in local storage'),
							session);
					}),
				a_: author$project$Data$Session$withLevel,
				a0: author$project$Data$Level$localStorageResponse
			});
		var onDraft = function () {
			var notFound = F2(
				function (draftId, session) {
					return A3(
						elm_community$basics_extra$Basics$Extra$flip,
						author$project$Data$Session$withDraftCache,
						session,
						A3(author$project$Data$RemoteCache$withLocalValue, draftId, elm$core$Maybe$Nothing, session.dC));
				});
			return onSingle(
				{
					aM: F3(
						function (draftId, error, session) {
							return A3(
								elm_community$basics_extra$Basics$Extra$flip,
								author$project$Data$Session$withDraftCache,
								session,
								A3(
									author$project$Data$RemoteCache$withLocalResult,
									draftId,
									elm$core$Result$Err(error),
									session.dC));
						}),
					bA: 'Draft',
					cb: notFound,
					a_: F2(
						function (draft, session) {
							return A3(
								elm_community$basics_extra$Basics$Extra$flip,
								author$project$Data$Session$withDraftCache,
								session,
								A3(
									author$project$Data$RemoteCache$withLocalValue,
									draft.b6,
									elm$core$Maybe$Just(draft),
									session.dC));
						}),
					a0: author$project$Data$Draft$localStorageResponse
				});
		}();
		var onCollection = F2(
			function (_n3, _n4) {
				var transform = _n3.a0;
				var success = _n3.a_;
				var failure = _n3.aM;
				var mdl = _n4.a;
				var cmd = _n4.b;
				var _n1 = transform(response);
				if (!_n1.$) {
					var request = _n1.a.iD;
					var result = _n1.a.iE;
					if (!result.$) {
						var collection = result.a;
						return _Utils_Tuple2(
							_Utils_update(
								mdl,
								{
									f: A2(success, collection, mdl.f)
								}),
							cmd);
					} else {
						var error = result.a;
						return _Utils_Tuple2(
							_Utils_update(
								mdl,
								{
									f: A3(
										failure,
										request,
										author$project$Data$RequestResult$badBody(error),
										mdl.f)
								}),
							elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										cmd,
										author$project$Ports$Console$errorString(
										elm$json$Json$Decode$errorToString(error))
									])));
					}
				} else {
					return _Utils_Tuple2(mdl, cmd);
				}
			});
		var onDraftBook = onCollection(
			{aM: author$project$Data$Session$draftBookError, a_: author$project$Data$Session$withDraftBook, a0: author$project$Data$DraftBook$localStorageResponse});
		var onSolutionBook = onCollection(
			{aM: author$project$Data$Session$solutionBookError, a_: author$project$Data$Session$withSolutionBook, a0: author$project$Data$SolutionBook$localStorageResponse});
		var onCampaign = onSingle(
			{
				aM: F3(
					function (campaignId, error, session) {
						return A3(
							elm_community$basics_extra$Basics$Extra$flip,
							author$project$Data$Session$withCampaignCache,
							session,
							A3(
								author$project$Data$Cache$withError,
								campaignId,
								author$project$Data$GetError$Other(
									elm$json$Json$Decode$errorToString(error)),
								session.aG));
					}),
				bA: 'Campaign',
				cb: F2(
					function (id, session) {
						return A3(
							author$project$Data$Session$campaignError,
							id,
							author$project$Data$GetError$Other('Not found in local storage'),
							session);
					}),
				a_: author$project$Data$Session$withCampaign,
				a0: author$project$Data$Campaign$localStorageResponse
			});
		var onResponse = function (mdl) {
			return onSolution(
				onSolutionBook(
					onDraftBook(
						onDraft(
							onLevel(
								onCampaign(
									_Utils_Tuple2(mdl, elm$core$Platform$Cmd$none)))))));
		};
		return author$project$Main$load(
			function () {
				switch (mainModel.$) {
					case 0:
						var model = mainModel.a;
						return A3(
							author$project$Main$updateWith,
							author$project$Main$Home,
							author$project$Main$HomeMsg,
							onResponse(model));
					case 1:
						var model = mainModel.a;
						return A3(
							author$project$Main$updateWith,
							author$project$Main$Campaign,
							author$project$Main$CampaignMsg,
							onResponse(model));
					case 2:
						var model = mainModel.a;
						return A3(
							author$project$Main$updateWith,
							author$project$Main$Campaigns,
							author$project$Main$CampaignsMsg,
							onResponse(model));
					case 3:
						var model = mainModel.a;
						return A3(
							author$project$Main$updateWith,
							author$project$Main$Credits,
							author$project$Main$CreditsMsg,
							onResponse(model));
					case 4:
						var model = mainModel.a;
						return A3(
							author$project$Main$updateWith,
							author$project$Main$Execution,
							author$project$Main$ExecutionMsg,
							onResponse(model));
					case 5:
						var model = mainModel.a;
						return A3(
							author$project$Main$updateWith,
							author$project$Main$Draft,
							author$project$Main$DraftMsg,
							onResponse(model));
					case 7:
						var model = mainModel.a;
						return A3(
							author$project$Main$updateWith,
							author$project$Main$Blueprints,
							author$project$Main$BlueprintsMsg,
							onResponse(model));
					case 6:
						var model = mainModel.a;
						return A3(
							author$project$Main$updateWith,
							author$project$Main$Blueprint,
							author$project$Main$BlueprintMsg,
							onResponse(model));
					case 8:
						var model = mainModel.a;
						return A3(
							author$project$Main$updateWith,
							author$project$Main$Initialize,
							author$project$Main$InitializeMsg,
							onResponse(model));
					default:
						var model = mainModel.a;
						return A3(
							author$project$Main$updateWith,
							author$project$Main$NotFound,
							author$project$Main$NotFoundMsg,
							onResponse(model));
				}
			}());
	});
var author$project$Main$withSession = F2(
	function (session, model) {
		switch (model.$) {
			case 0:
				var mdl = model.a;
				return author$project$Main$Home(
					_Utils_update(
						mdl,
						{f: session}));
			case 1:
				var mdl = model.a;
				return author$project$Main$Campaign(
					_Utils_update(
						mdl,
						{f: session}));
			case 2:
				var mdl = model.a;
				return author$project$Main$Campaigns(
					_Utils_update(
						mdl,
						{f: session}));
			case 3:
				var mdl = model.a;
				return author$project$Main$Credits(
					_Utils_update(
						mdl,
						{f: session}));
			case 4:
				var mdl = model.a;
				return author$project$Main$Execution(
					_Utils_update(
						mdl,
						{f: session}));
			case 5:
				var mdl = model.a;
				return author$project$Main$Draft(
					_Utils_update(
						mdl,
						{f: session}));
			case 7:
				var mdl = model.a;
				return author$project$Main$Blueprints(
					_Utils_update(
						mdl,
						{f: session}));
			case 6:
				var mdl = model.a;
				return author$project$Main$Blueprint(
					_Utils_update(
						mdl,
						{f: session}));
			case 8:
				var mdl = model.a;
				return author$project$Main$Initialize(
					_Utils_update(
						mdl,
						{f: session}));
			default:
				var mdl = model.a;
				return author$project$Main$NotFound(
					_Utils_update(
						mdl,
						{f: session}));
		}
	});
var author$project$Data$Board$withBoardInstruction = F2(
	function (boardInstruction, board) {
		return A3(author$project$Data$Board$set, boardInstruction.ep, boardInstruction.cU, board);
	});
var author$project$Data$Board$withSize = F3(
	function (w, h, board) {
		return A2(
			author$project$Data$Board$withInstructions,
			author$project$Data$Board$instructions(board),
			A2(author$project$Data$Board$empty, w, h));
	});
var author$project$Data$Board$withHeight = F2(
	function (h, board) {
		return A3(
			author$project$Data$Board$withSize,
			author$project$Data$Board$width(board),
			h,
			board);
	});
var author$project$Data$Board$withWidth = F2(
	function (w, board) {
		return A3(
			author$project$Data$Board$withSize,
			w,
			author$project$Data$Board$height(board),
			board);
	});
var author$project$Data$Int16$fromString = A2(
	elm$core$Basics$composeR,
	elm$core$String$toInt,
	elm$core$Maybe$map(author$project$Data$Int16$constructor));
var author$project$Data$Level$constraints = {h2: 31, h4: 31, h6: 1, h7: 1};
var author$project$Data$InstructionTool$encode = function (instructionTool) {
	switch (instructionTool.$) {
		case 0:
			var instruction = instructionTool.a;
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('JustInstruction')),
						_Utils_Tuple2(
						'instruction',
						author$project$Data$Instruction$encode(instruction))
					]));
		case 1:
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('ChangeAnyDirection'))
					]));
		case 2:
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('BranchAnyDirection'))
					]));
		case 3:
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('PushValueToStack'))
					]));
		default:
			return elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						elm$json$Json$Encode$string('Exception'))
					]));
	}
};
var author$project$Data$Suite$encode = function (suite) {
	return elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'input',
				A2(elm$json$Json$Encode$list, author$project$Data$Int16$encode, suite.hU)),
				_Utils_Tuple2(
				'output',
				A2(elm$json$Json$Encode$list, author$project$Data$Int16$encode, suite.iu))
			]));
};
var elm$json$Json$Encode$array = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				elm$core$Array$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var author$project$Data$Level$encode = function (level) {
	return elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'version',
				elm$json$Json$Encode$int(2)),
				_Utils_Tuple2(
				'id',
				elm$json$Json$Encode$string(level.b6)),
				_Utils_Tuple2(
				'index',
				elm$json$Json$Encode$int(level.b7)),
				_Utils_Tuple2(
				'campaignId',
				author$project$Data$CampaignId$encode(level.bW)),
				_Utils_Tuple2(
				'name',
				elm$json$Json$Encode$string(level.bA)),
				_Utils_Tuple2(
				'description',
				A2(elm$json$Json$Encode$list, elm$json$Json$Encode$string, level.bq)),
				_Utils_Tuple2(
				'suites',
				A2(elm$json$Json$Encode$list, author$project$Data$Suite$encode, level.bM)),
				_Utils_Tuple2(
				'initialBoard',
				author$project$Data$Board$encode(level.hT)),
				_Utils_Tuple2(
				'instructionTools',
				A2(elm$json$Json$Encode$array, author$project$Data$InstructionTool$encode, level.aO))
			]));
};
var author$project$Data$Level$saveToLocalStorage = function (level) {
	return author$project$Ports$LocalStorage$storageSetItem(
		_Utils_Tuple2(
			author$project$Data$Level$localStorageKey(level.b6),
			author$project$Data$Level$encode(level)));
};
var author$project$Data$Level$withInitialBoard = F2(
	function (initialBoard, level) {
		return _Utils_update(
			level,
			{hT: initialBoard});
	});
var author$project$Data$Level$withInstructionTools = F2(
	function (instructionTools, level) {
		return _Utils_update(
			level,
			{aO: instructionTools});
	});
var author$project$Data$Level$withSuites = F2(
	function (suites, level) {
		return _Utils_update(
			level,
			{bM: suites});
	});
var author$project$Data$Suite$withInput = F2(
	function (input, suite) {
		return _Utils_update(
			suite,
			{hU: input});
	});
var author$project$Data$Suite$withOutput = F2(
	function (output, suite) {
		return _Utils_update(
			suite,
			{iu: output});
	});
var elm$core$Elm$JsArray$appendN = _JsArray_appendN;
var elm$core$Elm$JsArray$slice = _JsArray_slice;
var elm$core$Array$appendHelpBuilder = F2(
	function (tail, builder) {
		var tailLen = elm$core$Elm$JsArray$length(tail);
		var notAppended = (elm$core$Array$branchFactor - elm$core$Elm$JsArray$length(builder.s)) - tailLen;
		var appended = A3(elm$core$Elm$JsArray$appendN, elm$core$Array$branchFactor, builder.s, tail);
		return (notAppended < 0) ? {
			t: A2(
				elm$core$List$cons,
				elm$core$Array$Leaf(appended),
				builder.t),
			p: builder.p + 1,
			s: A3(elm$core$Elm$JsArray$slice, notAppended, tailLen, tail)
		} : ((!notAppended) ? {
			t: A2(
				elm$core$List$cons,
				elm$core$Array$Leaf(appended),
				builder.t),
			p: builder.p + 1,
			s: elm$core$Elm$JsArray$empty
		} : {t: builder.t, p: builder.p, s: appended});
	});
var elm$core$Elm$JsArray$push = _JsArray_push;
var elm$core$Elm$JsArray$singleton = _JsArray_singleton;
var elm$core$Array$insertTailInTree = F4(
	function (shift, index, tail, tree) {
		var pos = elm$core$Array$bitMask & (index >>> shift);
		if (_Utils_cmp(
			pos,
			elm$core$Elm$JsArray$length(tree)) > -1) {
			if (shift === 5) {
				return A2(
					elm$core$Elm$JsArray$push,
					elm$core$Array$Leaf(tail),
					tree);
			} else {
				var newSub = elm$core$Array$SubTree(
					A4(elm$core$Array$insertTailInTree, shift - elm$core$Array$shiftStep, index, tail, elm$core$Elm$JsArray$empty));
				return A2(elm$core$Elm$JsArray$push, newSub, tree);
			}
		} else {
			var value = A2(elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!value.$) {
				var subTree = value.a;
				var newSub = elm$core$Array$SubTree(
					A4(elm$core$Array$insertTailInTree, shift - elm$core$Array$shiftStep, index, tail, subTree));
				return A3(elm$core$Elm$JsArray$unsafeSet, pos, newSub, tree);
			} else {
				var newSub = elm$core$Array$SubTree(
					A4(
						elm$core$Array$insertTailInTree,
						shift - elm$core$Array$shiftStep,
						index,
						tail,
						elm$core$Elm$JsArray$singleton(value)));
				return A3(elm$core$Elm$JsArray$unsafeSet, pos, newSub, tree);
			}
		}
	});
var elm$core$Array$unsafeReplaceTail = F2(
	function (newTail, _n0) {
		var len = _n0.a;
		var startShift = _n0.b;
		var tree = _n0.c;
		var tail = _n0.d;
		var originalTailLen = elm$core$Elm$JsArray$length(tail);
		var newTailLen = elm$core$Elm$JsArray$length(newTail);
		var newArrayLen = len + (newTailLen - originalTailLen);
		if (_Utils_eq(newTailLen, elm$core$Array$branchFactor)) {
			var overflow = _Utils_cmp(newArrayLen >>> elm$core$Array$shiftStep, 1 << startShift) > 0;
			if (overflow) {
				var newShift = startShift + elm$core$Array$shiftStep;
				var newTree = A4(
					elm$core$Array$insertTailInTree,
					newShift,
					len,
					newTail,
					elm$core$Elm$JsArray$singleton(
						elm$core$Array$SubTree(tree)));
				return A4(elm$core$Array$Array_elm_builtin, newArrayLen, newShift, newTree, elm$core$Elm$JsArray$empty);
			} else {
				return A4(
					elm$core$Array$Array_elm_builtin,
					newArrayLen,
					startShift,
					A4(elm$core$Array$insertTailInTree, startShift, len, newTail, tree),
					elm$core$Elm$JsArray$empty);
			}
		} else {
			return A4(elm$core$Array$Array_elm_builtin, newArrayLen, startShift, tree, newTail);
		}
	});
var elm$core$Array$appendHelpTree = F2(
	function (toAppend, array) {
		var len = array.a;
		var tree = array.c;
		var tail = array.d;
		var itemsToAppend = elm$core$Elm$JsArray$length(toAppend);
		var notAppended = (elm$core$Array$branchFactor - elm$core$Elm$JsArray$length(tail)) - itemsToAppend;
		var appended = A3(elm$core$Elm$JsArray$appendN, elm$core$Array$branchFactor, tail, toAppend);
		var newArray = A2(elm$core$Array$unsafeReplaceTail, appended, array);
		if (notAppended < 0) {
			var nextTail = A3(elm$core$Elm$JsArray$slice, notAppended, itemsToAppend, toAppend);
			return A2(elm$core$Array$unsafeReplaceTail, nextTail, newArray);
		} else {
			return newArray;
		}
	});
var elm$core$Array$builderFromArray = function (_n0) {
	var len = _n0.a;
	var tree = _n0.c;
	var tail = _n0.d;
	var helper = F2(
		function (node, acc) {
			if (!node.$) {
				var subTree = node.a;
				return A3(elm$core$Elm$JsArray$foldl, helper, acc, subTree);
			} else {
				return A2(elm$core$List$cons, node, acc);
			}
		});
	return {
		t: A3(elm$core$Elm$JsArray$foldl, helper, _List_Nil, tree),
		p: (len / elm$core$Array$branchFactor) | 0,
		s: tail
	};
};
var elm$core$Array$append = F2(
	function (a, _n0) {
		var aTail = a.d;
		var bLen = _n0.a;
		var bTree = _n0.c;
		var bTail = _n0.d;
		if (_Utils_cmp(bLen, elm$core$Array$branchFactor * 4) < 1) {
			var foldHelper = F2(
				function (node, array) {
					if (!node.$) {
						var tree = node.a;
						return A3(elm$core$Elm$JsArray$foldl, foldHelper, array, tree);
					} else {
						var leaf = node.a;
						return A2(elm$core$Array$appendHelpTree, leaf, array);
					}
				});
			return A2(
				elm$core$Array$appendHelpTree,
				bTail,
				A3(elm$core$Elm$JsArray$foldl, foldHelper, a, bTree));
		} else {
			var foldHelper = F2(
				function (node, builder) {
					if (!node.$) {
						var tree = node.a;
						return A3(elm$core$Elm$JsArray$foldl, foldHelper, builder, tree);
					} else {
						var leaf = node.a;
						return A2(elm$core$Array$appendHelpBuilder, leaf, builder);
					}
				});
			return A2(
				elm$core$Array$builderToArray,
				true,
				A2(
					elm$core$Array$appendHelpBuilder,
					bTail,
					A3(
						elm$core$Elm$JsArray$foldl,
						foldHelper,
						elm$core$Array$builderFromArray(a),
						bTree)));
		}
	});
var elm$core$Array$filter = F2(
	function (isGood, array) {
		return elm$core$Array$fromList(
			A3(
				elm$core$Array$foldr,
				F2(
					function (x, xs) {
						return isGood(x) ? A2(elm$core$List$cons, x, xs) : xs;
					}),
				_List_Nil,
				array));
	});
var elm$core$Elm$JsArray$map = _JsArray_map;
var elm$core$Array$map = F2(
	function (func, _n0) {
		var len = _n0.a;
		var startShift = _n0.b;
		var tree = _n0.c;
		var tail = _n0.d;
		var helper = function (node) {
			if (!node.$) {
				var subTree = node.a;
				return elm$core$Array$SubTree(
					A2(elm$core$Elm$JsArray$map, helper, subTree));
			} else {
				var values = node.a;
				return elm$core$Array$Leaf(
					A2(elm$core$Elm$JsArray$map, func, values));
			}
		};
		return A4(
			elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			A2(elm$core$Elm$JsArray$map, helper, tree),
			A2(elm$core$Elm$JsArray$map, func, tail));
	});
var elm$core$String$trim = _String_trim;
var author$project$Page$Blueprint$update = F2(
	function (msg, model) {
		var _n0 = krisajenkins$remotedata$RemoteData$toMaybe(
			A2(author$project$Data$Session$getLevel, model.a8, model.f));
		if (!_n0.$) {
			var level = _n0.a;
			switch (msg.$) {
				case 0:
					var widthString = msg.a;
					var modelWithWidth = _Utils_update(
						model,
						{gy: widthString});
					var _n2 = elm$core$String$toInt(widthString);
					if (!_n2.$) {
						var width = _n2.a;
						if (_Utils_cmp(width, author$project$Data$Level$constraints.h7) < 0) {
							return _Utils_Tuple2(modelWithWidth, elm$core$Platform$Cmd$none);
						} else {
							if (_Utils_cmp(width, author$project$Data$Level$constraints.h4) > 0) {
								return _Utils_Tuple2(modelWithWidth, elm$core$Platform$Cmd$none);
							} else {
								var newLevel = A3(
									elm_community$basics_extra$Basics$Extra$flip,
									author$project$Data$Level$withInitialBoard,
									level,
									A2(author$project$Data$Board$withWidth, width, level.hT));
								var session = A2(author$project$Data$Session$withLevel, newLevel, model.f);
								var cmd = author$project$Data$Level$saveToLocalStorage(newLevel);
								return _Utils_Tuple2(
									_Utils_update(
										modelWithWidth,
										{f: session}),
									cmd);
							}
						}
					} else {
						return _Utils_Tuple2(modelWithWidth, elm$core$Platform$Cmd$none);
					}
				case 1:
					var heightString = msg.a;
					var modelWithHeight = _Utils_update(
						model,
						{fp: heightString});
					var _n3 = elm$core$String$toInt(heightString);
					if (!_n3.$) {
						var height = _n3.a;
						if (_Utils_cmp(height, author$project$Data$Level$constraints.h6) < 0) {
							return _Utils_Tuple2(modelWithHeight, elm$core$Platform$Cmd$none);
						} else {
							if (_Utils_cmp(height, author$project$Data$Level$constraints.h2) > 0) {
								return _Utils_Tuple2(modelWithHeight, elm$core$Platform$Cmd$none);
							} else {
								var newLevel = A3(
									elm_community$basics_extra$Basics$Extra$flip,
									author$project$Data$Level$withInitialBoard,
									level,
									A2(author$project$Data$Board$withHeight, height, level.hT));
								var session = A2(author$project$Data$Session$withLevel, newLevel, model.f);
								var cmd = author$project$Data$Level$saveToLocalStorage(newLevel);
								return _Utils_Tuple2(
									_Utils_update(
										modelWithHeight,
										{f: session}),
									cmd);
							}
						}
					} else {
						return _Utils_Tuple2(modelWithHeight, elm$core$Platform$Cmd$none);
					}
				case 2:
					var inputString = msg.a;
					var modelWithInput = _Utils_update(
						model,
						{hU: inputString});
					var input = elm_community$maybe_extra$Maybe$Extra$values(
						A2(
							elm$core$List$map,
							author$project$Data$Int16$fromString,
							A2(
								elm$core$List$map,
								elm$core$String$trim,
								A2(elm$core$String$split, ',', inputString))));
					var suite = A2(
						author$project$Data$Suite$withInput,
						input,
						A2(
							elm$core$Maybe$withDefault,
							author$project$Data$Suite$empty,
							elm$core$List$head(level.bM)));
					var newLevel = A2(
						author$project$Data$Level$withSuites,
						_List_fromArray(
							[suite]),
						level);
					var newSession = A2(author$project$Data$Session$withLevel, newLevel, model.f);
					var cmd = author$project$Data$Level$saveToLocalStorage(newLevel);
					return _Utils_Tuple2(
						_Utils_update(
							modelWithInput,
							{f: newSession}),
						cmd);
				case 3:
					var outputString = msg.a;
					var output = elm_community$maybe_extra$Maybe$Extra$values(
						A2(
							elm$core$List$map,
							author$project$Data$Int16$fromString,
							A2(
								elm$core$List$map,
								elm$core$String$trim,
								A2(elm$core$String$split, ',', outputString))));
					var suite = A2(
						author$project$Data$Suite$withOutput,
						output,
						A2(
							elm$core$Maybe$withDefault,
							author$project$Data$Suite$empty,
							elm$core$List$head(level.bM)));
					var newLevel = A2(
						author$project$Data$Level$withSuites,
						_List_fromArray(
							[suite]),
						level);
					var newSession = A2(author$project$Data$Session$withLevel, newLevel, model.f);
					var modelWithOutput = _Utils_update(
						model,
						{iu: outputString});
					var cmd = author$project$Data$Level$saveToLocalStorage(newLevel);
					return _Utils_Tuple2(
						_Utils_update(
							modelWithOutput,
							{f: newSession}),
						cmd);
				case 4:
					var index = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								cg: elm$core$Maybe$Just(index)
							}),
						elm$core$Platform$Cmd$none);
				case 5:
					var index = msg.a;
					var instructionTool = msg.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aO: A3(elm$core$Array$set, index, instructionTool, model.aO)
							}),
						elm$core$Platform$Cmd$none);
				case 6:
					var index = msg.a;
					var newEnabledInstructionTools = A2(
						elm$core$Maybe$withDefault,
						model.aq,
						A2(
							elm$core$Maybe$map,
							A2(
								elm_community$basics_extra$Basics$Extra$flip,
								elm$core$Array$set(index),
								model.aq),
							A2(
								elm$core$Maybe$map,
								function (_n4) {
									var tool = _n4.a;
									var enabled = _n4.b;
									return _Utils_Tuple2(tool, !enabled);
								},
								A2(elm$core$Array$get, index, model.aq))));
					var newLevel = A3(
						elm_community$basics_extra$Basics$Extra$flip,
						author$project$Data$Level$withInstructionTools,
						level,
						A2(
							elm$core$Array$append,
							elm$core$Array$fromList(
								_List_fromArray(
									[
										author$project$Data$InstructionTool$JustInstruction(author$project$Data$Instruction$NoOp)
									])),
							A2(
								elm$core$Array$map,
								elm$core$Tuple$first,
								A2(elm$core$Array$filter, elm$core$Tuple$second, newEnabledInstructionTools))));
					var newSession = A2(author$project$Data$Session$withLevel, newLevel, model.f);
					var cmd = author$project$Data$Level$saveToLocalStorage(newLevel);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aq: newEnabledInstructionTools, f: newSession}),
						cmd);
				default:
					var boardInstruction = msg.a;
					var newLevel = A3(
						elm_community$basics_extra$Basics$Extra$flip,
						author$project$Data$Level$withInitialBoard,
						level,
						A3(elm_community$basics_extra$Basics$Extra$flip, author$project$Data$Board$withBoardInstruction, level.hT, boardInstruction));
					var newModel = A2(
						author$project$Page$Blueprint$setSession,
						model,
						A3(elm_community$basics_extra$Basics$Extra$flip, author$project$Data$Session$withLevel, model.f, newLevel));
					var cmd = author$project$Data$Level$saveToLocalStorage(newLevel);
					return _Utils_Tuple2(newModel, cmd);
			}
		} else {
			return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
		}
	});
var author$project$Data$Campaign$withLevelId = F2(
	function (levelId, campaign) {
		return _Utils_update(
			campaign,
			{
				aQ: A2(elm$core$List$cons, levelId, campaign.aQ)
			});
	});
var author$project$Data$Campaign$withoutLevelId = F2(
	function (levelId, campaign) {
		return _Utils_update(
			campaign,
			{
				aQ: A2(
					elm$core$List$filter,
					elm$core$Basics$neq(levelId),
					campaign.aQ)
			});
	});
var author$project$Data$GetError$toString = function (detailedHttpError) {
	switch (detailedHttpError.$) {
		case 0:
			return 'Could not connect to server';
		case 1:
			var string = detailedHttpError.a;
			return string;
		default:
			var string = detailedHttpError.a;
			return string;
	}
};
var elm$core$String$fromList = _String_fromList;
var elm$core$String$foldr = _String_foldr;
var elm$core$String$toList = function (string) {
	return A3(elm$core$String$foldr, elm$core$List$cons, _List_Nil, string);
};
var elm$core$Basics$negate = function (n) {
	return -n;
};
var elm$random$Random$Generator = elm$core$Basics$identity;
var elm$random$Random$Seed = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var elm$random$Random$next = function (_n0) {
	var state0 = _n0.a;
	var incr = _n0.b;
	return A2(elm$random$Random$Seed, ((state0 * 1664525) + incr) >>> 0, incr);
};
var elm$core$Bitwise$xor = _Bitwise_xor;
var elm$random$Random$peel = function (_n0) {
	var state = _n0.a;
	var word = (state ^ (state >>> ((state >>> 28) + 4))) * 277803737;
	return ((word >>> 22) ^ word) >>> 0;
};
var elm$random$Random$int = F2(
	function (a, b) {
		return function (seed0) {
			var _n0 = (_Utils_cmp(a, b) < 0) ? _Utils_Tuple2(a, b) : _Utils_Tuple2(b, a);
			var lo = _n0.a;
			var hi = _n0.b;
			var range = (hi - lo) + 1;
			if (!((range - 1) & range)) {
				return _Utils_Tuple2(
					(((range - 1) & elm$random$Random$peel(seed0)) >>> 0) + lo,
					elm$random$Random$next(seed0));
			} else {
				var threshhold = (((-range) >>> 0) % range) >>> 0;
				var accountForBias = function (seed) {
					accountForBias:
					while (true) {
						var x = elm$random$Random$peel(seed);
						var seedN = elm$random$Random$next(seed);
						if (_Utils_cmp(x, threshhold) < 0) {
							var $temp$seed = seedN;
							seed = $temp$seed;
							continue accountForBias;
						} else {
							return _Utils_Tuple2((x % range) + lo, seedN);
						}
					}
				};
				return accountForBias(seed0);
			}
		};
	});
var elm$random$Random$listHelp = F4(
	function (revList, n, gen, seed) {
		listHelp:
		while (true) {
			if (n < 1) {
				return _Utils_Tuple2(revList, seed);
			} else {
				var _n0 = gen(seed);
				var value = _n0.a;
				var newSeed = _n0.b;
				var $temp$revList = A2(elm$core$List$cons, value, revList),
					$temp$n = n - 1,
					$temp$gen = gen,
					$temp$seed = newSeed;
				revList = $temp$revList;
				n = $temp$n;
				gen = $temp$gen;
				seed = $temp$seed;
				continue listHelp;
			}
		}
	});
var elm$random$Random$list = F2(
	function (n, _n0) {
		var gen = _n0;
		return function (seed) {
			return A4(elm$random$Random$listHelp, _List_Nil, n, gen, seed);
		};
	});
var elm$random$Random$map = F2(
	function (func, _n0) {
		var genA = _n0;
		return function (seed0) {
			var _n1 = genA(seed0);
			var a = _n1.a;
			var seed1 = _n1.b;
			return _Utils_Tuple2(
				func(a),
				seed1);
		};
	});
var author$project$Data$LevelId$generator = function () {
	var chars = elm$core$Array$fromList(
		elm$core$String$toList('0123456789abcdef'));
	var getChar = function (i) {
		return A2(
			elm$core$Maybe$withDefault,
			'0',
			A2(elm$core$Array$get, i, chars));
	};
	var _char = A2(
		elm$random$Random$map,
		getChar,
		A2(
			elm$random$Random$int,
			0,
			elm$core$Array$length(chars) - 1));
	return A2(
		elm$random$Random$map,
		elm$core$String$fromList,
		A2(elm$random$Random$list, 16, _char));
}();
var elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var author$project$Data$Level$generator = A2(
	elm$random$Random$map,
	function (levelId) {
		return {
			bW: author$project$Data$CampaignId$blueprints,
			bq: _List_fromArray(
				['Enter a description']),
			b6: levelId,
			b7: 0,
			hT: A2(author$project$Data$Board$empty, 4, 4),
			aO: elm$core$Array$fromList(
				elm$core$List$singleton(
					author$project$Data$InstructionTool$JustInstruction(author$project$Data$Instruction$NoOp))),
			bA: 'New level',
			bM: _List_fromArray(
				[
					{hU: _List_Nil, iu: _List_Nil}
				])
		};
	},
	author$project$Data$LevelId$generator);
var author$project$Ports$LocalStorage$storageRemoveItem = _Platform_outgoingPort('storageRemoveItem', elm$json$Json$Encode$string);
var author$project$Data$Level$removeFromLocalStorage = function (levelId) {
	return author$project$Ports$LocalStorage$storageRemoveItem(
		author$project$Data$Level$localStorageKey(levelId));
};
var author$project$Data$Level$withDescription = F2(
	function (description, level) {
		return _Utils_update(
			level,
			{bq: description});
	});
var author$project$Data$Level$withName = F2(
	function (name, level) {
		return _Utils_update(
			level,
			{bA: name});
	});
var author$project$Data$Session$withLevels = F2(
	function (levels, session) {
		return A3(elm$core$List$foldl, author$project$Data$Session$withLevel, session, levels);
	});
var author$project$Data$Cache$remove = F2(
	function (key, cache) {
		return A2(
			elm$core$Dict$remove,
			key,
			author$project$Data$Cache$getDict(cache));
	});
var author$project$Data$Session$withoutLevel = F2(
	function (levelId, session) {
		return A2(
			author$project$Data$Session$setLevelCache,
			session,
			A2(author$project$Data$Cache$remove, levelId, session.as));
	});
var author$project$Page$Blueprints$LevelGenerated = function (a) {
	return {$: 1, a: a};
};
var elm$core$String$lines = _String_lines;
var elm$random$Random$Generate = elm$core$Basics$identity;
var elm$random$Random$initialSeed = function (x) {
	var _n0 = elm$random$Random$next(
		A2(elm$random$Random$Seed, 0, 1013904223));
	var state1 = _n0.a;
	var incr = _n0.b;
	var state2 = (state1 + x) >>> 0;
	return elm$random$Random$next(
		A2(elm$random$Random$Seed, state2, incr));
};
var elm$time$Time$posixToMillis = function (_n0) {
	var millis = _n0;
	return millis;
};
var elm$random$Random$init = A2(
	elm$core$Task$andThen,
	function (time) {
		return elm$core$Task$succeed(
			elm$random$Random$initialSeed(
				elm$time$Time$posixToMillis(time)));
	},
	elm$time$Time$now);
var elm$random$Random$step = F2(
	function (_n0, seed) {
		var generator = _n0;
		return generator(seed);
	});
var elm$random$Random$onEffects = F3(
	function (router, commands, seed) {
		if (!commands.b) {
			return elm$core$Task$succeed(seed);
		} else {
			var generator = commands.a;
			var rest = commands.b;
			var _n1 = A2(elm$random$Random$step, generator, seed);
			var value = _n1.a;
			var newSeed = _n1.b;
			return A2(
				elm$core$Task$andThen,
				function (_n2) {
					return A3(elm$random$Random$onEffects, router, rest, newSeed);
				},
				A2(elm$core$Platform$sendToApp, router, value));
		}
	});
var elm$random$Random$onSelfMsg = F3(
	function (_n0, _n1, seed) {
		return elm$core$Task$succeed(seed);
	});
var elm$random$Random$cmdMap = F2(
	function (func, _n0) {
		var generator = _n0;
		return A2(elm$random$Random$map, func, generator);
	});
_Platform_effectManagers['Random'] = _Platform_createManager(elm$random$Random$init, elm$random$Random$onEffects, elm$random$Random$onSelfMsg, elm$random$Random$cmdMap);
var elm$random$Random$command = _Platform_leaf('Random');
var elm$random$Random$generate = F2(
	function (tagger, generator) {
		return elm$random$Random$command(
			A2(elm$random$Random$map, tagger, generator));
	});
var author$project$Page$Blueprints$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 4:
				var levelId = msg.a;
				var _n1 = krisajenkins$remotedata$RemoteData$toMaybe(
					A2(author$project$Data$Session$getCampaign, author$project$Page$Blueprints$campaignId, model.f));
				if (!_n1.$) {
					var campaign = _n1.a;
					var newCampaign = A2(author$project$Data$Campaign$withoutLevelId, levelId, campaign);
					var newModel = A2(
						author$project$Page$Blueprints$setSession,
						model,
						A2(
							author$project$Data$Session$withoutLevel,
							levelId,
							A2(author$project$Data$Session$withCampaign, newCampaign, model.f)));
					var cmd = elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								author$project$Data$Level$removeFromLocalStorage(levelId),
								author$project$Data$Campaign$saveToLocalStorage(newCampaign)
							]));
					return _Utils_Tuple2(newModel, cmd);
				} else {
					return _Utils_Tuple2(
						model,
						author$project$Ports$Console$errorString('v1mC6LQO : Could not delete level ' + levelId));
				}
			case 3:
				var newName = msg.a;
				var _n2 = A2(
					elm$core$Maybe$andThen,
					krisajenkins$remotedata$RemoteData$toMaybe,
					A2(
						elm$core$Maybe$map,
						A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Data$Session$getLevel, model.f),
						model.aY));
				if (!_n2.$) {
					var oldLevel = _n2.a;
					var newLevel = A2(author$project$Data$Level$withName, newName, oldLevel);
					var newModel = A2(
						author$project$Page$Blueprints$setSession,
						model,
						A2(author$project$Data$Session$withLevel, newLevel, model.f));
					var cmd = author$project$Data$Level$saveToLocalStorage(newLevel);
					return _Utils_Tuple2(newModel, cmd);
				} else {
					return _Utils_Tuple2(
						model,
						author$project$Ports$Console$errorString('tWy71t5l : Could not update level name'));
				}
			case 5:
				var newDescription = msg.a;
				var _n3 = A2(
					elm$core$Maybe$andThen,
					krisajenkins$remotedata$RemoteData$toMaybe,
					A2(
						elm$core$Maybe$map,
						A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Data$Session$getLevel, model.f),
						model.aY));
				if (!_n3.$) {
					var oldLevel = _n3.a;
					var newLevel = A2(
						author$project$Data$Level$withDescription,
						elm$core$String$lines(newDescription),
						oldLevel);
					var newModel = A2(
						author$project$Page$Blueprints$setSession,
						model,
						A2(author$project$Data$Session$withLevel, newLevel, model.f));
					var cmd = author$project$Data$Level$saveToLocalStorage(newLevel);
					return _Utils_Tuple2(newModel, cmd);
				} else {
					return _Utils_Tuple2(
						model,
						author$project$Ports$Console$errorString('Pm6iHnXM : Could not update level description'));
				}
			case 2:
				var levelId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aY: elm$core$Maybe$Just(levelId)
						}),
					A2(
						author$project$Route$replaceUrl,
						model.f.fA,
						author$project$Route$Blueprints(
							elm$core$Maybe$Just(levelId))));
			case 0:
				return _Utils_Tuple2(
					model,
					A2(elm$random$Random$generate, author$project$Page$Blueprints$LevelGenerated, author$project$Data$Level$generator));
			case 1:
				var level = msg.a;
				var session = model.f;
				var campaign = A2(
					author$project$Data$Campaign$withLevelId,
					level.b6,
					A2(
						krisajenkins$remotedata$RemoteData$withDefault,
						author$project$Data$Campaign$empty(author$project$Page$Blueprints$campaignId),
						A2(author$project$Data$Session$getCampaign, author$project$Page$Blueprints$campaignId, session)));
				var cmd = elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							author$project$Data$Level$saveToLocalStorage(level),
							author$project$Data$Campaign$saveToLocalStorage(campaign),
							A2(
							author$project$Route$replaceUrl,
							model.f.fA,
							author$project$Route$Blueprints(
								elm$core$Maybe$Just(level.b6)))
						]));
				var newModel = _Utils_update(
					model,
					{
						aY: elm$core$Maybe$Just(level.b6),
						f: A2(
							author$project$Data$Session$withCampaign,
							campaign,
							A2(author$project$Data$Session$withLevel, level, model.f))
					});
				return _Utils_Tuple2(newModel, cmd);
			default:
				var result = msg.a;
				if (!result.$) {
					var blueprints = result.a;
					var campaign = {
						b6: author$project$Page$Blueprints$campaignId,
						aQ: A2(
							elm$core$List$map,
							function ($) {
								return $.b6;
							},
							blueprints)
					};
					var cmd = elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								author$project$Data$Campaign$saveToLocalStorage(campaign),
								elm$core$Platform$Cmd$batch(
								A2(elm$core$List$map, author$project$Data$Level$saveToLocalStorage, blueprints))
							]));
					return _Utils_Tuple2(
						A2(
							author$project$Page$Blueprints$setSession,
							model,
							A2(
								author$project$Data$Session$withLevels,
								blueprints,
								A2(author$project$Data$Session$withCampaign, campaign, model.f))),
						cmd);
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						A2(
							author$project$Page$Blueprints$setSession,
							model,
							A3(author$project$Data$Session$campaignError, author$project$Page$Blueprints$campaignId, error, model.f)),
						author$project$Ports$Console$errorString(
							author$project$Data$GetError$toString(error)));
				}
		}
	});
var author$project$Data$DraftId$generator = function () {
	var chars = elm$core$Array$fromList(
		elm$core$String$toList('0123456789abcdef'));
	var getChar = function (i) {
		return A2(
			elm$core$Maybe$withDefault,
			'0',
			A2(elm$core$Array$get, i, chars));
	};
	var _char = A2(
		elm$random$Random$map,
		getChar,
		A2(
			elm$random$Random$int,
			0,
			elm$core$Array$length(chars) - 1));
	return A2(
		elm$random$Random$map,
		elm$core$String$fromList,
		A2(elm$random$Random$list, 16, _char));
}();
var author$project$Data$Draft$generator = function (level) {
	return A2(
		elm$random$Random$map,
		function (id) {
			return {
				G: author$project$Data$History$singleton(level.hT),
				b6: id,
				a8: level.b6
			};
		},
		author$project$Data$DraftId$generator);
};
var author$project$Api$GCP$put = _Utils_update(
	author$project$Api$GCP$get,
	{a9: 'PUT'});
var author$project$Data$SaveError$InvalidAccessToken = function (a) {
	return {$: 1, a: a};
};
var author$project$Data$SaveError$NetworkError = {$: 0};
var author$project$Data$SaveError$Other = function (a) {
	return {$: 4, a: a};
};
var author$project$Data$SaveError$fromResponse = function (response) {
	switch (response.$) {
		case 0:
			var string = response.a;
			return elm$core$Result$Err(
				author$project$Data$SaveError$Other('Bad url: ' + string));
		case 1:
			return elm$core$Result$Err(
				author$project$Data$SaveError$Other('Request timed out'));
		case 2:
			return elm$core$Result$Err(author$project$Data$SaveError$NetworkError);
		case 3:
			var metadata = response.a;
			var body = response.b;
			var _n1 = metadata.gh;
			if (_n1 === 403) {
				return elm$core$Result$Err(
					author$project$Data$SaveError$InvalidAccessToken(body));
			} else {
				var statusCode = _n1;
				return elm$core$Result$Err(
					author$project$Data$SaveError$Other(
						'Status ' + (elm$core$String$fromInt(statusCode) + (': ' + body))));
			}
		default:
			return elm$core$Result$Ok(0);
	}
};
var author$project$Data$SaveError$expect = function (toMsg) {
	return A2(
		elm$http$Http$expectStringResponse,
		A2(elm$core$Basics$composeR, author$project$Extra$Result$getError, toMsg),
		author$project$Data$SaveError$fromResponse);
};
var author$project$Data$Draft$saveToServer = F3(
	function (toMsg, accessToken, draft) {
		return A2(
			author$project$Api$GCP$request,
			author$project$Data$SaveError$expect(toMsg),
			A2(
				author$project$Api$GCP$withBody,
				author$project$Data$Draft$encode(draft),
				A2(
					author$project$Api$GCP$withAccessToken,
					accessToken,
					A2(
						author$project$Api$GCP$withPath,
						_List_fromArray(
							['drafts']),
						author$project$Api$GCP$put))));
	});
var author$project$Data$DraftBook$withDraftId = F2(
	function (draftId, draftBook) {
		return _Utils_update(
			draftBook,
			{
				aK: A2(elm$core$Set$insert, draftId, draftBook.aK)
			});
	});
var author$project$Data$RemoteCache$withActualValue = F3(
	function (key, value, cache) {
		return _Utils_update(
			cache,
			{
				ae: A3(author$project$Data$Cache$withValue, key, value, cache.ae)
			});
	});
var author$project$Data$RemoteCache$withExpectedValue = F3(
	function (key, value, cache) {
		return _Utils_update(
			cache,
			{
				ai: A3(author$project$Data$Cache$withValue, key, value, cache.ai)
			});
	});
var author$project$Page$Campaign$GeneratedDraft = function (a) {
	return {$: 3, a: a};
};
var author$project$Page$Campaign$InternalMsg = function (a) {
	return {$: 0, a: a};
};
var elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var author$project$Route$pushUrl = F2(
	function (key, route) {
		return A2(
			elm$browser$Browser$Navigation$pushUrl,
			key,
			author$project$Route$toString(route));
	});
var author$project$SessionUpdate$GotSaveDraftResponse = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var author$project$Page$Campaign$update = F2(
	function (msg, model) {
		var generateDraft = function (levelId) {
			return A2(
				elm$random$Random$generate,
				A2(elm$core$Basics$composeL, author$project$Page$Campaign$InternalMsg, author$project$Page$Campaign$GeneratedDraft),
				author$project$Data$Draft$generator(levelId));
		};
		switch (msg.$) {
			case 0:
				var selectedLevelId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aY: elm$core$Maybe$Just(selectedLevelId)
						}),
					A2(
						author$project$Route$replaceUrl,
						model.f.fA,
						A2(
							author$project$Route$Campaign,
							model.bW,
							elm$core$Maybe$Just(selectedLevelId))));
			case 1:
				var draftId = msg.a;
				return _Utils_Tuple2(
					model,
					A2(
						author$project$Route$pushUrl,
						model.f.fA,
						author$project$Route$EditDraft(draftId)));
			case 2:
				var _n1 = A2(
					elm$core$Maybe$map,
					A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Data$Session$getLevel, model.f),
					model.aY);
				if ((!_n1.$) && (_n1.a.$ === 3)) {
					var level = _n1.a.a;
					return _Utils_Tuple2(
						model,
						generateDraft(level));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			default:
				var draft = msg.a;
				var saveDraftToServerCmd = function () {
					var _n2 = author$project$Data$Session$getAccessToken(model.f);
					if (!_n2.$) {
						var accessToken = _n2.a;
						return elm$core$Maybe$Just(
							A3(
								author$project$Data$Draft$saveToServer,
								A2(
									elm$core$Basics$composeL,
									author$project$Page$Campaign$SessionMsg,
									author$project$SessionUpdate$GotSaveDraftResponse(draft)),
								accessToken,
								draft));
					} else {
						return elm$core$Maybe$Nothing;
					}
				}();
				var draftCache = A3(
					author$project$Data$RemoteCache$withActualValue,
					draft.b6,
					elm$core$Maybe$Nothing,
					A3(
						author$project$Data$RemoteCache$withExpectedValue,
						draft.b6,
						elm$core$Maybe$Nothing,
						A3(
							author$project$Data$RemoteCache$withLocalValue,
							draft.b6,
							elm$core$Maybe$Just(draft),
							model.f.dC)));
				var draftBook = A2(
					author$project$Data$DraftBook$withDraftId,
					draft.b6,
					A2(
						elm$core$Maybe$withDefault,
						author$project$Data$DraftBook$empty(draft.a8),
						krisajenkins$remotedata$RemoteData$toMaybe(
							A2(author$project$Data$Cache$get, draft.a8, model.f.aJ))));
				var draftBookCache = A3(author$project$Data$Cache$withValue, draft.a8, draftBook, model.f.aJ);
				var newModel = A3(
					elm_community$basics_extra$Basics$Extra$flip,
					author$project$Page$Campaign$withSession,
					model,
					A2(
						author$project$Data$Session$withDraftBookCache,
						draftBookCache,
						A2(author$project$Data$Session$withDraftCache, draftCache, model.f)));
				var cmd = elm$core$Platform$Cmd$batch(
					elm_community$maybe_extra$Maybe$Extra$values(
						_List_fromArray(
							[
								elm$core$Maybe$Just(
								author$project$Data$Draft$saveToLocalStorage(draft)),
								saveDraftToServerCmd,
								elm$core$Maybe$Just(
								A2(
									author$project$Route$pushUrl,
									model.f.fA,
									author$project$Route$EditDraft(draft.b6)))
							])));
				return _Utils_Tuple2(newModel, cmd);
		}
	});
var author$project$Page$Campaigns$update = F2(
	function (msg, model) {
		return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
	});
var author$project$Api$GCP$delete = _Utils_update(
	author$project$Api$GCP$get,
	{a9: 'DELETE'});
var author$project$Data$Draft$deleteFromServer = F3(
	function (toMsg, accessToken, draftId) {
		return A2(
			author$project$Api$GCP$request,
			author$project$Data$SaveError$expect(toMsg),
			A2(
				author$project$Api$GCP$withAccessToken,
				accessToken,
				A2(
					author$project$Api$GCP$withQueryParameters,
					_List_fromArray(
						[
							A2(elm$url$Url$Builder$string, 'draftId', draftId)
						]),
					A2(
						author$project$Api$GCP$withPath,
						_List_fromArray(
							['drafts']),
						author$project$Api$GCP$delete))));
	});
var author$project$Data$History$push = F2(
	function (a, history) {
		return {
			x: a,
			y: _List_Nil,
			u: A2(elm$core$List$cons, history.x, history.u)
		};
	});
var author$project$Data$Draft$pushBoard = F2(
	function (board, draft) {
		return _Utils_update(
			draft,
			{
				G: A2(author$project$Data$History$push, board, draft.G)
			});
	});
var author$project$Data$History$forward = function (history) {
	var _n0 = history.y;
	if (!_n0.b) {
		return history;
	} else {
		var nearFuture = _n0.a;
		var distantFuture = _n0.b;
		return {
			x: nearFuture,
			y: distantFuture,
			u: A2(elm$core$List$cons, history.x, history.u)
		};
	}
};
var author$project$Data$Draft$redo = function (draft) {
	return _Utils_update(
		draft,
		{
			G: author$project$Data$History$forward(draft.G)
		});
};
var author$project$Data$Draft$removeFromLocalStorage = function (draftId) {
	return author$project$Ports$LocalStorage$storageRemoveItem(
		author$project$Data$Draft$localStorageKey(draftId));
};
var author$project$Data$History$back = function (history) {
	var _n0 = history.u;
	if (!_n0.b) {
		return history;
	} else {
		var nearPast = _n0.a;
		var distantPast = _n0.b;
		return {
			x: nearPast,
			y: A2(elm$core$List$cons, history.x, history.y),
			u: distantPast
		};
	}
};
var author$project$Data$Draft$undo = function (draft) {
	return _Utils_update(
		draft,
		{
			G: author$project$Data$History$back(draft.G)
		});
};
var author$project$Ports$LocalStorage$storageRemoveFromSet = _Platform_outgoingPort(
	'storageRemoveFromSet',
	function ($) {
		var a = $.a;
		var b = $.b;
		return A2(
			elm$json$Json$Encode$list,
			elm$core$Basics$identity,
			_List_fromArray(
				[
					elm$json$Json$Encode$string(a),
					elm$core$Basics$identity(b)
				]));
	});
var author$project$Data$DraftBook$removeDraftIdFromLocalStorage = F2(
	function (levelId, draftId) {
		return author$project$Ports$LocalStorage$storageRemoveFromSet(
			_Utils_Tuple2(
				author$project$Data$DraftBook$localStorageKey(levelId),
				author$project$Data$DraftId$encode(draftId)));
	});
var elm$core$Set$remove = F2(
	function (key, _n0) {
		var dict = _n0;
		return A2(elm$core$Dict$remove, key, dict);
	});
var author$project$Data$DraftBook$withoutDraftId = F2(
	function (draftId, draftBook) {
		return _Utils_update(
			draftBook,
			{
				aK: A2(elm$core$Set$remove, draftId, draftBook.aK)
			});
	});
var author$project$Data$Level$withInstructionTool = F3(
	function (index, instructionTool, level) {
		return _Utils_update(
			level,
			{
				aO: A3(elm$core$Array$set, index, instructionTool, level.aO)
			});
	});
var author$project$Extra$Cmd$withCmd = F2(
	function (cmd, value) {
		return _Utils_Tuple2(value, cmd);
	});
var author$project$Page$Draft$Deleting = {$: 1};
var author$project$Page$Draft$Importing = function (a) {
	return {$: 2, a: a};
};
var author$project$Page$Draft$updateDraft = F2(
	function (draft, model) {
		var cmd = function () {
			var _n0 = author$project$Data$Session$getAccessToken(model.f);
			if (!_n0.$) {
				var accessToken = _n0.a;
				return elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							A3(
							author$project$Data$Draft$saveToServer,
							A2(
								elm$core$Basics$composeL,
								author$project$Page$Draft$SessionMsg,
								author$project$SessionUpdate$GotSaveDraftResponse(draft)),
							accessToken,
							draft),
							author$project$Data$Draft$saveToLocalStorage(draft)
						]));
			} else {
				return author$project$Data$Draft$saveToLocalStorage(draft);
			}
		}();
		return _Utils_Tuple2(
			A3(
				elm_community$basics_extra$Basics$Extra$flip,
				author$project$Page$Draft$withSession,
				model,
				A3(
					elm_community$basics_extra$Basics$Extra$flip,
					author$project$Data$Session$withDraftCache,
					model.f,
					A3(
						author$project$Data$RemoteCache$withLocalValue,
						draft.b6,
						elm$core$Maybe$Just(draft),
						model.f.dC))),
			cmd);
	});
var author$project$SessionUpdate$GotDeleteDraftResponse = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var elm$browser$Browser$Navigation$back = F2(
	function (key, n) {
		return A2(_Browser_go, key, -n);
	});
var author$project$Page$Draft$update = F2(
	function (msg, model) {
		var maybeDraft = elm_community$maybe_extra$Maybe$Extra$join(
			krisajenkins$remotedata$RemoteData$toMaybe(
				A2(author$project$Data$Cache$get, model.o, model.f.dC.aj)));
		var maybeLevel = A2(
			elm$core$Maybe$andThen,
			krisajenkins$remotedata$RemoteData$toMaybe,
			A2(
				elm$core$Maybe$map,
				A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Data$Session$getLevel, model.f),
				A2(
					elm$core$Maybe$map,
					function ($) {
						return $.a8;
					},
					maybeDraft)));
		switch (msg.$) {
			case 0:
				var importData = msg.a;
				var _n1 = model.B;
				switch (_n1.$) {
					case 2:
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									B: author$project$Page$Draft$Importing(
										{cN: elm$core$Maybe$Nothing, cT: importData})
								}),
							elm$core$Platform$Cmd$none);
					case 1:
						return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
					default:
						return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 1:
				var importData = msg.a;
				if (!maybeDraft.$) {
					var draft = maybeDraft.a;
					var _n3 = A2(elm$json$Json$Decode$decodeString, author$project$Data$Board$decoder, importData);
					if (!_n3.$) {
						var board = _n3.a;
						return A2(
							author$project$Page$Draft$updateDraft,
							A2(author$project$Data$Draft$pushBoard, board, draft),
							_Utils_update(
								model,
								{B: author$project$Page$Draft$Editing}));
					} else {
						var error = _n3.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									B: author$project$Page$Draft$Importing(
										{
											cN: elm$core$Maybe$Just(
												elm$json$Json$Decode$errorToString(error)),
											cT: importData
										})
								}),
							elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 2:
				if (!maybeDraft.$) {
					var draft = maybeDraft.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								B: author$project$Page$Draft$Importing(
									{
										cN: elm$core$Maybe$Nothing,
										cT: A2(
											elm$json$Json$Encode$encode,
											2,
											author$project$Data$Board$encode(
												author$project$Data$History$current(draft.G)))
									})
							}),
						elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 3:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{B: author$project$Page$Draft$Editing}),
					elm$core$Platform$Cmd$none);
			case 4:
				if (!maybeDraft.$) {
					var draft = maybeDraft.a;
					return A2(
						author$project$Page$Draft$updateDraft,
						author$project$Data$Draft$undo(draft),
						model);
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 5:
				if (!maybeDraft.$) {
					var draft = maybeDraft.a;
					return A2(
						author$project$Page$Draft$updateDraft,
						author$project$Data$Draft$redo(draft),
						model);
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 6:
				var _n7 = _Utils_Tuple2(maybeDraft, maybeLevel);
				if ((!_n7.a.$) && (!_n7.b.$)) {
					var draft = _n7.a.a;
					var level = _n7.b.a;
					return A2(
						author$project$Page$Draft$updateDraft,
						A2(author$project$Data$Draft$pushBoard, level.hT, draft),
						model);
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 7:
				return _Utils_Tuple2(
					model,
					A2(elm$browser$Browser$Navigation$back, model.f.fA, 1));
			case 8:
				return _Utils_Tuple2(
					model,
					A2(
						author$project$Route$pushUrl,
						model.f.fA,
						author$project$Route$ExecuteDraft(model.o)));
			case 13:
				var index = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cg: elm$core$Maybe$Just(index)
						}),
					elm$core$Platform$Cmd$none);
			case 12:
				var index = msg.a;
				var instructionTool = msg.b;
				if (!maybeLevel.$) {
					var level = maybeLevel.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								f: A3(
									elm_community$basics_extra$Basics$Extra$flip,
									author$project$Data$Session$withLevel,
									model.f,
									A3(author$project$Data$Level$withInstructionTool, index, instructionTool, level))
							}),
						elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 14:
				var position = msg.a;
				var instruction = msg.b;
				if (!maybeDraft.$) {
					var oldDraft = maybeDraft.a;
					var board = A3(
						author$project$Data$Board$set,
						position,
						instruction,
						author$project$Data$History$current(oldDraft.G));
					var draft = A2(author$project$Data$Draft$pushBoard, board, oldDraft);
					return A2(author$project$Page$Draft$updateDraft, draft, model);
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{B: author$project$Page$Draft$Deleting}),
					elm$core$Platform$Cmd$none);
			case 11:
				return _Utils_eq(model.B, author$project$Page$Draft$Deleting) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{B: author$project$Page$Draft$Editing}),
					elm$core$Platform$Cmd$none) : _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
			default:
				if (!maybeDraft.$) {
					var draft = maybeDraft.a;
					var removeRemotelyCmd = A2(
						elm$core$Maybe$withDefault,
						elm$core$Platform$Cmd$none,
						A2(
							elm$core$Maybe$map,
							A2(
								elm_community$basics_extra$Basics$Extra$flip,
								author$project$Data$Draft$deleteFromServer(
									A2(
										elm$core$Basics$composeL,
										author$project$Page$Draft$SessionMsg,
										author$project$SessionUpdate$GotDeleteDraftResponse(draft.b6))),
								draft.b6),
							author$project$Data$Session$getAccessToken(model.f)));
					var removeLocallyCmd = elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								author$project$Data$Draft$removeFromLocalStorage(draft.b6),
								A2(author$project$Data$DraftBook$removeDraftIdFromLocalStorage, draft.a8, draft.b6)
							]));
					var newDraftCache = A3(author$project$Data$RemoteCache$withLocalValue, draft.b6, elm$core$Maybe$Nothing, model.f.dC);
					var newDraftBookCache = A3(
						elm_community$basics_extra$Basics$Extra$flip,
						author$project$Data$Cache$withValue(draft.a8),
						model.f.aJ,
						A2(
							author$project$Data$DraftBook$withoutDraftId,
							draft.b6,
							A2(
								krisajenkins$remotedata$RemoteData$withDefault,
								author$project$Data$DraftBook$empty(draft.a8),
								A2(author$project$Data$Cache$get, draft.a8, model.f.aJ))));
					var changeRouteCmd = A2(
						author$project$Route$replaceUrl,
						model.f.fA,
						A2(
							elm$core$Maybe$withDefault,
							author$project$Route$Home,
							A2(
								elm$core$Maybe$map,
								A2(
									elm_community$basics_extra$Basics$Extra$flip,
									author$project$Route$Campaign,
									elm$core$Maybe$Just(draft.a8)),
								A2(
									elm$core$Maybe$map,
									function ($) {
										return $.bW;
									},
									maybeLevel))));
					var cmd = elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[removeLocallyCmd, removeRemotelyCmd, changeRouteCmd]));
					return A2(
						author$project$Extra$Cmd$withCmd,
						cmd,
						A3(
							elm_community$basics_extra$Basics$Extra$flip,
							author$project$Page$Draft$withSession,
							model,
							A2(
								author$project$Data$Session$withDraftBookCache,
								newDraftBookCache,
								A2(author$project$Data$Session$withDraftCache, newDraftCache, model.f))));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
		}
	});
var author$project$Data$Cache$update = F3(
	function (key, _function, cache) {
		return A3(
			elm$core$Dict$update,
			key,
			A2(
				elm$core$Basics$composeR,
				elm$core$Maybe$withDefault(krisajenkins$remotedata$RemoteData$NotAsked),
				A2(elm$core$Basics$composeR, _function, elm$core$Maybe$Just)),
			author$project$Data$Cache$getDict(cache));
	});
var author$project$Data$HighScore$empty = function (levelId) {
	return {a8: levelId, bc: elm$core$Dict$empty, bd: elm$core$Dict$empty};
};
var author$project$Data$HighScore$withScore = F2(
	function (score, highScore) {
		return _Utils_update(
			highScore,
			{
				bc: A3(
					elm$core$Dict$update,
					score.bc,
					A2(
						elm$core$Basics$composeR,
						elm$core$Maybe$withDefault(0),
						A2(
							elm$core$Basics$composeR,
							elm$core$Basics$add(1),
							elm$core$Maybe$Just)),
					highScore.bc),
				bd: A3(
					elm$core$Dict$update,
					score.bd,
					A2(
						elm$core$Basics$composeR,
						elm$core$Maybe$withDefault(0),
						A2(
							elm$core$Basics$composeR,
							elm$core$Basics$add(1),
							elm$core$Maybe$Just)),
					highScore.bd)
			});
	});
var author$project$Data$History$toBeginning = function (history) {
	toBeginning:
	while (true) {
		var _n0 = history.u;
		if (!_n0.b) {
			return history;
		} else {
			var $temp$history = author$project$Data$History$back(history);
			history = $temp$history;
			continue toBeginning;
		}
	}
};
var author$project$Data$History$update = F2(
	function (fn, history) {
		return _Utils_update(
			history,
			{
				x: fn(history.x)
			});
	});
var author$project$Data$SolutionBook$saveToLocalStorage = F2(
	function (solutionId, levelId) {
		return author$project$Ports$LocalStorage$storagePushToSet(
			_Utils_Tuple2(
				author$project$Data$SolutionBook$localStorageKey(levelId),
				author$project$Data$SolutionId$encode(solutionId)));
	});
var author$project$Data$Solution$saveToLocalStorage = function (solution) {
	var value = author$project$Data$Solution$encode(solution);
	var key = author$project$Data$Solution$localStorageKey(solution.b6);
	return elm$core$Platform$Cmd$batch(
		_List_fromArray(
			[
				author$project$Ports$LocalStorage$storageSetItem(
				_Utils_Tuple2(key, value)),
				A2(author$project$Data$SolutionBook$saveToLocalStorage, solution.b6, solution.a8)
			]));
};
var author$project$Data$SolutionBook$withSolutionId = F2(
	function (solutionId, levelSolutions) {
		return _Utils_update(
			levelSolutions,
			{
				iY: A2(elm$core$Set$insert, solutionId, levelSolutions.iY)
			});
	});
var author$project$Page$Execution$FastForwarding = 2;
var author$project$Page$Execution$Running = 1;
var author$project$Data$Cache$values = function (cache) {
	return elm$core$Dict$values(
		author$project$Data$Cache$getDict(cache));
};
var author$project$Data$History$first = function (history) {
	return author$project$Data$History$current(
		author$project$Data$History$toBeginning(history));
};
var author$project$Data$SolutionId$generator = function () {
	var chars = elm$core$Array$fromList(
		elm$core$String$toList('0123456789abcdef'));
	var getChar = function (i) {
		return A2(
			elm$core$Maybe$withDefault,
			'0',
			A2(elm$core$Array$get, i, chars));
	};
	var _char = A2(
		elm$random$Random$map,
		getChar,
		A2(
			elm$random$Random$int,
			0,
			elm$core$Array$length(chars) - 1));
	return A2(
		elm$random$Random$map,
		elm$core$String$fromList,
		A2(elm$random$Random$list, 16, _char));
}();
var author$project$Data$Solution$generator = F3(
	function (levelId, score, board) {
		return A2(
			elm$random$Random$map,
			function (id) {
				return {cy: board, b6: id, a8: levelId, df: score};
			},
			author$project$Data$SolutionId$generator);
	});
var author$project$Page$Execution$GeneratedSolution = function (a) {
	return {$: 8, a: a};
};
var author$project$Page$Execution$hasException = function (suite) {
	return elm_community$maybe_extra$Maybe$Extra$isJust(
		author$project$Data$History$current(suite.r).b2);
};
var author$project$Page$Execution$isTerminated = function (suite) {
	return author$project$Data$History$current(suite.r).di;
};
var author$project$Page$Execution$canStepSuite = function (suite) {
	return A2(elm$core$Basics$composeL, elm$core$Basics$not, author$project$Page$Execution$isTerminated)(suite) && A2(elm$core$Basics$composeL, elm$core$Basics$not, author$project$Page$Execution$hasException)(suite);
};
var author$project$Data$History$hasFuture = A2(
	elm$core$Basics$composeL,
	A2(elm$core$Basics$composeL, elm$core$Basics$not, elm$core$List$isEmpty),
	function ($) {
		return $.y;
	});
var author$project$Page$Execution$hasNextSuite = A2(
	elm$core$Basics$composeR,
	function ($) {
		return $.l;
	},
	author$project$Data$History$hasFuture);
var author$project$Page$Execution$isOutputCorrect = function (suite) {
	return _Utils_eq(
		suite.cO,
		elm$core$List$reverse(
			author$project$Data$History$current(suite.r).iu));
};
var author$project$Page$Execution$isSuiteFailed = function (suite) {
	return author$project$Page$Execution$hasException(suite) || (author$project$Page$Execution$isTerminated(suite) && A2(elm$core$Basics$composeL, elm$core$Basics$not, author$project$Page$Execution$isOutputCorrect)(suite));
};
var author$project$Page$Execution$isSuiteSolved = function (suite) {
	return author$project$Page$Execution$isTerminated(suite) && A2(elm$core$Basics$composeL, elm$core$Basics$not, author$project$Page$Execution$isSuiteFailed)(suite);
};
var author$project$Page$Execution$canStepExecution = function (execution) {
	var suite = author$project$Data$History$current(execution.l);
	return author$project$Page$Execution$canStepSuite(suite) || (author$project$Page$Execution$hasNextSuite(execution) && author$project$Page$Execution$isSuiteSolved(suite));
};
var elm$core$List$sum = function (numbers) {
	return A3(elm$core$List$foldl, elm$core$Basics$add, 0, numbers);
};
var author$project$Data$Board$count = F2(
	function (predicate, board) {
		return elm$core$List$sum(
			elm$core$Array$toList(
				A2(
					elm$core$Array$map,
					function (row) {
						return elm$core$Array$length(
							A2(elm$core$Array$filter, predicate, row));
					},
					board)));
	});
var author$project$Data$History$toList = function (history) {
	return elm$core$List$concat(
		_List_fromArray(
			[
				elm$core$List$reverse(history.u),
				_List_fromArray(
				[history.x]),
				history.y
			]));
};
var author$project$Page$Execution$getNumberOfStepsForSuite = function (suite) {
	return author$project$Data$History$current(suite.r).cj;
};
var author$project$Page$Execution$getScore = function (execution) {
	var totalNumberOfInstructions = A2(
		author$project$Data$Board$count,
		elm$core$Basics$neq(author$project$Data$Instruction$NoOp),
		author$project$Data$History$first(
			author$project$Data$History$current(execution.l).r).cy);
	var numberOfSteps = elm$core$List$sum(
		A2(
			elm$core$List$map,
			author$project$Page$Execution$getNumberOfStepsForSuite,
			author$project$Data$History$toList(execution.l)));
	var initialNumberOfInstructions = A2(
		author$project$Data$Board$count,
		elm$core$Basics$neq(author$project$Data$Instruction$NoOp),
		execution.bv.hT);
	var numberOfInstructions = totalNumberOfInstructions - initialNumberOfInstructions;
	var score = {bc: numberOfInstructions, bd: numberOfSteps};
	return score;
};
var author$project$Page$Execution$isExecutionSolved = function (execution) {
	return A2(
		elm$core$List$all,
		author$project$Page$Execution$isSuiteSolved,
		author$project$Data$History$toList(execution.l));
};
var author$project$Data$Board$get = F2(
	function (_n0, board) {
		var x = _n0.eR;
		var y = _n0.eS;
		return A2(
			elm$core$Maybe$andThen,
			elm$core$Array$get(x),
			A2(elm$core$Array$get, y, board));
	});
var elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var author$project$Data$Int16$abs = function (_n0) {
	var a = _n0;
	return author$project$Data$Int16$constructor(
		elm$core$Basics$abs(a));
};
var author$project$Data$Int16$add = F2(
	function (_n0, _n1) {
		var a = _n0;
		var b = _n1;
		return author$project$Data$Int16$constructor(a + b);
	});
var author$project$Data$Int16$divide = F2(
	function (_n0, _n1) {
		var a = _n0;
		var b = _n1;
		return author$project$Data$Int16$constructor((a / b) | 0);
	});
var author$project$Data$Int16$isLessThan = F2(
	function (_n0, _n1) {
		var a = _n0;
		var b = _n1;
		return _Utils_cmp(a, b) < 0;
	});
var author$project$Data$Int16$multiply = F2(
	function (_n0, _n1) {
		var a = _n0;
		var b = _n1;
		return author$project$Data$Int16$constructor(a * b);
	});
var author$project$Data$Int16$negate = function (_n0) {
	var a = _n0;
	return author$project$Data$Int16$constructor(-a);
};
var author$project$Data$Int16$one = author$project$Data$Int16$constructor(1);
var author$project$Data$Int16$subtract = F2(
	function (_n0, _n1) {
		var a = _n0;
		var b = _n1;
		return author$project$Data$Int16$constructor(a - b);
	});
var author$project$Data$Int16$zero = author$project$Data$Int16$constructor(0);
var author$project$Page$Execution$peek = function (list) {
	return A2(
		elm$core$Maybe$withDefault,
		author$project$Data$Int16$zero,
		elm$core$List$head(list));
};
var author$project$Page$Execution$peek2 = function (list) {
	if (!list.b) {
		return _Utils_Tuple2(author$project$Data$Int16$zero, author$project$Data$Int16$zero);
	} else {
		if (!list.b.b) {
			var a = list.a;
			return _Utils_Tuple2(a, author$project$Data$Int16$zero);
		} else {
			var a = list.a;
			var _n1 = list.b;
			var b = _n1.a;
			return _Utils_Tuple2(a, b);
		}
	}
};
var author$project$Page$Execution$peekOp2 = F2(
	function (operation, stack) {
		var _n0 = author$project$Page$Execution$peek2(stack);
		var a = _n0.a;
		var b = _n0.b;
		return A2(
			elm$core$List$cons,
			A2(operation, a, b),
			stack);
	});
var author$project$Page$Execution$pop = function (list) {
	if (list.b) {
		var head = list.a;
		var tail = list.b;
		return _Utils_Tuple2(head, tail);
	} else {
		return _Utils_Tuple2(author$project$Data$Int16$zero, _List_Nil);
	}
};
var author$project$Page$Execution$pop2 = function (list) {
	if (!list.b) {
		return _Utils_Tuple3(author$project$Data$Int16$zero, author$project$Data$Int16$zero, _List_Nil);
	} else {
		if (!list.b.b) {
			var a = list.a;
			return _Utils_Tuple3(a, author$project$Data$Int16$zero, _List_Nil);
		} else {
			var a = list.a;
			var _n1 = list.b;
			var b = _n1.a;
			var tail = _n1.b;
			return _Utils_Tuple3(a, b, tail);
		}
	}
};
var author$project$Page$Execution$popOp = F2(
	function (operation, stack) {
		var _n0 = author$project$Page$Execution$pop(stack);
		var a = _n0.a;
		var stack1 = _n0.b;
		return A2(
			elm$core$List$cons,
			operation(a),
			stack1);
	});
var author$project$Page$Execution$popOp2 = F2(
	function (operation, stack) {
		var _n0 = author$project$Page$Execution$pop2(stack);
		var a = _n0.a;
		var b = _n0.b;
		var stack1 = _n0.c;
		return A2(
			elm$core$List$cons,
			A2(operation, a, b),
			stack1);
	});
var author$project$Page$Execution$stepExecutionStep = function (executionStep) {
	var stack = executionStep.k;
	var output = executionStep.iu;
	var instructionPointer = executionStep.aN;
	var position = instructionPointer.ep;
	var input = executionStep.hU;
	var incrementedExecutionStep = _Utils_update(
		executionStep,
		{cj: executionStep.cj + 1});
	var direction = instructionPointer.hm;
	var board = executionStep.cy;
	var boardHeight = author$project$Data$Board$height(board);
	var boardWidth = author$project$Data$Board$width(board);
	var moveInstructionPointer = F2(
		function (newDirection, pointer) {
			var oldPosition = pointer.ep;
			var newPosition = function () {
				switch (newDirection) {
					case 0:
						return _Utils_update(
							oldPosition,
							{
								eR: A2(elm$core$Basics$modBy, boardWidth, oldPosition.eR - 1)
							});
					case 1:
						return _Utils_update(
							oldPosition,
							{
								eS: A2(elm$core$Basics$modBy, boardWidth, oldPosition.eS - 1)
							});
					case 2:
						return _Utils_update(
							oldPosition,
							{
								eR: A2(elm$core$Basics$modBy, boardWidth, oldPosition.eR + 1)
							});
					default:
						return _Utils_update(
							oldPosition,
							{
								eS: A2(elm$core$Basics$modBy, boardWidth, oldPosition.eS + 1)
							});
				}
			}();
			return {hm: newDirection, ep: newPosition};
		});
	var movedExecutionStep = _Utils_update(
		incrementedExecutionStep,
		{
			aN: A2(moveInstructionPointer, direction, instructionPointer)
		});
	var instruction = A2(
		elm$core$Maybe$withDefault,
		author$project$Data$Instruction$NoOp,
		A2(author$project$Data$Board$get, position, board));
	switch (instruction.$) {
		case 1:
			var newDirection = instruction.a;
			return _Utils_update(
				incrementedExecutionStep,
				{
					aN: A2(moveInstructionPointer, newDirection, instructionPointer)
				});
		case 23:
			var trueDirection = instruction.a;
			var falseDirection = instruction.b;
			var newDirection = (!_Utils_eq(
				author$project$Page$Execution$peek(stack),
				author$project$Data$Int16$zero)) ? trueDirection : falseDirection;
			var newInstructionPointer = A2(moveInstructionPointer, newDirection, instructionPointer);
			return _Utils_update(
				incrementedExecutionStep,
				{aN: newInstructionPointer});
		case 21:
			var _n1 = author$project$Page$Execution$pop(input);
			var value = _n1.a;
			var newInput = _n1.b;
			return _Utils_update(
				movedExecutionStep,
				{
					hU: newInput,
					k: A2(elm$core$List$cons, value, stack)
				});
		case 22:
			return _Utils_update(
				movedExecutionStep,
				{
					iu: A2(
						elm$core$List$cons,
						author$project$Page$Execution$peek(stack),
						output)
				});
		case 2:
			var number = instruction.a;
			return _Utils_update(
				movedExecutionStep,
				{
					k: A2(elm$core$List$cons, number, stack)
				});
		case 3:
			return _Utils_update(
				movedExecutionStep,
				{
					k: author$project$Page$Execution$pop(stack).b
				});
		case 5:
			return _Utils_update(
				movedExecutionStep,
				{
					k: A2(
						elm$core$List$cons,
						author$project$Page$Execution$peek(stack),
						stack)
				});
		case 6:
			var _n2 = author$project$Page$Execution$pop2(stack);
			var a = _n2.a;
			var b = _n2.b;
			var stack1 = _n2.c;
			return _Utils_update(
				movedExecutionStep,
				{
					k: A2(
						elm$core$List$cons,
						b,
						A2(elm$core$List$cons, a, stack1))
				});
		case 7:
			return _Utils_update(
				movedExecutionStep,
				{
					k: A2(author$project$Page$Execution$popOp, author$project$Data$Int16$negate, stack)
				});
		case 8:
			return _Utils_update(
				movedExecutionStep,
				{
					k: A2(author$project$Page$Execution$popOp, author$project$Data$Int16$abs, stack)
				});
		case 9:
			return _Utils_update(
				movedExecutionStep,
				{
					k: A2(
						author$project$Page$Execution$popOp,
						function (a) {
							return _Utils_eq(a, author$project$Data$Int16$zero) ? author$project$Data$Int16$one : author$project$Data$Int16$zero;
						},
						stack)
				});
		case 10:
			return _Utils_update(
				movedExecutionStep,
				{
					k: A2(
						author$project$Page$Execution$popOp,
						author$project$Data$Int16$add(author$project$Data$Int16$one),
						stack)
				});
		case 11:
			return _Utils_update(
				movedExecutionStep,
				{
					k: A2(
						author$project$Page$Execution$popOp,
						A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Data$Int16$subtract, author$project$Data$Int16$one),
						stack)
				});
		case 12:
			return _Utils_update(
				movedExecutionStep,
				{
					k: A2(author$project$Page$Execution$popOp2, author$project$Data$Int16$add, stack)
				});
		case 13:
			return _Utils_update(
				movedExecutionStep,
				{
					k: A2(author$project$Page$Execution$popOp2, author$project$Data$Int16$subtract, stack)
				});
		case 14:
			return _Utils_update(
				movedExecutionStep,
				{
					k: A2(author$project$Page$Execution$popOp2, author$project$Data$Int16$multiply, stack)
				});
		case 15:
			return _Utils_update(
				movedExecutionStep,
				{
					k: A2(author$project$Page$Execution$popOp2, author$project$Data$Int16$divide, stack)
				});
		case 16:
			return _Utils_update(
				movedExecutionStep,
				{
					k: A2(
						author$project$Page$Execution$popOp2,
						F2(
							function (a, b) {
								return _Utils_eq(a, b) ? author$project$Data$Int16$one : author$project$Data$Int16$zero;
							}),
						stack)
				});
		case 17:
			return _Utils_update(
				movedExecutionStep,
				{
					k: A2(
						author$project$Page$Execution$peekOp2,
						F2(
							function (a, b) {
								return A2(author$project$Data$Int16$isLessThan, a, b) ? author$project$Data$Int16$one : author$project$Data$Int16$zero;
							}),
						stack)
				});
		case 18:
			return _Utils_update(
				movedExecutionStep,
				{
					k: A2(
						author$project$Page$Execution$popOp2,
						F2(
							function (a, b) {
								return ((!_Utils_eq(a, author$project$Data$Int16$zero)) && (!_Utils_eq(b, author$project$Data$Int16$zero))) ? author$project$Data$Int16$one : author$project$Data$Int16$zero;
							}),
						stack)
				});
		case 19:
			return _Utils_update(
				movedExecutionStep,
				{
					k: A2(
						author$project$Page$Execution$popOp2,
						F2(
							function (a, b) {
								return ((!_Utils_eq(a, author$project$Data$Int16$zero)) || (!_Utils_eq(b, author$project$Data$Int16$zero))) ? author$project$Data$Int16$one : author$project$Data$Int16$zero;
							}),
						stack)
				});
		case 20:
			return _Utils_update(
				movedExecutionStep,
				{
					k: A2(
						author$project$Page$Execution$popOp2,
						F2(
							function (a, b) {
								return (!_Utils_eq(
									!_Utils_eq(a, author$project$Data$Int16$zero),
									!_Utils_eq(b, author$project$Data$Int16$zero))) ? author$project$Data$Int16$one : author$project$Data$Int16$zero;
							}),
						stack)
				});
		case 0:
			return movedExecutionStep;
		case 24:
			return _Utils_update(
				incrementedExecutionStep,
				{di: true});
		case 26:
			var message = instruction.a;
			return _Utils_update(
				incrementedExecutionStep,
				{
					b2: elm$core$Maybe$Just(message)
				});
		case 4:
			return _Utils_update(
				incrementedExecutionStep,
				{
					aN: A2(
						moveInstructionPointer,
						direction,
						A2(moveInstructionPointer, direction, instructionPointer))
				});
		default:
			var _n3 = author$project$Page$Execution$pop(stack);
			var value = _n3.a;
			var tempStack = _n3.b;
			var newStack = _Utils_ap(
				tempStack,
				_List_fromArray(
					[value]));
			return _Utils_update(
				movedExecutionStep,
				{k: newStack});
	}
};
var author$project$Page$Execution$stepSuite = function (executionSuite) {
	var nextExecutionStep = author$project$Page$Execution$stepExecutionStep(
		author$project$Data$History$current(executionSuite.r));
	return _Utils_update(
		executionSuite,
		{
			r: A2(author$project$Data$History$push, nextExecutionStep, executionSuite.r)
		});
};
var author$project$Page$Execution$stepExecution = function (execution) {
	var suite = author$project$Data$History$current(execution.l);
	return author$project$Page$Execution$canStepSuite(suite) ? _Utils_update(
		execution,
		{
			l: A2(author$project$Data$History$update, author$project$Page$Execution$stepSuite, execution.l)
		}) : (author$project$Page$Execution$isSuiteSolved(suite) ? _Utils_update(
		execution,
		{
			l: author$project$Data$History$forward(execution.l)
		}) : execution);
};
var author$project$Page$Execution$stepModel = F2(
	function (oldExecution, model) {
		var _n0 = author$project$Page$Execution$canStepExecution(oldExecution) ? _Utils_Tuple2(
			author$project$Page$Execution$stepExecution(oldExecution),
			model.B) : _Utils_Tuple2(oldExecution, 0);
		var execution = _n0.a;
		var state = _n0.b;
		var generateSolutionCmd = function () {
			var isNewSolution = function (board) {
				return !A2(
					elm$core$List$any,
					A2(
						elm$core$Basics$composeR,
						function ($) {
							return $.cy;
						},
						elm$core$Basics$eq(board)),
					A2(
						elm$core$List$filter,
						A2(
							elm$core$Basics$composeR,
							function ($) {
								return $.a8;
							},
							elm$core$Basics$eq(execution.bv.b6)),
						A2(
							elm$core$List$filterMap,
							A2(elm$core$Basics$composeR, krisajenkins$remotedata$RemoteData$toMaybe, elm_community$maybe_extra$Maybe$Extra$join),
							author$project$Data$Cache$values(model.f.ex.aj))));
			};
			var initialBoard = author$project$Data$History$first(
				author$project$Data$History$current(execution.l).r).cy;
			if (author$project$Page$Execution$isExecutionSolved(execution) && isNewSolution(initialBoard)) {
				var score = author$project$Page$Execution$getScore(execution);
				return A2(
					elm$random$Random$generate,
					A2(elm$core$Basics$composeL, author$project$Page$Execution$InternalMsg, author$project$Page$Execution$GeneratedSolution),
					A3(author$project$Data$Solution$generator, execution.bv.b6, score, initialBoard));
			} else {
				return elm$core$Platform$Cmd$none;
			}
		}();
		var cmd = generateSolutionCmd;
		var newModel = _Utils_update(
			model,
			{
				ar: elm$core$Maybe$Just(execution),
				B: state
			});
		return _Utils_Tuple2(newModel, cmd);
	});
var author$project$Page$Execution$undoExecution = function (execution) {
	var undoSuite = function (suite) {
		return _Utils_update(
			suite,
			{
				r: author$project$Data$History$back(suite.r)
			});
	};
	return _Utils_update(
		execution,
		{
			l: A2(author$project$Data$History$update, undoSuite, execution.l)
		});
};
var author$project$SessionUpdate$GotSaveSolutionResponse = F2(
	function (a, b) {
		return {$: 11, a: a, b: b};
	});
var author$project$Page$Execution$update = F2(
	function (msg, model) {
		var _n0 = model.ar;
		if (!_n0.$) {
			var execution = _n0.a;
			switch (msg.$) {
				case 0:
					return A2(
						author$project$Page$Execution$stepModel,
						execution,
						_Utils_update(
							model,
							{B: 0}));
				case 1:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: elm$core$Maybe$Just(
									author$project$Page$Execution$undoExecution(execution)),
								B: 0
							}),
						elm$core$Platform$Cmd$none);
				case 2:
					return A2(
						author$project$Page$Execution$stepModel,
						execution,
						_Utils_update(
							model,
							{B: 1}));
				case 3:
					return A2(
						author$project$Page$Execution$stepModel,
						execution,
						_Utils_update(
							model,
							{B: 2}));
				case 4:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{B: 0}),
						elm$core$Platform$Cmd$none);
				case 5:
					var newExecution = _Utils_update(
						execution,
						{
							l: A2(
								author$project$Data$History$update,
								function (suite) {
									return _Utils_update(
										suite,
										{
											r: author$project$Data$History$toBeginning(suite.r)
										});
								},
								execution.l)
						});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: elm$core$Maybe$Just(newExecution),
								B: 0
							}),
						elm$core$Platform$Cmd$none);
				case 7:
					return _Utils_Tuple2(
						model,
						A2(
							author$project$Route$pushUrl,
							model.f.fA,
							author$project$Route$EditDraft(model.o)));
				case 6:
					var levelId = A2(
						elm$core$Maybe$map,
						function ($) {
							return $.a8;
						},
						elm_community$maybe_extra$Maybe$Extra$join(
							krisajenkins$remotedata$RemoteData$toMaybe(
								A2(author$project$Data$Cache$get, model.o, model.f.dC.aj))));
					var campaignId = A2(
						elm$core$Maybe$withDefault,
						author$project$Data$CampaignId$standard,
						A2(
							elm$core$Maybe$map,
							function ($) {
								return $.bW;
							},
							A2(
								elm$core$Maybe$andThen,
								krisajenkins$remotedata$RemoteData$toMaybe,
								A2(
									elm$core$Maybe$map,
									A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Data$Session$getLevel, model.f),
									levelId))));
					return _Utils_Tuple2(
						model,
						A2(
							author$project$Route$pushUrl,
							model.f.fA,
							A2(author$project$Route$Campaign, campaignId, levelId)));
				case 9:
					return A2(author$project$Page$Execution$stepModel, execution, model);
				default:
					var solution = msg.a;
					var solutionCache = A3(
						author$project$Data$RemoteCache$withLocalValue,
						solution.b6,
						elm$core$Maybe$Just(solution),
						model.f.ex);
					var solutionBookCache = A3(
						elm_community$basics_extra$Basics$Extra$flip,
						author$project$Data$Cache$withValue(solution.a8),
						model.f.aZ,
						A2(
							author$project$Data$SolutionBook$withSolutionId,
							solution.b6,
							A2(
								krisajenkins$remotedata$RemoteData$withDefault,
								author$project$Data$SolutionBook$empty(solution.a8),
								A2(author$project$Data$Cache$get, solution.a8, model.f.aZ))));
					var highScoreCache = A3(
						author$project$Data$Cache$update,
						solution.a8,
						A2(
							elm$core$Basics$composeR,
							krisajenkins$remotedata$RemoteData$withDefault(
								author$project$Data$HighScore$empty(solution.a8)),
							A2(
								elm$core$Basics$composeR,
								author$project$Data$HighScore$withScore(solution.df),
								krisajenkins$remotedata$RemoteData$Success)),
						model.f.U);
					var modelWithSolution = A3(
						elm_community$basics_extra$Basics$Extra$flip,
						author$project$Page$Execution$withSession,
						model,
						A2(
							author$project$Data$Session$withHighScoreCache,
							highScoreCache,
							A2(
								author$project$Data$Session$withSolutionCache,
								solutionCache,
								A2(author$project$Data$Session$withSolutionBookCache, solutionBookCache, model.f))));
					var _n2 = author$project$Data$Session$getAccessToken(model.f);
					if (!_n2.$) {
						var accessToken = _n2.a;
						return A2(
							author$project$Extra$Cmd$withExtraCmd,
							A3(
								author$project$Data$Solution$saveToServer,
								A2(
									elm$core$Basics$composeL,
									author$project$Page$Execution$SessionMsg,
									author$project$SessionUpdate$GotSaveSolutionResponse(solution)),
								accessToken,
								solution),
							A2(
								author$project$Extra$Cmd$withCmd,
								author$project$Data$Solution$saveToLocalStorage(solution),
								modelWithSolution));
					} else {
						return _Utils_Tuple2(
							modelWithSolution,
							author$project$Data$Solution$saveToLocalStorage(solution));
					}
			}
		} else {
			return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
		}
	});
var author$project$Page$Home$update = F2(
	function (msg, model) {
		return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
	});
var author$project$Api$Auth0$audience = 'https://us-central1-luminous-cubist-234816.cloudfunctions.net';
var author$project$Api$Auth0$encodeState = function (state) {
	return elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'route',
				elm$json$Json$Encode$string(state.aX))
			]));
};
var author$project$Api$Auth0$responseType = A2(
	elm$core$String$join,
	' ',
	_List_fromArray(
		['token']));
var author$project$Api$Auth0$scope = elm$core$Set$fromList(
	_List_fromArray(
		['openid', 'profile', 'read:drafts', 'read:blueprints', 'edit:drafts', 'edit:blueprints', 'read:solutions', 'submit:solutions', 'publish:blueprints']));
var author$project$Route$toUrl = A2(elm$core$Basics$composeR, author$project$Route$toString, elm$url$Url$fromString);
var elm_community$maybe_extra$Maybe$Extra$orElse = F2(
	function (ma, mb) {
		if (mb.$ === 1) {
			return ma;
		} else {
			return mb;
		}
	});
var author$project$Api$Auth0$login = function (url) {
	var state = A2(
		elm$json$Json$Encode$encode,
		0,
		author$project$Api$Auth0$encodeState(
			{
				aX: A2(
					elm$core$Maybe$withDefault,
					'',
					A2(
						elm$core$Maybe$andThen,
						function ($) {
							return $.dE;
						},
						A2(
							elm_community$maybe_extra$Maybe$Extra$orElse,
							author$project$Route$toUrl(author$project$Route$Home),
							url)))
			}));
	var scopes = A2(
		elm$core$String$join,
		' ',
		elm$core$Set$toList(author$project$Api$Auth0$scope));
	var path = _List_fromArray(
		['authorize']);
	var parameters = _List_fromArray(
		[
			A2(elm$url$Url$Builder$string, 'client_id', author$project$Api$Auth0$clientId),
			A2(elm$url$Url$Builder$string, 'response_type', author$project$Api$Auth0$responseType),
			A2(elm$url$Url$Builder$string, 'redirect_uri', author$project$Api$Auth0$redirectUri),
			A2(elm$url$Url$Builder$string, 'scope', scopes),
			A2(elm$url$Url$Builder$string, 'audience', author$project$Api$Auth0$audience),
			A2(elm$url$Url$Builder$string, 'state', state)
		]);
	return A3(elm$url$Url$Builder$crossOrigin, author$project$Api$Auth0$prePath, path, parameters);
};
var author$project$Api$Auth0$reLogin = function (url) {
	return A3(
		elm$url$Url$Builder$crossOrigin,
		author$project$Api$Auth0$prePath,
		_List_fromArray(
			['v2', 'logout']),
		_List_fromArray(
			[
				A2(elm$url$Url$Builder$string, 'client_id', author$project$Api$Auth0$clientId),
				A2(
				elm$url$Url$Builder$string,
				'returnTo',
				author$project$Api$Auth0$login(url))
			]));
};
var author$project$Data$Draft$removeRemoteFromLocalStorage = function (draftId) {
	return author$project$Ports$LocalStorage$storageRemoveItem(
		author$project$Data$Draft$remoteKey(draftId));
};
var author$project$Data$DraftBook$removeFromLocalStorage = function (levelId) {
	return author$project$Ports$LocalStorage$storageRemoveItem(
		author$project$Data$DraftBook$localStorageKey(levelId));
};
var author$project$Data$GetError$consoleError = function (error) {
	return author$project$Ports$Console$errorString(
		author$project$Data$GetError$toString(error));
};
var author$project$Data$SaveError$toString = function (detailedHttpError) {
	switch (detailedHttpError.$) {
		case 0:
			return 'Could not connect to server';
		case 1:
			var string = detailedHttpError.a;
			return string;
		case 2:
			return 'Duplicate';
		case 3:
			return 'Conflicting id';
		default:
			var string = detailedHttpError.a;
			return string;
	}
};
var author$project$Data$SaveError$consoleError = function (error) {
	return author$project$Ports$Console$errorString(
		author$project$Data$SaveError$toString(error));
};
var author$project$Data$Solution$removeFromLocalStorage = function (solutionId) {
	return author$project$Ports$LocalStorage$storageRemoveItem(
		author$project$Data$Solution$localStorageKey(solutionId));
};
var author$project$Data$Solution$remoteKey = function (solutionId) {
	return A2(
		elm$core$String$join,
		'.',
		_List_fromArray(
			[
				author$project$Data$Solution$localStorageKey(solutionId),
				'remote'
			]));
};
var author$project$Data$Solution$removeRemoteFromLocalStorage = function (solutionId) {
	return author$project$Ports$LocalStorage$storageRemoveItem(
		author$project$Data$Solution$remoteKey(solutionId));
};
var author$project$Data$Solution$saveRemoteToLocalStorage = function (solution) {
	var value = author$project$Data$Solution$encode(solution);
	var key = author$project$Data$Solution$remoteKey(solution.b6);
	return author$project$Ports$LocalStorage$storageSetItem(
		_Utils_Tuple2(key, value));
};
var author$project$Data$SolutionBook$removeSolutionIdFromLocalStorage = F2(
	function (solutionId, levelId) {
		return author$project$Ports$LocalStorage$storageRemoveFromSet(
			_Utils_Tuple2(
				author$project$Data$SolutionBook$localStorageKey(levelId),
				author$project$Data$SolutionId$encode(solutionId)));
	});
var author$project$Data$SolutionBook$withoutSolutionId = F2(
	function (solutionIds, solutionBook) {
		return _Utils_update(
			solutionBook,
			{
				iY: A2(elm$core$Set$remove, solutionIds, solutionBook.iY)
			});
	});
var author$project$Data$SubmitSolutionError$toString = function (detailedHttpError) {
	switch (detailedHttpError.$) {
		case 0:
			return 'Could not connect to server';
		case 1:
			var string = detailedHttpError.a;
			return string;
		case 2:
			return 'Duplicate';
		case 3:
			return 'Conflicting id';
		default:
			var string = detailedHttpError.a;
			return string;
	}
};
var author$project$Data$SubmitSolutionError$consoleError = function (error) {
	return author$project$Ports$Console$errorString(
		author$project$Data$SubmitSolutionError$toString(error));
};
var author$project$Page$Initialize$GeneratedSolution = function (a) {
	return {$: 0, a: a};
};
var author$project$Page$Initialize$GotDraftSaveResponse = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var author$project$Page$Initialize$Saved = function (a) {
	return {$: 1, a: a};
};
var author$project$Page$Initialize$Verified = function (a) {
	return {$: 3, a: a};
};
var author$project$Page$Initialize$DoNothing = {$: 0};
var author$project$Page$Initialize$KeepActual = function (a) {
	return {$: 2, a: a};
};
var author$project$Page$Initialize$KeepLocal = function (a) {
	return {$: 1, a: a};
};
var author$project$Page$Initialize$ResolveManually = function (a) {
	return {$: 3, a: a};
};
var author$project$Page$Initialize$determineDraftConflict = function (_n0) {
	var local = _n0.aj;
	var expected = _n0.ai;
	var actual = _n0.ae;
	switch (actual.$) {
		case 0:
			return author$project$Page$Initialize$DoNothing;
		case 1:
			return author$project$Page$Initialize$DoNothing;
		case 2:
			return author$project$Page$Initialize$DoNothing;
		default:
			if (actual.a.$ === 1) {
				var _n2 = actual.a;
				if (!local.$) {
					var localDraft = local.a;
					return author$project$Page$Initialize$KeepLocal(localDraft);
				} else {
					return author$project$Page$Initialize$DoNothing;
				}
			} else {
				var actualDraft = actual.a.a;
				if (!local.$) {
					var localDraft = local.a;
					if (!expected.$) {
						var expectedDraft = expected.a;
						return A2(author$project$Data$Draft$eq, localDraft, expectedDraft) ? (A2(author$project$Data$Draft$eq, localDraft, actualDraft) ? author$project$Page$Initialize$DoNothing : author$project$Page$Initialize$KeepActual(actualDraft)) : (A2(author$project$Data$Draft$eq, localDraft, actualDraft) ? author$project$Page$Initialize$KeepActual(actualDraft) : (A2(author$project$Data$Draft$eq, expectedDraft, actualDraft) ? author$project$Page$Initialize$KeepLocal(localDraft) : author$project$Page$Initialize$ResolveManually(
							{
								ae: elm$core$Maybe$Just(actualDraft),
								ai: elm$core$Maybe$Just(expectedDraft),
								aj: localDraft
							})));
					} else {
						return A2(author$project$Data$Draft$eq, localDraft, actualDraft) ? author$project$Page$Initialize$KeepActual(actualDraft) : author$project$Page$Initialize$ResolveManually(
							{
								ae: elm$core$Maybe$Just(actualDraft),
								ai: elm$core$Maybe$Nothing,
								aj: localDraft
							});
					}
				} else {
					return author$project$Page$Initialize$KeepActual(actualDraft);
				}
			}
	}
};
var author$project$Page$Initialize$Expired = function (a) {
	return {$: 1, a: a};
};
var author$project$Page$Initialize$withExpiredAccessToken = function (model) {
	return _Utils_update(
		model,
		{
			m: function () {
				var _n0 = model.m;
				switch (_n0.$) {
					case 0:
						return author$project$Page$Initialize$Missing;
					case 1:
						var accessToken = _n0.a;
						return author$project$Page$Initialize$Expired(accessToken);
					case 2:
						var accessToken = _n0.a;
						return author$project$Page$Initialize$Expired(accessToken);
					default:
						var accessToken = _n0.a.eT;
						return author$project$Page$Initialize$Expired(accessToken);
				}
			}()
		});
};
var author$project$Page$Initialize$withSavedDraft = F3(
	function (draft, maybeError, model) {
		if (!maybeError.$) {
			var error = maybeError.a;
			return _Utils_update(
				model,
				{
					aa: A3(
						elm$core$Dict$insert,
						draft.b6,
						author$project$Page$Initialize$Saved(
							elm$core$Result$Err(error)),
						model.aa)
				});
		} else {
			return _Utils_update(
				model,
				{
					aa: A3(
						elm$core$Dict$insert,
						draft.b6,
						author$project$Page$Initialize$Saved(
							elm$core$Result$Ok(draft)),
						model.aa)
				});
		}
	});
var author$project$Page$Initialize$withSavingDraft = F2(
	function (draft, model) {
		return _Utils_update(
			model,
			{
				aa: A3(
					elm$core$Dict$insert,
					draft.b6,
					author$project$Page$Initialize$Saving(draft),
					model.aa)
			});
	});
var elm$browser$Browser$Navigation$load = _Browser_load;
var elm$core$Dict$isEmpty = function (dict) {
	if (dict.$ === -2) {
		return true;
	} else {
		return false;
	}
};
var author$project$Page$Initialize$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var solution = msg.a;
				var localSolutions = A3(elm$core$Dict$insert, solution.b6, solution, model.at);
				var localSolutionBooks = A3(
					elm$core$Dict$update,
					solution.a8,
					A2(
						elm$core$Basics$composeR,
						elm$core$Maybe$withDefault(
							author$project$Data$SolutionBook$empty(solution.a8)),
						A2(
							elm$core$Basics$composeR,
							author$project$Data$SolutionBook$withSolutionId(solution.b6),
							elm$core$Maybe$Just)),
					model.aR);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aR: localSolutionBooks, at: localSolutions}),
					elm$core$Platform$Cmd$none);
			case 1:
				var result = msg.a;
				var modelWithActualUserInfo = _Utils_update(
					model,
					{
						aE: krisajenkins$remotedata$RemoteData$fromResult(result)
					});
				if (!result.$) {
					var actualUserInfo = result.a;
					var verified = function () {
						var _n3 = modelWithActualUserInfo.m;
						if (_n3.$ === 2) {
							var accessToken = _n3.a;
							return author$project$Page$Initialize$Verified(
								{eT: accessToken, cu: actualUserInfo});
						} else {
							return modelWithActualUserInfo.m;
						}
					}();
					var _n2 = modelWithActualUserInfo.a5;
					if (!_n2.$) {
						var expectedUserInfo = _n2.a;
						return _Utils_eq(expectedUserInfo.dh, actualUserInfo.dh) ? _Utils_Tuple2(
							_Utils_update(
								modelWithActualUserInfo,
								{
									m: verified,
									a5: elm$core$Maybe$Just(actualUserInfo)
								}),
							elm$core$Platform$Cmd$none) : _Utils_Tuple2(modelWithActualUserInfo, elm$core$Platform$Cmd$none);
					} else {
						var localStorageIsClean = elm$core$Dict$isEmpty(modelWithActualUserInfo.j);
						return localStorageIsClean ? _Utils_Tuple2(
							_Utils_update(
								modelWithActualUserInfo,
								{
									m: verified,
									a5: elm$core$Maybe$Just(actualUserInfo)
								}),
							elm$core$Platform$Cmd$none) : _Utils_Tuple2(modelWithActualUserInfo, elm$core$Platform$Cmd$none);
					}
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						author$project$Page$Initialize$withExpiredAccessToken(modelWithActualUserInfo),
						author$project$Ports$Console$errorString(
							author$project$Data$GetError$toString(error)));
				}
			case 2:
				var draftId = msg.a;
				var result = msg.b;
				var modelWithActualDraft = A3(
					elm_community$basics_extra$Basics$Extra$flip,
					author$project$Page$Initialize$withActualDrafts,
					model,
					A3(author$project$Data$Cache$withResult, draftId, result, model.C));
				var _n4 = author$project$Page$Initialize$determineDraftConflict(
					{
						ae: krisajenkins$remotedata$RemoteData$fromResult(result),
						ai: A2(elm$core$Dict$get, draftId, modelWithActualDraft.n),
						aj: A2(elm$core$Dict$get, draftId, modelWithActualDraft.j)
					});
				switch (_n4.$) {
					case 0:
						return author$project$Extra$Cmd$noCmd(modelWithActualDraft);
					case 1:
						var localDraft = _n4.a;
						var _n5 = modelWithActualDraft.m;
						if (_n5.$ === 3) {
							var accessToken = _n5.a.eT;
							return A2(
								author$project$Extra$Cmd$withCmd,
								A3(
									author$project$Data$Draft$saveToServer,
									author$project$Page$Initialize$GotDraftSaveResponse(localDraft),
									accessToken,
									localDraft),
								A2(author$project$Page$Initialize$withSavingDraft, localDraft, modelWithActualDraft));
						} else {
							return A2(
								author$project$Extra$Cmd$withCmd,
								author$project$Ports$Console$errorString('aa167786    Access token expired during initialization'),
								modelWithActualDraft);
						}
					case 2:
						var actualDraft = _n4.a;
						return author$project$Extra$Cmd$noCmd(
							_Utils_update(
								modelWithActualDraft,
								{
									n: A3(elm$core$Dict$insert, draftId, actualDraft, modelWithActualDraft.n),
									j: A3(elm$core$Dict$insert, draftId, actualDraft, modelWithActualDraft.j)
								}));
					case 4:
						if (_n4.a.$ === 1) {
							var message = _n4.a.a;
							return A2(
								author$project$Extra$Cmd$withCmd,
								author$project$Ports$Console$errorString(message),
								author$project$Page$Initialize$withExpiredAccessToken(modelWithActualDraft));
						} else {
							var error = _n4.a;
							return A2(
								author$project$Extra$Cmd$withCmd,
								author$project$Data$GetError$consoleError(error),
								modelWithActualDraft);
						}
					default:
						return author$project$Extra$Cmd$noCmd(modelWithActualDraft);
				}
			case 3:
				var draft = msg.a;
				var result = msg.b;
				var modelWithSavedDraft = A3(author$project$Page$Initialize$withSavedDraft, draft, result, model);
				if (result.$ === 1) {
					return _Utils_Tuple2(
						_Utils_update(
							modelWithSavedDraft,
							{
								C: A3(
									author$project$Data$Cache$withValue,
									draft.b6,
									elm$core$Maybe$Just(draft),
									model.C),
								n: A3(elm$core$Dict$insert, draft.b6, draft, modelWithSavedDraft.n),
								j: A3(elm$core$Dict$insert, draft.b6, draft, modelWithSavedDraft.j)
							}),
						elm$core$Platform$Cmd$none);
				} else {
					var error = result.a;
					if (error.$ === 1) {
						var message = error.a;
						return _Utils_Tuple2(
							author$project$Page$Initialize$withExpiredAccessToken(modelWithSavedDraft),
							author$project$Data$SaveError$consoleError(error));
					} else {
						return _Utils_Tuple2(
							modelWithSavedDraft,
							author$project$Data$SaveError$consoleError(error));
					}
				}
			case 4:
				var solution = msg.a;
				var maybeError = msg.b;
				var modelWithSavedResponse = _Utils_update(
					model,
					{
						al: A3(
							elm$core$Dict$insert,
							solution.b6,
							author$project$Page$Initialize$Saved(
								function () {
									if (!maybeError.$) {
										var error = maybeError.a;
										return elm$core$Result$Err(error);
									} else {
										return elm$core$Result$Ok(solution);
									}
								}()),
							model.al)
					});
				if (maybeError.$ === 1) {
					return A2(
						author$project$Extra$Cmd$withCmd,
						author$project$Data$Solution$saveRemoteToLocalStorage(solution),
						modelWithSavedResponse);
				} else {
					var error = maybeError.a;
					switch (error.$) {
						case 0:
							return author$project$Extra$Cmd$noCmd(
								author$project$Page$Initialize$withExpiredAccessToken(model));
						case 1:
							var message = error.a;
							return author$project$Extra$Cmd$noCmd(
								author$project$Page$Initialize$withExpiredAccessToken(model));
						case 2:
							var localSolutions = A2(elm$core$Dict$remove, solution.b6, model.at);
							var localSolutionBooks = A3(
								elm$core$Dict$update,
								solution.a8,
								elm$core$Maybe$map(
									author$project$Data$SolutionBook$withoutSolutionId(solution.b6)),
								model.aR);
							var expectedSolutions = A2(elm$core$Dict$remove, solution.b6, model.a4);
							var actualSolutions = A3(author$project$Data$Cache$withValue, solution.b6, elm$core$Maybe$Nothing, model.bm);
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{bm: actualSolutions, a4: expectedSolutions, aR: localSolutionBooks, at: localSolutions}),
								elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											author$project$Data$Solution$removeFromLocalStorage(solution.b6),
											author$project$Data$Solution$removeRemoteFromLocalStorage(solution.b6),
											A2(author$project$Data$SolutionBook$removeSolutionIdFromLocalStorage, solution.b6, solution.a8)
										])));
						case 3:
							var localSolutions = A2(elm$core$Dict$remove, solution.b6, model.at);
							var localSolutionBooks = A3(
								elm$core$Dict$update,
								solution.a8,
								elm$core$Maybe$map(
									author$project$Data$SolutionBook$withoutSolutionId(solution.b6)),
								model.aR);
							var expectedSolutions = A2(elm$core$Dict$remove, solution.b6, model.a4);
							var actualSolutions = A3(author$project$Data$Cache$withValue, solution.b6, elm$core$Maybe$Nothing, model.bm);
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{bm: actualSolutions, a4: expectedSolutions, aR: localSolutionBooks, at: localSolutions}),
								elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											author$project$Data$Solution$removeFromLocalStorage(solution.b6),
											author$project$Data$Solution$removeRemoteFromLocalStorage(solution.b6),
											A2(author$project$Data$SolutionBook$removeSolutionIdFromLocalStorage, solution.b6, solution.a8),
											A2(
											elm$random$Random$generate,
											author$project$Page$Initialize$GeneratedSolution,
											A3(author$project$Data$Solution$generator, solution.a8, solution.df, solution.cy))
										])));
						default:
							return A2(
								author$project$Extra$Cmd$withCmd,
								author$project$Data$SubmitSolutionError$consoleError(error),
								modelWithSavedResponse);
					}
				}
			case 5:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{m: author$project$Page$Initialize$Missing}),
					elm$core$Platform$Cmd$none);
			case 6:
				var draftId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							n: A2(elm$core$Dict$remove, draftId, model.n),
							j: A2(elm$core$Dict$remove, draftId, model.j)
						}),
					elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								author$project$Data$Draft$removeFromLocalStorage(draftId),
								author$project$Data$Draft$removeRemoteFromLocalStorage(draftId)
							])));
			case 7:
				var draftId = msg.a;
				var _n11 = _Utils_Tuple2(
					model.m,
					A2(elm$core$Dict$get, draftId, model.j));
				if ((_n11.a.$ === 3) && (!_n11.b.$)) {
					var accessToken = _n11.a.a.eT;
					var localDraft = _n11.b.a;
					return A2(
						author$project$Extra$Cmd$withCmd,
						A3(
							author$project$Data$Draft$saveToServer,
							author$project$Page$Initialize$GotDraftSaveResponse(localDraft),
							accessToken,
							localDraft),
						A2(author$project$Page$Initialize$withSavingDraft, localDraft, model));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 8:
				var draftId = msg.a;
				var _n12 = A2(author$project$Data$Cache$get, draftId, model.C);
				if (_n12.$ === 3) {
					if (!_n12.a.$) {
						var actualDraft = _n12.a.a;
						return author$project$Extra$Cmd$noCmd(
							_Utils_update(
								model,
								{
									n: A3(elm$core$Dict$insert, draftId, actualDraft, model.n),
									j: A3(elm$core$Dict$insert, draftId, actualDraft, model.j)
								}));
					} else {
						var _n13 = _n12.a;
						return author$project$Extra$Cmd$noCmd(
							_Utils_update(
								model,
								{
									n: A2(elm$core$Dict$remove, draftId, model.n),
									j: A2(elm$core$Dict$remove, draftId, model.j)
								}));
					}
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 9:
				var _n14 = _Utils_Tuple2(
					model.m,
					krisajenkins$remotedata$RemoteData$toMaybe(model.aE));
				if ((_n14.a.$ === 2) && (!_n14.b.$)) {
					var accessToken = _n14.a.a;
					var actualUserInfo = _n14.b.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								m: author$project$Page$Initialize$Verified(
									{eT: accessToken, cu: actualUserInfo})
							}),
						elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			case 10:
				var _n15 = _Utils_Tuple2(
					model.m,
					krisajenkins$remotedata$RemoteData$toMaybe(model.aE));
				if ((_n15.a.$ === 2) && (!_n15.b.$)) {
					var accessToken = _n15.a.a;
					var actualUserInfo = _n15.b.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								m: author$project$Page$Initialize$Verified(
									{eT: accessToken, cu: actualUserInfo}),
								j: elm$core$Dict$empty
							}),
						elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									elm$core$Platform$Cmd$batch(
									A2(
										elm$core$List$map,
										author$project$Data$Draft$removeFromLocalStorage,
										elm$core$Dict$keys(model.j))),
									elm$core$Platform$Cmd$batch(
									A2(
										elm$core$List$map,
										author$project$Data$Draft$removeRemoteFromLocalStorage,
										elm$core$Dict$keys(model.n))),
									elm$core$Platform$Cmd$batch(
									A2(
										elm$core$List$map,
										author$project$Data$DraftBook$removeFromLocalStorage,
										elm$core$Dict$keys(model.d$)))
								])));
				} else {
					return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(
					model,
					elm$browser$Browser$Navigation$load(
						author$project$Api$Auth0$reLogin(
							author$project$Route$toUrl(model.aX))));
		}
	});
var author$project$Ports$LocalStorage$storageClear = _Platform_outgoingPort(
	'storageClear',
	function ($) {
		return elm$json$Json$Encode$null;
	});
var author$project$Data$RemoteCache$withActualError = F3(
	function (key, error, cache) {
		return _Utils_update(
			cache,
			{
				ae: A3(author$project$Data$Cache$withError, key, error, cache.ae)
			});
	});
var author$project$Data$RemoteCache$withActualResult = F3(
	function (key, result, cache) {
		return _Utils_update(
			cache,
			{
				ae: A3(author$project$Data$Cache$withResult, key, result, cache.ae)
			});
	});
var author$project$Data$Session$withoutAccessToken = function (session) {
	var user = session.bQ;
	return _Utils_update(
		session,
		{
			bQ: _Utils_update(
				user,
				{eT: elm$core$Maybe$Nothing})
		});
};
var author$project$Ports$Console$info = _Platform_outgoingPort('info', elm$core$Basics$identity);
var author$project$Ports$Console$infoString = A2(elm$core$Basics$composeR, elm$json$Json$Encode$string, author$project$Ports$Console$info);
var author$project$SessionUpdate$GeneratedSolution = function (a) {
	return {$: 0, a: a};
};
var author$project$SessionUpdate$gotActualDraft = F3(
	function (draftId, maybeActualDraft, session) {
		var sessionWithActualDraft = A3(
			elm_community$basics_extra$Basics$Extra$flip,
			author$project$Data$Session$withDraftCache,
			session,
			A3(author$project$Data$RemoteCache$withActualValue, draftId, maybeActualDraft, session.dC));
		var overwrite = function (draft) {
			return A2(
				author$project$Extra$Cmd$withExtraCmd,
				author$project$Data$Draft$saveToLocalStorage(draft),
				A2(
					author$project$Extra$Cmd$withCmd,
					author$project$Data$Draft$saveRemoteToLocalStorage(draft),
					A3(
						elm_community$basics_extra$Basics$Extra$flip,
						author$project$Data$Session$withDraftCache,
						sessionWithActualDraft,
						A3(
							author$project$Data$RemoteCache$withExpectedValue,
							draftId,
							elm$core$Maybe$Just(draft),
							A3(
								author$project$Data$RemoteCache$withLocalValue,
								draftId,
								elm$core$Maybe$Just(draft),
								sessionWithActualDraft.dC)))));
		};
		if (!maybeActualDraft.$) {
			var actualDraft = maybeActualDraft.a;
			var _n1 = elm_community$maybe_extra$Maybe$Extra$join(
				krisajenkins$remotedata$RemoteData$toMaybe(
					A2(author$project$Data$Cache$get, draftId, sessionWithActualDraft.dC.aj)));
			if (_n1.$ === 1) {
				return overwrite(actualDraft);
			} else {
				var localDraft = _n1.a;
				return A2(
					elm$core$Maybe$withDefault,
					false,
					A2(
						elm$core$Maybe$map,
						author$project$Data$Draft$eq(localDraft),
						elm_community$maybe_extra$Maybe$Extra$join(
							krisajenkins$remotedata$RemoteData$toMaybe(
								A2(author$project$Data$Cache$get, draftId, sessionWithActualDraft.dC.ai))))) ? overwrite(actualDraft) : _Utils_Tuple2(sessionWithActualDraft, elm$core$Platform$Cmd$none);
			}
		} else {
			return krisajenkins$remotedata$RemoteData$isNotAsked(
				A2(author$project$Data$Cache$get, draftId, session.dC.aj)) ? A2(
				author$project$Extra$Cmd$withCmd,
				author$project$Data$Draft$loadFromLocalStorage(draftId),
				A3(
					elm_community$basics_extra$Basics$Extra$flip,
					author$project$Data$Session$withDraftCache,
					sessionWithActualDraft,
					A2(author$project$Data$RemoteCache$withLocalLoading, draftId, sessionWithActualDraft.dC))) : _Utils_Tuple2(sessionWithActualDraft, elm$core$Platform$Cmd$none);
		}
	});
var author$project$SessionUpdate$gotActualSolution = F3(
	function (solutionId, maybeActualSolution, oldSession) {
		var newSession = A3(
			elm_community$basics_extra$Basics$Extra$flip,
			author$project$Data$Session$withSolutionCache,
			oldSession,
			A3(author$project$Data$RemoteCache$withActualValue, solutionId, maybeActualSolution, oldSession.ex));
		if (!maybeActualSolution.$) {
			var actualSolution = maybeActualSolution.a;
			return A2(
				author$project$Extra$Cmd$withExtraCmd,
				author$project$Data$Solution$saveToLocalStorage(actualSolution),
				A2(
					author$project$Extra$Cmd$withCmd,
					author$project$Data$Solution$saveRemoteToLocalStorage(actualSolution),
					A3(
						elm_community$basics_extra$Basics$Extra$flip,
						author$project$Data$Session$withSolutionCache,
						newSession,
						A3(
							author$project$Data$RemoteCache$withExpectedValue,
							solutionId,
							elm$core$Maybe$Just(actualSolution),
							A3(
								author$project$Data$RemoteCache$withLocalValue,
								solutionId,
								elm$core$Maybe$Just(actualSolution),
								newSession.ex)))));
		} else {
			return _Utils_Tuple2(newSession, elm$core$Platform$Cmd$none);
		}
	});
var author$project$SessionUpdate$gotSolutionsByLevelId = F3(
	function (levelId, solutions, session) {
		var solutionBookCache = A3(
			author$project$Data$Cache$update,
			levelId,
			A2(
				elm$core$Basics$composeR,
				krisajenkins$remotedata$RemoteData$withDefault(
					author$project$Data$SolutionBook$empty(levelId)),
				A2(
					elm$core$Basics$composeR,
					author$project$Data$SolutionBook$withSolutionIds(
						elm$core$Set$fromList(
							A2(
								elm$core$List$map,
								function ($) {
									return $.b6;
								},
								solutions))),
					krisajenkins$remotedata$RemoteData$Success)),
			session.aZ);
		return A2(
			author$project$Extra$Cmd$fold,
			A2(
				elm$core$List$map,
				function (solution) {
					return A2(
						author$project$SessionUpdate$gotActualSolution,
						solution.b6,
						elm$core$Maybe$Just(solution));
				},
				solutions),
			A2(author$project$Data$Session$withSolutionBookCache, solutionBookCache, session));
	});
var elm_community$dict_extra$Dict$Extra$groupBy = F2(
	function (keyfn, list) {
		return A3(
			elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A3(
						elm$core$Dict$update,
						keyfn(x),
						A2(
							elm$core$Basics$composeR,
							elm$core$Maybe$map(
								elm$core$List$cons(x)),
							A2(
								elm$core$Basics$composeR,
								elm$core$Maybe$withDefault(
									_List_fromArray(
										[x])),
								elm$core$Maybe$Just)),
						acc);
				}),
			elm$core$Dict$empty,
			list);
	});
var author$project$SessionUpdate$update = F2(
	function (msg, session) {
		switch (msg.$) {
			case 0:
				var solution = msg.a;
				var solutionCache = A3(
					author$project$Data$RemoteCache$withActualValue,
					solution.b6,
					elm$core$Maybe$Just(solution),
					session.ex);
				var solutionBookCache = A3(
					author$project$Data$Cache$update,
					solution.b6,
					krisajenkins$remotedata$RemoteData$map(
						author$project$Data$SolutionBook$withSolutionId(solution.b6)),
					session.aZ);
				var cmd = elm$core$Platform$Cmd$batch(
					elm_community$maybe_extra$Maybe$Extra$values(
						_List_fromArray(
							[
								elm$core$Maybe$Just(
								author$project$Data$Solution$saveToLocalStorage(solution)),
								elm$core$Maybe$Just(
								A2(author$project$Data$SolutionBook$saveToLocalStorage, solution.b6, solution.a8)),
								A2(
								elm$core$Maybe$map,
								A2(
									elm_community$basics_extra$Basics$Extra$flip,
									author$project$Data$Solution$saveToServer(
										author$project$SessionUpdate$GotSaveSolutionResponse(solution)),
									solution),
								author$project$Data$Session$getAccessToken(session))
							])));
				return A2(
					author$project$Extra$Cmd$withCmd,
					cmd,
					A2(
						author$project$Data$Session$withSolutionBookCache,
						solutionBookCache,
						A2(author$project$Data$Session$withSolutionCache, solutionCache, session)));
			case 1:
				var draftId = msg.a;
				var result = msg.b;
				if (result.$ === 1) {
					return A2(
						author$project$Extra$Cmd$withCmd,
						author$project$Data$Draft$removeRemoteFromLocalStorage(draftId),
						A3(
							elm_community$basics_extra$Basics$Extra$flip,
							author$project$Data$Session$withDraftCache,
							session,
							A3(
								author$project$Data$RemoteCache$withExpectedValue,
								draftId,
								elm$core$Maybe$Nothing,
								A3(author$project$Data$RemoteCache$withActualValue, draftId, elm$core$Maybe$Nothing, session.dC))));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						session,
						author$project$Data$SaveError$consoleError(error));
				}
			case 4:
				var levelId = msg.a;
				var result = msg.b;
				return A2(
					author$project$Extra$Cmd$withCmd,
					A2(
						elm$core$Maybe$withDefault,
						elm$core$Platform$Cmd$none,
						A2(
							elm$core$Maybe$map,
							author$project$Data$GetError$consoleError,
							author$project$Extra$Result$getError(result))),
					A3(
						elm_community$basics_extra$Basics$Extra$flip,
						author$project$Data$Session$withHighScoreCache,
						session,
						A3(author$project$Data$Cache$withResult, levelId, result, session.U)));
			case 6:
				var campaignId = msg.a;
				var result = msg.b;
				if (!result.$) {
					var levels = result.a;
					var saveLevelsLocallyCmd = elm$core$Platform$Cmd$batch(
						A2(elm$core$List$map, author$project$Data$Level$saveToLocalStorage, levels));
					var campaign = {
						b6: campaignId,
						aQ: A2(
							elm$core$List$map,
							function ($) {
								return $.b6;
							},
							levels)
					};
					var saveCampaignLocallyCmd = author$project$Data$Campaign$saveToLocalStorage(campaign);
					var cmd = elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[saveCampaignLocallyCmd, saveLevelsLocallyCmd]));
					return _Utils_Tuple2(
						A2(
							author$project$Data$Session$withLevels,
							levels,
							A2(author$project$Data$Session$withCampaign, campaign, session)),
						cmd);
				} else {
					var error = result.a;
					return A2(
						author$project$Extra$Cmd$withExtraCmd,
						author$project$Data$GetError$consoleError(error),
						A2(
							author$project$Extra$Cmd$withCmd,
							author$project$Data$Campaign$loadFromLocalStorage(campaignId),
							A3(
								elm_community$basics_extra$Basics$Extra$flip,
								author$project$Data$Session$withCampaignCache,
								session,
								A2(author$project$Data$Cache$loading, campaignId, session.aG))));
				}
			case 9:
				var solutionId = msg.a;
				var result = msg.b;
				if (!result.$) {
					var solution = result.a;
					return A3(author$project$SessionUpdate$gotActualSolution, solutionId, solution, session);
				} else {
					var error = result.a;
					return A2(
						author$project$Extra$Cmd$withExtraCmd,
						author$project$Data$GetError$consoleError(error),
						A2(
							author$project$Extra$Cmd$withCmd,
							author$project$Data$Solution$loadFromLocalStorage(solutionId),
							A3(
								elm_community$basics_extra$Basics$Extra$flip,
								author$project$Data$Session$withSolutionCache,
								session,
								A2(
									author$project$Data$RemoteCache$withLocalLoading,
									solutionId,
									A3(author$project$Data$RemoteCache$withActualError, solutionId, error, session.ex)))));
				}
			case 7:
				var levelId = msg.a;
				var result = msg.b;
				if (!result.$) {
					var solutions = result.a;
					return A3(author$project$SessionUpdate$gotSolutionsByLevelId, levelId, solutions, session);
				} else {
					var error = result.a;
					return A2(
						author$project$Extra$Cmd$withExtraCmd,
						author$project$Data$GetError$consoleError(error),
						A2(
							author$project$Extra$Cmd$withCmd,
							author$project$Data$SolutionBook$loadFromLocalStorage(levelId),
							A3(
								elm_community$basics_extra$Basics$Extra$flip,
								author$project$Data$Session$withSolutionBookCache,
								session,
								A2(author$project$Data$Cache$loading, levelId, session.aZ))));
				}
			case 8:
				var levelIds = msg.a;
				var result = msg.b;
				if (!result.$) {
					var solutions = result.a;
					return A3(
						elm_community$basics_extra$Basics$Extra$flip,
						author$project$Extra$Cmd$fold,
						session,
						A2(
							elm$core$List$map,
							function (_n6) {
								var levelId = _n6.a;
								var solutionsByLevelId = _n6.b;
								return A2(author$project$SessionUpdate$gotSolutionsByLevelId, levelId, solutionsByLevelId);
							},
							elm$core$Dict$toList(
								A2(
									elm$core$Dict$union,
									A2(
										elm_community$dict_extra$Dict$Extra$groupBy,
										function ($) {
											return $.a8;
										},
										solutions),
									elm$core$Dict$fromList(
										A2(
											elm$core$List$map,
											A2(elm_community$basics_extra$Basics$Extra$flip, elm$core$Tuple$pair, _List_Nil),
											levelIds))))));
				} else {
					var error = result.a;
					var functions = A2(
						elm$core$List$map,
						F2(
							function (levelId, sess) {
								return A2(
									author$project$Extra$Cmd$withExtraCmd,
									author$project$Data$GetError$consoleError(error),
									A2(
										author$project$Extra$Cmd$withCmd,
										author$project$Data$SolutionBook$loadFromLocalStorage(levelId),
										A3(
											elm_community$basics_extra$Basics$Extra$flip,
											author$project$Data$Session$withSolutionBookCache,
											sess,
											A2(author$project$Data$Cache$loading, levelId, sess.aZ))));
							}),
						levelIds);
					return A2(author$project$Extra$Cmd$fold, functions, session);
				}
			case 5:
				var levelId = msg.a;
				var result = msg.b;
				if (!result.$) {
					var level = result.a;
					return A2(
						author$project$Extra$Cmd$withCmd,
						author$project$Data$Level$saveToLocalStorage(level),
						A3(
							elm_community$basics_extra$Basics$Extra$flip,
							author$project$Data$Session$withLevelCache,
							session,
							A3(author$project$Data$Cache$withValue, level.b6, level, session.as)));
				} else {
					var error = result.a;
					return A2(
						author$project$Extra$Cmd$withExtraCmd,
						author$project$Data$GetError$consoleError(error),
						A2(
							author$project$Extra$Cmd$withCmd,
							author$project$Data$Level$loadFromLocalStorage(levelId),
							A3(
								elm_community$basics_extra$Basics$Extra$flip,
								author$project$Data$Session$withLevelCache,
								session,
								A2(author$project$Data$Cache$loading, levelId, session.as))));
				}
			case 2:
				var draftId = msg.a;
				var result = msg.b;
				var sessionWithActualDraft = A3(
					elm_community$basics_extra$Basics$Extra$flip,
					author$project$Data$Session$withDraftCache,
					session,
					A3(author$project$Data$RemoteCache$withActualResult, draftId, result, session.dC));
				if (!result.$) {
					var actualDraft = result.a;
					return A3(author$project$SessionUpdate$gotActualDraft, draftId, actualDraft, sessionWithActualDraft);
				} else {
					if (!result.a.$) {
						var _n9 = result.a;
						return A2(
							author$project$Extra$Cmd$withCmd,
							author$project$Ports$Console$infoString('No network, going offline'),
							author$project$Data$Session$withoutAccessToken(sessionWithActualDraft));
					} else {
						var error = result.a;
						return A2(
							author$project$Extra$Cmd$withCmd,
							author$project$Data$GetError$consoleError(error),
							sessionWithActualDraft);
					}
				}
			case 3:
				var request = msg.a;
				var result = msg.b;
				if (!result.$) {
					var drafts = result.a;
					var draftBook = A2(
						author$project$Data$DraftBook$withDraftIds,
						elm$core$Set$fromList(
							A2(
								elm$core$List$map,
								function ($) {
									return $.b6;
								},
								drafts)),
						A2(
							krisajenkins$remotedata$RemoteData$withDefault,
							author$project$Data$DraftBook$empty(request),
							A2(author$project$Data$Cache$get, request, session.aJ)));
					var draftBookCache = A3(author$project$Data$Cache$withValue, request, draftBook, session.aJ);
					var sessionWithDraftBookCache = A2(author$project$Data$Session$withDraftBookCache, draftBookCache, session);
					return A2(
						author$project$Extra$Cmd$fold,
						A2(
							elm$core$List$map,
							function (draft) {
								return A2(
									author$project$SessionUpdate$gotActualDraft,
									draft.b6,
									elm$core$Maybe$Just(draft));
							},
							drafts),
						sessionWithDraftBookCache);
				} else {
					var error = result.a;
					return A2(
						author$project$Extra$Cmd$withExtraCmd,
						author$project$Data$GetError$consoleError(error),
						A2(
							author$project$Extra$Cmd$withCmd,
							author$project$Data$DraftBook$loadFromLocalStorage(request),
							A3(
								elm_community$basics_extra$Basics$Extra$flip,
								author$project$Data$Session$withDraftBookCache,
								session,
								A2(author$project$Data$Cache$loading, request, session.aJ))));
				}
			case 10:
				var draft = msg.a;
				var result = msg.b;
				if (result.$ === 1) {
					return A2(
						author$project$Extra$Cmd$withCmd,
						author$project$Data$Draft$saveRemoteToLocalStorage(draft),
						A3(
							elm_community$basics_extra$Basics$Extra$flip,
							author$project$Data$Session$withDraftCache,
							session,
							A3(
								author$project$Data$RemoteCache$withExpectedValue,
								draft.b6,
								elm$core$Maybe$Just(draft),
								A3(
									author$project$Data$RemoteCache$withActualValue,
									draft.b6,
									elm$core$Maybe$Just(draft),
									session.dC))));
				} else {
					var error = result.a;
					return _Utils_Tuple2(
						session,
						author$project$Data$SaveError$consoleError(error));
				}
			default:
				var solution = msg.a;
				var result = msg.b;
				if (result.$ === 1) {
					return A2(
						author$project$Extra$Cmd$withCmd,
						author$project$Data$Solution$saveRemoteToLocalStorage(solution),
						A3(
							elm_community$basics_extra$Basics$Extra$flip,
							author$project$Data$Session$withSolutionCache,
							session,
							A3(
								author$project$Data$RemoteCache$withExpectedValue,
								solution.b6,
								elm$core$Maybe$Just(solution),
								A3(
									author$project$Data$RemoteCache$withActualValue,
									solution.b6,
									elm$core$Maybe$Just(solution),
									session.ex))));
				} else {
					var error = result.a;
					switch (error.$) {
						case 0:
							return _Utils_Tuple2(
								session,
								author$project$Ports$Console$infoString('zyxpyks2wk7qjlyv    Offline'));
						case 1:
							var message = error.a;
							return _Utils_Tuple2(
								author$project$Data$Session$withoutAccessToken(session),
								author$project$Ports$Console$errorString(message));
						case 2:
							var solutionCache = A3(
								author$project$Data$RemoteCache$withActualValue,
								solution.b6,
								elm$core$Maybe$Nothing,
								A3(
									author$project$Data$RemoteCache$withExpectedValue,
									solution.b6,
									elm$core$Maybe$Nothing,
									A3(author$project$Data$RemoteCache$withLocalValue, solution.b6, elm$core$Maybe$Nothing, session.ex)));
							var solutionBookCache = A3(
								author$project$Data$Cache$update,
								solution.b6,
								krisajenkins$remotedata$RemoteData$map(
									author$project$Data$SolutionBook$withoutSolutionId(solution.b6)),
								session.aZ);
							var cmd = elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										author$project$Data$Solution$removeFromLocalStorage(solution.b6),
										author$project$Data$Solution$removeRemoteFromLocalStorage(solution.b6),
										A2(author$project$Data$SolutionBook$removeSolutionIdFromLocalStorage, solution.b6, solution.a8)
									]));
							return A2(
								author$project$Extra$Cmd$withCmd,
								cmd,
								A2(
									author$project$Data$Session$withSolutionBookCache,
									solutionBookCache,
									A2(author$project$Data$Session$withSolutionCache, solutionCache, session)));
						case 3:
							var solutionCache = A3(
								author$project$Data$RemoteCache$withActualValue,
								solution.b6,
								elm$core$Maybe$Nothing,
								A3(
									author$project$Data$RemoteCache$withExpectedValue,
									solution.b6,
									elm$core$Maybe$Nothing,
									A3(author$project$Data$RemoteCache$withLocalValue, solution.b6, elm$core$Maybe$Nothing, session.ex)));
							var solutionBookCache = A3(
								author$project$Data$Cache$update,
								solution.b6,
								krisajenkins$remotedata$RemoteData$map(
									author$project$Data$SolutionBook$withoutSolutionId(solution.b6)),
								session.aZ);
							var cmd = elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										author$project$Data$Solution$removeFromLocalStorage(solution.b6),
										author$project$Data$Solution$removeRemoteFromLocalStorage(solution.b6),
										A2(author$project$Data$SolutionBook$removeSolutionIdFromLocalStorage, solution.b6, solution.a8),
										A2(
										elm$random$Random$generate,
										author$project$SessionUpdate$GeneratedSolution,
										A3(author$project$Data$Solution$generator, solution.a8, solution.df, solution.cy))
									]));
							return A2(
								author$project$Extra$Cmd$withCmd,
								cmd,
								A2(
									author$project$Data$Session$withSolutionBookCache,
									solutionBookCache,
									A2(author$project$Data$Session$withSolutionCache, solutionCache, session)));
						default:
							return _Utils_Tuple2(
								session,
								author$project$Data$SubmitSolutionError$consoleError(error));
					}
				}
		}
	});
var author$project$Main$update = F2(
	function (msg, model) {
		return author$project$Main$load(
			function () {
				var _n0 = _Utils_Tuple2(msg, model);
				_n0$15:
				while (true) {
					switch (_n0.a.$) {
						case 1:
							var urlRequest = _n0.a.a;
							if (!urlRequest.$) {
								var url = urlRequest.a;
								var _n2 = url.dE;
								if (_n2.$ === 1) {
									return _Utils_Tuple2(model, elm$core$Platform$Cmd$none);
								} else {
									return _Utils_Tuple2(
										model,
										A2(
											elm$browser$Browser$Navigation$pushUrl,
											author$project$Main$getSession(model).fA,
											elm$url$Url$toString(url)));
								}
							} else {
								var href = urlRequest.a;
								var cmd = elm$core$Platform$Cmd$batch(
									elm_community$maybe_extra$Maybe$Extra$values(
										_List_fromArray(
											[
												_Utils_eq(href, author$project$Api$Auth0$logout) ? elm$core$Maybe$Just(
												author$project$Ports$LocalStorage$storageClear(0)) : elm$core$Maybe$Nothing,
												elm$core$Maybe$Just(
												elm$browser$Browser$Navigation$load(href))
											])));
								return _Utils_Tuple2(model, cmd);
							}
						case 0:
							var url = _n0.a.a;
							return A2(
								author$project$Main$changeUrl,
								url,
								author$project$Main$getSession(model));
						case 12:
							var response = _n0.a.a;
							var mdl = _n0.b;
							return A2(author$project$Main$localStorageResponseUpdate, response, mdl);
						case 5:
							if (!_n0.a.a.$) {
								if (_n0.b.$ === 4) {
									var message = _n0.a.a.a;
									var mdl = _n0.b.a;
									return A3(
										author$project$Main$updateWith,
										author$project$Main$Execution,
										author$project$Main$ExecutionMsg,
										A2(author$project$Page$Execution$update, message, mdl));
								} else {
									break _n0$15;
								}
							} else {
								var message = _n0.a.a.a;
								return A3(
									author$project$Main$updateWith,
									A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Main$withSession, model),
									A2(elm$core$Basics$composeL, author$project$Main$ExecutionMsg, author$project$Page$Execution$SessionMsg),
									A2(
										author$project$SessionUpdate$update,
										message,
										author$project$Main$getSession(model)));
							}
						case 6:
							if (!_n0.a.a.$) {
								if (_n0.b.$ === 5) {
									var message = _n0.a.a.a;
									var mdl = _n0.b.a;
									return A3(
										author$project$Main$updateWith,
										author$project$Main$Draft,
										author$project$Main$DraftMsg,
										A2(author$project$Page$Draft$update, message, mdl));
								} else {
									break _n0$15;
								}
							} else {
								var message = _n0.a.a.a;
								return A3(
									author$project$Main$updateWith,
									A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Main$withSession, model),
									A2(elm$core$Basics$composeL, author$project$Main$DraftMsg, author$project$Page$Draft$SessionMsg),
									A2(
										author$project$SessionUpdate$update,
										message,
										author$project$Main$getSession(model)));
							}
						case 2:
							if (!_n0.a.a.$) {
								if (_n0.b.$ === 1) {
									var message = _n0.a.a.a;
									var mdl = _n0.b.a;
									return A3(
										author$project$Main$updateWith,
										author$project$Main$Campaign,
										author$project$Main$CampaignMsg,
										A2(author$project$Page$Campaign$update, message, mdl));
								} else {
									break _n0$15;
								}
							} else {
								var message = _n0.a.a.a;
								return A3(
									author$project$Main$updateWith,
									A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Main$withSession, model),
									A2(elm$core$Basics$composeL, author$project$Main$CampaignMsg, author$project$Page$Campaign$SessionMsg),
									A2(
										author$project$SessionUpdate$update,
										message,
										author$project$Main$getSession(model)));
							}
						case 3:
							if (!_n0.a.a.$) {
								if (_n0.b.$ === 2) {
									var message = _n0.a.a.a;
									var mdl = _n0.b.a;
									return A3(
										author$project$Main$updateWith,
										author$project$Main$Campaigns,
										author$project$Main$CampaignsMsg,
										A2(author$project$Page$Campaigns$update, message, mdl));
								} else {
									break _n0$15;
								}
							} else {
								var message = _n0.a.a.a;
								return A3(
									author$project$Main$updateWith,
									A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Main$withSession, model),
									A2(elm$core$Basics$composeL, author$project$Main$CampaignsMsg, author$project$Page$Campaigns$SessionMsg),
									A2(
										author$project$SessionUpdate$update,
										message,
										author$project$Main$getSession(model)));
							}
						case 7:
							if (!_n0.b.$) {
								var message = _n0.a.a;
								var mdl = _n0.b.a;
								return A3(
									author$project$Main$updateWith,
									author$project$Main$Home,
									author$project$Main$HomeMsg,
									A2(author$project$Page$Home$update, message, mdl));
							} else {
								break _n0$15;
							}
						case 8:
							if (_n0.b.$ === 7) {
								var message = _n0.a.a;
								var mdl = _n0.b.a;
								return A3(
									author$project$Main$updateWith,
									author$project$Main$Blueprints,
									author$project$Main$BlueprintsMsg,
									A2(author$project$Page$Blueprints$update, message, mdl));
							} else {
								break _n0$15;
							}
						case 9:
							if (_n0.b.$ === 6) {
								var message = _n0.a.a;
								var mdl = _n0.b.a;
								return A3(
									author$project$Main$updateWith,
									author$project$Main$Blueprint,
									author$project$Main$BlueprintMsg,
									A2(author$project$Page$Blueprint$update, message, mdl));
							} else {
								break _n0$15;
							}
						case 10:
							if (_n0.b.$ === 8) {
								var message = _n0.a.a;
								var mdl = _n0.b.a;
								return A3(
									author$project$Main$updateWith,
									author$project$Main$Initialize,
									author$project$Main$InitializeMsg,
									A2(author$project$Page$Initialize$update, message, mdl));
							} else {
								break _n0$15;
							}
						default:
							break _n0$15;
					}
				}
				var message = _n0.a;
				var mdl = _n0.b;
				return _Utils_Tuple2(
					model,
					author$project$Ports$Console$errorString('v51oox6osqnrm33f    Wrong message for model'));
			}());
	});
var author$project$ApplicationName$applicationName = 'Manick';
var author$project$Data$BoardInstruction$withInstruction = F2(
	function (instruction, boardInstruction) {
		return _Utils_update(
			boardInstruction,
			{cU: instruction});
	});
var author$project$Data$InstructionTool$getInstruction = function (instructionTool) {
	switch (instructionTool.$) {
		case 0:
			var instruction = instructionTool.a;
			return instruction;
		case 1:
			var direction = instructionTool.a;
			return author$project$Data$Instruction$ChangeDirection(direction);
		case 2:
			var trueDirection = instructionTool.a;
			var falseDirection = instructionTool.b;
			return A2(author$project$Data$Instruction$Branch, trueDirection, falseDirection);
		case 3:
			var value = instructionTool.a;
			return A2(
				elm$core$Maybe$withDefault,
				author$project$Data$Instruction$Exception(value + ' is not a number'),
				A2(
					elm$core$Maybe$map,
					author$project$Data$Instruction$PushToStack,
					author$project$Data$Int16$fromString(value)));
		default:
			var exceptionMessage = instructionTool.a;
			return author$project$Data$Instruction$Exception(exceptionMessage);
	}
};
var author$project$InstructionView$description = function (instruction) {
	switch (instruction.$) {
		case 0:
			return 'Do nothing';
		case 1:
			switch (instruction.a) {
				case 0:
					var _n1 = instruction.a;
					return 'Change the direction of the instruction pointer to left';
				case 1:
					var _n2 = instruction.a;
					return 'Change the direction of the instruction pointer to up';
				case 2:
					var _n3 = instruction.a;
					return 'Change the direction of the instruction pointer to right';
				default:
					var _n4 = instruction.a;
					return 'Change the direction of the instruction pointer to down';
			}
		case 23:
			switch (instruction.a) {
				case 0:
					switch (instruction.b) {
						case 0:
							var _n5 = instruction.a;
							var _n6 = instruction.b;
							return 'Peek the stack. If the value is not zero, go left, otherwise go left';
						case 1:
							var _n7 = instruction.a;
							var _n8 = instruction.b;
							return 'Peek the stack. If the value is not zero, go left, otherwise go up';
						case 2:
							var _n9 = instruction.a;
							var _n10 = instruction.b;
							return 'Peek the stack. If the value is not zero, go left, otherwise go right';
						default:
							var _n11 = instruction.a;
							var _n12 = instruction.b;
							return 'Peek the stack. If the value is not zero, go left, otherwise go down';
					}
				case 1:
					switch (instruction.b) {
						case 0:
							var _n13 = instruction.a;
							var _n14 = instruction.b;
							return 'Peek the stack. If the value is not zero, go up, otherwise go left';
						case 1:
							var _n15 = instruction.a;
							var _n16 = instruction.b;
							return 'Peek the stack. If the value is not zero, go up, otherwise go up';
						case 2:
							var _n17 = instruction.a;
							var _n18 = instruction.b;
							return 'Peek the stack. If the value is not zero, go up, otherwise go right';
						default:
							var _n19 = instruction.a;
							var _n20 = instruction.b;
							return 'Peek the stack. If the value is not zero, go up, otherwise go down';
					}
				case 2:
					switch (instruction.b) {
						case 0:
							var _n21 = instruction.a;
							var _n22 = instruction.b;
							return 'Peek the stack. If the value is not zero, go right, otherwise go left';
						case 1:
							var _n23 = instruction.a;
							var _n24 = instruction.b;
							return 'Peek the stack. If the value is not zero, go right, otherwise go up';
						case 2:
							var _n25 = instruction.a;
							var _n26 = instruction.b;
							return 'Peek the stack. If the value is not zero, go right, otherwise go right';
						default:
							var _n27 = instruction.a;
							var _n28 = instruction.b;
							return 'Peek the stack. If the value is not zero, go right, otherwise go down';
					}
				default:
					switch (instruction.b) {
						case 0:
							var _n29 = instruction.a;
							var _n30 = instruction.b;
							return 'Peek the stack. If the value is not zero, go down, otherwise go left';
						case 1:
							var _n31 = instruction.a;
							var _n32 = instruction.b;
							return 'Peek the stack. If the value is not zero, go down, otherwise go up';
						case 2:
							var _n33 = instruction.a;
							var _n34 = instruction.b;
							return 'Peek the stack. If the value is not zero, go down, otherwise go right';
						default:
							var _n35 = instruction.a;
							var _n36 = instruction.b;
							return 'Peek the stack. If the value is not zero, go down, otherwise go down';
					}
			}
		case 12:
			return 'Pop the top two values from the stack, add them, and push the result to the stack';
		case 13:
			return 'Pop the top value a and the second value b from the stack, and push b - a to the stack';
		case 21:
			return 'Pop the top value from the input and push it to the stack';
		case 22:
			return 'Peek the top value from the stack and push it to the output';
		case 5:
			return 'Duplicate the top value in the stack';
		case 10:
			return 'Pop the top value a from the stack and push a + 1 to the stack';
		case 11:
			return 'Pop the top value a from the stack and push a - 1 to the stack';
		case 6:
			return 'Swap the top two values in the stack';
		case 3:
			return 'Discard the top value from the stack';
		case 4:
			return 'Move the instruction pointer two steps in the current direction';
		case 24:
			return 'End the program';
		case 25:
			return 'Move the top value of the stack to the bottom of the stack';
		case 17:
			return 'Peek at the top two values a and b in the stack, if a < b push 1 to the stack, otherwize push 0 to the stack';
		case 26:
			var message = instruction.a;
			return message;
		case 2:
			var value = instruction.a;
			return 'Push ' + (author$project$Data$Int16$toString(value) + ' to the stack');
		case 7:
			return 'TODO: Negate the top value of the stack';
		case 8:
			return 'TODO: Replace the top value of the stack with it\'s absolute value';
		case 9:
			return 'TODO: If the top value of the stack is 0, replace it with 1, otherwise replace it with 0';
		case 14:
			return 'TODO: Peek';
		case 15:
			return 'TODO: Divide';
		case 16:
			return 'TODO: Equals';
		case 18:
			return 'TODO: And';
		case 19:
			return 'TODO: Or';
		default:
			return 'TODO: XOr';
	}
};
var mdgriffith$elm_ui$Internal$Model$AlignY = function (a) {
	return {$: 5, a: a};
};
var mdgriffith$elm_ui$Internal$Model$CenterY = 1;
var mdgriffith$elm_ui$Element$centerY = mdgriffith$elm_ui$Internal$Model$AlignY(1);
var mdgriffith$elm_ui$Internal$Model$Height = function (a) {
	return {$: 8, a: a};
};
var mdgriffith$elm_ui$Element$height = mdgriffith$elm_ui$Internal$Model$Height;
var mdgriffith$elm_ui$Internal$Model$Content = {$: 1};
var mdgriffith$elm_ui$Element$shrink = mdgriffith$elm_ui$Internal$Model$Content;
var mdgriffith$elm_ui$Internal$Model$Width = function (a) {
	return {$: 7, a: a};
};
var mdgriffith$elm_ui$Element$width = mdgriffith$elm_ui$Internal$Model$Width;
var mdgriffith$elm_ui$Internal$Model$Unkeyed = function (a) {
	return {$: 0, a: a};
};
var mdgriffith$elm_ui$Internal$Model$AsEl = 2;
var mdgriffith$elm_ui$Internal$Model$asEl = 2;
var mdgriffith$elm_ui$Internal$Model$Generic = {$: 0};
var mdgriffith$elm_ui$Internal$Model$div = mdgriffith$elm_ui$Internal$Model$Generic;
var mdgriffith$elm_ui$Internal$Flag$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var mdgriffith$elm_ui$Internal$Flag$none = A2(mdgriffith$elm_ui$Internal$Flag$Field, 0, 0);
var mdgriffith$elm_ui$Internal$Model$NoNearbyChildren = {$: 0};
var mdgriffith$elm_ui$Internal$Style$classes = {gE: 'a', dp: 'atv', gG: 'ab', gH: 'cx', gI: 'cy', gJ: 'acb', gK: 'accx', gL: 'accy', gM: 'acr', eX: 'al', eY: 'ar', gN: 'at', dq: 'ah', dr: 'av', gQ: 's', gV: 'bh', gW: 'b', gZ: 'w7', g$: 'bd', g0: 'bdt', cz: 'bn', g1: 'bs', cC: 'cpe', g9: 'cp', ha: 'cpx', hb: 'cpy', aH: 'c', cE: 'ctr', cG: 'cb', cH: 'ccx', aI: 'ccy', bX: 'cl', cI: 'cr', he: 'ct', hg: 'cptr', hh: 'ctxt', hA: 'fcs', hC: 'fs', hF: 'g', dF: 'hbh', cS: 'hc', dG: 'hf', fr: 'hfp', hJ: 'hv', hM: 'ic', hO: 'fr', hV: 'iml', hW: 'it', hY: 'i', bB: 'nb', fN: 'notxt', ik: 'ol', $9: 'or', be: 'oq', iv: 'oh', iw: 'pg', fT: 'p', ix: 'ppe', iH: 'ui', f6: 'r', iL: 'sb', iM: 'sbx', iN: 'sby', iP: 'sbt', iW: 'e', iX: 'cap', i_: 'sev', i4: 'sk', dj: 't', ja: 'tc', jb: 'w8', jc: 'w2', jd: 'w9', je: 'tj', dk: 'tja', jf: 'tl', jg: 'w3', jh: 'w5', ji: 'w4', jj: 'tr', jk: 'w6', jl: 'w1', jm: 'tun', gq: 'ts', bj: 'clr', ju: 'u', eO: 'wc', gz: 'we', eP: 'wf', gA: 'wfp', eQ: 'wrp'};
var mdgriffith$elm_ui$Internal$Model$columnClass = mdgriffith$elm_ui$Internal$Style$classes.gQ + (' ' + mdgriffith$elm_ui$Internal$Style$classes.aH);
var mdgriffith$elm_ui$Internal$Model$gridClass = mdgriffith$elm_ui$Internal$Style$classes.gQ + (' ' + mdgriffith$elm_ui$Internal$Style$classes.hF);
var mdgriffith$elm_ui$Internal$Model$pageClass = mdgriffith$elm_ui$Internal$Style$classes.gQ + (' ' + mdgriffith$elm_ui$Internal$Style$classes.iw);
var mdgriffith$elm_ui$Internal$Model$paragraphClass = mdgriffith$elm_ui$Internal$Style$classes.gQ + (' ' + mdgriffith$elm_ui$Internal$Style$classes.fT);
var mdgriffith$elm_ui$Internal$Model$rowClass = mdgriffith$elm_ui$Internal$Style$classes.gQ + (' ' + mdgriffith$elm_ui$Internal$Style$classes.f6);
var mdgriffith$elm_ui$Internal$Model$singleClass = mdgriffith$elm_ui$Internal$Style$classes.gQ + (' ' + mdgriffith$elm_ui$Internal$Style$classes.iW);
var mdgriffith$elm_ui$Internal$Model$contextClasses = function (context) {
	switch (context) {
		case 0:
			return mdgriffith$elm_ui$Internal$Model$rowClass;
		case 1:
			return mdgriffith$elm_ui$Internal$Model$columnClass;
		case 2:
			return mdgriffith$elm_ui$Internal$Model$singleClass;
		case 3:
			return mdgriffith$elm_ui$Internal$Model$gridClass;
		case 4:
			return mdgriffith$elm_ui$Internal$Model$paragraphClass;
		default:
			return mdgriffith$elm_ui$Internal$Model$pageClass;
	}
};
var mdgriffith$elm_ui$Internal$Model$Keyed = function (a) {
	return {$: 1, a: a};
};
var mdgriffith$elm_ui$Internal$Model$NoStyleSheet = {$: 0};
var mdgriffith$elm_ui$Internal$Model$Styled = function (a) {
	return {$: 1, a: a};
};
var mdgriffith$elm_ui$Internal$Model$Unstyled = function (a) {
	return {$: 0, a: a};
};
var mdgriffith$elm_ui$Internal$Model$addChildren = F2(
	function (existing, nearbyChildren) {
		switch (nearbyChildren.$) {
			case 0:
				return existing;
			case 1:
				var behind = nearbyChildren.a;
				return _Utils_ap(behind, existing);
			case 2:
				var inFront = nearbyChildren.a;
				return _Utils_ap(existing, inFront);
			default:
				var behind = nearbyChildren.a;
				var inFront = nearbyChildren.b;
				return _Utils_ap(
					behind,
					_Utils_ap(existing, inFront));
		}
	});
var mdgriffith$elm_ui$Internal$Model$addKeyedChildren = F3(
	function (key, existing, nearbyChildren) {
		switch (nearbyChildren.$) {
			case 0:
				return existing;
			case 1:
				var behind = nearbyChildren.a;
				return _Utils_ap(
					A2(
						elm$core$List$map,
						function (x) {
							return _Utils_Tuple2(key, x);
						},
						behind),
					existing);
			case 2:
				var inFront = nearbyChildren.a;
				return _Utils_ap(
					existing,
					A2(
						elm$core$List$map,
						function (x) {
							return _Utils_Tuple2(key, x);
						},
						inFront));
			default:
				var behind = nearbyChildren.a;
				var inFront = nearbyChildren.b;
				return _Utils_ap(
					A2(
						elm$core$List$map,
						function (x) {
							return _Utils_Tuple2(key, x);
						},
						behind),
					_Utils_ap(
						existing,
						A2(
							elm$core$List$map,
							function (x) {
								return _Utils_Tuple2(key, x);
							},
							inFront)));
		}
	});
var mdgriffith$elm_ui$Internal$Model$AsParagraph = 4;
var mdgriffith$elm_ui$Internal$Model$asParagraph = 4;
var elm$html$Html$div = _VirtualDom_node('div');
var elm$html$Html$p = _VirtualDom_node('p');
var elm$html$Html$s = _VirtualDom_node('s');
var elm$html$Html$u = _VirtualDom_node('u');
var elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			elm$json$Json$Encode$string(string));
	});
var elm$html$Html$Attributes$class = elm$html$Html$Attributes$stringProperty('className');
var elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var mdgriffith$elm_ui$Internal$Flag$Flag = function (a) {
	return {$: 0, a: a};
};
var mdgriffith$elm_ui$Internal$Flag$Second = function (a) {
	return {$: 1, a: a};
};
var mdgriffith$elm_ui$Internal$Flag$flag = function (i) {
	return (i > 31) ? mdgriffith$elm_ui$Internal$Flag$Second(1 << (i - 32)) : mdgriffith$elm_ui$Internal$Flag$Flag(1 << i);
};
var mdgriffith$elm_ui$Internal$Flag$alignBottom = mdgriffith$elm_ui$Internal$Flag$flag(41);
var mdgriffith$elm_ui$Internal$Flag$alignRight = mdgriffith$elm_ui$Internal$Flag$flag(40);
var mdgriffith$elm_ui$Internal$Flag$centerX = mdgriffith$elm_ui$Internal$Flag$flag(42);
var mdgriffith$elm_ui$Internal$Flag$centerY = mdgriffith$elm_ui$Internal$Flag$flag(43);
var mdgriffith$elm_ui$Internal$Flag$heightBetween = mdgriffith$elm_ui$Internal$Flag$flag(45);
var mdgriffith$elm_ui$Internal$Flag$heightFill = mdgriffith$elm_ui$Internal$Flag$flag(37);
var mdgriffith$elm_ui$Internal$Flag$present = F2(
	function (myFlag, _n0) {
		var fieldOne = _n0.a;
		var fieldTwo = _n0.b;
		if (!myFlag.$) {
			var first = myFlag.a;
			return _Utils_eq(first & fieldOne, first);
		} else {
			var second = myFlag.a;
			return _Utils_eq(second & fieldTwo, second);
		}
	});
var mdgriffith$elm_ui$Internal$Flag$widthBetween = mdgriffith$elm_ui$Internal$Flag$flag(44);
var mdgriffith$elm_ui$Internal$Flag$widthFill = mdgriffith$elm_ui$Internal$Flag$flag(39);
var elm$core$Set$member = F2(
	function (key, _n0) {
		var dict = _n0;
		return A2(elm$core$Dict$member, key, dict);
	});
var mdgriffith$elm_ui$Internal$Model$lengthClassName = function (x) {
	switch (x.$) {
		case 0:
			var px = x.a;
			return elm$core$String$fromInt(px) + 'px';
		case 1:
			return 'auto';
		case 2:
			var i = x.a;
			return elm$core$String$fromInt(i) + 'fr';
		case 3:
			var min = x.a;
			var len = x.b;
			return 'min' + (elm$core$String$fromInt(min) + mdgriffith$elm_ui$Internal$Model$lengthClassName(len));
		default:
			var max = x.a;
			var len = x.b;
			return 'max' + (elm$core$String$fromInt(max) + mdgriffith$elm_ui$Internal$Model$lengthClassName(len));
	}
};
var elm$core$Basics$round = _Basics_round;
var mdgriffith$elm_ui$Internal$Model$floatClass = function (x) {
	return elm$core$String$fromInt(
		elm$core$Basics$round(x * 255));
};
var mdgriffith$elm_ui$Internal$Model$transformClass = function (transform) {
	switch (transform.$) {
		case 0:
			return elm$core$Maybe$Nothing;
		case 1:
			var _n1 = transform.a;
			var x = _n1.a;
			var y = _n1.b;
			var z = _n1.c;
			return elm$core$Maybe$Just(
				'mv-' + (mdgriffith$elm_ui$Internal$Model$floatClass(x) + ('-' + (mdgriffith$elm_ui$Internal$Model$floatClass(y) + ('-' + mdgriffith$elm_ui$Internal$Model$floatClass(z))))));
		default:
			var _n2 = transform.a;
			var tx = _n2.a;
			var ty = _n2.b;
			var tz = _n2.c;
			var _n3 = transform.b;
			var sx = _n3.a;
			var sy = _n3.b;
			var sz = _n3.c;
			var _n4 = transform.c;
			var ox = _n4.a;
			var oy = _n4.b;
			var oz = _n4.c;
			var angle = transform.d;
			return elm$core$Maybe$Just(
				'tfrm-' + (mdgriffith$elm_ui$Internal$Model$floatClass(tx) + ('-' + (mdgriffith$elm_ui$Internal$Model$floatClass(ty) + ('-' + (mdgriffith$elm_ui$Internal$Model$floatClass(tz) + ('-' + (mdgriffith$elm_ui$Internal$Model$floatClass(sx) + ('-' + (mdgriffith$elm_ui$Internal$Model$floatClass(sy) + ('-' + (mdgriffith$elm_ui$Internal$Model$floatClass(sz) + ('-' + (mdgriffith$elm_ui$Internal$Model$floatClass(ox) + ('-' + (mdgriffith$elm_ui$Internal$Model$floatClass(oy) + ('-' + (mdgriffith$elm_ui$Internal$Model$floatClass(oz) + ('-' + mdgriffith$elm_ui$Internal$Model$floatClass(angle))))))))))))))))))));
	}
};
var mdgriffith$elm_ui$Internal$Model$getStyleName = function (style) {
	switch (style.$) {
		case 13:
			var name = style.a;
			return name;
		case 12:
			var name = style.a;
			var o = style.b;
			return name;
		case 0:
			var _class = style.a;
			return _class;
		case 1:
			var name = style.a;
			return name;
		case 2:
			var i = style.a;
			return 'font-size-' + elm$core$String$fromInt(i);
		case 3:
			var _class = style.a;
			return _class;
		case 4:
			var _class = style.a;
			return _class;
		case 5:
			var cls = style.a;
			var x = style.b;
			var y = style.c;
			return cls;
		case 7:
			var cls = style.a;
			var top = style.b;
			var right = style.c;
			var bottom = style.d;
			var left = style.e;
			return cls;
		case 6:
			var cls = style.a;
			var top = style.b;
			var right = style.c;
			var bottom = style.d;
			var left = style.e;
			return cls;
		case 8:
			var template = style.a;
			return 'grid-rows-' + (A2(
				elm$core$String$join,
				'-',
				A2(elm$core$List$map, mdgriffith$elm_ui$Internal$Model$lengthClassName, template.iI)) + ('-cols-' + (A2(
				elm$core$String$join,
				'-',
				A2(elm$core$List$map, mdgriffith$elm_ui$Internal$Model$lengthClassName, template.ap)) + ('-space-x-' + (mdgriffith$elm_ui$Internal$Model$lengthClassName(template.i$.a) + ('-space-y-' + mdgriffith$elm_ui$Internal$Model$lengthClassName(template.i$.b)))))));
		case 9:
			var pos = style.a;
			return 'gp grid-pos-' + (elm$core$String$fromInt(pos.f6) + ('-' + (elm$core$String$fromInt(pos.e6) + ('-' + (elm$core$String$fromInt(pos.gy) + ('-' + elm$core$String$fromInt(pos.fp)))))));
		case 11:
			var selector = style.a;
			var subStyle = style.b;
			var name = function () {
				switch (selector) {
					case 0:
						return 'fs';
					case 1:
						return 'hv';
					default:
						return 'act';
				}
			}();
			return A2(
				elm$core$String$join,
				' ',
				A2(
					elm$core$List$map,
					function (sty) {
						var _n1 = mdgriffith$elm_ui$Internal$Model$getStyleName(sty);
						if (_n1 === '') {
							return '';
						} else {
							var styleName = _n1;
							return styleName + ('-' + name);
						}
					},
					subStyle));
		default:
			var x = style.a;
			return A2(
				elm$core$Maybe$withDefault,
				'',
				mdgriffith$elm_ui$Internal$Model$transformClass(x));
	}
};
var mdgriffith$elm_ui$Internal$Model$reduceStyles = F2(
	function (style, nevermind) {
		var cache = nevermind.a;
		var existing = nevermind.b;
		var styleName = mdgriffith$elm_ui$Internal$Model$getStyleName(style);
		return A2(elm$core$Set$member, styleName, cache) ? nevermind : _Utils_Tuple2(
			A2(elm$core$Set$insert, styleName, cache),
			A2(elm$core$List$cons, style, existing));
	});
var elm$core$Tuple$mapFirst = F2(
	function (func, _n0) {
		var x = _n0.a;
		var y = _n0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var elm$core$Tuple$mapSecond = F2(
	function (func, _n0) {
		var x = _n0.a;
		var y = _n0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var mdgriffith$elm_ui$Internal$Model$Property = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var mdgriffith$elm_ui$Internal$Model$Style = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var elm$core$String$fromFloat = _String_fromNumber;
var mdgriffith$elm_ui$Internal$Model$formatColor = function (_n0) {
	var red = _n0.a;
	var green = _n0.b;
	var blue = _n0.c;
	var alpha = _n0.d;
	return 'rgba(' + (elm$core$String$fromInt(
		elm$core$Basics$round(red * 255)) + ((',' + elm$core$String$fromInt(
		elm$core$Basics$round(green * 255))) + ((',' + elm$core$String$fromInt(
		elm$core$Basics$round(blue * 255))) + (',' + (elm$core$String$fromFloat(alpha) + ')')))));
};
var mdgriffith$elm_ui$Internal$Model$formatBoxShadow = function (shadow) {
	return A2(
		elm$core$String$join,
		' ',
		A2(
			elm$core$List$filterMap,
			elm$core$Basics$identity,
			_List_fromArray(
				[
					shadow.fw ? elm$core$Maybe$Just('inset') : elm$core$Maybe$Nothing,
					elm$core$Maybe$Just(
					elm$core$String$fromFloat(shadow.a.a) + 'px'),
					elm$core$Maybe$Just(
					elm$core$String$fromFloat(shadow.a.b) + 'px'),
					elm$core$Maybe$Just(
					elm$core$String$fromFloat(shadow.bo) + 'px'),
					elm$core$Maybe$Just(
					elm$core$String$fromFloat(shadow.ew) + 'px'),
					elm$core$Maybe$Just(
					mdgriffith$elm_ui$Internal$Model$formatColor(shadow.bp))
				])));
};
var mdgriffith$elm_ui$Internal$Style$dot = function (c) {
	return '.' + c;
};
var mdgriffith$elm_ui$Internal$Model$renderFocusStyle = function (focus) {
	return A2(
		mdgriffith$elm_ui$Internal$Model$Style,
		mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gQ) + (':focus .focusable, ' + (mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gQ) + '.focusable:focus')),
		A2(
			elm$core$List$filterMap,
			elm$core$Basics$identity,
			_List_fromArray(
				[
					A2(
					elm$core$Maybe$map,
					function (color) {
						return A2(
							mdgriffith$elm_ui$Internal$Model$Property,
							'border-color',
							mdgriffith$elm_ui$Internal$Model$formatColor(color));
					},
					focus.g_),
					A2(
					elm$core$Maybe$map,
					function (color) {
						return A2(
							mdgriffith$elm_ui$Internal$Model$Property,
							'background-color',
							mdgriffith$elm_ui$Internal$Model$formatColor(color));
					},
					focus.gT),
					A2(
					elm$core$Maybe$map,
					function (shadow) {
						return A2(
							mdgriffith$elm_ui$Internal$Model$Property,
							'box-shadow',
							mdgriffith$elm_ui$Internal$Model$formatBoxShadow(
								{
									bo: shadow.bo,
									bp: shadow.bp,
									fw: false,
									a: A2(
										elm$core$Tuple$mapSecond,
										elm$core$Basics$toFloat,
										A2(elm$core$Tuple$mapFirst, elm$core$Basics$toFloat, shadow.a)),
									ew: shadow.ew
								}));
					},
					focus.iV),
					elm$core$Maybe$Just(
					A2(mdgriffith$elm_ui$Internal$Model$Property, 'outline', 'none'))
				])));
};
var elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var mdgriffith$elm_ui$Internal$Style$Batch = function (a) {
	return {$: 5, a: a};
};
var mdgriffith$elm_ui$Internal$Style$Child = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var mdgriffith$elm_ui$Internal$Style$Class = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var mdgriffith$elm_ui$Internal$Style$Descriptor = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var mdgriffith$elm_ui$Internal$Style$Left = 3;
var mdgriffith$elm_ui$Internal$Style$Prop = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var mdgriffith$elm_ui$Internal$Style$Right = 2;
var mdgriffith$elm_ui$Internal$Style$Self = elm$core$Basics$identity;
var mdgriffith$elm_ui$Internal$Style$Supports = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var mdgriffith$elm_ui$Internal$Style$Content = elm$core$Basics$identity;
var mdgriffith$elm_ui$Internal$Style$Bottom = 1;
var mdgriffith$elm_ui$Internal$Style$CenterX = 4;
var mdgriffith$elm_ui$Internal$Style$CenterY = 5;
var mdgriffith$elm_ui$Internal$Style$Top = 0;
var mdgriffith$elm_ui$Internal$Style$alignments = _List_fromArray(
	[0, 1, 2, 3, 4, 5]);
var mdgriffith$elm_ui$Internal$Style$contentName = function (desc) {
	switch (desc) {
		case 0:
			var _n1 = desc;
			return mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.he);
		case 1:
			var _n2 = desc;
			return mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.cG);
		case 2:
			var _n3 = desc;
			return mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.cI);
		case 3:
			var _n4 = desc;
			return mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.bX);
		case 4:
			var _n5 = desc;
			return mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.cH);
		default:
			var _n6 = desc;
			return mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.aI);
	}
};
var mdgriffith$elm_ui$Internal$Style$selfName = function (desc) {
	switch (desc) {
		case 0:
			var _n1 = desc;
			return mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gN);
		case 1:
			var _n2 = desc;
			return mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gG);
		case 2:
			var _n3 = desc;
			return mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.eY);
		case 3:
			var _n4 = desc;
			return mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.eX);
		case 4:
			var _n5 = desc;
			return mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gH);
		default:
			var _n6 = desc;
			return mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gI);
	}
};
var mdgriffith$elm_ui$Internal$Style$describeAlignment = function (values) {
	var createDescription = function (alignment) {
		var _n0 = values(alignment);
		var content = _n0.a;
		var indiv = _n0.b;
		return _List_fromArray(
			[
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$contentName(alignment),
				content),
				A2(
				mdgriffith$elm_ui$Internal$Style$Child,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gQ),
				_List_fromArray(
					[
						A2(
						mdgriffith$elm_ui$Internal$Style$Descriptor,
						mdgriffith$elm_ui$Internal$Style$selfName(alignment),
						indiv)
					]))
			]);
	};
	return mdgriffith$elm_ui$Internal$Style$Batch(
		A2(elm$core$List$concatMap, createDescription, mdgriffith$elm_ui$Internal$Style$alignments));
};
var mdgriffith$elm_ui$Internal$Style$elDescription = _List_fromArray(
	[
		A2(mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
		A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'column'),
		A2(mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre'),
		A2(
		mdgriffith$elm_ui$Internal$Style$Descriptor,
		mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.dF),
		_List_fromArray(
			[
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
				A2(
				mdgriffith$elm_ui$Internal$Style$Child,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gV),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '-1')
					]))
			])),
		A2(
		mdgriffith$elm_ui$Internal$Style$Descriptor,
		mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.iP),
		_List_fromArray(
			[
				A2(
				mdgriffith$elm_ui$Internal$Style$Child,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.dj),
				_List_fromArray(
					[
						A2(
						mdgriffith$elm_ui$Internal$Style$Descriptor,
						mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.dG),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Descriptor,
						mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.eP),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'auto !important')
							]))
					]))
			])),
		A2(
		mdgriffith$elm_ui$Internal$Style$Child,
		mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.cS),
		_List_fromArray(
			[
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto')
			])),
		A2(
		mdgriffith$elm_ui$Internal$Style$Child,
		mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.dG),
		_List_fromArray(
			[
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '100000')
			])),
		A2(
		mdgriffith$elm_ui$Internal$Style$Child,
		mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.eP),
		_List_fromArray(
			[
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
			])),
		A2(
		mdgriffith$elm_ui$Internal$Style$Child,
		mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.eO),
		_List_fromArray(
			[
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
			])),
		mdgriffith$elm_ui$Internal$Style$describeAlignment(
		function (alignment) {
			switch (alignment) {
				case 0:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
							]),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important'),
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', '0 !important')
							]));
				case 1:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
							]),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', '0 !important')
							]));
				case 2:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
							]),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-end')
							]));
				case 3:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
							]),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
							]));
				case 4:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
							]),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'center')
							]));
				default:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2(
								mdgriffith$elm_ui$Internal$Style$Child,
								mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gQ),
								_List_fromArray(
									[
										A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto'),
										A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto')
									]))
							]),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important')
							]));
			}
		})
	]);
var mdgriffith$elm_ui$Internal$Style$gridAlignments = function (values) {
	var createDescription = function (alignment) {
		return _List_fromArray(
			[
				A2(
				mdgriffith$elm_ui$Internal$Style$Child,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gQ),
				_List_fromArray(
					[
						A2(
						mdgriffith$elm_ui$Internal$Style$Descriptor,
						mdgriffith$elm_ui$Internal$Style$selfName(alignment),
						values(alignment))
					]))
			]);
	};
	return mdgriffith$elm_ui$Internal$Style$Batch(
		A2(elm$core$List$concatMap, createDescription, mdgriffith$elm_ui$Internal$Style$alignments));
};
var mdgriffith$elm_ui$Internal$Style$Above = 0;
var mdgriffith$elm_ui$Internal$Style$Behind = 5;
var mdgriffith$elm_ui$Internal$Style$Below = 1;
var mdgriffith$elm_ui$Internal$Style$OnLeft = 3;
var mdgriffith$elm_ui$Internal$Style$OnRight = 2;
var mdgriffith$elm_ui$Internal$Style$Within = 4;
var mdgriffith$elm_ui$Internal$Style$locations = function () {
	var loc = 0;
	var _n0 = function () {
		switch (loc) {
			case 0:
				return 0;
			case 1:
				return 0;
			case 2:
				return 0;
			case 3:
				return 0;
			case 4:
				return 0;
			default:
				return 0;
		}
	}();
	return _List_fromArray(
		[0, 1, 2, 3, 4, 5]);
}();
var mdgriffith$elm_ui$Internal$Style$baseSheet = _List_fromArray(
	[
		A2(
		mdgriffith$elm_ui$Internal$Style$Class,
		'html,body',
		_List_fromArray(
			[
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'padding', '0'),
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0')
			])),
		A2(
		mdgriffith$elm_ui$Internal$Style$Class,
		_Utils_ap(
			mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gQ),
			_Utils_ap(
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.iW),
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.hM))),
		_List_fromArray(
			[
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'block')
			])),
		A2(
		mdgriffith$elm_ui$Internal$Style$Class,
		mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gQ) + ':focus',
		_List_fromArray(
			[
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'outline', 'none')
			])),
		A2(
		mdgriffith$elm_ui$Internal$Style$Class,
		mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.iH),
		_List_fromArray(
			[
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto'),
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'min-height', '100%'),
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				_Utils_ap(
					mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gQ),
					mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.dG)),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.dG),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%')
							]))
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Child,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.hO),
				_List_fromArray(
					[
						A2(
						mdgriffith$elm_ui$Internal$Style$Descriptor,
						mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.bB),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'fixed')
							]))
					]))
			])),
		A2(
		mdgriffith$elm_ui$Internal$Style$Class,
		mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.bB),
		_List_fromArray(
			[
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'relative'),
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'border', 'none'),
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'row'),
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.iW),
				mdgriffith$elm_ui$Internal$Style$elDescription),
				mdgriffith$elm_ui$Internal$Style$Batch(
				function (fn) {
					return A2(elm$core$List$map, fn, mdgriffith$elm_ui$Internal$Style$locations);
				}(
					function (loc) {
						switch (loc) {
							case 0:
								return A2(
									mdgriffith$elm_ui$Internal$Style$Descriptor,
									mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gE),
									_List_fromArray(
										[
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'bottom', '100%'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2(
											mdgriffith$elm_ui$Internal$Style$Child,
											mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.dG),
											_List_fromArray(
												[
													A2(mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto')
												])),
											A2(
											mdgriffith$elm_ui$Internal$Style$Child,
											mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.eP),
											_List_fromArray(
												[
													A2(mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
												])),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2(mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							case 1:
								return A2(
									mdgriffith$elm_ui$Internal$Style$Descriptor,
									mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gW),
									_List_fromArray(
										[
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'bottom', '0'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'height', '0'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2(mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												])),
											A2(
											mdgriffith$elm_ui$Internal$Style$Child,
											mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.dG),
											_List_fromArray(
												[
													A2(mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto')
												]))
										]));
							case 2:
								return A2(
									mdgriffith$elm_ui$Internal$Style$Descriptor,
									mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.$9),
									_List_fromArray(
										[
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'left', '100%'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2(mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							case 3:
								return A2(
									mdgriffith$elm_ui$Internal$Style$Descriptor,
									mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.ik),
									_List_fromArray(
										[
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'right', '100%'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2(mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							case 4:
								return A2(
									mdgriffith$elm_ui$Internal$Style$Descriptor,
									mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.hO),
									_List_fromArray(
										[
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2(mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							default:
								return A2(
									mdgriffith$elm_ui$Internal$Style$Descriptor,
									mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gV),
									_List_fromArray(
										[
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2(mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
						}
					}))
			])),
		A2(
		mdgriffith$elm_ui$Internal$Style$Class,
		mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gQ),
		_List_fromArray(
			[
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'relative'),
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'border', 'none'),
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '0'),
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'row'),
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'resize', 'none'),
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'font-feature-settings', 'inherit'),
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'box-sizing', 'border-box'),
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0'),
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'padding', '0'),
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'border-width', '0'),
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'solid'),
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'font-size', 'inherit'),
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'color', 'inherit'),
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'font-family', 'inherit'),
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'line-height', '1'),
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', 'inherit'),
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'none'),
				A2(mdgriffith$elm_ui$Internal$Style$Prop, 'font-style', 'inherit'),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.eQ),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-wrap', 'wrap')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.fN),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, '-moz-user-select', 'none'),
						A2(mdgriffith$elm_ui$Internal$Style$Prop, '-webkit-user-select', 'none'),
						A2(mdgriffith$elm_ui$Internal$Style$Prop, '-ms-user-select', 'none'),
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'user-select', 'none')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.hg),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'cursor', 'pointer')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.hh),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'cursor', 'text')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.ix),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none !important')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.cC),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto !important')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.bj),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.be),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap(mdgriffith$elm_ui$Internal$Style$classes.hJ, mdgriffith$elm_ui$Internal$Style$classes.bj)) + ':hover',
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap(mdgriffith$elm_ui$Internal$Style$classes.hJ, mdgriffith$elm_ui$Internal$Style$classes.be)) + ':hover',
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap(mdgriffith$elm_ui$Internal$Style$classes.hA, mdgriffith$elm_ui$Internal$Style$classes.bj)) + ':focus',
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap(mdgriffith$elm_ui$Internal$Style$classes.hA, mdgriffith$elm_ui$Internal$Style$classes.be)) + ':focus',
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap(mdgriffith$elm_ui$Internal$Style$classes.dp, mdgriffith$elm_ui$Internal$Style$classes.bj)) + ':active',
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap(mdgriffith$elm_ui$Internal$Style$classes.dp, mdgriffith$elm_ui$Internal$Style$classes.be)) + ':active',
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gq),
				_List_fromArray(
					[
						A2(
						mdgriffith$elm_ui$Internal$Style$Prop,
						'transition',
						A2(
							elm$core$String$join,
							', ',
							A2(
								elm$core$List$map,
								function (x) {
									return x + ' 160ms';
								},
								_List_fromArray(
									['transform', 'opacity', 'filter', 'background-color', 'color', 'font-size']))))
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.iL),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'overflow', 'auto'),
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.iM),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-x', 'auto'),
						A2(
						mdgriffith$elm_ui$Internal$Style$Descriptor,
						mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.f6),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
							]))
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.iN),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-y', 'auto'),
						A2(
						mdgriffith$elm_ui$Internal$Style$Descriptor,
						mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.aH),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Descriptor,
						mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.iW),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
							]))
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.g9),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'overflow', 'hidden')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.ha),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-x', 'hidden')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.hb),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-y', 'hidden')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.eO),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'width', 'auto')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.cz),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'border-width', '0')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.g$),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'dashed')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.g0),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'dotted')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.g1),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'solid')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.dj),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre'),
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-block')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.hW),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'line-height', '1.05')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.iW),
				mdgriffith$elm_ui$Internal$Style$elDescription),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.f6),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'row'),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gQ),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', '0%'),
								A2(
								mdgriffith$elm_ui$Internal$Style$Descriptor,
								mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gz),
								_List_fromArray(
									[
										A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
									]))
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.dG),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch !important')
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.fr),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch !important')
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.eP),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '100000')
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.cE),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0'),
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch')
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + mdgriffith$elm_ui$Internal$Style$classes.gM,
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1')
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						's:first-of-type.' + mdgriffith$elm_ui$Internal$Style$classes.gK,
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								mdgriffith$elm_ui$Internal$Style$Child,
								mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gH),
								_List_fromArray(
									[
										A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin-left', 'auto !important')
									]))
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + mdgriffith$elm_ui$Internal$Style$classes.gK,
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								mdgriffith$elm_ui$Internal$Style$Child,
								mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gH),
								_List_fromArray(
									[
										A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin-right', 'auto !important')
									]))
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						's:only-of-type.' + mdgriffith$elm_ui$Internal$Style$classes.gK,
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								mdgriffith$elm_ui$Internal$Style$Child,
								mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gI),
								_List_fromArray(
									[
										A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
										A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important')
									]))
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + (mdgriffith$elm_ui$Internal$Style$classes.gK + ' ~ u'),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + (mdgriffith$elm_ui$Internal$Style$classes.gM + (' ~ s.' + mdgriffith$elm_ui$Internal$Style$classes.gK)),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2(mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
											]),
										_List_fromArray(
											[
												A2(mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
											]));
								case 1:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2(mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
											]),
										_List_fromArray(
											[
												A2(mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-end')
											]));
								case 2:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2(mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
											]),
										_List_Nil);
								case 3:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2(mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
											]),
										_List_Nil);
								case 4:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2(mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'center')
											]),
										_List_Nil);
								default:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2(mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
											]),
										_List_fromArray(
											[
												A2(mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'center')
											]));
							}
						}),
						A2(
						mdgriffith$elm_ui$Internal$Style$Descriptor,
						mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.i_),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'space-between')
							]))
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.aH),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'column'),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.dG),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '100000')
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.eP),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gA),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.eO),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + mdgriffith$elm_ui$Internal$Style$classes.gJ,
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1')
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						's:first-of-type.' + mdgriffith$elm_ui$Internal$Style$classes.gL,
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								mdgriffith$elm_ui$Internal$Style$Child,
								mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gI),
								_List_fromArray(
									[
										A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
										A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', '0 !important')
									]))
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + mdgriffith$elm_ui$Internal$Style$classes.gL,
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								mdgriffith$elm_ui$Internal$Style$Child,
								mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gI),
								_List_fromArray(
									[
										A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important'),
										A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', '0 !important')
									]))
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						's:only-of-type.' + mdgriffith$elm_ui$Internal$Style$classes.gL,
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								mdgriffith$elm_ui$Internal$Style$Child,
								mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gI),
								_List_fromArray(
									[
										A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
										A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important')
									]))
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + (mdgriffith$elm_ui$Internal$Style$classes.gL + ' ~ u'),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + (mdgriffith$elm_ui$Internal$Style$classes.gJ + (' ~ s.' + mdgriffith$elm_ui$Internal$Style$classes.gL)),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2(mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
											]),
										_List_fromArray(
											[
												A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto')
											]));
								case 1:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2(mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
											]),
										_List_fromArray(
											[
												A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto')
											]));
								case 2:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2(mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
											]),
										_List_fromArray(
											[
												A2(mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-end')
											]));
								case 3:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2(mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
											]),
										_List_fromArray(
											[
												A2(mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
											]));
								case 4:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2(mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
											]),
										_List_fromArray(
											[
												A2(mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'center')
											]));
								default:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2(mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'center')
											]),
										_List_Nil);
							}
						}),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.cE),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0'),
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch !important')
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Descriptor,
						mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.i_),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'space-between')
							]))
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.hF),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'display', '-ms-grid'),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						'.gp',
						_List_fromArray(
							[
								A2(
								mdgriffith$elm_ui$Internal$Style$Child,
								mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gQ),
								_List_fromArray(
									[
										A2(mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
									]))
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Supports,
						_Utils_Tuple2('display', 'grid'),
						_List_fromArray(
							[
								_Utils_Tuple2('display', 'grid')
							])),
						mdgriffith$elm_ui$Internal$Style$gridAlignments(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _List_fromArray(
										[
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
										]);
								case 1:
									return _List_fromArray(
										[
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
										]);
								case 2:
									return _List_fromArray(
										[
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
										]);
								case 3:
									return _List_fromArray(
										[
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
										]);
								case 4:
									return _List_fromArray(
										[
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
										]);
								default:
									return _List_fromArray(
										[
											A2(mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'center')
										]);
							}
						})
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.iw),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'block'),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gQ + ':first-child'),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important')
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						mdgriffith$elm_ui$Internal$Style$dot(
							mdgriffith$elm_ui$Internal$Style$classes.gQ + (mdgriffith$elm_ui$Internal$Style$selfName(3) + (':first-child + .' + mdgriffith$elm_ui$Internal$Style$classes.gQ))),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important')
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						mdgriffith$elm_ui$Internal$Style$dot(
							mdgriffith$elm_ui$Internal$Style$classes.gQ + (mdgriffith$elm_ui$Internal$Style$selfName(2) + (':first-child + .' + mdgriffith$elm_ui$Internal$Style$classes.gQ))),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important')
							])),
						mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 1:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 2:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2(mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'right'),
												A2(
												mdgriffith$elm_ui$Internal$Style$Descriptor,
												'::after',
												_List_fromArray(
													[
														A2(mdgriffith$elm_ui$Internal$Style$Prop, 'content', '\"\"'),
														A2(mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'table'),
														A2(mdgriffith$elm_ui$Internal$Style$Prop, 'clear', 'both')
													]))
											]));
								case 3:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2(mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'left'),
												A2(
												mdgriffith$elm_ui$Internal$Style$Descriptor,
												'::after',
												_List_fromArray(
													[
														A2(mdgriffith$elm_ui$Internal$Style$Prop, 'content', '\"\"'),
														A2(mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'table'),
														A2(mdgriffith$elm_ui$Internal$Style$Prop, 'clear', 'both')
													]))
											]));
								case 4:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								default:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
							}
						})
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.hV),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre-wrap')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.fT),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'block'),
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal'),
						A2(
						mdgriffith$elm_ui$Internal$Style$Descriptor,
						mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.dF),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
								A2(
								mdgriffith$elm_ui$Internal$Style$Child,
								mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gV),
								_List_fromArray(
									[
										A2(mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '-1')
									]))
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.dj),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal')
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.iW),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal'),
								A2(
								mdgriffith$elm_ui$Internal$Style$Descriptor,
								mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.hO),
								_List_fromArray(
									[
										A2(mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								mdgriffith$elm_ui$Internal$Style$Descriptor,
								mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gV),
								_List_fromArray(
									[
										A2(mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								mdgriffith$elm_ui$Internal$Style$Descriptor,
								mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gE),
								_List_fromArray(
									[
										A2(mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								mdgriffith$elm_ui$Internal$Style$Descriptor,
								mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gW),
								_List_fromArray(
									[
										A2(mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								mdgriffith$elm_ui$Internal$Style$Descriptor,
								mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.$9),
								_List_fromArray(
									[
										A2(mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								mdgriffith$elm_ui$Internal$Style$Descriptor,
								mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.ik),
								_List_fromArray(
									[
										A2(mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								mdgriffith$elm_ui$Internal$Style$Child,
								mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.dj),
								_List_fromArray(
									[
										A2(mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
										A2(mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal')
									]))
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.f6),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-flex')
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.aH),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-flex')
							])),
						A2(
						mdgriffith$elm_ui$Internal$Style$Child,
						mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.hF),
						_List_fromArray(
							[
								A2(mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-grid')
							])),
						mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 1:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 2:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2(mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'right')
											]));
								case 3:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2(mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'left')
											]));
								case 4:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								default:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
							}
						})
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				'.hidden',
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'none')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.jl),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '100')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.jc),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '200')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.jg),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '300')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.ji),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '400')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.jh),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '500')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.jk),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '600')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gZ),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '700')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.jb),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '800')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.jd),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '900')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.hY),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'font-style', 'italic')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.i4),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'line-through')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.ju),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'underline'),
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip-ink', 'auto'),
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip', 'ink')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				_Utils_ap(
					mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.ju),
					mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.i4)),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'line-through underline'),
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip-ink', 'auto'),
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip', 'ink')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.jm),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'font-style', 'normal')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.je),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'justify')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.dk),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'justify-all')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.ja),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'center')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.jj),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'right')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.jf),
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'left')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Descriptor,
				'.modal',
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'fixed'),
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none')
					]))
			]))
	]);
var mdgriffith$elm_ui$Internal$Style$fontVariant = function (_var) {
	return _List_fromArray(
		[
			A2(
			mdgriffith$elm_ui$Internal$Style$Class,
			'.v-' + _var,
			_List_fromArray(
				[
					A2(mdgriffith$elm_ui$Internal$Style$Prop, 'font-feature-settings', '\"' + (_var + '\"'))
				])),
			A2(
			mdgriffith$elm_ui$Internal$Style$Class,
			'.v-' + (_var + '-off'),
			_List_fromArray(
				[
					A2(mdgriffith$elm_ui$Internal$Style$Prop, 'font-feature-settings', '\"' + (_var + '\" 0'))
				]))
		]);
};
var mdgriffith$elm_ui$Internal$Style$commonValues = elm$core$List$concat(
	_List_fromArray(
		[
			A2(
			elm$core$List$map,
			function (x) {
				return A2(
					mdgriffith$elm_ui$Internal$Style$Class,
					'.border-' + elm$core$String$fromInt(x),
					_List_fromArray(
						[
							A2(
							mdgriffith$elm_ui$Internal$Style$Prop,
							'border-width',
							elm$core$String$fromInt(x) + 'px')
						]));
			},
			A2(elm$core$List$range, 0, 6)),
			A2(
			elm$core$List$map,
			function (i) {
				return A2(
					mdgriffith$elm_ui$Internal$Style$Class,
					'.font-size-' + elm$core$String$fromInt(i),
					_List_fromArray(
						[
							A2(
							mdgriffith$elm_ui$Internal$Style$Prop,
							'font-size',
							elm$core$String$fromInt(i) + 'px')
						]));
			},
			A2(elm$core$List$range, 8, 32)),
			A2(
			elm$core$List$map,
			function (i) {
				return A2(
					mdgriffith$elm_ui$Internal$Style$Class,
					'.p-' + elm$core$String$fromInt(i),
					_List_fromArray(
						[
							A2(
							mdgriffith$elm_ui$Internal$Style$Prop,
							'padding',
							elm$core$String$fromInt(i) + 'px')
						]));
			},
			A2(elm$core$List$range, 0, 24)),
			_List_fromArray(
			[
				A2(
				mdgriffith$elm_ui$Internal$Style$Class,
				'.v-smcp',
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'font-variant', 'small-caps')
					])),
				A2(
				mdgriffith$elm_ui$Internal$Style$Class,
				'.v-smcp-off',
				_List_fromArray(
					[
						A2(mdgriffith$elm_ui$Internal$Style$Prop, 'font-variant', 'normal')
					]))
			]),
			mdgriffith$elm_ui$Internal$Style$fontVariant('zero'),
			mdgriffith$elm_ui$Internal$Style$fontVariant('onum'),
			mdgriffith$elm_ui$Internal$Style$fontVariant('liga'),
			mdgriffith$elm_ui$Internal$Style$fontVariant('dlig'),
			mdgriffith$elm_ui$Internal$Style$fontVariant('ordn'),
			mdgriffith$elm_ui$Internal$Style$fontVariant('tnum'),
			mdgriffith$elm_ui$Internal$Style$fontVariant('afrc'),
			mdgriffith$elm_ui$Internal$Style$fontVariant('frac')
		]));
var mdgriffith$elm_ui$Internal$Style$explainer = '\n.explain {\n    border: 6px solid rgb(174, 121, 15) !important;\n}\n.explain > .' + (mdgriffith$elm_ui$Internal$Style$classes.gQ + (' {\n    border: 4px dashed rgb(0, 151, 167) !important;\n}\n\n.ctr {\n    border: none !important;\n}\n.explain > .ctr > .' + (mdgriffith$elm_ui$Internal$Style$classes.gQ + ' {\n    border: 4px dashed rgb(0, 151, 167) !important;\n}\n\n')));
var mdgriffith$elm_ui$Internal$Style$sliderOverrides = '\n\n/* General Input Reset */\ninput[type=range] {\n  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */\n  /* width: 100%;  Specific width is required for Firefox. */\n  background: transparent; /* Otherwise white in Chrome */\n  position:absolute;\n  left:0;\n  top:0;\n  z-index:10;\n  width: 100%;\n  outline: dashed 1px;\n  height: 100%;\n  opacity: 0;\n}\n\n/* Hide all syling for track */\ninput[type=range]::-moz-range-track {\n    background: transparent;\n    cursor: pointer;\n}\ninput[type=range]::-ms-track {\n    background: transparent;\n    cursor: pointer;\n}\ninput[type=range]::-webkit-slider-runnable-track {\n    background: transparent;\n    cursor: pointer;\n}\n\n/* Thumbs */\ninput[type=range]::-webkit-slider-thumb {\n    -webkit-appearance: none;\n    opacity: 0.5;\n    width: 80px;\n    height: 80px;\n    background-color: black;\n    border:none;\n    border-radius: 5px;\n}\ninput[type=range]::-moz-range-thumb {\n    opacity: 0.5;\n    width: 80px;\n    height: 80px;\n    background-color: black;\n    border:none;\n    border-radius: 5px;\n}\ninput[type=range]::-ms-thumb {\n    opacity: 0.5;\n    width: 80px;\n    height: 80px;\n    background-color: black;\n    border:none;\n    border-radius: 5px;\n}\ninput[type=range][orient=vertical]{\n    writing-mode: bt-lr; /* IE */\n    -webkit-appearance: slider-vertical;  /* WebKit */\n}\n';
var mdgriffith$elm_ui$Internal$Style$overrides = '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {' + (mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gQ) + (mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.f6) + (' > ' + (mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gQ) + (' { flex-basis: auto !important; } ' + (mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gQ) + (mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.f6) + (' > ' + (mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.gQ) + (mdgriffith$elm_ui$Internal$Style$dot(mdgriffith$elm_ui$Internal$Style$classes.cE) + (' { flex-basis: auto !important; }}' + (mdgriffith$elm_ui$Internal$Style$sliderOverrides + mdgriffith$elm_ui$Internal$Style$explainer))))))))))));
var elm$core$String$concat = function (strings) {
	return A2(elm$core$String$join, '', strings);
};
var mdgriffith$elm_ui$Internal$Style$Intermediate = elm$core$Basics$identity;
var mdgriffith$elm_ui$Internal$Style$emptyIntermediate = F2(
	function (selector, closing) {
		return {cD: closing, E: _List_Nil, aV: _List_Nil, aw: selector};
	});
var mdgriffith$elm_ui$Internal$Style$renderRules = F2(
	function (_n0, rulesToRender) {
		var parent = _n0;
		var generateIntermediates = F2(
			function (rule, rendered) {
				switch (rule.$) {
					case 0:
						var name = rule.a;
						var val = rule.b;
						return _Utils_update(
							rendered,
							{
								aV: A2(
									elm$core$List$cons,
									_Utils_Tuple2(name, val),
									rendered.aV)
							});
					case 2:
						var _n2 = rule.a;
						var prop = _n2.a;
						var value = _n2.b;
						var props = rule.b;
						return _Utils_update(
							rendered,
							{
								E: A2(
									elm$core$List$cons,
									{cD: '\n}', E: _List_Nil, aV: props, aw: '@supports (' + (prop + (':' + (value + (') {' + parent.aw))))},
									rendered.E)
							});
					case 4:
						var selector = rule.a;
						var adjRules = rule.b;
						return _Utils_update(
							rendered,
							{
								E: A2(
									elm$core$List$cons,
									A2(
										mdgriffith$elm_ui$Internal$Style$renderRules,
										A2(mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.aw + (' + ' + selector), ''),
										adjRules),
									rendered.E)
							});
					case 1:
						var child = rule.a;
						var childRules = rule.b;
						return _Utils_update(
							rendered,
							{
								E: A2(
									elm$core$List$cons,
									A2(
										mdgriffith$elm_ui$Internal$Style$renderRules,
										A2(mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.aw + (' > ' + child), ''),
										childRules),
									rendered.E)
							});
					case 3:
						var descriptor = rule.a;
						var descriptorRules = rule.b;
						return _Utils_update(
							rendered,
							{
								E: A2(
									elm$core$List$cons,
									A2(
										mdgriffith$elm_ui$Internal$Style$renderRules,
										A2(
											mdgriffith$elm_ui$Internal$Style$emptyIntermediate,
											_Utils_ap(parent.aw, descriptor),
											''),
										descriptorRules),
									rendered.E)
							});
					default:
						var batched = rule.a;
						return _Utils_update(
							rendered,
							{
								E: A2(
									elm$core$List$cons,
									A2(
										mdgriffith$elm_ui$Internal$Style$renderRules,
										A2(mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.aw, ''),
										batched),
									rendered.E)
							});
				}
			});
		return A3(elm$core$List$foldr, generateIntermediates, parent, rulesToRender);
	});
var mdgriffith$elm_ui$Internal$Style$renderCompact = function (styleClasses) {
	var renderValues = function (values) {
		return elm$core$String$concat(
			A2(
				elm$core$List$map,
				function (_n3) {
					var x = _n3.a;
					var y = _n3.b;
					return x + (':' + (y + ';'));
				},
				values));
	};
	var renderClass = function (rule) {
		var _n2 = rule.aV;
		if (!_n2.b) {
			return '';
		} else {
			return rule.aw + ('{' + (renderValues(rule.aV) + (rule.cD + '}')));
		}
	};
	var renderIntermediate = function (_n0) {
		var rule = _n0;
		return _Utils_ap(
			renderClass(rule),
			elm$core$String$concat(
				A2(elm$core$List$map, renderIntermediate, rule.E)));
	};
	return elm$core$String$concat(
		A2(
			elm$core$List$map,
			renderIntermediate,
			A3(
				elm$core$List$foldr,
				F2(
					function (_n1, existing) {
						var name = _n1.a;
						var styleRules = _n1.b;
						return A2(
							elm$core$List$cons,
							A2(
								mdgriffith$elm_ui$Internal$Style$renderRules,
								A2(mdgriffith$elm_ui$Internal$Style$emptyIntermediate, name, ''),
								styleRules),
							existing);
					}),
				_List_Nil,
				styleClasses)));
};
var mdgriffith$elm_ui$Internal$Style$rules = _Utils_ap(
	mdgriffith$elm_ui$Internal$Style$overrides,
	mdgriffith$elm_ui$Internal$Style$renderCompact(
		_Utils_ap(mdgriffith$elm_ui$Internal$Style$baseSheet, mdgriffith$elm_ui$Internal$Style$commonValues)));
var mdgriffith$elm_ui$Internal$Model$staticRoot = A3(
	elm$virtual_dom$VirtualDom$node,
	'style',
	_List_Nil,
	_List_fromArray(
		[
			elm$virtual_dom$VirtualDom$text(mdgriffith$elm_ui$Internal$Style$rules)
		]));
var elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var mdgriffith$elm_ui$Internal$Model$fontName = function (font) {
	switch (font.$) {
		case 0:
			return 'serif';
		case 1:
			return 'sans-serif';
		case 2:
			return 'monospace';
		case 3:
			var name = font.a;
			return '\"' + (name + '\"');
		case 4:
			var name = font.a;
			var url = font.b;
			return '\"' + (name + '\"');
		default:
			var name = font.a.bA;
			return '\"' + (name + '\"');
	}
};
var mdgriffith$elm_ui$Internal$Model$isSmallCaps = function (_var) {
	switch (_var.$) {
		case 0:
			var name = _var.a;
			return name === 'smcp';
		case 1:
			var name = _var.a;
			return false;
		default:
			var name = _var.a;
			var index = _var.b;
			return (name === 'smcp') && (index === 1);
	}
};
var mdgriffith$elm_ui$Internal$Model$hasSmallCaps = function (typeface) {
	if (typeface.$ === 5) {
		var font = typeface.a;
		return A2(elm$core$List$any, mdgriffith$elm_ui$Internal$Model$isSmallCaps, font.gt);
	} else {
		return false;
	}
};
var mdgriffith$elm_ui$Internal$Model$renderProps = F3(
	function (force, _n0, existing) {
		var key = _n0.a;
		var val = _n0.b;
		return force ? (existing + ('\n  ' + (key + (': ' + (val + ' !important;'))))) : (existing + ('\n  ' + (key + (': ' + (val + ';')))));
	});
var mdgriffith$elm_ui$Internal$Model$bracket = F2(
	function (selector, rules) {
		var renderPair = function (_n0) {
			var name = _n0.a;
			var val = _n0.b;
			return name + (': ' + (val + ';'));
		};
		return selector + (' {' + (A2(
			elm$core$String$join,
			'',
			A2(elm$core$List$map, renderPair, rules)) + '}'));
	});
var mdgriffith$elm_ui$Internal$Model$fontRule = F3(
	function (name, modifier, _n0) {
		var parentAdj = _n0.a;
		var textAdjustment = _n0.b;
		return _List_fromArray(
			[
				A2(mdgriffith$elm_ui$Internal$Model$bracket, '.' + (name + ('.' + (modifier + (', ' + ('.' + (name + (' .' + modifier))))))), parentAdj),
				A2(mdgriffith$elm_ui$Internal$Model$bracket, '.' + (name + ('.' + (modifier + ('> .' + (mdgriffith$elm_ui$Internal$Style$classes.dj + (', .' + (name + (' .' + (modifier + (' > .' + mdgriffith$elm_ui$Internal$Style$classes.dj)))))))))), textAdjustment)
			]);
	});
var mdgriffith$elm_ui$Internal$Model$renderFontAdjustmentRule = F3(
	function (fontToAdjust, _n0, otherFontName) {
		var full = _n0.a;
		var capital = _n0.b;
		var name = _Utils_eq(fontToAdjust, otherFontName) ? fontToAdjust : (otherFontName + (' .' + fontToAdjust));
		return A2(
			elm$core$String$join,
			' ',
			_Utils_ap(
				A3(mdgriffith$elm_ui$Internal$Model$fontRule, name, mdgriffith$elm_ui$Internal$Style$classes.iX, capital),
				A3(mdgriffith$elm_ui$Internal$Model$fontRule, name, mdgriffith$elm_ui$Internal$Style$classes.hC, full)));
	});
var mdgriffith$elm_ui$Internal$Model$renderNullAdjustmentRule = F2(
	function (fontToAdjust, otherFontName) {
		var name = _Utils_eq(fontToAdjust, otherFontName) ? fontToAdjust : (otherFontName + (' .' + fontToAdjust));
		return A2(
			elm$core$String$join,
			' ',
			_List_fromArray(
				[
					A2(
					mdgriffith$elm_ui$Internal$Model$bracket,
					'.' + (name + ('.' + (mdgriffith$elm_ui$Internal$Style$classes.iX + (', ' + ('.' + (name + (' .' + mdgriffith$elm_ui$Internal$Style$classes.iX))))))),
					_List_fromArray(
						[
							_Utils_Tuple2('line-height', '1')
						])),
					A2(
					mdgriffith$elm_ui$Internal$Model$bracket,
					'.' + (name + ('.' + (mdgriffith$elm_ui$Internal$Style$classes.iX + ('> .' + (mdgriffith$elm_ui$Internal$Style$classes.dj + (', .' + (name + (' .' + (mdgriffith$elm_ui$Internal$Style$classes.iX + (' > .' + mdgriffith$elm_ui$Internal$Style$classes.dj)))))))))),
					_List_fromArray(
						[
							_Utils_Tuple2('vertical-align', '0'),
							_Utils_Tuple2('line-height', '1')
						]))
				]));
	});
var elm$core$List$maximum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return elm$core$Maybe$Just(
			A3(elm$core$List$foldl, elm$core$Basics$max, x, xs));
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var elm$core$List$minimum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return elm$core$Maybe$Just(
			A3(elm$core$List$foldl, elm$core$Basics$min, x, xs));
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var mdgriffith$elm_ui$Internal$Model$adjust = F3(
	function (size, height, vertical) {
		return {fp: height / size, ew: size, gu: vertical};
	});
var mdgriffith$elm_ui$Internal$Model$convertAdjustment = function (adjustment) {
	var lines = _List_fromArray(
		[adjustment.g3, adjustment.gU, adjustment.hl, adjustment.hZ]);
	var lineHeight = 1.5;
	var normalDescender = (lineHeight - 1) / 2;
	var oldMiddle = lineHeight / 2;
	var descender = A2(
		elm$core$Maybe$withDefault,
		adjustment.hl,
		elm$core$List$minimum(lines));
	var newBaseline = A2(
		elm$core$Maybe$withDefault,
		adjustment.gU,
		elm$core$List$minimum(
			A2(
				elm$core$List$filter,
				function (x) {
					return !_Utils_eq(x, descender);
				},
				lines)));
	var base = lineHeight;
	var ascender = A2(
		elm$core$Maybe$withDefault,
		adjustment.g3,
		elm$core$List$maximum(lines));
	var capitalSize = 1 / (ascender - newBaseline);
	var capitalVertical = 1 - ascender;
	var fullSize = 1 / (ascender - descender);
	var fullVertical = 1 - ascender;
	var newCapitalMiddle = ((ascender - newBaseline) / 2) + newBaseline;
	var newFullMiddle = ((ascender - descender) / 2) + descender;
	return {
		g3: A3(mdgriffith$elm_ui$Internal$Model$adjust, capitalSize, ascender - newBaseline, capitalVertical),
		fm: A3(mdgriffith$elm_ui$Internal$Model$adjust, fullSize, ascender - descender, fullVertical)
	};
};
var mdgriffith$elm_ui$Internal$Model$fontAdjustmentRules = function (converted) {
	return _Utils_Tuple2(
		_List_fromArray(
			[
				_Utils_Tuple2('display', 'block')
			]),
		_List_fromArray(
			[
				_Utils_Tuple2('display', 'inline-block'),
				_Utils_Tuple2(
				'line-height',
				elm$core$String$fromFloat(converted.fp)),
				_Utils_Tuple2(
				'vertical-align',
				elm$core$String$fromFloat(converted.gu) + 'em'),
				_Utils_Tuple2(
				'font-size',
				elm$core$String$fromFloat(converted.ew) + 'em')
			]));
};
var mdgriffith$elm_ui$Internal$Model$typefaceAdjustment = function (typefaces) {
	return A3(
		elm$core$List$foldl,
		F2(
			function (face, found) {
				if (found.$ === 1) {
					if (face.$ === 5) {
						var _with = face.a;
						var _n2 = _with.gF;
						if (_n2.$ === 1) {
							return found;
						} else {
							var adjustment = _n2.a;
							return elm$core$Maybe$Just(
								_Utils_Tuple2(
									mdgriffith$elm_ui$Internal$Model$fontAdjustmentRules(
										function ($) {
											return $.fm;
										}(
											mdgriffith$elm_ui$Internal$Model$convertAdjustment(adjustment))),
									mdgriffith$elm_ui$Internal$Model$fontAdjustmentRules(
										function ($) {
											return $.g3;
										}(
											mdgriffith$elm_ui$Internal$Model$convertAdjustment(adjustment)))));
						}
					} else {
						return found;
					}
				} else {
					return found;
				}
			}),
		elm$core$Maybe$Nothing,
		typefaces);
};
var mdgriffith$elm_ui$Internal$Model$renderTopLevelValues = function (rules) {
	var withImport = function (font) {
		if (font.$ === 4) {
			var url = font.b;
			return elm$core$Maybe$Just('@import url(\'' + (url + '\');'));
		} else {
			return elm$core$Maybe$Nothing;
		}
	};
	var fontImports = function (_n2) {
		var name = _n2.a;
		var typefaces = _n2.b;
		var imports = A2(
			elm$core$String$join,
			'\n',
			A2(elm$core$List$filterMap, withImport, typefaces));
		return imports;
	};
	var allNames = A2(elm$core$List$map, elm$core$Tuple$first, rules);
	var fontAdjustments = function (_n1) {
		var name = _n1.a;
		var typefaces = _n1.b;
		var _n0 = mdgriffith$elm_ui$Internal$Model$typefaceAdjustment(typefaces);
		if (_n0.$ === 1) {
			return A2(
				elm$core$String$join,
				'',
				A2(
					elm$core$List$map,
					mdgriffith$elm_ui$Internal$Model$renderNullAdjustmentRule(name),
					allNames));
		} else {
			var adjustment = _n0.a;
			return A2(
				elm$core$String$join,
				'',
				A2(
					elm$core$List$map,
					A2(mdgriffith$elm_ui$Internal$Model$renderFontAdjustmentRule, name, adjustment),
					allNames));
		}
	};
	return _Utils_ap(
		A2(
			elm$core$String$join,
			'\n',
			A2(elm$core$List$map, fontImports, rules)),
		A2(
			elm$core$String$join,
			'\n',
			A2(elm$core$List$map, fontAdjustments, rules)));
};
var mdgriffith$elm_ui$Internal$Model$renderVariant = function (_var) {
	switch (_var.$) {
		case 0:
			var name = _var.a;
			return '\"' + (name + '\"');
		case 1:
			var name = _var.a;
			return '\"' + (name + '\" 0');
		default:
			var name = _var.a;
			var index = _var.b;
			return '\"' + (name + ('\" ' + elm$core$String$fromInt(index)));
	}
};
var mdgriffith$elm_ui$Internal$Model$renderVariants = function (typeface) {
	if (typeface.$ === 5) {
		var font = typeface.a;
		return elm$core$Maybe$Just(
			A2(
				elm$core$String$join,
				', ',
				A2(elm$core$List$map, mdgriffith$elm_ui$Internal$Model$renderVariant, font.gt)));
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var mdgriffith$elm_ui$Internal$Model$topLevelValue = function (rule) {
	if (rule.$ === 1) {
		var name = rule.a;
		var typefaces = rule.b;
		return elm$core$Maybe$Just(
			_Utils_Tuple2(name, typefaces));
	} else {
		return elm$core$Maybe$Nothing;
	}
};
var mdgriffith$elm_ui$Internal$Model$transformValue = function (transform) {
	switch (transform.$) {
		case 0:
			return elm$core$Maybe$Nothing;
		case 1:
			var _n1 = transform.a;
			var x = _n1.a;
			var y = _n1.b;
			var z = _n1.c;
			return elm$core$Maybe$Just(
				'translate3d(' + (elm$core$String$fromFloat(x) + ('px, ' + (elm$core$String$fromFloat(y) + ('px, ' + (elm$core$String$fromFloat(z) + 'px)'))))));
		default:
			var _n2 = transform.a;
			var tx = _n2.a;
			var ty = _n2.b;
			var tz = _n2.c;
			var _n3 = transform.b;
			var sx = _n3.a;
			var sy = _n3.b;
			var sz = _n3.c;
			var _n4 = transform.c;
			var ox = _n4.a;
			var oy = _n4.b;
			var oz = _n4.c;
			var angle = transform.d;
			var translate = 'translate3d(' + (elm$core$String$fromFloat(tx) + ('px, ' + (elm$core$String$fromFloat(ty) + ('px, ' + (elm$core$String$fromFloat(tz) + 'px)')))));
			var scale = 'scale3d(' + (elm$core$String$fromFloat(sx) + (', ' + (elm$core$String$fromFloat(sy) + (', ' + (elm$core$String$fromFloat(sz) + ')')))));
			var rotate = 'rotate3d(' + (elm$core$String$fromFloat(ox) + (', ' + (elm$core$String$fromFloat(oy) + (', ' + (elm$core$String$fromFloat(oz) + (', ' + (elm$core$String$fromFloat(angle) + 'rad)')))))));
			return elm$core$Maybe$Just(translate + (' ' + (scale + (' ' + rotate))));
	}
};
var mdgriffith$elm_ui$Internal$Model$toStyleSheetString = F2(
	function (options, stylesheet) {
		var renderStyle = F3(
			function (maybePseudo, selector, props) {
				if (maybePseudo.$ === 1) {
					return selector + ('{' + (A3(
						elm$core$List$foldl,
						mdgriffith$elm_ui$Internal$Model$renderProps(false),
						'',
						props) + '\n}'));
				} else {
					var pseudo = maybePseudo.a;
					switch (pseudo) {
						case 1:
							var _n17 = options.hJ;
							switch (_n17) {
								case 0:
									return '';
								case 2:
									return selector + ('-hv {' + (A3(
										elm$core$List$foldl,
										mdgriffith$elm_ui$Internal$Model$renderProps(true),
										'',
										props) + '\n}'));
								default:
									return selector + ('-hv:hover {' + (A3(
										elm$core$List$foldl,
										mdgriffith$elm_ui$Internal$Model$renderProps(false),
										'',
										props) + '\n}'));
							}
						case 0:
							var renderedProps = A3(
								elm$core$List$foldl,
								mdgriffith$elm_ui$Internal$Model$renderProps(false),
								'',
								props);
							return A2(
								elm$core$String$join,
								'\n',
								_List_fromArray(
									[selector + ('-fs:focus {' + (renderedProps + '\n}')), '.' + (mdgriffith$elm_ui$Internal$Style$classes.gQ + (':focus ~ ' + (selector + ('-fs:not(.focus)  {' + (renderedProps + '\n}'))))), '.' + (mdgriffith$elm_ui$Internal$Style$classes.gQ + (':focus ' + (selector + ('-fs  {' + (renderedProps + '\n}'))))), '.focusable-parent:focus ~ ' + ('.' + (mdgriffith$elm_ui$Internal$Style$classes.gQ + (' ' + (selector + ('-fs {' + (renderedProps + '\n}'))))))]));
						default:
							return selector + ('-act:active {' + (A3(
								elm$core$List$foldl,
								mdgriffith$elm_ui$Internal$Model$renderProps(false),
								'',
								props) + '\n}'));
					}
				}
			});
		var renderStyleRule = F2(
			function (rule, maybePseudo) {
				switch (rule.$) {
					case 0:
						var selector = rule.a;
						var props = rule.b;
						return A3(renderStyle, maybePseudo, selector, props);
					case 13:
						var name = rule.a;
						var prop = rule.b;
						return A3(
							renderStyle,
							maybePseudo,
							'.' + name,
							_List_fromArray(
								[
									A2(mdgriffith$elm_ui$Internal$Model$Property, 'box-shadow', prop)
								]));
					case 12:
						var name = rule.a;
						var transparency = rule.b;
						var opacity = A2(
							elm$core$Basics$max,
							0,
							A2(elm$core$Basics$min, 1, 1 - transparency));
						return A3(
							renderStyle,
							maybePseudo,
							'.' + name,
							_List_fromArray(
								[
									A2(
									mdgriffith$elm_ui$Internal$Model$Property,
									'opacity',
									elm$core$String$fromFloat(opacity))
								]));
					case 2:
						var i = rule.a;
						return A3(
							renderStyle,
							maybePseudo,
							'.font-size-' + elm$core$String$fromInt(i),
							_List_fromArray(
								[
									A2(
									mdgriffith$elm_ui$Internal$Model$Property,
									'font-size',
									elm$core$String$fromInt(i) + 'px')
								]));
					case 1:
						var name = rule.a;
						var typefaces = rule.b;
						var features = A2(
							elm$core$String$join,
							', ',
							A2(elm$core$List$filterMap, mdgriffith$elm_ui$Internal$Model$renderVariants, typefaces));
						var families = _List_fromArray(
							[
								A2(
								mdgriffith$elm_ui$Internal$Model$Property,
								'font-family',
								A2(
									elm$core$String$join,
									', ',
									A2(elm$core$List$map, mdgriffith$elm_ui$Internal$Model$fontName, typefaces))),
								A2(mdgriffith$elm_ui$Internal$Model$Property, 'font-feature-settings', features),
								A2(
								mdgriffith$elm_ui$Internal$Model$Property,
								'font-variant',
								A2(elm$core$List$any, mdgriffith$elm_ui$Internal$Model$hasSmallCaps, typefaces) ? 'small-caps' : 'normal')
							]);
						return A2(
							elm$core$String$join,
							' ',
							_List_fromArray(
								[
									A3(renderStyle, maybePseudo, '.' + name, families)
								]));
					case 3:
						var _class = rule.a;
						var prop = rule.b;
						var val = rule.c;
						return A3(
							renderStyle,
							maybePseudo,
							'.' + _class,
							_List_fromArray(
								[
									A2(mdgriffith$elm_ui$Internal$Model$Property, prop, val)
								]));
					case 4:
						var _class = rule.a;
						var prop = rule.b;
						var color = rule.c;
						return A3(
							renderStyle,
							maybePseudo,
							'.' + _class,
							_List_fromArray(
								[
									A2(
									mdgriffith$elm_ui$Internal$Model$Property,
									prop,
									mdgriffith$elm_ui$Internal$Model$formatColor(color))
								]));
					case 5:
						var cls = rule.a;
						var x = rule.b;
						var y = rule.c;
						var yPx = elm$core$String$fromInt(y) + 'px';
						var xPx = elm$core$String$fromInt(x) + 'px';
						var single = '.' + mdgriffith$elm_ui$Internal$Style$classes.iW;
						var row = '.' + mdgriffith$elm_ui$Internal$Style$classes.f6;
						var wrappedRow = '.' + (mdgriffith$elm_ui$Internal$Style$classes.eQ + row);
						var right = '.' + mdgriffith$elm_ui$Internal$Style$classes.eY;
						var paragraph = '.' + mdgriffith$elm_ui$Internal$Style$classes.fT;
						var page = '.' + mdgriffith$elm_ui$Internal$Style$classes.iw;
						var left = '.' + mdgriffith$elm_ui$Internal$Style$classes.eX;
						var halfY = elm$core$String$fromFloat(y / 2) + 'px';
						var halfX = elm$core$String$fromFloat(x / 2) + 'px';
						var column = '.' + mdgriffith$elm_ui$Internal$Style$classes.aH;
						var _class = '.' + cls;
						var any = '.' + mdgriffith$elm_ui$Internal$Style$classes.gQ;
						return elm$core$String$concat(
							_List_fromArray(
								[
									A3(
									renderStyle,
									maybePseudo,
									_class + (row + (' > ' + (any + (' + ' + any)))),
									_List_fromArray(
										[
											A2(mdgriffith$elm_ui$Internal$Model$Property, 'margin-left', xPx)
										])),
									A3(
									renderStyle,
									maybePseudo,
									_class + (wrappedRow + (' > ' + any)),
									_List_fromArray(
										[
											A2(mdgriffith$elm_ui$Internal$Model$Property, 'margin', halfY + (' ' + halfX))
										])),
									A3(
									renderStyle,
									maybePseudo,
									_class + (column + (' > ' + (any + (' + ' + any)))),
									_List_fromArray(
										[
											A2(mdgriffith$elm_ui$Internal$Model$Property, 'margin-top', yPx)
										])),
									A3(
									renderStyle,
									maybePseudo,
									_class + (page + (' > ' + (any + (' + ' + any)))),
									_List_fromArray(
										[
											A2(mdgriffith$elm_ui$Internal$Model$Property, 'margin-top', yPx)
										])),
									A3(
									renderStyle,
									maybePseudo,
									_class + (page + (' > ' + left)),
									_List_fromArray(
										[
											A2(mdgriffith$elm_ui$Internal$Model$Property, 'margin-right', xPx)
										])),
									A3(
									renderStyle,
									maybePseudo,
									_class + (page + (' > ' + right)),
									_List_fromArray(
										[
											A2(mdgriffith$elm_ui$Internal$Model$Property, 'margin-left', xPx)
										])),
									A3(
									renderStyle,
									maybePseudo,
									_Utils_ap(_class, paragraph),
									_List_fromArray(
										[
											A2(
											mdgriffith$elm_ui$Internal$Model$Property,
											'line-height',
											'calc(1em + ' + (elm$core$String$fromInt(y) + 'px)'))
										])),
									A3(
									renderStyle,
									maybePseudo,
									'textarea' + _class,
									_List_fromArray(
										[
											A2(
											mdgriffith$elm_ui$Internal$Model$Property,
											'line-height',
											'calc(1em + ' + (elm$core$String$fromInt(y) + 'px)'))
										])),
									A3(
									renderStyle,
									maybePseudo,
									_class + (paragraph + (' > ' + left)),
									_List_fromArray(
										[
											A2(mdgriffith$elm_ui$Internal$Model$Property, 'margin-right', xPx)
										])),
									A3(
									renderStyle,
									maybePseudo,
									_class + (paragraph + (' > ' + right)),
									_List_fromArray(
										[
											A2(mdgriffith$elm_ui$Internal$Model$Property, 'margin-left', xPx)
										])),
									A3(
									renderStyle,
									maybePseudo,
									_class + (paragraph + '::after'),
									_List_fromArray(
										[
											A2(mdgriffith$elm_ui$Internal$Model$Property, 'content', '\'\''),
											A2(mdgriffith$elm_ui$Internal$Model$Property, 'display', 'block'),
											A2(mdgriffith$elm_ui$Internal$Model$Property, 'height', '0'),
											A2(mdgriffith$elm_ui$Internal$Model$Property, 'width', '0'),
											A2(
											mdgriffith$elm_ui$Internal$Model$Property,
											'margin-top',
											elm$core$String$fromInt((-1) * ((y / 2) | 0)) + 'px')
										])),
									A3(
									renderStyle,
									maybePseudo,
									_class + (paragraph + '::before'),
									_List_fromArray(
										[
											A2(mdgriffith$elm_ui$Internal$Model$Property, 'content', '\'\''),
											A2(mdgriffith$elm_ui$Internal$Model$Property, 'display', 'block'),
											A2(mdgriffith$elm_ui$Internal$Model$Property, 'height', '0'),
											A2(mdgriffith$elm_ui$Internal$Model$Property, 'width', '0'),
											A2(
											mdgriffith$elm_ui$Internal$Model$Property,
											'margin-bottom',
											elm$core$String$fromInt((-1) * ((y / 2) | 0)) + 'px')
										]))
								]));
					case 7:
						var cls = rule.a;
						var top = rule.b;
						var right = rule.c;
						var bottom = rule.d;
						var left = rule.e;
						var _class = '.' + cls;
						return A3(
							renderStyle,
							maybePseudo,
							_class,
							_List_fromArray(
								[
									A2(
									mdgriffith$elm_ui$Internal$Model$Property,
									'padding',
									elm$core$String$fromInt(top) + ('px ' + (elm$core$String$fromInt(right) + ('px ' + (elm$core$String$fromInt(bottom) + ('px ' + (elm$core$String$fromInt(left) + 'px')))))))
								]));
					case 6:
						var cls = rule.a;
						var top = rule.b;
						var right = rule.c;
						var bottom = rule.d;
						var left = rule.e;
						var _class = '.' + cls;
						return A3(
							renderStyle,
							maybePseudo,
							_class,
							_List_fromArray(
								[
									A2(
									mdgriffith$elm_ui$Internal$Model$Property,
									'border-width',
									elm$core$String$fromInt(top) + ('px ' + (elm$core$String$fromInt(right) + ('px ' + (elm$core$String$fromInt(bottom) + ('px ' + (elm$core$String$fromInt(left) + 'px')))))))
								]));
					case 8:
						var template = rule.a;
						var toGridLengthHelper = F3(
							function (minimum, maximum, x) {
								toGridLengthHelper:
								while (true) {
									switch (x.$) {
										case 0:
											var px = x.a;
											return elm$core$String$fromInt(px) + 'px';
										case 1:
											var _n2 = _Utils_Tuple2(minimum, maximum);
											if (_n2.a.$ === 1) {
												if (_n2.b.$ === 1) {
													var _n3 = _n2.a;
													var _n4 = _n2.b;
													return 'max-content';
												} else {
													var _n6 = _n2.a;
													var maxSize = _n2.b.a;
													return 'minmax(max-content, ' + (elm$core$String$fromInt(maxSize) + 'px)');
												}
											} else {
												if (_n2.b.$ === 1) {
													var minSize = _n2.a.a;
													var _n5 = _n2.b;
													return 'minmax(' + (elm$core$String$fromInt(minSize) + ('px, ' + 'max-content)'));
												} else {
													var minSize = _n2.a.a;
													var maxSize = _n2.b.a;
													return 'minmax(' + (elm$core$String$fromInt(minSize) + ('px, ' + (elm$core$String$fromInt(maxSize) + 'px)')));
												}
											}
										case 2:
											var i = x.a;
											var _n7 = _Utils_Tuple2(minimum, maximum);
											if (_n7.a.$ === 1) {
												if (_n7.b.$ === 1) {
													var _n8 = _n7.a;
													var _n9 = _n7.b;
													return elm$core$String$fromInt(i) + 'fr';
												} else {
													var _n11 = _n7.a;
													var maxSize = _n7.b.a;
													return 'minmax(max-content, ' + (elm$core$String$fromInt(maxSize) + 'px)');
												}
											} else {
												if (_n7.b.$ === 1) {
													var minSize = _n7.a.a;
													var _n10 = _n7.b;
													return 'minmax(' + (elm$core$String$fromInt(minSize) + ('px, ' + (elm$core$String$fromInt(i) + ('fr' + 'fr)'))));
												} else {
													var minSize = _n7.a.a;
													var maxSize = _n7.b.a;
													return 'minmax(' + (elm$core$String$fromInt(minSize) + ('px, ' + (elm$core$String$fromInt(maxSize) + 'px)')));
												}
											}
										case 3:
											var m = x.a;
											var len = x.b;
											var $temp$minimum = elm$core$Maybe$Just(m),
												$temp$maximum = maximum,
												$temp$x = len;
											minimum = $temp$minimum;
											maximum = $temp$maximum;
											x = $temp$x;
											continue toGridLengthHelper;
										default:
											var m = x.a;
											var len = x.b;
											var $temp$minimum = minimum,
												$temp$maximum = elm$core$Maybe$Just(m),
												$temp$x = len;
											minimum = $temp$minimum;
											maximum = $temp$maximum;
											x = $temp$x;
											continue toGridLengthHelper;
									}
								}
							});
						var toGridLength = function (x) {
							return A3(toGridLengthHelper, elm$core$Maybe$Nothing, elm$core$Maybe$Nothing, x);
						};
						var xSpacing = toGridLength(template.i$.a);
						var ySpacing = toGridLength(template.i$.b);
						var rows = function (x) {
							return 'grid-template-rows: ' + (x + ';');
						}(
							A2(
								elm$core$String$join,
								' ',
								A2(elm$core$List$map, toGridLength, template.iI)));
						var msRows = function (x) {
							return '-ms-grid-rows: ' + (x + ';');
						}(
							A2(
								elm$core$String$join,
								ySpacing,
								A2(elm$core$List$map, toGridLength, template.ap)));
						var msColumns = function (x) {
							return '-ms-grid-columns: ' + (x + ';');
						}(
							A2(
								elm$core$String$join,
								ySpacing,
								A2(elm$core$List$map, toGridLength, template.ap)));
						var gapY = 'grid-row-gap:' + (toGridLength(template.i$.b) + ';');
						var gapX = 'grid-column-gap:' + (toGridLength(template.i$.a) + ';');
						var columns = function (x) {
							return 'grid-template-columns: ' + (x + ';');
						}(
							A2(
								elm$core$String$join,
								' ',
								A2(elm$core$List$map, toGridLength, template.ap)));
						var _class = '.grid-rows-' + (A2(
							elm$core$String$join,
							'-',
							A2(elm$core$List$map, mdgriffith$elm_ui$Internal$Model$lengthClassName, template.iI)) + ('-cols-' + (A2(
							elm$core$String$join,
							'-',
							A2(elm$core$List$map, mdgriffith$elm_ui$Internal$Model$lengthClassName, template.ap)) + ('-space-x-' + (mdgriffith$elm_ui$Internal$Model$lengthClassName(template.i$.a) + ('-space-y-' + mdgriffith$elm_ui$Internal$Model$lengthClassName(template.i$.b)))))));
						var modernGrid = _class + ('{' + (columns + (rows + (gapX + (gapY + '}')))));
						var supports = '@supports (display:grid) {' + (modernGrid + '}');
						var base = _class + ('{' + (msColumns + (msRows + '}')));
						return _Utils_ap(base, supports);
					case 9:
						var position = rule.a;
						var msPosition = A2(
							elm$core$String$join,
							' ',
							_List_fromArray(
								[
									'-ms-grid-row: ' + (elm$core$String$fromInt(position.f6) + ';'),
									'-ms-grid-row-span: ' + (elm$core$String$fromInt(position.fp) + ';'),
									'-ms-grid-column: ' + (elm$core$String$fromInt(position.e6) + ';'),
									'-ms-grid-column-span: ' + (elm$core$String$fromInt(position.gy) + ';')
								]));
						var modernPosition = A2(
							elm$core$String$join,
							' ',
							_List_fromArray(
								[
									'grid-row: ' + (elm$core$String$fromInt(position.f6) + (' / ' + (elm$core$String$fromInt(position.f6 + position.fp) + ';'))),
									'grid-column: ' + (elm$core$String$fromInt(position.e6) + (' / ' + (elm$core$String$fromInt(position.e6 + position.gy) + ';')))
								]));
						var _class = '.grid-pos-' + (elm$core$String$fromInt(position.f6) + ('-' + (elm$core$String$fromInt(position.e6) + ('-' + (elm$core$String$fromInt(position.gy) + ('-' + elm$core$String$fromInt(position.fp)))))));
						var modernGrid = _class + ('{' + (modernPosition + '}'));
						var supports = '@supports (display:grid) {' + (modernGrid + '}');
						var base = _class + ('{' + (msPosition + '}'));
						return _Utils_ap(base, supports);
					case 11:
						var _class = rule.a;
						var styles = rule.b;
						var renderPseudoRule = function (style) {
							return A2(
								renderStyleRule,
								style,
								elm$core$Maybe$Just(_class));
						};
						return A2(
							elm$core$String$join,
							' ',
							A2(elm$core$List$map, renderPseudoRule, styles));
					default:
						var transform = rule.a;
						var val = mdgriffith$elm_ui$Internal$Model$transformValue(transform);
						var _class = mdgriffith$elm_ui$Internal$Model$transformClass(transform);
						var _n12 = _Utils_Tuple2(_class, val);
						if ((!_n12.a.$) && (!_n12.b.$)) {
							var cls = _n12.a.a;
							var v = _n12.b.a;
							return A3(
								renderStyle,
								maybePseudo,
								'.' + cls,
								_List_fromArray(
									[
										A2(mdgriffith$elm_ui$Internal$Model$Property, 'transform', v)
									]));
						} else {
							return '';
						}
				}
			});
		var combine = F2(
			function (style, rendered) {
				return {
					dd: _Utils_ap(
						rendered.dd,
						A2(renderStyleRule, style, elm$core$Maybe$Nothing)),
					cp: function () {
						var _n14 = mdgriffith$elm_ui$Internal$Model$topLevelValue(style);
						if (_n14.$ === 1) {
							return rendered.cp;
						} else {
							var topLevel = _n14.a;
							return A2(elm$core$List$cons, topLevel, rendered.cp);
						}
					}()
				};
			});
		var _n13 = A3(
			elm$core$List$foldl,
			combine,
			{dd: '', cp: _List_Nil},
			stylesheet);
		var topLevel = _n13.cp;
		var rules = _n13.dd;
		return _Utils_ap(
			mdgriffith$elm_ui$Internal$Model$renderTopLevelValues(topLevel),
			rules);
	});
var mdgriffith$elm_ui$Internal$Model$toStyleSheet = F2(
	function (options, styleSheet) {
		return A3(
			elm$virtual_dom$VirtualDom$node,
			'style',
			_List_Nil,
			_List_fromArray(
				[
					elm$virtual_dom$VirtualDom$text(
					A2(mdgriffith$elm_ui$Internal$Model$toStyleSheetString, options, styleSheet))
				]));
	});
var mdgriffith$elm_ui$Internal$Model$embedKeyed = F4(
	function (_static, opts, styles, children) {
		return _static ? A2(
			elm$core$List$cons,
			_Utils_Tuple2('static-stylesheet', mdgriffith$elm_ui$Internal$Model$staticRoot),
			A2(
				elm$core$List$cons,
				_Utils_Tuple2(
					'dynamic-stylesheet',
					A2(
						mdgriffith$elm_ui$Internal$Model$toStyleSheet,
						opts,
						A3(
							elm$core$List$foldl,
							mdgriffith$elm_ui$Internal$Model$reduceStyles,
							_Utils_Tuple2(
								elm$core$Set$empty,
								_List_fromArray(
									[
										mdgriffith$elm_ui$Internal$Model$renderFocusStyle(opts.hA)
									])),
							styles).b)),
				children)) : A2(
			elm$core$List$cons,
			_Utils_Tuple2(
				'dynamic-stylesheet',
				A2(
					mdgriffith$elm_ui$Internal$Model$toStyleSheet,
					opts,
					A3(
						elm$core$List$foldl,
						mdgriffith$elm_ui$Internal$Model$reduceStyles,
						_Utils_Tuple2(
							elm$core$Set$empty,
							_List_fromArray(
								[
									mdgriffith$elm_ui$Internal$Model$renderFocusStyle(opts.hA)
								])),
						styles).b)),
			children);
	});
var mdgriffith$elm_ui$Internal$Model$embedWith = F4(
	function (_static, opts, styles, children) {
		return _static ? A2(
			elm$core$List$cons,
			mdgriffith$elm_ui$Internal$Model$staticRoot,
			A2(
				elm$core$List$cons,
				A2(
					mdgriffith$elm_ui$Internal$Model$toStyleSheet,
					opts,
					A3(
						elm$core$List$foldl,
						mdgriffith$elm_ui$Internal$Model$reduceStyles,
						_Utils_Tuple2(
							elm$core$Set$empty,
							_List_fromArray(
								[
									mdgriffith$elm_ui$Internal$Model$renderFocusStyle(opts.hA)
								])),
						styles).b),
				children)) : A2(
			elm$core$List$cons,
			A2(
				mdgriffith$elm_ui$Internal$Model$toStyleSheet,
				opts,
				A3(
					elm$core$List$foldl,
					mdgriffith$elm_ui$Internal$Model$reduceStyles,
					_Utils_Tuple2(
						elm$core$Set$empty,
						_List_fromArray(
							[
								mdgriffith$elm_ui$Internal$Model$renderFocusStyle(opts.hA)
							])),
					styles).b),
			children);
	});
var mdgriffith$elm_ui$Internal$Model$finalizeNode = F6(
	function (has, node, attributes, children, embedMode, parentContext) {
		var createNode = F2(
			function (nodeName, attrs) {
				if (children.$ === 1) {
					var keyed = children.a;
					return A3(
						elm$virtual_dom$VirtualDom$keyedNode,
						nodeName,
						attrs,
						function () {
							switch (embedMode.$) {
								case 0:
									return keyed;
								case 2:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4(mdgriffith$elm_ui$Internal$Model$embedKeyed, false, opts, styles, keyed);
								default:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4(mdgriffith$elm_ui$Internal$Model$embedKeyed, true, opts, styles, keyed);
							}
						}());
				} else {
					var unkeyed = children.a;
					return A2(
						function () {
							switch (nodeName) {
								case 'div':
									return elm$html$Html$div;
								case 'p':
									return elm$html$Html$p;
								default:
									return elm$virtual_dom$VirtualDom$node(nodeName);
							}
						}(),
						attrs,
						function () {
							switch (embedMode.$) {
								case 0:
									return unkeyed;
								case 2:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4(mdgriffith$elm_ui$Internal$Model$embedWith, false, opts, styles, unkeyed);
								default:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4(mdgriffith$elm_ui$Internal$Model$embedWith, true, opts, styles, unkeyed);
							}
						}());
				}
			});
		var html = function () {
			switch (node.$) {
				case 0:
					return A2(createNode, 'div', attributes);
				case 1:
					var nodeName = node.a;
					return A2(createNode, nodeName, attributes);
				default:
					var nodeName = node.a;
					var internal = node.b;
					return A3(
						elm$virtual_dom$VirtualDom$node,
						nodeName,
						attributes,
						_List_fromArray(
							[
								A2(
								createNode,
								internal,
								_List_fromArray(
									[
										elm$html$Html$Attributes$class(mdgriffith$elm_ui$Internal$Style$classes.gQ + (' ' + mdgriffith$elm_ui$Internal$Style$classes.iW))
									]))
							]));
			}
		}();
		switch (parentContext) {
			case 0:
				return (A2(mdgriffith$elm_ui$Internal$Flag$present, mdgriffith$elm_ui$Internal$Flag$widthFill, has) && (!A2(mdgriffith$elm_ui$Internal$Flag$present, mdgriffith$elm_ui$Internal$Flag$widthBetween, has))) ? html : (A2(mdgriffith$elm_ui$Internal$Flag$present, mdgriffith$elm_ui$Internal$Flag$alignRight, has) ? A2(
					elm$html$Html$u,
					_List_fromArray(
						[
							elm$html$Html$Attributes$class(
							A2(
								elm$core$String$join,
								' ',
								_List_fromArray(
									[mdgriffith$elm_ui$Internal$Style$classes.gQ, mdgriffith$elm_ui$Internal$Style$classes.iW, mdgriffith$elm_ui$Internal$Style$classes.cE, mdgriffith$elm_ui$Internal$Style$classes.aI, mdgriffith$elm_ui$Internal$Style$classes.gM])))
						]),
					_List_fromArray(
						[html])) : (A2(mdgriffith$elm_ui$Internal$Flag$present, mdgriffith$elm_ui$Internal$Flag$centerX, has) ? A2(
					elm$html$Html$s,
					_List_fromArray(
						[
							elm$html$Html$Attributes$class(
							A2(
								elm$core$String$join,
								' ',
								_List_fromArray(
									[mdgriffith$elm_ui$Internal$Style$classes.gQ, mdgriffith$elm_ui$Internal$Style$classes.iW, mdgriffith$elm_ui$Internal$Style$classes.cE, mdgriffith$elm_ui$Internal$Style$classes.aI, mdgriffith$elm_ui$Internal$Style$classes.gK])))
						]),
					_List_fromArray(
						[html])) : html));
			case 1:
				return (A2(mdgriffith$elm_ui$Internal$Flag$present, mdgriffith$elm_ui$Internal$Flag$heightFill, has) && (!A2(mdgriffith$elm_ui$Internal$Flag$present, mdgriffith$elm_ui$Internal$Flag$heightBetween, has))) ? html : (A2(mdgriffith$elm_ui$Internal$Flag$present, mdgriffith$elm_ui$Internal$Flag$centerY, has) ? A2(
					elm$html$Html$s,
					_List_fromArray(
						[
							elm$html$Html$Attributes$class(
							A2(
								elm$core$String$join,
								' ',
								_List_fromArray(
									[mdgriffith$elm_ui$Internal$Style$classes.gQ, mdgriffith$elm_ui$Internal$Style$classes.iW, mdgriffith$elm_ui$Internal$Style$classes.cE, mdgriffith$elm_ui$Internal$Style$classes.gL])))
						]),
					_List_fromArray(
						[html])) : (A2(mdgriffith$elm_ui$Internal$Flag$present, mdgriffith$elm_ui$Internal$Flag$alignBottom, has) ? A2(
					elm$html$Html$u,
					_List_fromArray(
						[
							elm$html$Html$Attributes$class(
							A2(
								elm$core$String$join,
								' ',
								_List_fromArray(
									[mdgriffith$elm_ui$Internal$Style$classes.gQ, mdgriffith$elm_ui$Internal$Style$classes.iW, mdgriffith$elm_ui$Internal$Style$classes.cE, mdgriffith$elm_ui$Internal$Style$classes.gJ])))
						]),
					_List_fromArray(
						[html])) : html));
			default:
				return html;
		}
	});
var elm$html$Html$text = elm$virtual_dom$VirtualDom$text;
var mdgriffith$elm_ui$Internal$Model$textElement = function (str) {
	return A2(
		elm$html$Html$div,
		_List_fromArray(
			[
				elm$html$Html$Attributes$class(
				A2(
					elm$core$String$join,
					' ',
					_List_fromArray(
						[mdgriffith$elm_ui$Internal$Style$classes.gQ, mdgriffith$elm_ui$Internal$Style$classes.dj, mdgriffith$elm_ui$Internal$Style$classes.eO, mdgriffith$elm_ui$Internal$Style$classes.cS])))
			]),
		_List_fromArray(
			[
				elm$html$Html$text(str)
			]));
};
var mdgriffith$elm_ui$Internal$Model$textElementFill = function (str) {
	return A3(
		elm$virtual_dom$VirtualDom$node,
		'div',
		_List_fromArray(
			[
				elm$html$Html$Attributes$class(
				A2(
					elm$core$String$join,
					' ',
					_List_fromArray(
						[mdgriffith$elm_ui$Internal$Style$classes.gQ, mdgriffith$elm_ui$Internal$Style$classes.dj, mdgriffith$elm_ui$Internal$Style$classes.eP, mdgriffith$elm_ui$Internal$Style$classes.dG])))
			]),
		_List_fromArray(
			[
				elm$virtual_dom$VirtualDom$text(str)
			]));
};
var mdgriffith$elm_ui$Internal$Model$createElement = F3(
	function (context, children, rendered) {
		var gatherKeyed = F2(
			function (_n8, _n9) {
				var key = _n8.a;
				var child = _n8.b;
				var htmls = _n9.a;
				var existingStyles = _n9.b;
				switch (child.$) {
					case 0:
						var html = child.a;
						return _Utils_eq(context, mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								elm$core$List$cons,
								_Utils_Tuple2(
									key,
									html(context)),
								htmls),
							existingStyles) : _Utils_Tuple2(
							A2(
								elm$core$List$cons,
								_Utils_Tuple2(
									key,
									html(context)),
								htmls),
							existingStyles);
					case 1:
						var styled = child.a;
						return _Utils_eq(context, mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								elm$core$List$cons,
								_Utils_Tuple2(
									key,
									A2(styled.hL, mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context)),
								htmls),
							elm$core$List$isEmpty(existingStyles) ? styled.i5 : _Utils_ap(styled.i5, existingStyles)) : _Utils_Tuple2(
							A2(
								elm$core$List$cons,
								_Utils_Tuple2(
									key,
									A2(styled.hL, mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context)),
								htmls),
							elm$core$List$isEmpty(existingStyles) ? styled.i5 : _Utils_ap(styled.i5, existingStyles));
					case 2:
						var str = child.a;
						return _Utils_Tuple2(
							A2(
								elm$core$List$cons,
								_Utils_Tuple2(
									key,
									_Utils_eq(context, mdgriffith$elm_ui$Internal$Model$asEl) ? mdgriffith$elm_ui$Internal$Model$textElementFill(str) : mdgriffith$elm_ui$Internal$Model$textElement(str)),
								htmls),
							existingStyles);
					default:
						return _Utils_Tuple2(htmls, existingStyles);
				}
			});
		var gather = F2(
			function (child, _n6) {
				var htmls = _n6.a;
				var existingStyles = _n6.b;
				switch (child.$) {
					case 0:
						var html = child.a;
						return _Utils_eq(context, mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								elm$core$List$cons,
								html(context),
								htmls),
							existingStyles) : _Utils_Tuple2(
							A2(
								elm$core$List$cons,
								html(context),
								htmls),
							existingStyles);
					case 1:
						var styled = child.a;
						return _Utils_eq(context, mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								elm$core$List$cons,
								A2(styled.hL, mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context),
								htmls),
							elm$core$List$isEmpty(existingStyles) ? styled.i5 : _Utils_ap(styled.i5, existingStyles)) : _Utils_Tuple2(
							A2(
								elm$core$List$cons,
								A2(styled.hL, mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context),
								htmls),
							elm$core$List$isEmpty(existingStyles) ? styled.i5 : _Utils_ap(styled.i5, existingStyles));
					case 2:
						var str = child.a;
						return _Utils_Tuple2(
							A2(
								elm$core$List$cons,
								_Utils_eq(context, mdgriffith$elm_ui$Internal$Model$asEl) ? mdgriffith$elm_ui$Internal$Model$textElementFill(str) : mdgriffith$elm_ui$Internal$Model$textElement(str),
								htmls),
							existingStyles);
					default:
						return _Utils_Tuple2(htmls, existingStyles);
				}
			});
		if (children.$ === 1) {
			var keyedChildren = children.a;
			var _n1 = A3(
				elm$core$List$foldr,
				gatherKeyed,
				_Utils_Tuple2(_List_Nil, _List_Nil),
				keyedChildren);
			var keyed = _n1.a;
			var styles = _n1.b;
			var newStyles = elm$core$List$isEmpty(styles) ? rendered.i5 : _Utils_ap(rendered.i5, styles);
			if (!newStyles.b) {
				return mdgriffith$elm_ui$Internal$Model$Unstyled(
					A5(
						mdgriffith$elm_ui$Internal$Model$finalizeNode,
						rendered.a7,
						rendered.bb,
						rendered.cx,
						mdgriffith$elm_ui$Internal$Model$Keyed(
							A3(mdgriffith$elm_ui$Internal$Model$addKeyedChildren, 'nearby-element-pls', keyed, rendered.g8)),
						mdgriffith$elm_ui$Internal$Model$NoStyleSheet));
			} else {
				var allStyles = newStyles;
				return mdgriffith$elm_ui$Internal$Model$Styled(
					{
						hL: A4(
							mdgriffith$elm_ui$Internal$Model$finalizeNode,
							rendered.a7,
							rendered.bb,
							rendered.cx,
							mdgriffith$elm_ui$Internal$Model$Keyed(
								A3(mdgriffith$elm_ui$Internal$Model$addKeyedChildren, 'nearby-element-pls', keyed, rendered.g8))),
						i5: allStyles
					});
			}
		} else {
			var unkeyedChildren = children.a;
			var _n3 = A3(
				elm$core$List$foldr,
				gather,
				_Utils_Tuple2(_List_Nil, _List_Nil),
				unkeyedChildren);
			var unkeyed = _n3.a;
			var styles = _n3.b;
			var newStyles = elm$core$List$isEmpty(styles) ? rendered.i5 : _Utils_ap(rendered.i5, styles);
			if (!newStyles.b) {
				return mdgriffith$elm_ui$Internal$Model$Unstyled(
					A5(
						mdgriffith$elm_ui$Internal$Model$finalizeNode,
						rendered.a7,
						rendered.bb,
						rendered.cx,
						mdgriffith$elm_ui$Internal$Model$Unkeyed(
							A2(mdgriffith$elm_ui$Internal$Model$addChildren, unkeyed, rendered.g8)),
						mdgriffith$elm_ui$Internal$Model$NoStyleSheet));
			} else {
				var allStyles = newStyles;
				return mdgriffith$elm_ui$Internal$Model$Styled(
					{
						hL: A4(
							mdgriffith$elm_ui$Internal$Model$finalizeNode,
							rendered.a7,
							rendered.bb,
							rendered.cx,
							mdgriffith$elm_ui$Internal$Model$Unkeyed(
								A2(mdgriffith$elm_ui$Internal$Model$addChildren, unkeyed, rendered.g8))),
						i5: allStyles
					});
			}
		}
	});
var elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var elm$core$Bitwise$or = _Bitwise_or;
var mdgriffith$elm_ui$Internal$Flag$add = F2(
	function (myFlag, _n0) {
		var one = _n0.a;
		var two = _n0.b;
		if (!myFlag.$) {
			var first = myFlag.a;
			return A2(mdgriffith$elm_ui$Internal$Flag$Field, first | one, two);
		} else {
			var second = myFlag.a;
			return A2(mdgriffith$elm_ui$Internal$Flag$Field, one, second | two);
		}
	});
var mdgriffith$elm_ui$Internal$Flag$height = mdgriffith$elm_ui$Internal$Flag$flag(7);
var mdgriffith$elm_ui$Internal$Flag$heightContent = mdgriffith$elm_ui$Internal$Flag$flag(36);
var mdgriffith$elm_ui$Internal$Flag$merge = F2(
	function (_n0, _n1) {
		var one = _n0.a;
		var two = _n0.b;
		var three = _n1.a;
		var four = _n1.b;
		return A2(mdgriffith$elm_ui$Internal$Flag$Field, one | three, two | four);
	});
var mdgriffith$elm_ui$Internal$Flag$width = mdgriffith$elm_ui$Internal$Flag$flag(6);
var mdgriffith$elm_ui$Internal$Flag$widthContent = mdgriffith$elm_ui$Internal$Flag$flag(38);
var mdgriffith$elm_ui$Internal$Flag$xAlign = mdgriffith$elm_ui$Internal$Flag$flag(30);
var mdgriffith$elm_ui$Internal$Flag$yAlign = mdgriffith$elm_ui$Internal$Flag$flag(29);
var mdgriffith$elm_ui$Internal$Model$Embedded = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var mdgriffith$elm_ui$Internal$Model$NodeName = function (a) {
	return {$: 1, a: a};
};
var mdgriffith$elm_ui$Internal$Model$Single = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var mdgriffith$elm_ui$Internal$Model$Transform = function (a) {
	return {$: 10, a: a};
};
var mdgriffith$elm_ui$Internal$Model$ChildrenBehind = function (a) {
	return {$: 1, a: a};
};
var mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var mdgriffith$elm_ui$Internal$Model$ChildrenInFront = function (a) {
	return {$: 2, a: a};
};
var mdgriffith$elm_ui$Internal$Model$nearbyElement = F2(
	function (location, elem) {
		return A2(
			elm$html$Html$div,
			_List_fromArray(
				[
					elm$html$Html$Attributes$class(
					function () {
						switch (location) {
							case 0:
								return A2(
									elm$core$String$join,
									' ',
									_List_fromArray(
										[mdgriffith$elm_ui$Internal$Style$classes.bB, mdgriffith$elm_ui$Internal$Style$classes.iW, mdgriffith$elm_ui$Internal$Style$classes.gE]));
							case 1:
								return A2(
									elm$core$String$join,
									' ',
									_List_fromArray(
										[mdgriffith$elm_ui$Internal$Style$classes.bB, mdgriffith$elm_ui$Internal$Style$classes.iW, mdgriffith$elm_ui$Internal$Style$classes.gW]));
							case 2:
								return A2(
									elm$core$String$join,
									' ',
									_List_fromArray(
										[mdgriffith$elm_ui$Internal$Style$classes.bB, mdgriffith$elm_ui$Internal$Style$classes.iW, mdgriffith$elm_ui$Internal$Style$classes.$9]));
							case 3:
								return A2(
									elm$core$String$join,
									' ',
									_List_fromArray(
										[mdgriffith$elm_ui$Internal$Style$classes.bB, mdgriffith$elm_ui$Internal$Style$classes.iW, mdgriffith$elm_ui$Internal$Style$classes.ik]));
							case 4:
								return A2(
									elm$core$String$join,
									' ',
									_List_fromArray(
										[mdgriffith$elm_ui$Internal$Style$classes.bB, mdgriffith$elm_ui$Internal$Style$classes.iW, mdgriffith$elm_ui$Internal$Style$classes.hO]));
							default:
								return A2(
									elm$core$String$join,
									' ',
									_List_fromArray(
										[mdgriffith$elm_ui$Internal$Style$classes.bB, mdgriffith$elm_ui$Internal$Style$classes.iW, mdgriffith$elm_ui$Internal$Style$classes.gV]));
						}
					}())
				]),
			_List_fromArray(
				[
					function () {
					switch (elem.$) {
						case 3:
							return elm$virtual_dom$VirtualDom$text('');
						case 2:
							var str = elem.a;
							return mdgriffith$elm_ui$Internal$Model$textElement(str);
						case 0:
							var html = elem.a;
							return html(mdgriffith$elm_ui$Internal$Model$asEl);
						default:
							var styled = elem.a;
							return A2(styled.hL, mdgriffith$elm_ui$Internal$Model$NoStyleSheet, mdgriffith$elm_ui$Internal$Model$asEl);
					}
				}()
				]));
	});
var mdgriffith$elm_ui$Internal$Model$addNearbyElement = F3(
	function (location, elem, existing) {
		var nearby = A2(mdgriffith$elm_ui$Internal$Model$nearbyElement, location, elem);
		switch (existing.$) {
			case 0:
				if (location === 5) {
					return mdgriffith$elm_ui$Internal$Model$ChildrenBehind(
						_List_fromArray(
							[nearby]));
				} else {
					return mdgriffith$elm_ui$Internal$Model$ChildrenInFront(
						_List_fromArray(
							[nearby]));
				}
			case 1:
				var existingBehind = existing.a;
				if (location === 5) {
					return mdgriffith$elm_ui$Internal$Model$ChildrenBehind(
						A2(elm$core$List$cons, nearby, existingBehind));
				} else {
					return A2(
						mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						existingBehind,
						_List_fromArray(
							[nearby]));
				}
			case 2:
				var existingInFront = existing.a;
				if (location === 5) {
					return A2(
						mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						_List_fromArray(
							[nearby]),
						existingInFront);
				} else {
					return mdgriffith$elm_ui$Internal$Model$ChildrenInFront(
						A2(elm$core$List$cons, nearby, existingInFront));
				}
			default:
				var existingBehind = existing.a;
				var existingInFront = existing.b;
				if (location === 5) {
					return A2(
						mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						A2(elm$core$List$cons, nearby, existingBehind),
						existingInFront);
				} else {
					return A2(
						mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						existingBehind,
						A2(elm$core$List$cons, nearby, existingInFront));
				}
		}
	});
var mdgriffith$elm_ui$Internal$Model$addNodeName = F2(
	function (newNode, old) {
		switch (old.$) {
			case 0:
				return mdgriffith$elm_ui$Internal$Model$NodeName(newNode);
			case 1:
				var name = old.a;
				return A2(mdgriffith$elm_ui$Internal$Model$Embedded, name, newNode);
			default:
				var x = old.a;
				var y = old.b;
				return A2(mdgriffith$elm_ui$Internal$Model$Embedded, x, y);
		}
	});
var mdgriffith$elm_ui$Internal$Model$alignXName = function (align) {
	switch (align) {
		case 0:
			return mdgriffith$elm_ui$Internal$Style$classes.dq + (' ' + mdgriffith$elm_ui$Internal$Style$classes.eX);
		case 2:
			return mdgriffith$elm_ui$Internal$Style$classes.dq + (' ' + mdgriffith$elm_ui$Internal$Style$classes.eY);
		default:
			return mdgriffith$elm_ui$Internal$Style$classes.dq + (' ' + mdgriffith$elm_ui$Internal$Style$classes.gH);
	}
};
var mdgriffith$elm_ui$Internal$Model$alignYName = function (align) {
	switch (align) {
		case 0:
			return mdgriffith$elm_ui$Internal$Style$classes.dr + (' ' + mdgriffith$elm_ui$Internal$Style$classes.gN);
		case 2:
			return mdgriffith$elm_ui$Internal$Style$classes.dr + (' ' + mdgriffith$elm_ui$Internal$Style$classes.gG);
		default:
			return mdgriffith$elm_ui$Internal$Style$classes.dr + (' ' + mdgriffith$elm_ui$Internal$Style$classes.gI);
	}
};
var mdgriffith$elm_ui$Internal$Model$FullTransform = F4(
	function (a, b, c, d) {
		return {$: 2, a: a, b: b, c: c, d: d};
	});
var mdgriffith$elm_ui$Internal$Model$Moved = function (a) {
	return {$: 1, a: a};
};
var mdgriffith$elm_ui$Internal$Model$composeTransformation = F2(
	function (transform, component) {
		switch (transform.$) {
			case 0:
				switch (component.$) {
					case 0:
						var x = component.a;
						return mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(x, 0, 0));
					case 1:
						var y = component.a;
						return mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(0, y, 0));
					case 2:
						var z = component.a;
						return mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(0, 0, z));
					case 3:
						var xyz = component.a;
						return mdgriffith$elm_ui$Internal$Model$Moved(xyz);
					case 4:
						var xyz = component.a;
						var angle = component.b;
						return A4(
							mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(0, 0, 0),
							_Utils_Tuple3(1, 1, 1),
							xyz,
							angle);
					default:
						var xyz = component.a;
						return A4(
							mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(0, 0, 0),
							xyz,
							_Utils_Tuple3(0, 0, 1),
							0);
				}
			case 1:
				var moved = transform.a;
				var x = moved.a;
				var y = moved.b;
				var z = moved.c;
				switch (component.$) {
					case 0:
						var newX = component.a;
						return mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(newX, y, z));
					case 1:
						var newY = component.a;
						return mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(x, newY, z));
					case 2:
						var newZ = component.a;
						return mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(x, y, newZ));
					case 3:
						var xyz = component.a;
						return mdgriffith$elm_ui$Internal$Model$Moved(xyz);
					case 4:
						var xyz = component.a;
						var angle = component.b;
						return A4(
							mdgriffith$elm_ui$Internal$Model$FullTransform,
							moved,
							_Utils_Tuple3(1, 1, 1),
							xyz,
							angle);
					default:
						var scale = component.a;
						return A4(
							mdgriffith$elm_ui$Internal$Model$FullTransform,
							moved,
							scale,
							_Utils_Tuple3(0, 0, 1),
							0);
				}
			default:
				var moved = transform.a;
				var x = moved.a;
				var y = moved.b;
				var z = moved.c;
				var scaled = transform.b;
				var origin = transform.c;
				var angle = transform.d;
				switch (component.$) {
					case 0:
						var newX = component.a;
						return A4(
							mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(newX, y, z),
							scaled,
							origin,
							angle);
					case 1:
						var newY = component.a;
						return A4(
							mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(x, newY, z),
							scaled,
							origin,
							angle);
					case 2:
						var newZ = component.a;
						return A4(
							mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(x, y, newZ),
							scaled,
							origin,
							angle);
					case 3:
						var newMove = component.a;
						return A4(mdgriffith$elm_ui$Internal$Model$FullTransform, newMove, scaled, origin, angle);
					case 4:
						var newOrigin = component.a;
						var newAngle = component.b;
						return A4(mdgriffith$elm_ui$Internal$Model$FullTransform, moved, scaled, newOrigin, newAngle);
					default:
						var newScale = component.a;
						return A4(mdgriffith$elm_ui$Internal$Model$FullTransform, moved, newScale, origin, angle);
				}
		}
	});
var mdgriffith$elm_ui$Internal$Model$renderHeight = function (h) {
	switch (h.$) {
		case 0:
			var px = h.a;
			var val = elm$core$String$fromInt(px);
			var name = 'height-px-' + val;
			return _Utils_Tuple3(
				mdgriffith$elm_ui$Internal$Flag$none,
				name,
				_List_fromArray(
					[
						A3(mdgriffith$elm_ui$Internal$Model$Single, name, 'height', val + 'px')
					]));
		case 1:
			return _Utils_Tuple3(
				A2(mdgriffith$elm_ui$Internal$Flag$add, mdgriffith$elm_ui$Internal$Flag$heightContent, mdgriffith$elm_ui$Internal$Flag$none),
				mdgriffith$elm_ui$Internal$Style$classes.cS,
				_List_Nil);
		case 2:
			var portion = h.a;
			return (portion === 1) ? _Utils_Tuple3(
				A2(mdgriffith$elm_ui$Internal$Flag$add, mdgriffith$elm_ui$Internal$Flag$heightFill, mdgriffith$elm_ui$Internal$Flag$none),
				mdgriffith$elm_ui$Internal$Style$classes.dG,
				_List_Nil) : _Utils_Tuple3(
				A2(mdgriffith$elm_ui$Internal$Flag$add, mdgriffith$elm_ui$Internal$Flag$heightFill, mdgriffith$elm_ui$Internal$Flag$none),
				mdgriffith$elm_ui$Internal$Style$classes.fr + (' height-fill-' + elm$core$String$fromInt(portion)),
				_List_fromArray(
					[
						A3(
						mdgriffith$elm_ui$Internal$Model$Single,
						mdgriffith$elm_ui$Internal$Style$classes.gQ + ('.' + (mdgriffith$elm_ui$Internal$Style$classes.f6 + (' > ' + mdgriffith$elm_ui$Internal$Style$dot(
							'height-fill-' + elm$core$String$fromInt(portion))))),
						'flex-grow',
						elm$core$String$fromInt(portion * 100000))
					]));
		case 3:
			var minSize = h.a;
			var len = h.b;
			var cls = 'min-height-' + elm$core$String$fromInt(minSize);
			var style = A3(
				mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'min-height',
				elm$core$String$fromInt(minSize) + 'px');
			var _n1 = mdgriffith$elm_ui$Internal$Model$renderHeight(len);
			var newFlag = _n1.a;
			var newAttrs = _n1.b;
			var newStyle = _n1.c;
			return _Utils_Tuple3(
				A2(mdgriffith$elm_ui$Internal$Flag$add, mdgriffith$elm_ui$Internal$Flag$heightBetween, newFlag),
				cls + (' ' + newAttrs),
				A2(elm$core$List$cons, style, newStyle));
		default:
			var maxSize = h.a;
			var len = h.b;
			var cls = 'max-height-' + elm$core$String$fromInt(maxSize);
			var style = A3(
				mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'max-height',
				elm$core$String$fromInt(maxSize) + 'px');
			var _n2 = mdgriffith$elm_ui$Internal$Model$renderHeight(len);
			var newFlag = _n2.a;
			var newAttrs = _n2.b;
			var newStyle = _n2.c;
			return _Utils_Tuple3(
				A2(mdgriffith$elm_ui$Internal$Flag$add, mdgriffith$elm_ui$Internal$Flag$heightBetween, newFlag),
				cls + (' ' + newAttrs),
				A2(elm$core$List$cons, style, newStyle));
	}
};
var mdgriffith$elm_ui$Internal$Model$renderWidth = function (w) {
	switch (w.$) {
		case 0:
			var px = w.a;
			return _Utils_Tuple3(
				mdgriffith$elm_ui$Internal$Flag$none,
				mdgriffith$elm_ui$Internal$Style$classes.gz + (' width-px-' + elm$core$String$fromInt(px)),
				_List_fromArray(
					[
						A3(
						mdgriffith$elm_ui$Internal$Model$Single,
						'width-px-' + elm$core$String$fromInt(px),
						'width',
						elm$core$String$fromInt(px) + 'px')
					]));
		case 1:
			return _Utils_Tuple3(
				A2(mdgriffith$elm_ui$Internal$Flag$add, mdgriffith$elm_ui$Internal$Flag$widthContent, mdgriffith$elm_ui$Internal$Flag$none),
				mdgriffith$elm_ui$Internal$Style$classes.eO,
				_List_Nil);
		case 2:
			var portion = w.a;
			return (portion === 1) ? _Utils_Tuple3(
				A2(mdgriffith$elm_ui$Internal$Flag$add, mdgriffith$elm_ui$Internal$Flag$widthFill, mdgriffith$elm_ui$Internal$Flag$none),
				mdgriffith$elm_ui$Internal$Style$classes.eP,
				_List_Nil) : _Utils_Tuple3(
				A2(mdgriffith$elm_ui$Internal$Flag$add, mdgriffith$elm_ui$Internal$Flag$widthFill, mdgriffith$elm_ui$Internal$Flag$none),
				mdgriffith$elm_ui$Internal$Style$classes.gA + (' width-fill-' + elm$core$String$fromInt(portion)),
				_List_fromArray(
					[
						A3(
						mdgriffith$elm_ui$Internal$Model$Single,
						mdgriffith$elm_ui$Internal$Style$classes.gQ + ('.' + (mdgriffith$elm_ui$Internal$Style$classes.f6 + (' > ' + mdgriffith$elm_ui$Internal$Style$dot(
							'width-fill-' + elm$core$String$fromInt(portion))))),
						'flex-grow',
						elm$core$String$fromInt(portion * 100000))
					]));
		case 3:
			var minSize = w.a;
			var len = w.b;
			var cls = 'min-width-' + elm$core$String$fromInt(minSize);
			var style = A3(
				mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'min-width',
				elm$core$String$fromInt(minSize) + 'px');
			var _n1 = mdgriffith$elm_ui$Internal$Model$renderWidth(len);
			var newFlag = _n1.a;
			var newAttrs = _n1.b;
			var newStyle = _n1.c;
			return _Utils_Tuple3(
				A2(mdgriffith$elm_ui$Internal$Flag$add, mdgriffith$elm_ui$Internal$Flag$widthBetween, newFlag),
				cls + (' ' + newAttrs),
				A2(elm$core$List$cons, style, newStyle));
		default:
			var maxSize = w.a;
			var len = w.b;
			var cls = 'max-width-' + elm$core$String$fromInt(maxSize);
			var style = A3(
				mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'max-width',
				elm$core$String$fromInt(maxSize) + 'px');
			var _n2 = mdgriffith$elm_ui$Internal$Model$renderWidth(len);
			var newFlag = _n2.a;
			var newAttrs = _n2.b;
			var newStyle = _n2.c;
			return _Utils_Tuple3(
				A2(mdgriffith$elm_ui$Internal$Flag$add, mdgriffith$elm_ui$Internal$Flag$widthBetween, newFlag),
				cls + (' ' + newAttrs),
				A2(elm$core$List$cons, style, newStyle));
	}
};
var mdgriffith$elm_ui$Internal$Flag$borderWidth = mdgriffith$elm_ui$Internal$Flag$flag(27);
var mdgriffith$elm_ui$Internal$Model$skippable = F2(
	function (flag, style) {
		if (_Utils_eq(flag, mdgriffith$elm_ui$Internal$Flag$borderWidth)) {
			if (style.$ === 3) {
				var val = style.c;
				switch (val) {
					case '0px':
						return true;
					case '1px':
						return true;
					case '2px':
						return true;
					case '3px':
						return true;
					case '4px':
						return true;
					case '5px':
						return true;
					case '6px':
						return true;
					default:
						return false;
				}
			} else {
				return false;
			}
		} else {
			switch (style.$) {
				case 2:
					var i = style.a;
					return (i >= 8) && (i <= 32);
				case 7:
					var name = style.a;
					var t = style.b;
					var r = style.c;
					var b = style.d;
					var l = style.e;
					return _Utils_eq(t, b) && (_Utils_eq(t, r) && (_Utils_eq(t, l) && ((t >= 0) && (t <= 24))));
				default:
					return false;
			}
		}
	});
var mdgriffith$elm_ui$Internal$Model$gatherAttrRecursive = F8(
	function (classes, node, has, transform, styles, attrs, children, elementAttrs) {
		gatherAttrRecursive:
		while (true) {
			if (!elementAttrs.b) {
				var _n1 = mdgriffith$elm_ui$Internal$Model$transformClass(transform);
				if (_n1.$ === 1) {
					return {
						cx: A2(
							elm$core$List$cons,
							elm$html$Html$Attributes$class(classes),
							attrs),
						g8: children,
						a7: has,
						bb: node,
						i5: styles
					};
				} else {
					var _class = _n1.a;
					return {
						cx: A2(
							elm$core$List$cons,
							elm$html$Html$Attributes$class(classes + (' ' + _class)),
							attrs),
						g8: children,
						a7: has,
						bb: node,
						i5: A2(
							elm$core$List$cons,
							mdgriffith$elm_ui$Internal$Model$Transform(transform),
							styles)
					};
				}
			} else {
				var attribute = elementAttrs.a;
				var remaining = elementAttrs.b;
				switch (attribute.$) {
					case 0:
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = has,
							$temp$transform = transform,
							$temp$styles = styles,
							$temp$attrs = attrs,
							$temp$children = children,
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 3:
						var flag = attribute.a;
						var exactClassName = attribute.b;
						if (A2(mdgriffith$elm_ui$Internal$Flag$present, flag, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							var $temp$classes = exactClassName + (' ' + classes),
								$temp$node = node,
								$temp$has = A2(mdgriffith$elm_ui$Internal$Flag$add, flag, has),
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						}
					case 1:
						var actualAttribute = attribute.a;
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = has,
							$temp$transform = transform,
							$temp$styles = styles,
							$temp$attrs = A2(elm$core$List$cons, actualAttribute, attrs),
							$temp$children = children,
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 4:
						var flag = attribute.a;
						var style = attribute.b;
						if (A2(mdgriffith$elm_ui$Internal$Flag$present, flag, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							if (A2(mdgriffith$elm_ui$Internal$Model$skippable, flag, style)) {
								var $temp$classes = mdgriffith$elm_ui$Internal$Model$getStyleName(style) + (' ' + classes),
									$temp$node = node,
									$temp$has = A2(mdgriffith$elm_ui$Internal$Flag$add, flag, has),
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							} else {
								var $temp$classes = mdgriffith$elm_ui$Internal$Model$getStyleName(style) + (' ' + classes),
									$temp$node = node,
									$temp$has = A2(mdgriffith$elm_ui$Internal$Flag$add, flag, has),
									$temp$transform = transform,
									$temp$styles = A2(elm$core$List$cons, style, styles),
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							}
						}
					case 10:
						var flag = attribute.a;
						var component = attribute.b;
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = A2(mdgriffith$elm_ui$Internal$Flag$add, flag, has),
							$temp$transform = A2(mdgriffith$elm_ui$Internal$Model$composeTransformation, transform, component),
							$temp$styles = styles,
							$temp$attrs = attrs,
							$temp$children = children,
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 7:
						var width = attribute.a;
						if (A2(mdgriffith$elm_ui$Internal$Flag$present, mdgriffith$elm_ui$Internal$Flag$width, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							switch (width.$) {
								case 0:
									var px = width.a;
									var $temp$classes = (mdgriffith$elm_ui$Internal$Style$classes.gz + (' width-px-' + elm$core$String$fromInt(px))) + (' ' + classes),
										$temp$node = node,
										$temp$has = A2(mdgriffith$elm_ui$Internal$Flag$add, mdgriffith$elm_ui$Internal$Flag$width, has),
										$temp$transform = transform,
										$temp$styles = A2(
										elm$core$List$cons,
										A3(
											mdgriffith$elm_ui$Internal$Model$Single,
											'width-px-' + elm$core$String$fromInt(px),
											'width',
											elm$core$String$fromInt(px) + 'px'),
										styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 1:
									var $temp$classes = classes + (' ' + mdgriffith$elm_ui$Internal$Style$classes.eO),
										$temp$node = node,
										$temp$has = A2(
										mdgriffith$elm_ui$Internal$Flag$add,
										mdgriffith$elm_ui$Internal$Flag$widthContent,
										A2(mdgriffith$elm_ui$Internal$Flag$add, mdgriffith$elm_ui$Internal$Flag$width, has)),
										$temp$transform = transform,
										$temp$styles = styles,
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 2:
									var portion = width.a;
									if (portion === 1) {
										var $temp$classes = classes + (' ' + mdgriffith$elm_ui$Internal$Style$classes.eP),
											$temp$node = node,
											$temp$has = A2(
											mdgriffith$elm_ui$Internal$Flag$add,
											mdgriffith$elm_ui$Internal$Flag$widthFill,
											A2(mdgriffith$elm_ui$Internal$Flag$add, mdgriffith$elm_ui$Internal$Flag$width, has)),
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									} else {
										var $temp$classes = classes + (' ' + (mdgriffith$elm_ui$Internal$Style$classes.gA + (' width-fill-' + elm$core$String$fromInt(portion)))),
											$temp$node = node,
											$temp$has = A2(
											mdgriffith$elm_ui$Internal$Flag$add,
											mdgriffith$elm_ui$Internal$Flag$widthFill,
											A2(mdgriffith$elm_ui$Internal$Flag$add, mdgriffith$elm_ui$Internal$Flag$width, has)),
											$temp$transform = transform,
											$temp$styles = A2(
											elm$core$List$cons,
											A3(
												mdgriffith$elm_ui$Internal$Model$Single,
												mdgriffith$elm_ui$Internal$Style$classes.gQ + ('.' + (mdgriffith$elm_ui$Internal$Style$classes.f6 + (' > ' + mdgriffith$elm_ui$Internal$Style$dot(
													'width-fill-' + elm$core$String$fromInt(portion))))),
												'flex-grow',
												elm$core$String$fromInt(portion * 100000)),
											styles),
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									}
								default:
									var _n4 = mdgriffith$elm_ui$Internal$Model$renderWidth(width);
									var addToFlags = _n4.a;
									var newClass = _n4.b;
									var newStyles = _n4.c;
									var $temp$classes = classes + (' ' + newClass),
										$temp$node = node,
										$temp$has = A2(mdgriffith$elm_ui$Internal$Flag$merge, addToFlags, has),
										$temp$transform = transform,
										$temp$styles = _Utils_ap(newStyles, styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
							}
						}
					case 8:
						var height = attribute.a;
						if (A2(mdgriffith$elm_ui$Internal$Flag$present, mdgriffith$elm_ui$Internal$Flag$height, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							switch (height.$) {
								case 0:
									var px = height.a;
									var val = elm$core$String$fromInt(px) + 'px';
									var name = 'height-px-' + val;
									var $temp$classes = name + (' ' + classes),
										$temp$node = node,
										$temp$has = A2(mdgriffith$elm_ui$Internal$Flag$add, mdgriffith$elm_ui$Internal$Flag$height, has),
										$temp$transform = transform,
										$temp$styles = A2(
										elm$core$List$cons,
										A3(mdgriffith$elm_ui$Internal$Model$Single, name, 'height ', val),
										styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 1:
									var $temp$classes = mdgriffith$elm_ui$Internal$Style$classes.cS + (' ' + classes),
										$temp$node = node,
										$temp$has = A2(
										mdgriffith$elm_ui$Internal$Flag$add,
										mdgriffith$elm_ui$Internal$Flag$heightContent,
										A2(mdgriffith$elm_ui$Internal$Flag$add, mdgriffith$elm_ui$Internal$Flag$height, has)),
										$temp$transform = transform,
										$temp$styles = styles,
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 2:
									var portion = height.a;
									if (portion === 1) {
										var $temp$classes = mdgriffith$elm_ui$Internal$Style$classes.dG + (' ' + classes),
											$temp$node = node,
											$temp$has = A2(
											mdgriffith$elm_ui$Internal$Flag$add,
											mdgriffith$elm_ui$Internal$Flag$heightFill,
											A2(mdgriffith$elm_ui$Internal$Flag$add, mdgriffith$elm_ui$Internal$Flag$height, has)),
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									} else {
										var $temp$classes = classes + (' ' + (mdgriffith$elm_ui$Internal$Style$classes.fr + (' height-fill-' + elm$core$String$fromInt(portion)))),
											$temp$node = node,
											$temp$has = A2(
											mdgriffith$elm_ui$Internal$Flag$add,
											mdgriffith$elm_ui$Internal$Flag$heightFill,
											A2(mdgriffith$elm_ui$Internal$Flag$add, mdgriffith$elm_ui$Internal$Flag$height, has)),
											$temp$transform = transform,
											$temp$styles = A2(
											elm$core$List$cons,
											A3(
												mdgriffith$elm_ui$Internal$Model$Single,
												mdgriffith$elm_ui$Internal$Style$classes.gQ + ('.' + (mdgriffith$elm_ui$Internal$Style$classes.aH + (' > ' + mdgriffith$elm_ui$Internal$Style$dot(
													'height-fill-' + elm$core$String$fromInt(portion))))),
												'flex-grow',
												elm$core$String$fromInt(portion * 100000)),
											styles),
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									}
								default:
									var _n6 = mdgriffith$elm_ui$Internal$Model$renderHeight(height);
									var addToFlags = _n6.a;
									var newClass = _n6.b;
									var newStyles = _n6.c;
									var $temp$classes = classes + (' ' + newClass),
										$temp$node = node,
										$temp$has = A2(mdgriffith$elm_ui$Internal$Flag$merge, addToFlags, has),
										$temp$transform = transform,
										$temp$styles = _Utils_ap(newStyles, styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
							}
						}
					case 2:
						var description = attribute.a;
						switch (description.$) {
							case 0:
								var $temp$classes = classes,
									$temp$node = A2(mdgriffith$elm_ui$Internal$Model$addNodeName, 'main', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 1:
								var $temp$classes = classes,
									$temp$node = A2(mdgriffith$elm_ui$Internal$Model$addNodeName, 'nav', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 2:
								var $temp$classes = classes,
									$temp$node = A2(mdgriffith$elm_ui$Internal$Model$addNodeName, 'footer', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 3:
								var $temp$classes = classes,
									$temp$node = A2(mdgriffith$elm_ui$Internal$Model$addNodeName, 'aside', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 4:
								var i = description.a;
								if (i <= 1) {
									var $temp$classes = classes,
										$temp$node = A2(mdgriffith$elm_ui$Internal$Model$addNodeName, 'h1', node),
										$temp$has = has,
										$temp$transform = transform,
										$temp$styles = styles,
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								} else {
									if (i < 7) {
										var $temp$classes = classes,
											$temp$node = A2(
											mdgriffith$elm_ui$Internal$Model$addNodeName,
											'h' + elm$core$String$fromInt(i),
											node),
											$temp$has = has,
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									} else {
										var $temp$classes = classes,
											$temp$node = A2(mdgriffith$elm_ui$Internal$Model$addNodeName, 'h6', node),
											$temp$has = has,
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									}
								}
							case 9:
								var newNode = function () {
									switch (node.$) {
										case 0:
											return mdgriffith$elm_ui$Internal$Model$NodeName('p');
										case 1:
											var name = node.a;
											return mdgriffith$elm_ui$Internal$Model$NodeName(name);
										default:
											var x = node.a;
											var y = node.b;
											return A2(mdgriffith$elm_ui$Internal$Model$Embedded, x, y);
									}
								}();
								var $temp$classes = classes,
									$temp$node = newNode,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 8:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									elm$core$List$cons,
									A2(elm$virtual_dom$VirtualDom$attribute, 'role', 'button'),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 5:
								var label = description.a;
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									elm$core$List$cons,
									A2(elm$virtual_dom$VirtualDom$attribute, 'aria-label', label),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 6:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									elm$core$List$cons,
									A2(elm$virtual_dom$VirtualDom$attribute, 'aria-live', 'polite'),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							default:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									elm$core$List$cons,
									A2(elm$virtual_dom$VirtualDom$attribute, 'aria-live', 'assertive'),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
						}
					case 9:
						var location = attribute.a;
						var elem = attribute.b;
						var newStyles = function () {
							switch (elem.$) {
								case 3:
									return styles;
								case 2:
									var str = elem.a;
									return styles;
								case 0:
									var html = elem.a;
									return styles;
								default:
									var styled = elem.a;
									return _Utils_ap(styles, styled.i5);
							}
						}();
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = has,
							$temp$transform = transform,
							$temp$styles = newStyles,
							$temp$attrs = attrs,
							$temp$children = A3(mdgriffith$elm_ui$Internal$Model$addNearbyElement, location, elem, children),
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 6:
						var x = attribute.a;
						if (A2(mdgriffith$elm_ui$Internal$Flag$present, mdgriffith$elm_ui$Internal$Flag$xAlign, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							var $temp$classes = mdgriffith$elm_ui$Internal$Model$alignXName(x) + (' ' + classes),
								$temp$node = node,
								$temp$has = function (flags) {
								switch (x) {
									case 1:
										return A2(mdgriffith$elm_ui$Internal$Flag$add, mdgriffith$elm_ui$Internal$Flag$centerX, flags);
									case 2:
										return A2(mdgriffith$elm_ui$Internal$Flag$add, mdgriffith$elm_ui$Internal$Flag$alignRight, flags);
									default:
										return flags;
								}
							}(
								A2(mdgriffith$elm_ui$Internal$Flag$add, mdgriffith$elm_ui$Internal$Flag$xAlign, has)),
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						}
					default:
						var y = attribute.a;
						if (A2(mdgriffith$elm_ui$Internal$Flag$present, mdgriffith$elm_ui$Internal$Flag$yAlign, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							var $temp$classes = mdgriffith$elm_ui$Internal$Model$alignYName(y) + (' ' + classes),
								$temp$node = node,
								$temp$has = function (flags) {
								switch (y) {
									case 1:
										return A2(mdgriffith$elm_ui$Internal$Flag$add, mdgriffith$elm_ui$Internal$Flag$centerY, flags);
									case 2:
										return A2(mdgriffith$elm_ui$Internal$Flag$add, mdgriffith$elm_ui$Internal$Flag$alignBottom, flags);
									default:
										return flags;
								}
							}(
								A2(mdgriffith$elm_ui$Internal$Flag$add, mdgriffith$elm_ui$Internal$Flag$yAlign, has)),
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						}
				}
			}
		}
	});
var mdgriffith$elm_ui$Internal$Model$Untransformed = {$: 0};
var mdgriffith$elm_ui$Internal$Model$untransformed = mdgriffith$elm_ui$Internal$Model$Untransformed;
var mdgriffith$elm_ui$Internal$Model$element = F4(
	function (context, node, attributes, children) {
		return A3(
			mdgriffith$elm_ui$Internal$Model$createElement,
			context,
			children,
			A8(
				mdgriffith$elm_ui$Internal$Model$gatherAttrRecursive,
				mdgriffith$elm_ui$Internal$Model$contextClasses(context),
				node,
				mdgriffith$elm_ui$Internal$Flag$none,
				mdgriffith$elm_ui$Internal$Model$untransformed,
				_List_Nil,
				_List_Nil,
				mdgriffith$elm_ui$Internal$Model$NoNearbyChildren,
				elm$core$List$reverse(attributes)));
	});
var mdgriffith$elm_ui$Element$el = F2(
	function (attrs, child) {
		return A4(
			mdgriffith$elm_ui$Internal$Model$element,
			mdgriffith$elm_ui$Internal$Model$asEl,
			mdgriffith$elm_ui$Internal$Model$div,
			A2(
				elm$core$List$cons,
				mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$shrink),
				A2(
					elm$core$List$cons,
					mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$shrink),
					attrs)),
			mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[child])));
	});
var elm$html$Html$Attributes$alt = elm$html$Html$Attributes$stringProperty('alt');
var elm$html$Html$Attributes$src = function (url) {
	return A2(
		elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var mdgriffith$elm_ui$Internal$Model$Attr = function (a) {
	return {$: 1, a: a};
};
var mdgriffith$elm_ui$Internal$Model$htmlClass = function (cls) {
	return mdgriffith$elm_ui$Internal$Model$Attr(
		elm$html$Html$Attributes$class(cls));
};
var mdgriffith$elm_ui$Element$image = F2(
	function (attrs, _n0) {
		var src = _n0.bJ;
		var description = _n0.bq;
		var imageAttributes = A2(
			elm$core$List$filter,
			function (a) {
				switch (a.$) {
					case 7:
						return true;
					case 8:
						return true;
					default:
						return false;
				}
			},
			attrs);
		return A4(
			mdgriffith$elm_ui$Internal$Model$element,
			mdgriffith$elm_ui$Internal$Model$asEl,
			mdgriffith$elm_ui$Internal$Model$div,
			A2(
				elm$core$List$cons,
				mdgriffith$elm_ui$Internal$Model$htmlClass(mdgriffith$elm_ui$Internal$Style$classes.hM),
				attrs),
			mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[
						A4(
						mdgriffith$elm_ui$Internal$Model$element,
						mdgriffith$elm_ui$Internal$Model$asEl,
						mdgriffith$elm_ui$Internal$Model$NodeName('img'),
						_Utils_ap(
							_List_fromArray(
								[
									mdgriffith$elm_ui$Internal$Model$Attr(
									elm$html$Html$Attributes$src(src)),
									mdgriffith$elm_ui$Internal$Model$Attr(
									elm$html$Html$Attributes$alt(description))
								]),
							imageAttributes),
						mdgriffith$elm_ui$Internal$Model$Unkeyed(_List_Nil))
					])));
	});
var mdgriffith$elm_ui$Internal$Model$Empty = {$: 3};
var mdgriffith$elm_ui$Element$none = mdgriffith$elm_ui$Internal$Model$Empty;
var mdgriffith$elm_ui$Internal$Model$Text = function (a) {
	return {$: 2, a: a};
};
var mdgriffith$elm_ui$Element$text = function (content) {
	return mdgriffith$elm_ui$Internal$Model$Text(content);
};
var mdgriffith$elm_ui$Internal$Flag$fontSize = mdgriffith$elm_ui$Internal$Flag$flag(4);
var mdgriffith$elm_ui$Internal$Model$FontSize = function (a) {
	return {$: 2, a: a};
};
var mdgriffith$elm_ui$Internal$Model$StyleClass = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var mdgriffith$elm_ui$Element$Font$size = function (i) {
	return A2(
		mdgriffith$elm_ui$Internal$Model$StyleClass,
		mdgriffith$elm_ui$Internal$Flag$fontSize,
		mdgriffith$elm_ui$Internal$Model$FontSize(i));
};
var author$project$InstructionView$view = F2(
	function (attributes, instruction) {
		switch (instruction.$) {
			case 0:
				return A2(mdgriffith$elm_ui$Element$el, attributes, mdgriffith$elm_ui$Element$none);
			case 1:
				switch (instruction.a) {
					case 0:
						var _n1 = instruction.a;
						return A2(
							mdgriffith$elm_ui$Element$image,
							attributes,
							{
								bq: author$project$InstructionView$description(instruction),
								bJ: 'assets/instruction-images/change-direction-left.svg'
							});
					case 1:
						var _n2 = instruction.a;
						return A2(
							mdgriffith$elm_ui$Element$image,
							attributes,
							{
								bq: author$project$InstructionView$description(instruction),
								bJ: 'assets/instruction-images/change-direction-up.svg'
							});
					case 2:
						var _n3 = instruction.a;
						return A2(
							mdgriffith$elm_ui$Element$image,
							attributes,
							{
								bq: author$project$InstructionView$description(instruction),
								bJ: 'assets/instruction-images/change-direction-right.svg'
							});
					default:
						var _n4 = instruction.a;
						return A2(
							mdgriffith$elm_ui$Element$image,
							attributes,
							{
								bq: author$project$InstructionView$description(instruction),
								bJ: 'assets/instruction-images/change-direction-down.svg'
							});
				}
			case 23:
				switch (instruction.a) {
					case 0:
						switch (instruction.b) {
							case 0:
								var _n5 = instruction.a;
								var _n6 = instruction.b;
								return A2(
									mdgriffith$elm_ui$Element$image,
									attributes,
									{
										bq: author$project$InstructionView$description(instruction),
										bJ: 'assets/instruction-images/branch-left-left.svg'
									});
							case 1:
								var _n7 = instruction.a;
								var _n8 = instruction.b;
								return A2(
									mdgriffith$elm_ui$Element$image,
									attributes,
									{
										bq: author$project$InstructionView$description(instruction),
										bJ: 'assets/instruction-images/branch-left-up.svg'
									});
							case 2:
								var _n9 = instruction.a;
								var _n10 = instruction.b;
								return A2(
									mdgriffith$elm_ui$Element$image,
									attributes,
									{
										bq: author$project$InstructionView$description(instruction),
										bJ: 'assets/instruction-images/branch-left-right.svg'
									});
							default:
								var _n11 = instruction.a;
								var _n12 = instruction.b;
								return A2(
									mdgriffith$elm_ui$Element$image,
									attributes,
									{
										bq: author$project$InstructionView$description(instruction),
										bJ: 'assets/instruction-images/branch-left-down.svg'
									});
						}
					case 1:
						switch (instruction.b) {
							case 0:
								var _n13 = instruction.a;
								var _n14 = instruction.b;
								return A2(
									mdgriffith$elm_ui$Element$image,
									attributes,
									{
										bq: author$project$InstructionView$description(instruction),
										bJ: 'assets/instruction-images/branch-up-left.svg'
									});
							case 1:
								var _n15 = instruction.a;
								var _n16 = instruction.b;
								return A2(
									mdgriffith$elm_ui$Element$image,
									attributes,
									{
										bq: author$project$InstructionView$description(instruction),
										bJ: 'assets/instruction-images/branch-up-up.svg'
									});
							case 2:
								var _n17 = instruction.a;
								var _n18 = instruction.b;
								return A2(
									mdgriffith$elm_ui$Element$image,
									attributes,
									{
										bq: author$project$InstructionView$description(instruction),
										bJ: 'assets/instruction-images/branch-up-right.svg'
									});
							default:
								var _n19 = instruction.a;
								var _n20 = instruction.b;
								return A2(
									mdgriffith$elm_ui$Element$image,
									attributes,
									{
										bq: author$project$InstructionView$description(instruction),
										bJ: 'assets/instruction-images/branch-up-down.svg'
									});
						}
					case 2:
						switch (instruction.b) {
							case 0:
								var _n21 = instruction.a;
								var _n22 = instruction.b;
								return A2(
									mdgriffith$elm_ui$Element$image,
									attributes,
									{
										bq: author$project$InstructionView$description(instruction),
										bJ: 'assets/instruction-images/branch-right-left.svg'
									});
							case 1:
								var _n23 = instruction.a;
								var _n24 = instruction.b;
								return A2(
									mdgriffith$elm_ui$Element$image,
									attributes,
									{
										bq: author$project$InstructionView$description(instruction),
										bJ: 'assets/instruction-images/branch-right-up.svg'
									});
							case 2:
								var _n25 = instruction.a;
								var _n26 = instruction.b;
								return A2(
									mdgriffith$elm_ui$Element$image,
									attributes,
									{
										bq: author$project$InstructionView$description(instruction),
										bJ: 'assets/instruction-images/branch-right-right.svg'
									});
							default:
								var _n27 = instruction.a;
								var _n28 = instruction.b;
								return A2(
									mdgriffith$elm_ui$Element$image,
									attributes,
									{
										bq: author$project$InstructionView$description(instruction),
										bJ: 'assets/instruction-images/branch-right-down.svg'
									});
						}
					default:
						switch (instruction.b) {
							case 0:
								var _n29 = instruction.a;
								var _n30 = instruction.b;
								return A2(
									mdgriffith$elm_ui$Element$image,
									attributes,
									{
										bq: author$project$InstructionView$description(instruction),
										bJ: 'assets/instruction-images/branch-down-left.svg'
									});
							case 1:
								var _n31 = instruction.a;
								var _n32 = instruction.b;
								return A2(
									mdgriffith$elm_ui$Element$image,
									attributes,
									{
										bq: author$project$InstructionView$description(instruction),
										bJ: 'assets/instruction-images/branch-down-up.svg'
									});
							case 2:
								var _n33 = instruction.a;
								var _n34 = instruction.b;
								return A2(
									mdgriffith$elm_ui$Element$image,
									attributes,
									{
										bq: author$project$InstructionView$description(instruction),
										bJ: 'assets/instruction-images/branch-down-right.svg'
									});
							default:
								var _n35 = instruction.a;
								var _n36 = instruction.b;
								return A2(
									mdgriffith$elm_ui$Element$image,
									attributes,
									{
										bq: author$project$InstructionView$description(instruction),
										bJ: 'assets/instruction-images/branch-down-down.svg'
									});
						}
				}
			case 12:
				return A2(
					mdgriffith$elm_ui$Element$image,
					attributes,
					{
						bq: author$project$InstructionView$description(instruction),
						bJ: 'assets/instruction-images/add.svg'
					});
			case 13:
				return A2(
					mdgriffith$elm_ui$Element$image,
					attributes,
					{
						bq: author$project$InstructionView$description(instruction),
						bJ: 'assets/instruction-images/subtract.svg'
					});
			case 21:
				return A2(
					mdgriffith$elm_ui$Element$image,
					attributes,
					{
						bq: author$project$InstructionView$description(instruction),
						bJ: 'assets/instruction-images/read.svg'
					});
			case 22:
				return A2(
					mdgriffith$elm_ui$Element$image,
					attributes,
					{
						bq: author$project$InstructionView$description(instruction),
						bJ: 'assets/instruction-images/print.svg'
					});
			case 5:
				return A2(
					mdgriffith$elm_ui$Element$image,
					attributes,
					{
						bq: author$project$InstructionView$description(instruction),
						bJ: 'assets/instruction-images/duplicate.svg'
					});
			case 10:
				return A2(
					mdgriffith$elm_ui$Element$image,
					attributes,
					{
						bq: author$project$InstructionView$description(instruction),
						bJ: 'assets/instruction-images/increment.svg'
					});
			case 11:
				return A2(
					mdgriffith$elm_ui$Element$image,
					attributes,
					{
						bq: author$project$InstructionView$description(instruction),
						bJ: 'assets/instruction-images/decrement.svg'
					});
			case 6:
				return A2(
					mdgriffith$elm_ui$Element$image,
					attributes,
					{
						bq: author$project$InstructionView$description(instruction),
						bJ: 'assets/instruction-images/swap.svg'
					});
			case 3:
				return A2(
					mdgriffith$elm_ui$Element$image,
					attributes,
					{
						bq: author$project$InstructionView$description(instruction),
						bJ: 'assets/instruction-images/pop-from-stack.svg'
					});
			case 2:
				var value = instruction.a;
				return A2(
					mdgriffith$elm_ui$Element$el,
					_Utils_ap(
						_List_fromArray(
							[
								mdgriffith$elm_ui$Element$Font$size(26),
								mdgriffith$elm_ui$Element$centerY
							]),
						attributes),
					mdgriffith$elm_ui$Element$text(
						author$project$Data$Int16$toString(value)));
			case 4:
				return A2(
					mdgriffith$elm_ui$Element$image,
					attributes,
					{
						bq: author$project$InstructionView$description(instruction),
						bJ: 'assets/instruction-images/jump-one-forward.svg'
					});
			case 24:
				return A2(
					mdgriffith$elm_ui$Element$image,
					attributes,
					{
						bq: author$project$InstructionView$description(instruction),
						bJ: 'assets/instruction-images/terminate.svg'
					});
			case 26:
				return A2(
					mdgriffith$elm_ui$Element$image,
					attributes,
					{
						bq: author$project$InstructionView$description(instruction),
						bJ: 'assets/instruction-images/exception.svg'
					});
			case 25:
				return A2(
					mdgriffith$elm_ui$Element$image,
					attributes,
					{
						bq: author$project$InstructionView$description(instruction),
						bJ: 'assets/instruction-images/send-to-bottom.svg'
					});
			case 17:
				return A2(
					mdgriffith$elm_ui$Element$image,
					attributes,
					{
						bq: author$project$InstructionView$description(instruction),
						bJ: 'assets/instruction-images/compare-less-than.svg'
					});
			default:
				return A2(
					mdgriffith$elm_ui$Element$el,
					_Utils_ap(
						_List_fromArray(
							[
								mdgriffith$elm_ui$Element$Font$size(26),
								mdgriffith$elm_ui$Element$centerY
							]),
						attributes),
					mdgriffith$elm_ui$Element$text('TODO'));
		}
	});
var author$project$InstructionToolView$view = F2(
	function (attributes, instructionTool) {
		switch (instructionTool.$) {
			case 0:
				var instruction = instructionTool.a;
				return A2(author$project$InstructionView$view, attributes, instruction);
			case 1:
				var direction = instructionTool.a;
				return A2(
					mdgriffith$elm_ui$Element$image,
					attributes,
					{bq: 'Change direction', bJ: 'assets/instruction-images/four-filled-arrows.svg'});
			case 2:
				var trueDirection = instructionTool.a;
				var falseDirection = instructionTool.b;
				return A2(
					mdgriffith$elm_ui$Element$image,
					attributes,
					{bq: 'Branch', bJ: 'assets/instruction-images/four-half-filled-arrows.svg'});
			case 3:
				return A2(
					mdgriffith$elm_ui$Element$el,
					_Utils_ap(
						_List_fromArray(
							[
								mdgriffith$elm_ui$Element$Font$size(26),
								mdgriffith$elm_ui$Element$centerY
							]),
						attributes),
					mdgriffith$elm_ui$Element$text('n'));
			default:
				var errorMessage = instructionTool.a;
				return A2(
					author$project$InstructionView$view,
					attributes,
					author$project$Data$Instruction$Exception(errorMessage));
		}
	});
var author$project$Page$Blueprint$ChangedHeight = function (a) {
	return {$: 1, a: a};
};
var author$project$Page$Blueprint$ChangedInput = function (a) {
	return {$: 2, a: a};
};
var author$project$Page$Blueprint$ChangedOutput = function (a) {
	return {$: 3, a: a};
};
var author$project$Page$Blueprint$ChangedWidth = function (a) {
	return {$: 0, a: a};
};
var author$project$Page$Blueprint$InitialInstructionPlaced = function (a) {
	return {$: 7, a: a};
};
var author$project$Page$Blueprint$InstructionToolEnabled = function (a) {
	return {$: 6, a: a};
};
var author$project$Page$Blueprint$InstructionToolReplaced = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var author$project$Page$Blueprint$InstructionToolSelected = function (a) {
	return {$: 4, a: a};
};
var elm$html$Html$Attributes$href = function (url) {
	return A2(
		elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var elm$html$Html$Attributes$rel = _VirtualDom_attribute('rel');
var mdgriffith$elm_ui$Element$link = F2(
	function (attrs, _n0) {
		var url = _n0.jw;
		var label = _n0.fB;
		return A4(
			mdgriffith$elm_ui$Internal$Model$element,
			mdgriffith$elm_ui$Internal$Model$asEl,
			mdgriffith$elm_ui$Internal$Model$NodeName('a'),
			A2(
				elm$core$List$cons,
				mdgriffith$elm_ui$Internal$Model$Attr(
					elm$html$Html$Attributes$href(url)),
				A2(
					elm$core$List$cons,
					mdgriffith$elm_ui$Internal$Model$Attr(
						elm$html$Html$Attributes$rel('noopener noreferrer')),
					A2(
						elm$core$List$cons,
						mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$shrink),
						A2(
							elm$core$List$cons,
							mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$shrink),
							A2(
								elm$core$List$cons,
								mdgriffith$elm_ui$Internal$Model$htmlClass(mdgriffith$elm_ui$Internal$Style$classes.cH + (' ' + mdgriffith$elm_ui$Internal$Style$classes.aI)),
								attrs))))),
			mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[label])));
	});
var author$project$Route$link = F3(
	function (attributes, label, route) {
		return A2(
			mdgriffith$elm_ui$Element$link,
			attributes,
			{
				fB: label,
				jw: author$project$Route$toString(route)
			});
	});
var mdgriffith$elm_ui$Internal$Flag$hover = mdgriffith$elm_ui$Internal$Flag$flag(33);
var mdgriffith$elm_ui$Internal$Model$Hover = 1;
var mdgriffith$elm_ui$Internal$Model$PseudoSelector = F2(
	function (a, b) {
		return {$: 11, a: a, b: b};
	});
var elm$virtual_dom$VirtualDom$mapAttribute = _VirtualDom_mapAttribute;
var mdgriffith$elm_ui$Internal$Model$AlignX = function (a) {
	return {$: 6, a: a};
};
var mdgriffith$elm_ui$Internal$Model$Class = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var mdgriffith$elm_ui$Internal$Model$Describe = function (a) {
	return {$: 2, a: a};
};
var mdgriffith$elm_ui$Internal$Model$Nearby = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var mdgriffith$elm_ui$Internal$Model$NoAttribute = {$: 0};
var mdgriffith$elm_ui$Internal$Model$TransformComponent = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var mdgriffith$elm_ui$Internal$Model$map = F2(
	function (fn, el) {
		switch (el.$) {
			case 1:
				var styled = el.a;
				return mdgriffith$elm_ui$Internal$Model$Styled(
					{
						hL: F2(
							function (add, context) {
								return A2(
									elm$virtual_dom$VirtualDom$map,
									fn,
									A2(styled.hL, add, context));
							}),
						i5: styled.i5
					});
			case 0:
				var html = el.a;
				return mdgriffith$elm_ui$Internal$Model$Unstyled(
					A2(
						elm$core$Basics$composeL,
						elm$virtual_dom$VirtualDom$map(fn),
						html));
			case 2:
				var str = el.a;
				return mdgriffith$elm_ui$Internal$Model$Text(str);
			default:
				return mdgriffith$elm_ui$Internal$Model$Empty;
		}
	});
var mdgriffith$elm_ui$Internal$Model$mapAttrFromStyle = F2(
	function (fn, attr) {
		switch (attr.$) {
			case 0:
				return mdgriffith$elm_ui$Internal$Model$NoAttribute;
			case 2:
				var description = attr.a;
				return mdgriffith$elm_ui$Internal$Model$Describe(description);
			case 6:
				var x = attr.a;
				return mdgriffith$elm_ui$Internal$Model$AlignX(x);
			case 5:
				var y = attr.a;
				return mdgriffith$elm_ui$Internal$Model$AlignY(y);
			case 7:
				var x = attr.a;
				return mdgriffith$elm_ui$Internal$Model$Width(x);
			case 8:
				var x = attr.a;
				return mdgriffith$elm_ui$Internal$Model$Height(x);
			case 3:
				var x = attr.a;
				var y = attr.b;
				return A2(mdgriffith$elm_ui$Internal$Model$Class, x, y);
			case 4:
				var flag = attr.a;
				var style = attr.b;
				return A2(mdgriffith$elm_ui$Internal$Model$StyleClass, flag, style);
			case 9:
				var location = attr.a;
				var elem = attr.b;
				return A2(
					mdgriffith$elm_ui$Internal$Model$Nearby,
					location,
					A2(mdgriffith$elm_ui$Internal$Model$map, fn, elem));
			case 1:
				var htmlAttr = attr.a;
				return mdgriffith$elm_ui$Internal$Model$Attr(
					A2(elm$virtual_dom$VirtualDom$mapAttribute, fn, htmlAttr));
			default:
				var fl = attr.a;
				var trans = attr.b;
				return A2(mdgriffith$elm_ui$Internal$Model$TransformComponent, fl, trans);
		}
	});
var mdgriffith$elm_ui$Internal$Model$removeNever = function (style) {
	return A2(mdgriffith$elm_ui$Internal$Model$mapAttrFromStyle, elm$core$Basics$never, style);
};
var mdgriffith$elm_ui$Internal$Model$unwrapDecsHelper = F2(
	function (attr, _n0) {
		var styles = _n0.a;
		var trans = _n0.b;
		var _n1 = mdgriffith$elm_ui$Internal$Model$removeNever(attr);
		switch (_n1.$) {
			case 4:
				var style = _n1.b;
				return _Utils_Tuple2(
					A2(elm$core$List$cons, style, styles),
					trans);
			case 10:
				var flag = _n1.a;
				var component = _n1.b;
				return _Utils_Tuple2(
					styles,
					A2(mdgriffith$elm_ui$Internal$Model$composeTransformation, trans, component));
			default:
				return _Utils_Tuple2(styles, trans);
		}
	});
var mdgriffith$elm_ui$Internal$Model$unwrapDecorations = function (attrs) {
	var _n0 = A3(
		elm$core$List$foldl,
		mdgriffith$elm_ui$Internal$Model$unwrapDecsHelper,
		_Utils_Tuple2(_List_Nil, mdgriffith$elm_ui$Internal$Model$Untransformed),
		attrs);
	var styles = _n0.a;
	var transform = _n0.b;
	return A2(
		elm$core$List$cons,
		mdgriffith$elm_ui$Internal$Model$Transform(transform),
		styles);
};
var mdgriffith$elm_ui$Element$mouseOver = function (decs) {
	return A2(
		mdgriffith$elm_ui$Internal$Model$StyleClass,
		mdgriffith$elm_ui$Internal$Flag$hover,
		A2(
			mdgriffith$elm_ui$Internal$Model$PseudoSelector,
			1,
			mdgriffith$elm_ui$Internal$Model$unwrapDecorations(decs)));
};
var mdgriffith$elm_ui$Internal$Flag$padding = mdgriffith$elm_ui$Internal$Flag$flag(2);
var mdgriffith$elm_ui$Internal$Model$PaddingStyle = F5(
	function (a, b, c, d, e) {
		return {$: 7, a: a, b: b, c: c, d: d, e: e};
	});
var mdgriffith$elm_ui$Element$padding = function (x) {
	return A2(
		mdgriffith$elm_ui$Internal$Model$StyleClass,
		mdgriffith$elm_ui$Internal$Flag$padding,
		A5(
			mdgriffith$elm_ui$Internal$Model$PaddingStyle,
			'p-' + elm$core$String$fromInt(x),
			x,
			x,
			x,
			x));
};
var mdgriffith$elm_ui$Internal$Model$Px = function (a) {
	return {$: 0, a: a};
};
var mdgriffith$elm_ui$Element$px = mdgriffith$elm_ui$Internal$Model$Px;
var mdgriffith$elm_ui$Internal$Model$Rgba = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var mdgriffith$elm_ui$Element$rgb = F3(
	function (r, g, b) {
		return A4(mdgriffith$elm_ui$Internal$Model$Rgba, r, g, b, 1);
	});
var mdgriffith$elm_ui$Internal$Flag$bgColor = mdgriffith$elm_ui$Internal$Flag$flag(8);
var mdgriffith$elm_ui$Internal$Model$Colored = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var mdgriffith$elm_ui$Internal$Model$formatColorClass = function (_n0) {
	var red = _n0.a;
	var green = _n0.b;
	var blue = _n0.c;
	var alpha = _n0.d;
	return mdgriffith$elm_ui$Internal$Model$floatClass(red) + ('-' + (mdgriffith$elm_ui$Internal$Model$floatClass(green) + ('-' + (mdgriffith$elm_ui$Internal$Model$floatClass(blue) + ('-' + mdgriffith$elm_ui$Internal$Model$floatClass(alpha))))));
};
var mdgriffith$elm_ui$Element$Background$color = function (clr) {
	return A2(
		mdgriffith$elm_ui$Internal$Model$StyleClass,
		mdgriffith$elm_ui$Internal$Flag$bgColor,
		A3(
			mdgriffith$elm_ui$Internal$Model$Colored,
			'bg-' + mdgriffith$elm_ui$Internal$Model$formatColorClass(clr),
			'background-color',
			clr));
};
var mdgriffith$elm_ui$Internal$Flag$borderColor = mdgriffith$elm_ui$Internal$Flag$flag(28);
var mdgriffith$elm_ui$Element$Border$color = function (clr) {
	return A2(
		mdgriffith$elm_ui$Internal$Model$StyleClass,
		mdgriffith$elm_ui$Internal$Flag$borderColor,
		A3(
			mdgriffith$elm_ui$Internal$Model$Colored,
			'bc-' + mdgriffith$elm_ui$Internal$Model$formatColorClass(clr),
			'border-color',
			clr));
};
var mdgriffith$elm_ui$Internal$Model$BorderWidth = F5(
	function (a, b, c, d, e) {
		return {$: 6, a: a, b: b, c: c, d: d, e: e};
	});
var mdgriffith$elm_ui$Element$Border$width = function (v) {
	return A2(
		mdgriffith$elm_ui$Internal$Model$StyleClass,
		mdgriffith$elm_ui$Internal$Flag$borderWidth,
		A5(
			mdgriffith$elm_ui$Internal$Model$BorderWidth,
			'b-' + elm$core$String$fromInt(v),
			v,
			v,
			v,
			v));
};
var mdgriffith$elm_ui$Internal$Flag$fontAlignment = mdgriffith$elm_ui$Internal$Flag$flag(12);
var mdgriffith$elm_ui$Element$Font$center = A2(mdgriffith$elm_ui$Internal$Model$Class, mdgriffith$elm_ui$Internal$Flag$fontAlignment, mdgriffith$elm_ui$Internal$Style$classes.ja);
var elm$json$Json$Encode$bool = _Json_wrap;
var elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			elm$json$Json$Encode$bool(bool));
	});
var elm$html$Html$Attributes$disabled = elm$html$Html$Attributes$boolProperty('disabled');
var elm$html$Html$Attributes$tabindex = function (n) {
	return A2(
		_VirtualDom_attribute,
		'tabIndex',
		elm$core$String$fromInt(n));
};
var mdgriffith$elm_ui$Internal$Flag$cursor = mdgriffith$elm_ui$Internal$Flag$flag(21);
var mdgriffith$elm_ui$Element$pointer = A2(mdgriffith$elm_ui$Internal$Model$Class, mdgriffith$elm_ui$Internal$Flag$cursor, mdgriffith$elm_ui$Internal$Style$classes.hg);
var elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			elm$virtual_dom$VirtualDom$on,
			event,
			elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var elm$html$Html$Events$onClick = function (msg) {
	return A2(
		elm$html$Html$Events$on,
		'click',
		elm$json$Json$Decode$succeed(msg));
};
var mdgriffith$elm_ui$Element$Events$onClick = A2(elm$core$Basics$composeL, mdgriffith$elm_ui$Internal$Model$Attr, elm$html$Html$Events$onClick);
var mdgriffith$elm_ui$Element$Input$hasFocusStyle = function (attr) {
	if (((attr.$ === 4) && (attr.b.$ === 11)) && (!attr.b.a)) {
		var _n1 = attr.b;
		var _n2 = _n1.a;
		return true;
	} else {
		return false;
	}
};
var mdgriffith$elm_ui$Element$Input$focusDefault = function (attrs) {
	return A2(elm$core$List$any, mdgriffith$elm_ui$Element$Input$hasFocusStyle, attrs) ? mdgriffith$elm_ui$Internal$Model$NoAttribute : mdgriffith$elm_ui$Internal$Model$htmlClass('focusable');
};
var mdgriffith$elm_ui$Element$Input$enter = 'Enter';
var elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			elm$virtual_dom$VirtualDom$on,
			event,
			elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var mdgriffith$elm_ui$Element$Input$onKey = F2(
	function (desiredCode, msg) {
		var decode = function (code) {
			return _Utils_eq(code, desiredCode) ? elm$json$Json$Decode$succeed(msg) : elm$json$Json$Decode$fail('Not the enter key');
		};
		var isKey = A2(
			elm$json$Json$Decode$andThen,
			decode,
			A2(elm$json$Json$Decode$field, 'key', elm$json$Json$Decode$string));
		return mdgriffith$elm_ui$Internal$Model$Attr(
			A2(
				elm$html$Html$Events$preventDefaultOn,
				'keyup',
				A2(
					elm$json$Json$Decode$map,
					function (fired) {
						return _Utils_Tuple2(fired, true);
					},
					isKey)));
	});
var mdgriffith$elm_ui$Element$Input$onEnter = function (msg) {
	return A2(mdgriffith$elm_ui$Element$Input$onKey, mdgriffith$elm_ui$Element$Input$enter, msg);
};
var mdgriffith$elm_ui$Internal$Model$Button = {$: 8};
var mdgriffith$elm_ui$Element$Input$button = F2(
	function (attrs, _n0) {
		var onPress = _n0.il;
		var label = _n0.fB;
		return A4(
			mdgriffith$elm_ui$Internal$Model$element,
			mdgriffith$elm_ui$Internal$Model$asEl,
			mdgriffith$elm_ui$Internal$Model$div,
			A2(
				elm$core$List$cons,
				mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$shrink),
				A2(
					elm$core$List$cons,
					mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$shrink),
					A2(
						elm$core$List$cons,
						mdgriffith$elm_ui$Internal$Model$htmlClass(mdgriffith$elm_ui$Internal$Style$classes.cH + (' ' + (mdgriffith$elm_ui$Internal$Style$classes.aI + (' ' + (mdgriffith$elm_ui$Internal$Style$classes.iP + (' ' + mdgriffith$elm_ui$Internal$Style$classes.fN)))))),
						A2(
							elm$core$List$cons,
							mdgriffith$elm_ui$Element$pointer,
							A2(
								elm$core$List$cons,
								mdgriffith$elm_ui$Element$Input$focusDefault(attrs),
								A2(
									elm$core$List$cons,
									mdgriffith$elm_ui$Internal$Model$Describe(mdgriffith$elm_ui$Internal$Model$Button),
									A2(
										elm$core$List$cons,
										mdgriffith$elm_ui$Internal$Model$Attr(
											elm$html$Html$Attributes$tabindex(0)),
										function () {
											if (onPress.$ === 1) {
												return A2(
													elm$core$List$cons,
													mdgriffith$elm_ui$Internal$Model$Attr(
														elm$html$Html$Attributes$disabled(true)),
													attrs);
											} else {
												var msg = onPress.a;
												return A2(
													elm$core$List$cons,
													mdgriffith$elm_ui$Element$Events$onClick(msg),
													A2(
														elm$core$List$cons,
														mdgriffith$elm_ui$Element$Input$onEnter(msg),
														attrs));
											}
										}()))))))),
			mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[label])));
	});
var author$project$ViewComponents$imageButton = F3(
	function (attributes, onPress, image) {
		return A2(
			mdgriffith$elm_ui$Element$Input$button,
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$Border$width(3),
					mdgriffith$elm_ui$Element$Border$color(
					A3(mdgriffith$elm_ui$Element$rgb, 1, 1, 1))
				]),
			{
				fB: A2(
					mdgriffith$elm_ui$Element$el,
					elm$core$List$concat(
						_List_fromArray(
							[
								_List_fromArray(
								[
									mdgriffith$elm_ui$Element$width(
									mdgriffith$elm_ui$Element$px(100)),
									mdgriffith$elm_ui$Element$height(
									mdgriffith$elm_ui$Element$px(100)),
									mdgriffith$elm_ui$Element$Font$center,
									mdgriffith$elm_ui$Element$padding(10),
									mdgriffith$elm_ui$Element$mouseOver(
									_List_fromArray(
										[
											mdgriffith$elm_ui$Element$Background$color(
											A3(mdgriffith$elm_ui$Element$rgb, 0.5, 0.5, 0.5))
										]))
								]),
								attributes
							])),
					image),
				il: onPress
			});
	});
var mdgriffith$elm_ui$Internal$Model$Fill = function (a) {
	return {$: 2, a: a};
};
var mdgriffith$elm_ui$Element$fill = mdgriffith$elm_ui$Internal$Model$Fill(1);
var mdgriffith$elm_ui$Element$rgba = mdgriffith$elm_ui$Internal$Model$Rgba;
var author$project$ViewComponents$instructionButton = F3(
	function (attributes, onPress, instruction) {
		var attrs2 = function () {
			if (instruction.$ === 26) {
				return _List_fromArray(
					[
						mdgriffith$elm_ui$Element$Background$color(
						A4(mdgriffith$elm_ui$Element$rgba, 1, 0, 0, 0.1))
					]);
			} else {
				return _List_Nil;
			}
		}();
		return A3(
			author$project$ViewComponents$imageButton,
			elm$core$List$concat(
				_List_fromArray(
					[attrs2, attributes])),
			onPress,
			A2(
				author$project$InstructionView$view,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
						mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill)
					]),
				instruction));
	});
var elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var elm$html$Html$Attributes$style = elm$virtual_dom$VirtualDom$style;
var mdgriffith$elm_ui$Element$htmlAttribute = mdgriffith$elm_ui$Internal$Model$Attr;
var author$project$View$Instruction$view = function (params) {
	var onPress = params.hn ? elm$core$Maybe$Nothing : params.ij;
	var attributes = function () {
		if (params.hn) {
			var backgroundColor = function () {
				var _n0 = params.cU;
				if (_n0.$ === 26) {
					return A3(mdgriffith$elm_ui$Element$rgb, 0.1, 0, 0);
				} else {
					return A3(mdgriffith$elm_ui$Element$rgb, 0.15, 0.15, 0.15);
				}
			}();
			return _List_fromArray(
				[
					mdgriffith$elm_ui$Element$Background$color(backgroundColor),
					mdgriffith$elm_ui$Element$htmlAttribute(
					A2(elm$html$Html$Attributes$style, 'cursor', 'not-allowed')),
					mdgriffith$elm_ui$Element$mouseOver(_List_Nil)
				]);
		} else {
			return _List_Nil;
		}
	}();
	return A3(author$project$ViewComponents$instructionButton, attributes, onPress, params.cU);
};
var mdgriffith$elm_ui$Internal$Model$AsColumn = 1;
var mdgriffith$elm_ui$Internal$Model$asColumn = 1;
var mdgriffith$elm_ui$Element$column = F2(
	function (attrs, children) {
		return A4(
			mdgriffith$elm_ui$Internal$Model$element,
			mdgriffith$elm_ui$Internal$Model$asColumn,
			mdgriffith$elm_ui$Internal$Model$div,
			A2(
				elm$core$List$cons,
				mdgriffith$elm_ui$Internal$Model$htmlClass(mdgriffith$elm_ui$Internal$Style$classes.he + (' ' + mdgriffith$elm_ui$Internal$Style$classes.bX)),
				A2(
					elm$core$List$cons,
					mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$shrink),
					A2(
						elm$core$List$cons,
						mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$shrink),
						attrs))),
			mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var mdgriffith$elm_ui$Internal$Model$AsRow = 0;
var mdgriffith$elm_ui$Internal$Model$asRow = 0;
var mdgriffith$elm_ui$Element$row = F2(
	function (attrs, children) {
		return A4(
			mdgriffith$elm_ui$Internal$Model$element,
			mdgriffith$elm_ui$Internal$Model$asRow,
			mdgriffith$elm_ui$Internal$Model$div,
			A2(
				elm$core$List$cons,
				mdgriffith$elm_ui$Internal$Model$htmlClass(mdgriffith$elm_ui$Internal$Style$classes.bX + (' ' + mdgriffith$elm_ui$Internal$Style$classes.aI)),
				A2(
					elm$core$List$cons,
					mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$shrink),
					A2(
						elm$core$List$cons,
						mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$shrink),
						attrs))),
			mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var mdgriffith$elm_ui$Internal$Flag$overflow = mdgriffith$elm_ui$Internal$Flag$flag(20);
var mdgriffith$elm_ui$Element$scrollbars = A2(mdgriffith$elm_ui$Internal$Model$Class, mdgriffith$elm_ui$Internal$Flag$overflow, mdgriffith$elm_ui$Internal$Style$classes.iL);
var mdgriffith$elm_ui$Internal$Flag$spacing = mdgriffith$elm_ui$Internal$Flag$flag(3);
var mdgriffith$elm_ui$Internal$Model$SpacingStyle = F3(
	function (a, b, c) {
		return {$: 5, a: a, b: b, c: c};
	});
var mdgriffith$elm_ui$Internal$Model$spacingName = F2(
	function (x, y) {
		return 'spacing-' + (elm$core$String$fromInt(x) + ('-' + elm$core$String$fromInt(y)));
	});
var mdgriffith$elm_ui$Element$spacing = function (x) {
	return A2(
		mdgriffith$elm_ui$Internal$Model$StyleClass,
		mdgriffith$elm_ui$Internal$Flag$spacing,
		A3(
			mdgriffith$elm_ui$Internal$Model$SpacingStyle,
			A2(mdgriffith$elm_ui$Internal$Model$spacingName, x, x),
			x,
			x));
};
var author$project$View$Board$view = function (params) {
	var disabledSet = elm$core$Set$fromList(
		A2(
			elm$core$List$map,
			function (_n0) {
				var x = _n0.eR;
				var y = _n0.eS;
				return _Utils_Tuple2(x, y);
			},
			params.ho));
	var viewRow = F2(
		function (rowIndex, boardRow) {
			var viewCell = F2(
				function (columnIndex, instruction) {
					var position = {eR: columnIndex, eS: rowIndex};
					var indicated = A2(
						elm$core$Maybe$withDefault,
						false,
						A2(
							elm$core$Maybe$map,
							elm$core$Basics$eq(position),
							params.iT));
					var disabled = A2(
						elm$core$Set$member,
						_Utils_Tuple2(position.eR, position.eS),
						disabledSet);
					var boardInstruction = {cU: instruction, ep: position};
					var onClick = A2(
						elm$core$Maybe$map,
						elm$core$Basics$apR(boardInstruction),
						params.ij);
					return author$project$View$Instruction$view(
						{hn: disabled, hR: indicated, cU: instruction, ij: onClick});
				});
			return A2(
				mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$spacing(10)
					]),
				elm$core$Array$toList(
					A2(elm$core$Array$indexedMap, viewCell, boardRow)));
		});
	return A2(
		mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				mdgriffith$elm_ui$Element$spacing(10),
				mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
				mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill),
				mdgriffith$elm_ui$Element$padding(10),
				mdgriffith$elm_ui$Element$scrollbars
			]),
		elm$core$Array$toList(
			A2(elm$core$Array$indexedMap, viewRow, params.cy)));
};
var author$project$Data$User$getUserInfo = function (user) {
	return user.cu;
};
var author$project$Data$UserInfo$getUserName = function (userInfo) {
	return A2(
		elm$core$Maybe$withDefault,
		userInfo.dh,
		A2(
			elm_community$maybe_extra$Maybe$Extra$orElse,
			userInfo.c5,
			A2(
				elm_community$maybe_extra$Maybe$Extra$orElse,
				userInfo.cP,
				A2(elm_community$maybe_extra$Maybe$Extra$orElse, userInfo.cQ, userInfo.bA))));
};
var author$project$View$Header$parentRoute = function (session) {
	var _n0 = author$project$Route$fromUrl(session.jw);
	if (!_n0.$) {
		switch (_n0.a.$) {
			case 0:
				var _n1 = _n0.a;
				return elm$core$Maybe$Nothing;
			case 1:
				var _n2 = _n0.a;
				return elm$core$Maybe$Just(author$project$Route$Campaigns);
			case 2:
				var _n3 = _n0.a;
				return elm$core$Maybe$Just(author$project$Route$Home);
			case 3:
				var draftId = _n0.a.a;
				var _n4 = A2(
					elm$core$Maybe$andThen,
					krisajenkins$remotedata$RemoteData$toMaybe,
					A2(
						elm$core$Maybe$map,
						A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Data$Cache$get, session.as),
						A2(
							elm$core$Maybe$map,
							function ($) {
								return $.a8;
							},
							elm_community$maybe_extra$Maybe$Extra$join(
								krisajenkins$remotedata$RemoteData$toMaybe(
									A2(author$project$Data$Cache$get, draftId, session.dC.aj))))));
				if (!_n4.$) {
					var level = _n4.a;
					return elm$core$Maybe$Just(
						A2(
							author$project$Route$Campaign,
							level.bW,
							elm$core$Maybe$Just(level.b6)));
				} else {
					return elm$core$Maybe$Just(author$project$Route$Home);
				}
			case 4:
				var draftId = _n0.a.a;
				return elm$core$Maybe$Just(
					author$project$Route$EditDraft(draftId));
			case 5:
				return elm$core$Maybe$Just(author$project$Route$Home);
			case 6:
				var levelId = _n0.a.a;
				return elm$core$Maybe$Just(
					A2(
						author$project$Route$Campaign,
						author$project$Data$CampaignId$blueprints,
						elm$core$Maybe$Just(levelId)));
			case 7:
				var _n5 = _n0.a;
				return elm$core$Maybe$Just(author$project$Route$Home);
			default:
				var _n6 = _n0.a;
				return elm$core$Maybe$Just(author$project$Route$Home);
		}
	} else {
		return elm$core$Maybe$Just(author$project$Route$Home);
	}
};
var mdgriffith$elm_ui$Internal$Model$Left = 0;
var mdgriffith$elm_ui$Element$alignLeft = mdgriffith$elm_ui$Internal$Model$AlignX(0);
var mdgriffith$elm_ui$Internal$Model$Right = 2;
var mdgriffith$elm_ui$Element$alignRight = mdgriffith$elm_ui$Internal$Model$AlignX(2);
var author$project$View$Header$view = function (session) {
	var online = elm_community$maybe_extra$Maybe$Extra$isJust(
		author$project$Data$User$getToken(session.bQ));
	var userInfo = A2(
		mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				mdgriffith$elm_ui$Element$alignRight,
				mdgriffith$elm_ui$Element$padding(20)
			]),
		mdgriffith$elm_ui$Element$text(
			A2(
				elm$core$Maybe$withDefault,
				'Guest',
				A2(
					elm$core$Maybe$map,
					function (userName) {
						return online ? userName : (userName + ' (offline)');
					},
					A2(
						elm$core$Maybe$map,
						author$project$Data$UserInfo$getUserName,
						author$project$Data$User$getUserInfo(session.bQ))))));
	var loginButton = A2(
		mdgriffith$elm_ui$Element$link,
		_List_fromArray(
			[
				mdgriffith$elm_ui$Element$alignRight,
				mdgriffith$elm_ui$Element$padding(20),
				mdgriffith$elm_ui$Element$mouseOver(
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$Background$color(
						A3(mdgriffith$elm_ui$Element$rgb, 0.5, 0.5, 0.5))
					]))
			]),
		online ? {
			fB: mdgriffith$elm_ui$Element$text('Sign out'),
			jw: author$project$Api$Auth0$logout
		} : {
			fB: mdgriffith$elm_ui$Element$text('Sign in'),
			jw: author$project$Api$Auth0$login(
				elm$core$Maybe$Just(session.jw))
		});
	var backButton = function () {
		var _n0 = author$project$View$Header$parentRoute(session);
		if (!_n0.$) {
			var route = _n0.a;
			return A3(
				author$project$Route$link,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$alignLeft,
						mdgriffith$elm_ui$Element$padding(20),
						mdgriffith$elm_ui$Element$mouseOver(
						_List_fromArray(
							[
								mdgriffith$elm_ui$Element$Background$color(
								A3(mdgriffith$elm_ui$Element$rgb, 0.5, 0.5, 0.5))
							]))
					]),
				mdgriffith$elm_ui$Element$text('< Back'),
				route);
		} else {
			return mdgriffith$elm_ui$Element$none;
		}
	}();
	return A2(
		mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
				mdgriffith$elm_ui$Element$Background$color(
				A3(mdgriffith$elm_ui$Element$rgb, 0.1, 0.1, 0.1))
			]),
		_List_fromArray(
			[backButton, userInfo, loginButton]));
};
var mdgriffith$elm_ui$Element$Input$Above = 2;
var mdgriffith$elm_ui$Element$Input$Label = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var mdgriffith$elm_ui$Element$Input$labelAbove = mdgriffith$elm_ui$Element$Input$Label(2);
var mdgriffith$elm_ui$Element$Input$Placeholder = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var mdgriffith$elm_ui$Element$Input$placeholder = mdgriffith$elm_ui$Element$Input$Placeholder;
var mdgriffith$elm_ui$Element$Input$TextInputNode = function (a) {
	return {$: 0, a: a};
};
var elm$html$Html$Attributes$type_ = elm$html$Html$Attributes$stringProperty('type');
var elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			elm$virtual_dom$VirtualDom$on,
			event,
			elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3(elm$core$List$foldr, elm$json$Json$Decode$field, decoder, fields);
	});
var elm$html$Html$Events$targetValue = A2(
	elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	elm$json$Json$Decode$string);
var elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			elm$json$Json$Decode$map,
			elm$html$Html$Events$alwaysStop,
			A2(elm$json$Json$Decode$map, tagger, elm$html$Html$Events$targetValue)));
};
var mdgriffith$elm_ui$Internal$Flag$transparency = mdgriffith$elm_ui$Internal$Flag$flag(0);
var mdgriffith$elm_ui$Internal$Model$Transparency = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var mdgriffith$elm_ui$Element$alpha = function (o) {
	var transparency = function (x) {
		return 1 - x;
	}(
		A2(
			elm$core$Basics$min,
			1.0,
			A2(elm$core$Basics$max, 0.0, o)));
	return A2(
		mdgriffith$elm_ui$Internal$Model$StyleClass,
		mdgriffith$elm_ui$Internal$Flag$transparency,
		A2(
			mdgriffith$elm_ui$Internal$Model$Transparency,
			'transparency-' + mdgriffith$elm_ui$Internal$Model$floatClass(transparency),
			transparency));
};
var mdgriffith$elm_ui$Internal$Model$InFront = 4;
var mdgriffith$elm_ui$Element$inFront = function (element) {
	return A2(mdgriffith$elm_ui$Internal$Model$Nearby, 4, element);
};
var mdgriffith$elm_ui$Internal$Model$paddingName = F4(
	function (top, right, bottom, left) {
		return 'pad-' + (elm$core$String$fromInt(top) + ('-' + (elm$core$String$fromInt(right) + ('-' + (elm$core$String$fromInt(bottom) + ('-' + elm$core$String$fromInt(left)))))));
	});
var mdgriffith$elm_ui$Element$paddingEach = function (_n0) {
	var top = _n0.go;
	var right = _n0.f5;
	var bottom = _n0.e$;
	var left = _n0.fD;
	return (_Utils_eq(top, right) && (_Utils_eq(top, bottom) && _Utils_eq(top, left))) ? A2(
		mdgriffith$elm_ui$Internal$Model$StyleClass,
		mdgriffith$elm_ui$Internal$Flag$padding,
		A5(
			mdgriffith$elm_ui$Internal$Model$PaddingStyle,
			'p-' + elm$core$String$fromInt(top),
			top,
			top,
			top,
			top)) : A2(
		mdgriffith$elm_ui$Internal$Model$StyleClass,
		mdgriffith$elm_ui$Internal$Flag$padding,
		A5(
			mdgriffith$elm_ui$Internal$Model$PaddingStyle,
			A4(mdgriffith$elm_ui$Internal$Model$paddingName, top, right, bottom, left),
			top,
			right,
			bottom,
			left));
};
var mdgriffith$elm_ui$Internal$Flag$fontColor = mdgriffith$elm_ui$Internal$Flag$flag(14);
var mdgriffith$elm_ui$Element$Font$color = function (fontColor) {
	return A2(
		mdgriffith$elm_ui$Internal$Model$StyleClass,
		mdgriffith$elm_ui$Internal$Flag$fontColor,
		A3(
			mdgriffith$elm_ui$Internal$Model$Colored,
			'fc-' + mdgriffith$elm_ui$Internal$Model$formatColorClass(fontColor),
			'color',
			fontColor));
};
var mdgriffith$elm_ui$Element$Input$Padding = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var mdgriffith$elm_ui$Element$Input$applyLabel = F3(
	function (attrs, label, input) {
		if (label.$ === 1) {
			var labelText = label.a;
			return A4(
				mdgriffith$elm_ui$Internal$Model$element,
				mdgriffith$elm_ui$Internal$Model$asColumn,
				mdgriffith$elm_ui$Internal$Model$NodeName('label'),
				attrs,
				mdgriffith$elm_ui$Internal$Model$Unkeyed(
					_List_fromArray(
						[input])));
		} else {
			var position = label.a;
			var labelAttrs = label.b;
			var labelChild = label.c;
			var labelElement = A4(
				mdgriffith$elm_ui$Internal$Model$element,
				mdgriffith$elm_ui$Internal$Model$asEl,
				mdgriffith$elm_ui$Internal$Model$div,
				labelAttrs,
				mdgriffith$elm_ui$Internal$Model$Unkeyed(
					_List_fromArray(
						[labelChild])));
			switch (position) {
				case 2:
					return A4(
						mdgriffith$elm_ui$Internal$Model$element,
						mdgriffith$elm_ui$Internal$Model$asColumn,
						mdgriffith$elm_ui$Internal$Model$NodeName('label'),
						attrs,
						mdgriffith$elm_ui$Internal$Model$Unkeyed(
							_List_fromArray(
								[labelElement, input])));
				case 3:
					return A4(
						mdgriffith$elm_ui$Internal$Model$element,
						mdgriffith$elm_ui$Internal$Model$asColumn,
						mdgriffith$elm_ui$Internal$Model$NodeName('label'),
						attrs,
						mdgriffith$elm_ui$Internal$Model$Unkeyed(
							_List_fromArray(
								[input, labelElement])));
				case 0:
					return A4(
						mdgriffith$elm_ui$Internal$Model$element,
						mdgriffith$elm_ui$Internal$Model$asRow,
						mdgriffith$elm_ui$Internal$Model$NodeName('label'),
						attrs,
						mdgriffith$elm_ui$Internal$Model$Unkeyed(
							_List_fromArray(
								[input, labelElement])));
				default:
					return A4(
						mdgriffith$elm_ui$Internal$Model$element,
						mdgriffith$elm_ui$Internal$Model$asRow,
						mdgriffith$elm_ui$Internal$Model$NodeName('label'),
						attrs,
						mdgriffith$elm_ui$Internal$Model$Unkeyed(
							_List_fromArray(
								[labelElement, input])));
			}
		}
	});
var elm$html$Html$Attributes$attribute = elm$virtual_dom$VirtualDom$attribute;
var mdgriffith$elm_ui$Element$Input$autofill = A2(
	elm$core$Basics$composeL,
	mdgriffith$elm_ui$Internal$Model$Attr,
	elm$html$Html$Attributes$attribute('autocomplete'));
var mdgriffith$elm_ui$Element$Input$charcoal = A3(mdgriffith$elm_ui$Element$rgb, 136 / 255, 138 / 255, 133 / 255);
var mdgriffith$elm_ui$Internal$Flag$borderRound = mdgriffith$elm_ui$Internal$Flag$flag(17);
var mdgriffith$elm_ui$Element$Border$rounded = function (radius) {
	return A2(
		mdgriffith$elm_ui$Internal$Model$StyleClass,
		mdgriffith$elm_ui$Internal$Flag$borderRound,
		A3(
			mdgriffith$elm_ui$Internal$Model$Single,
			'br-' + elm$core$String$fromInt(radius),
			'border-radius',
			elm$core$String$fromInt(radius) + 'px'));
};
var mdgriffith$elm_ui$Element$Input$darkGrey = A3(mdgriffith$elm_ui$Element$rgb, 186 / 255, 189 / 255, 182 / 255);
var mdgriffith$elm_ui$Element$paddingXY = F2(
	function (x, y) {
		return _Utils_eq(x, y) ? A2(
			mdgriffith$elm_ui$Internal$Model$StyleClass,
			mdgriffith$elm_ui$Internal$Flag$padding,
			A5(
				mdgriffith$elm_ui$Internal$Model$PaddingStyle,
				'p-' + elm$core$String$fromInt(x),
				x,
				x,
				x,
				x)) : A2(
			mdgriffith$elm_ui$Internal$Model$StyleClass,
			mdgriffith$elm_ui$Internal$Flag$padding,
			A5(
				mdgriffith$elm_ui$Internal$Model$PaddingStyle,
				'p-' + (elm$core$String$fromInt(x) + ('-' + elm$core$String$fromInt(y))),
				y,
				x,
				y,
				x));
	});
var mdgriffith$elm_ui$Element$Input$defaultTextPadding = A2(mdgriffith$elm_ui$Element$paddingXY, 12, 12);
var mdgriffith$elm_ui$Element$Input$white = A3(mdgriffith$elm_ui$Element$rgb, 1, 1, 1);
var mdgriffith$elm_ui$Element$Input$defaultTextBoxStyle = _List_fromArray(
	[
		mdgriffith$elm_ui$Element$Input$defaultTextPadding,
		mdgriffith$elm_ui$Element$Border$rounded(3),
		mdgriffith$elm_ui$Element$Border$color(mdgriffith$elm_ui$Element$Input$darkGrey),
		mdgriffith$elm_ui$Element$Background$color(mdgriffith$elm_ui$Element$Input$white),
		mdgriffith$elm_ui$Element$Border$width(1),
		mdgriffith$elm_ui$Element$spacing(3),
		mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill)
	]);
var mdgriffith$elm_ui$Internal$Model$Label = function (a) {
	return {$: 5, a: a};
};
var mdgriffith$elm_ui$Element$Input$hiddenLabelAttribute = function (label) {
	if (label.$ === 1) {
		var textLabel = label.a;
		return mdgriffith$elm_ui$Internal$Model$Describe(
			mdgriffith$elm_ui$Internal$Model$Label(textLabel));
	} else {
		return mdgriffith$elm_ui$Internal$Model$NoAttribute;
	}
};
var mdgriffith$elm_ui$Element$Input$inheritablePlaceholderAttributes = function (attr) {
	_n0$3:
	while (true) {
		if (attr.$ === 4) {
			switch (attr.b.$) {
				case 7:
					var _n1 = attr.b;
					return true;
				case 6:
					var _n2 = attr.b;
					return true;
				case 10:
					return true;
				default:
					break _n0$3;
			}
		} else {
			break _n0$3;
		}
	}
	return false;
};
var mdgriffith$elm_ui$Element$Input$isHiddenLabel = function (label) {
	if (label.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var elm$html$Html$Attributes$spellcheck = elm$html$Html$Attributes$boolProperty('spellcheck');
var mdgriffith$elm_ui$Element$Input$spellcheck = A2(elm$core$Basics$composeL, mdgriffith$elm_ui$Internal$Model$Attr, elm$html$Html$Attributes$spellcheck);
var mdgriffith$elm_ui$Internal$Flag$heightTextAreaContent = mdgriffith$elm_ui$Internal$Flag$flag(47);
var mdgriffith$elm_ui$Element$Input$textHeightContent = F4(
	function (textValue, spacing, maybePadding, maybeBorder) {
		var topBottom = function (_n0) {
			var t = _n0.a;
			var b = _n0.c;
			return t + b;
		};
		var newlineCount = function (x) {
			return (x < 1) ? 1 : x;
		}(
			elm$core$List$length(
				elm$core$String$lines(textValue)));
		var additionalSpacing = (((newlineCount - 1) * spacing) + A2(
			elm$core$Maybe$withDefault,
			0,
			A2(elm$core$Maybe$map, topBottom, maybePadding))) + A2(
			elm$core$Maybe$withDefault,
			0,
			A2(elm$core$Maybe$map, topBottom, maybeBorder));
		var heightValue = function (count) {
			return 'calc(' + (elm$core$String$fromInt(count) + ('em + ' + (elm$core$String$fromInt(additionalSpacing) + 'px) !important')));
		};
		return A2(
			mdgriffith$elm_ui$Internal$Model$StyleClass,
			mdgriffith$elm_ui$Internal$Flag$heightTextAreaContent,
			A3(
				mdgriffith$elm_ui$Internal$Model$Single,
				'textarea-height-' + elm$core$String$fromInt(newlineCount),
				'height',
				heightValue(newlineCount)));
	});
var elm$html$Html$Attributes$value = elm$html$Html$Attributes$stringProperty('value');
var mdgriffith$elm_ui$Element$Input$value = A2(elm$core$Basics$composeL, mdgriffith$elm_ui$Internal$Model$Attr, elm$html$Html$Attributes$value);
var mdgriffith$elm_ui$Internal$Model$LivePolite = {$: 6};
var mdgriffith$elm_ui$Element$Region$announce = mdgriffith$elm_ui$Internal$Model$Describe(mdgriffith$elm_ui$Internal$Model$LivePolite);
var mdgriffith$elm_ui$Internal$Model$filter = function (attrs) {
	return A3(
		elm$core$List$foldr,
		F2(
			function (x, _n0) {
				var found = _n0.a;
				var has = _n0.b;
				switch (x.$) {
					case 0:
						return _Utils_Tuple2(found, has);
					case 3:
						var key = x.a;
						return _Utils_Tuple2(
							A2(elm$core$List$cons, x, found),
							has);
					case 1:
						var attr = x.a;
						return _Utils_Tuple2(
							A2(elm$core$List$cons, x, found),
							has);
					case 4:
						var style = x.b;
						return _Utils_Tuple2(
							A2(elm$core$List$cons, x, found),
							has);
					case 7:
						var width = x.a;
						return A2(elm$core$Set$member, 'width', has) ? _Utils_Tuple2(found, has) : _Utils_Tuple2(
							A2(elm$core$List$cons, x, found),
							A2(elm$core$Set$insert, 'width', has));
					case 8:
						var height = x.a;
						return A2(elm$core$Set$member, 'height', has) ? _Utils_Tuple2(found, has) : _Utils_Tuple2(
							A2(elm$core$List$cons, x, found),
							A2(elm$core$Set$insert, 'height', has));
					case 2:
						var description = x.a;
						return A2(elm$core$Set$member, 'described', has) ? _Utils_Tuple2(found, has) : _Utils_Tuple2(
							A2(elm$core$List$cons, x, found),
							A2(elm$core$Set$insert, 'described', has));
					case 9:
						var location = x.a;
						var elem = x.b;
						return _Utils_Tuple2(
							A2(elm$core$List$cons, x, found),
							has);
					case 6:
						return A2(elm$core$Set$member, 'align-x', has) ? _Utils_Tuple2(found, has) : _Utils_Tuple2(
							A2(elm$core$List$cons, x, found),
							A2(elm$core$Set$insert, 'align-x', has));
					case 5:
						return A2(elm$core$Set$member, 'align-y', has) ? _Utils_Tuple2(found, has) : _Utils_Tuple2(
							A2(elm$core$List$cons, x, found),
							A2(elm$core$Set$insert, 'align-y', has));
					default:
						return A2(elm$core$Set$member, 'transform', has) ? _Utils_Tuple2(found, has) : _Utils_Tuple2(
							A2(elm$core$List$cons, x, found),
							A2(elm$core$Set$insert, 'transform', has));
				}
			}),
		_Utils_Tuple2(_List_Nil, elm$core$Set$empty),
		attrs).a;
};
var mdgriffith$elm_ui$Internal$Model$get = F2(
	function (attrs, isAttr) {
		return A3(
			elm$core$List$foldr,
			F2(
				function (x, found) {
					return isAttr(x) ? A2(elm$core$List$cons, x, found) : found;
				}),
			_List_Nil,
			mdgriffith$elm_ui$Internal$Model$filter(attrs));
	});
var mdgriffith$elm_ui$Internal$Model$isContent = function (len) {
	isContent:
	while (true) {
		switch (len.$) {
			case 1:
				return true;
			case 4:
				var l = len.b;
				var $temp$len = l;
				len = $temp$len;
				continue isContent;
			case 3:
				var l = len.b;
				var $temp$len = l;
				len = $temp$len;
				continue isContent;
			default:
				return false;
		}
	}
};
var mdgriffith$elm_ui$Internal$Model$unstyled = A2(elm$core$Basics$composeL, mdgriffith$elm_ui$Internal$Model$Unstyled, elm$core$Basics$always);
var mdgriffith$elm_ui$Element$Input$textHelper = F3(
	function (textInput, attrs, textOptions) {
		var forNearby = function (attr) {
			if (attr.$ === 9) {
				return true;
			} else {
				return false;
			}
		};
		var behavior = _List_fromArray(
			[
				mdgriffith$elm_ui$Internal$Model$Attr(
				elm$html$Html$Events$onInput(textOptions.c6))
			]);
		var attributes = _Utils_ap(mdgriffith$elm_ui$Element$Input$defaultTextBoxStyle, attrs);
		var attributesFromChild = A2(
			mdgriffith$elm_ui$Internal$Model$get,
			attributes,
			function (attr) {
				_n21$7:
				while (true) {
					switch (attr.$) {
						case 7:
							if (attr.a.$ === 2) {
								return true;
							} else {
								break _n21$7;
							}
						case 8:
							if (attr.a.$ === 2) {
								return true;
							} else {
								break _n21$7;
							}
						case 6:
							return true;
						case 5:
							return true;
						case 4:
							switch (attr.b.$) {
								case 5:
									var _n22 = attr.b;
									return true;
								case 2:
									return true;
								case 1:
									var _n23 = attr.b;
									return true;
								default:
									break _n21$7;
							}
						default:
							break _n21$7;
					}
				}
				return false;
			});
		var forPlaceholder = A2(elm$core$List$filter, mdgriffith$elm_ui$Element$Input$inheritablePlaceholderAttributes, attributes);
		var heightFillFromChild = A2(
			mdgriffith$elm_ui$Internal$Model$get,
			attributes,
			function (attr) {
				if ((attr.$ === 8) && (attr.a.$ === 2)) {
					return true;
				} else {
					return false;
				}
			});
		var inputPadding = A2(
			mdgriffith$elm_ui$Internal$Model$get,
			attributes,
			function (attr) {
				if ((attr.$ === 4) && (attr.b.$ === 7)) {
					var _n19 = attr.b;
					return true;
				} else {
					return false;
				}
			});
		var nearbys = A2(
			mdgriffith$elm_ui$Internal$Model$get,
			attributes,
			function (attr) {
				if (attr.$ === 9) {
					return true;
				} else {
					return false;
				}
			});
		var noNearbys = A2(
			elm$core$List$filter,
			A2(elm$core$Basics$composeL, elm$core$Basics$not, forNearby),
			attributes);
		var _n0 = function () {
			var _n1 = textInput.am;
			if (!_n1.$) {
				var inputType = _n1.a;
				return _Utils_Tuple3(
					'input',
					_Utils_ap(
						_List_fromArray(
							[
								mdgriffith$elm_ui$Element$Input$value(textOptions.dj),
								mdgriffith$elm_ui$Internal$Model$Attr(
								elm$html$Html$Attributes$type_(inputType)),
								mdgriffith$elm_ui$Element$Input$spellcheck(textInput.ab),
								mdgriffith$elm_ui$Internal$Model$htmlClass(mdgriffith$elm_ui$Internal$Style$classes.hW),
								function () {
								var _n2 = textInput.Z;
								if (_n2.$ === 1) {
									return mdgriffith$elm_ui$Internal$Model$NoAttribute;
								} else {
									var fill = _n2.a;
									return mdgriffith$elm_ui$Element$Input$autofill(fill);
								}
							}()
							]),
						noNearbys),
					_List_Nil);
			} else {
				var _n3 = A3(
					elm$core$List$foldr,
					F2(
						function (attr, found) {
							_n4$5:
							while (true) {
								switch (attr.$) {
									case 2:
										return found;
									case 8:
										var len = attr.a;
										var _n5 = found.cS;
										if (_n5.$ === 1) {
											return _Utils_update(
												found,
												{
													O: A2(elm$core$List$cons, attr, found.O),
													cS: elm$core$Maybe$Just(
														mdgriffith$elm_ui$Internal$Model$isContent(len))
												});
										} else {
											return found;
										}
									case 4:
										switch (attr.b.$) {
											case 6:
												var _n6 = attr.b;
												var t = _n6.b;
												var r = _n6.c;
												var b = _n6.d;
												var l = _n6.e;
												var _n7 = found.c0;
												if (_n7.$ === 1) {
													return _Utils_update(
														found,
														{
															O: A2(elm$core$List$cons, attr, found.O),
															c0: elm$core$Maybe$Just(
																A4(mdgriffith$elm_ui$Element$Input$Padding, t, r, b, l))
														});
												} else {
													return found;
												}
											case 7:
												var _n8 = attr.b;
												var t = _n8.b;
												var r = _n8.c;
												var b = _n8.d;
												var l = _n8.e;
												var _n9 = found.c1;
												if (_n9.$ === 1) {
													return _Utils_update(
														found,
														{
															O: found.O,
															c1: elm$core$Maybe$Just(
																A4(mdgriffith$elm_ui$Element$Input$Padding, t, r, b, l))
														});
												} else {
													return found;
												}
											case 5:
												var _n10 = attr.b;
												var x = _n10.b;
												var y = _n10.c;
												var _n11 = found.c2;
												if (_n11.$ === 1) {
													return _Utils_update(
														found,
														{
															O: A2(elm$core$List$cons, attr, found.O),
															c2: elm$core$Maybe$Just(y)
														});
												} else {
													return found;
												}
											default:
												break _n4$5;
										}
									default:
										break _n4$5;
								}
							}
							return _Utils_update(
								found,
								{
									O: A2(elm$core$List$cons, attr, found.O)
								});
						}),
					{O: _List_Nil, cS: elm$core$Maybe$Nothing, c0: elm$core$Maybe$Nothing, c1: elm$core$Maybe$Nothing, c2: elm$core$Maybe$Nothing},
					attributes);
				var maybePadding = _n3.c1;
				var heightContent = _n3.cS;
				var maybeSpacing = _n3.c2;
				var adjustedAttributes = _n3.O;
				var maybeBorder = _n3.c0;
				var spacing = A2(elm$core$Maybe$withDefault, 5, maybeSpacing);
				return _Utils_Tuple3(
					'textarea',
					_Utils_ap(
						_List_fromArray(
							[
								mdgriffith$elm_ui$Element$Input$spellcheck(textInput.ab),
								mdgriffith$elm_ui$Internal$Model$htmlClass(mdgriffith$elm_ui$Internal$Style$classes.hV),
								A2(
								elm$core$Maybe$withDefault,
								mdgriffith$elm_ui$Internal$Model$NoAttribute,
								A2(elm$core$Maybe$map, mdgriffith$elm_ui$Element$Input$autofill, textInput.Z)),
								function () {
								if (maybePadding.$ === 1) {
									return mdgriffith$elm_ui$Internal$Model$NoAttribute;
								} else {
									var _n13 = maybePadding.a;
									var t = _n13.a;
									var r = _n13.b;
									var b = _n13.c;
									var l = _n13.d;
									return mdgriffith$elm_ui$Element$paddingEach(
										{
											e$: A2(elm$core$Basics$max, 0, b - ((spacing / 2) | 0)),
											fD: l,
											f5: r,
											go: A2(elm$core$Basics$max, 0, t - ((spacing / 2) | 0))
										});
								}
							}(),
								function () {
								if (heightContent.$ === 1) {
									return mdgriffith$elm_ui$Internal$Model$NoAttribute;
								} else {
									if (heightContent.a) {
										return A4(mdgriffith$elm_ui$Element$Input$textHeightContent, textOptions.dj, spacing, maybePadding, maybeBorder);
									} else {
										return mdgriffith$elm_ui$Internal$Model$NoAttribute;
									}
								}
							}()
							]),
						adjustedAttributes),
					_List_fromArray(
						[
							mdgriffith$elm_ui$Internal$Model$unstyled(
							elm$html$Html$text(textOptions.dj))
						]));
			}
		}();
		var inputNode = _n0.a;
		var inputAttrs = _n0.b;
		var inputChildren = _n0.c;
		var inputElement = A4(
			mdgriffith$elm_ui$Internal$Model$element,
			mdgriffith$elm_ui$Internal$Model$asEl,
			mdgriffith$elm_ui$Internal$Model$div,
			A2(
				elm$core$List$cons,
				mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
				elm$core$List$concat(
					_List_fromArray(
						[
							nearbys,
							heightFillFromChild,
							function () {
							var _n15 = textOptions.db;
							if (_n15.$ === 1) {
								return _List_Nil;
							} else {
								var _n16 = _n15.a;
								var placeholderAttrs = _n16.a;
								var placeholderEl = _n16.b;
								return _List_fromArray(
									[
										mdgriffith$elm_ui$Element$inFront(
										A2(
											mdgriffith$elm_ui$Element$el,
											A2(
												elm$core$List$cons,
												mdgriffith$elm_ui$Element$Input$defaultTextPadding,
												_Utils_ap(
													forPlaceholder,
													_Utils_ap(
														_List_fromArray(
															[
																mdgriffith$elm_ui$Element$Font$color(mdgriffith$elm_ui$Element$Input$charcoal),
																mdgriffith$elm_ui$Internal$Model$htmlClass(mdgriffith$elm_ui$Internal$Style$classes.fN + (' ' + mdgriffith$elm_ui$Internal$Style$classes.ix)),
																mdgriffith$elm_ui$Element$Border$color(
																A4(mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0)),
																mdgriffith$elm_ui$Element$Background$color(
																A4(mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0)),
																mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill),
																mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
																mdgriffith$elm_ui$Element$alpha(
																(textOptions.dj === '') ? 1 : 0)
															]),
														placeholderAttrs))),
											placeholderEl))
									]);
							}
						}()
						]))),
			mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[
						A4(
						mdgriffith$elm_ui$Internal$Model$element,
						mdgriffith$elm_ui$Internal$Model$asEl,
						mdgriffith$elm_ui$Internal$Model$NodeName(inputNode),
						elm$core$List$concat(
							_List_fromArray(
								[
									_List_fromArray(
									[
										mdgriffith$elm_ui$Element$Input$focusDefault(attrs),
										mdgriffith$elm_ui$Element$Input$hiddenLabelAttribute(textOptions.fB)
									]),
									inputAttrs,
									behavior
								])),
						mdgriffith$elm_ui$Internal$Model$Unkeyed(inputChildren))
					])));
		return A3(
			mdgriffith$elm_ui$Element$Input$applyLabel,
			A2(
				elm$core$List$cons,
				A2(mdgriffith$elm_ui$Internal$Model$Class, mdgriffith$elm_ui$Internal$Flag$cursor, mdgriffith$elm_ui$Internal$Style$classes.hh),
				A2(
					elm$core$List$cons,
					mdgriffith$elm_ui$Element$Input$isHiddenLabel(textOptions.fB) ? mdgriffith$elm_ui$Internal$Model$NoAttribute : mdgriffith$elm_ui$Element$spacing(5),
					A2(elm$core$List$cons, mdgriffith$elm_ui$Element$Region$announce, attributesFromChild))),
			textOptions.fB,
			inputElement);
	});
var mdgriffith$elm_ui$Element$Input$text = mdgriffith$elm_ui$Element$Input$textHelper(
	{
		Z: elm$core$Maybe$Nothing,
		ab: false,
		am: mdgriffith$elm_ui$Element$Input$TextInputNode('text')
	});
var author$project$View$Input$textInput = F2(
	function (attributes, configuration) {
		var placeholder = A2(
			elm$core$Maybe$map,
			mdgriffith$elm_ui$Element$Input$placeholder(_List_Nil),
			A2(elm$core$Maybe$map, mdgriffith$elm_ui$Element$text, configuration.db));
		var label = A2(
			mdgriffith$elm_ui$Element$Input$labelAbove,
			_List_Nil,
			mdgriffith$elm_ui$Element$text(configuration.cW));
		var allAttributes = _Utils_ap(
			attributes,
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$Background$color(
					A3(mdgriffith$elm_ui$Element$rgb, 0.1, 0.1, 0.1))
				]));
		return A2(
			mdgriffith$elm_ui$Element$Input$text,
			allAttributes,
			{fB: label, c6: configuration.c6, db: placeholder, dj: configuration.dj});
	});
var elm$html$Html$Attributes$max = elm$html$Html$Attributes$stringProperty('max');
var elm$html$Html$Attributes$min = elm$html$Html$Attributes$stringProperty('min');
var elm$html$Html$Attributes$step = function (n) {
	return A2(elm$html$Html$Attributes$stringProperty, 'step', n);
};
var author$project$View$Input$numericInput = F2(
	function (attributes, configuration) {
		var numericAttributes = A2(
			elm$core$List$map,
			mdgriffith$elm_ui$Element$htmlAttribute,
			elm_community$maybe_extra$Maybe$Extra$values(
				_List_fromArray(
					[
						elm$core$Maybe$Just(
						elm$html$Html$Attributes$type_('number')),
						A2(
						elm$core$Maybe$map,
						A2(elm$core$Basics$composeR, elm$core$String$fromFloat, elm$html$Html$Attributes$min),
						configuration.h5),
						A2(
						elm$core$Maybe$map,
						A2(elm$core$Basics$composeR, elm$core$String$fromFloat, elm$html$Html$Attributes$max),
						configuration.h0),
						A2(
						elm$core$Maybe$map,
						A2(elm$core$Basics$composeR, elm$core$String$fromFloat, elm$html$Html$Attributes$step),
						configuration.gi),
						elm$core$Maybe$Just(
						elm$html$Html$Attributes$class('number'))
					])));
		var allAttributes = elm$core$List$concat(
			_List_fromArray(
				[numericAttributes, attributes]));
		return A2(author$project$View$Input$textInput, allAttributes, configuration);
	});
var author$project$Data$Direction$all = _List_fromArray(
	[0, 1, 2, 3]);
var author$project$Data$Suite$constraints = {
	h0: A2(elm$core$Basics$pow, 2, 16) - 1,
	h5: -A2(elm$core$Basics$pow, 2, 16)
};
var author$project$InstructionToolView$description = function (instructionTool) {
	switch (instructionTool.$) {
		case 0:
			var instruction = instructionTool.a;
			return author$project$InstructionView$description(instruction);
		case 1:
			var direction = instructionTool.a;
			return 'Change direction';
		case 2:
			var trueDirection = instructionTool.a;
			var falseDirection = instructionTool.b;
			return 'Branch direction (if 0 go black, else go white)';
		case 3:
			return 'Push a value to the stack';
		default:
			return 'Exception';
	}
};
var author$project$ViewComponents$branchDirectionExtraButton = F4(
	function (attributes, onPress, _true, direction) {
		var _n0 = function () {
			var _n1 = _Utils_Tuple2(_true, direction);
			if (_n1.a) {
				switch (_n1.b) {
					case 0:
						var _n2 = _n1.b;
						return _Utils_Tuple2('assets/instruction-images/small-filled-arrow-left.svg', 'Go left when not zero');
					case 1:
						var _n3 = _n1.b;
						return _Utils_Tuple2('assets/instruction-images/small-filled-arrow-up.svg', 'Go up when not zero');
					case 2:
						var _n4 = _n1.b;
						return _Utils_Tuple2('assets/instruction-images/small-filled-arrow-right.svg', 'Go right when not zero');
					default:
						var _n5 = _n1.b;
						return _Utils_Tuple2('assets/instruction-images/small-filled-arrow-down.svg', 'Go down when not zero');
				}
			} else {
				switch (_n1.b) {
					case 0:
						var _n6 = _n1.b;
						return _Utils_Tuple2('assets/instruction-images/small-hollow-arrow-left.svg', 'Go left when zero');
					case 1:
						var _n7 = _n1.b;
						return _Utils_Tuple2('assets/instruction-images/small-hollow-arrow-up.svg', 'Go up when zero');
					case 2:
						var _n8 = _n1.b;
						return _Utils_Tuple2('assets/instruction-images/small-hollow-arrow-right.svg', 'Go right when zero');
					default:
						var _n9 = _n1.b;
						return _Utils_Tuple2('assets/instruction-images/small-hollow-arrow-down.svg', 'Go down when zero');
				}
			}
		}();
		var sourceFile = _n0.a;
		var description = _n0.b;
		return A3(
			author$project$ViewComponents$imageButton,
			elm$core$List$concat(
				_List_fromArray(
					[attributes])),
			onPress,
			A2(
				mdgriffith$elm_ui$Element$image,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
						mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill)
					]),
				{bq: description, bJ: sourceFile}));
	});
var author$project$ViewComponents$instructionToolButton = F3(
	function (attributes, onPress, instructionTool) {
		var attrs2 = function () {
			if ((!instructionTool.$) && (instructionTool.a.$ === 26)) {
				return _List_fromArray(
					[
						mdgriffith$elm_ui$Element$Background$color(
						A4(mdgriffith$elm_ui$Element$rgba, 1, 0, 0, 0.1))
					]);
			} else {
				return _List_Nil;
			}
		}();
		return A3(
			author$project$ViewComponents$imageButton,
			elm$core$List$concat(
				_List_fromArray(
					[attrs2, attributes])),
			onPress,
			A2(
				author$project$InstructionToolView$view,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
						mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill)
					]),
				instructionTool));
	});
var elm$html$Html$Attributes$title = elm$html$Html$Attributes$stringProperty('title');
var mdgriffith$elm_ui$Internal$Model$CenterX = 1;
var mdgriffith$elm_ui$Element$centerX = mdgriffith$elm_ui$Internal$Model$AlignX(1);
var mdgriffith$elm_ui$Element$scrollbarY = A2(mdgriffith$elm_ui$Internal$Model$Class, mdgriffith$elm_ui$Internal$Flag$overflow, mdgriffith$elm_ui$Internal$Style$classes.iN);
var mdgriffith$elm_ui$Internal$Model$Padding = F5(
	function (a, b, c, d, e) {
		return {$: 0, a: a, b: b, c: c, d: d, e: e};
	});
var mdgriffith$elm_ui$Internal$Model$Spaced = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var mdgriffith$elm_ui$Internal$Model$extractSpacingAndPadding = function (attrs) {
	return A3(
		elm$core$List$foldr,
		F2(
			function (attr, _n0) {
				var pad = _n0.a;
				var spacing = _n0.b;
				return _Utils_Tuple2(
					function () {
						if (!pad.$) {
							var x = pad.a;
							return pad;
						} else {
							if ((attr.$ === 4) && (attr.b.$ === 7)) {
								var _n3 = attr.b;
								var name = _n3.a;
								var t = _n3.b;
								var r = _n3.c;
								var b = _n3.d;
								var l = _n3.e;
								return elm$core$Maybe$Just(
									A5(mdgriffith$elm_ui$Internal$Model$Padding, name, t, r, b, l));
							} else {
								return elm$core$Maybe$Nothing;
							}
						}
					}(),
					function () {
						if (!spacing.$) {
							var x = spacing.a;
							return spacing;
						} else {
							if ((attr.$ === 4) && (attr.b.$ === 5)) {
								var _n6 = attr.b;
								var name = _n6.a;
								var x = _n6.b;
								var y = _n6.c;
								return elm$core$Maybe$Just(
									A3(mdgriffith$elm_ui$Internal$Model$Spaced, name, x, y));
							} else {
								return elm$core$Maybe$Nothing;
							}
						}
					}());
			}),
		_Utils_Tuple2(elm$core$Maybe$Nothing, elm$core$Maybe$Nothing),
		attrs);
};
var mdgriffith$elm_ui$Element$wrappedRow = F2(
	function (attrs, children) {
		var _n0 = mdgriffith$elm_ui$Internal$Model$extractSpacingAndPadding(attrs);
		var padded = _n0.a;
		var spaced = _n0.b;
		if (spaced.$ === 1) {
			return A4(
				mdgriffith$elm_ui$Internal$Model$element,
				mdgriffith$elm_ui$Internal$Model$asRow,
				mdgriffith$elm_ui$Internal$Model$div,
				A2(
					elm$core$List$cons,
					mdgriffith$elm_ui$Internal$Model$htmlClass(mdgriffith$elm_ui$Internal$Style$classes.bX + (' ' + (mdgriffith$elm_ui$Internal$Style$classes.aI + (' ' + mdgriffith$elm_ui$Internal$Style$classes.eQ)))),
					A2(
						elm$core$List$cons,
						mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$shrink),
						A2(
							elm$core$List$cons,
							mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$shrink),
							attrs))),
				mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
		} else {
			var _n2 = spaced.a;
			var spaceName = _n2.a;
			var x = _n2.b;
			var y = _n2.c;
			var newPadding = function () {
				if (!padded.$) {
					var _n5 = padded.a;
					var name = _n5.a;
					var t = _n5.b;
					var r = _n5.c;
					var b = _n5.d;
					var l = _n5.e;
					return ((_Utils_cmp(r, (x / 2) | 0) > -1) && (_Utils_cmp(b, (y / 2) | 0) > -1)) ? elm$core$Maybe$Just(
						mdgriffith$elm_ui$Element$paddingEach(
							{e$: b - ((y / 2) | 0), fD: l - ((x / 2) | 0), f5: r - ((x / 2) | 0), go: t - ((y / 2) | 0)})) : elm$core$Maybe$Nothing;
				} else {
					return elm$core$Maybe$Nothing;
				}
			}();
			if (!newPadding.$) {
				var pad = newPadding.a;
				return A4(
					mdgriffith$elm_ui$Internal$Model$element,
					mdgriffith$elm_ui$Internal$Model$asRow,
					mdgriffith$elm_ui$Internal$Model$div,
					A2(
						elm$core$List$cons,
						mdgriffith$elm_ui$Internal$Model$htmlClass(mdgriffith$elm_ui$Internal$Style$classes.bX + (' ' + (mdgriffith$elm_ui$Internal$Style$classes.aI + (' ' + mdgriffith$elm_ui$Internal$Style$classes.eQ)))),
						A2(
							elm$core$List$cons,
							mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$shrink),
							A2(
								elm$core$List$cons,
								mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$shrink),
								_Utils_ap(
									attrs,
									_List_fromArray(
										[pad]))))),
					mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
			} else {
				var halfY = -(y / 2);
				var halfX = -(x / 2);
				return A4(
					mdgriffith$elm_ui$Internal$Model$element,
					mdgriffith$elm_ui$Internal$Model$asEl,
					mdgriffith$elm_ui$Internal$Model$div,
					attrs,
					mdgriffith$elm_ui$Internal$Model$Unkeyed(
						_List_fromArray(
							[
								A4(
								mdgriffith$elm_ui$Internal$Model$element,
								mdgriffith$elm_ui$Internal$Model$asRow,
								mdgriffith$elm_ui$Internal$Model$div,
								A2(
									elm$core$List$cons,
									mdgriffith$elm_ui$Internal$Model$htmlClass(mdgriffith$elm_ui$Internal$Style$classes.bX + (' ' + (mdgriffith$elm_ui$Internal$Style$classes.aI + (' ' + mdgriffith$elm_ui$Internal$Style$classes.eQ)))),
									A2(
										elm$core$List$cons,
										mdgriffith$elm_ui$Internal$Model$Attr(
											A2(
												elm$html$Html$Attributes$style,
												'margin',
												elm$core$String$fromFloat(halfY) + ('px' + (' ' + (elm$core$String$fromFloat(halfX) + 'px'))))),
										A2(
											elm$core$List$cons,
											mdgriffith$elm_ui$Internal$Model$Attr(
												A2(
													elm$html$Html$Attributes$style,
													'width',
													'calc(100% + ' + (elm$core$String$fromInt(x) + 'px)'))),
											A2(
												elm$core$List$cons,
												mdgriffith$elm_ui$Internal$Model$Attr(
													A2(
														elm$html$Html$Attributes$style,
														'height',
														'calc(100% + ' + (elm$core$String$fromInt(y) + 'px)'))),
												A2(
													elm$core$List$cons,
													A2(
														mdgriffith$elm_ui$Internal$Model$StyleClass,
														mdgriffith$elm_ui$Internal$Flag$spacing,
														A3(mdgriffith$elm_ui$Internal$Model$SpacingStyle, spaceName, x, y)),
													_List_Nil))))),
								mdgriffith$elm_ui$Internal$Model$Unkeyed(children))
							])));
			}
		}
	});
var author$project$View$InstructionTools$view = function (conf) {
	var viewTool = F2(
		function (index, instructionTool) {
			var selected = A2(
				elm$core$Maybe$withDefault,
				false,
				A2(
					elm$core$Maybe$map,
					elm$core$Basics$eq(index),
					conf.iS));
			var onPress = A2(
				elm$core$Maybe$map,
				elm$core$Basics$apR(index),
				conf.io);
			var attributes = selected ? _List_fromArray(
				[
					mdgriffith$elm_ui$Element$Background$color(
					A4(mdgriffith$elm_ui$Element$rgba, 1, 1, 1, 0.25)),
					mdgriffith$elm_ui$Element$htmlAttribute(
					elm$html$Html$Attributes$title(
						author$project$InstructionToolView$description(instructionTool)))
				]) : _List_fromArray(
				[
					mdgriffith$elm_ui$Element$htmlAttribute(
					elm$html$Html$Attributes$title(
						author$project$InstructionToolView$description(instructionTool)))
				]);
			return A3(author$project$ViewComponents$instructionToolButton, attributes, onPress, instructionTool);
		});
	var toolsView = A2(
		mdgriffith$elm_ui$Element$wrappedRow,
		_List_fromArray(
			[
				mdgriffith$elm_ui$Element$width(
				mdgriffith$elm_ui$Element$px(222)),
				mdgriffith$elm_ui$Element$spacing(10),
				mdgriffith$elm_ui$Element$centerX
			]),
		elm$core$Array$toList(
			A2(elm$core$Array$indexedMap, viewTool, conf.aO)));
	var toolExtraView = function () {
		var _n0 = conf.iS;
		if (!_n0.$) {
			var index = _n0.a;
			var _n1 = A2(elm$core$Array$get, index, conf.aO);
			if (!_n1.$) {
				switch (_n1.a.$) {
					case 1:
						var selectedDirection = _n1.a.a;
						return elm$core$Maybe$Just(
							A2(
								mdgriffith$elm_ui$Element$wrappedRow,
								_List_fromArray(
									[
										mdgriffith$elm_ui$Element$spacing(10),
										mdgriffith$elm_ui$Element$width(
										mdgriffith$elm_ui$Element$px(222)),
										mdgriffith$elm_ui$Element$centerX
									]),
								A2(
									elm$core$List$map,
									function (direction) {
										var onPress = elm$core$Maybe$Just(
											A2(
												conf.im,
												index,
												author$project$Data$InstructionTool$ChangeAnyDirection(direction)));
										var instruction = author$project$Data$Instruction$ChangeDirection(direction);
										var attributes = _Utils_eq(selectedDirection, direction) ? _List_fromArray(
											[
												mdgriffith$elm_ui$Element$Background$color(
												A3(mdgriffith$elm_ui$Element$rgb, 0.25, 0.25, 0.25))
											]) : _List_Nil;
										return A3(author$project$ViewComponents$instructionButton, attributes, onPress, instruction);
									},
									author$project$Data$Direction$all)));
					case 2:
						var _n2 = _n1.a;
						var trueDirection = _n2.a;
						var falseDirection = _n2.b;
						return elm$core$Maybe$Just(
							A2(
								mdgriffith$elm_ui$Element$row,
								_List_fromArray(
									[
										mdgriffith$elm_ui$Element$centerX,
										mdgriffith$elm_ui$Element$spacing(10)
									]),
								_List_fromArray(
									[
										A2(
										mdgriffith$elm_ui$Element$column,
										_List_fromArray(
											[
												mdgriffith$elm_ui$Element$spacing(10)
											]),
										A2(
											elm$core$List$map,
											function (direction) {
												var onPress = elm$core$Maybe$Just(
													A2(
														conf.im,
														index,
														A2(author$project$Data$InstructionTool$BranchAnyDirection, direction, falseDirection)));
												var attributes = _Utils_eq(trueDirection, direction) ? _List_fromArray(
													[
														mdgriffith$elm_ui$Element$Background$color(
														A3(mdgriffith$elm_ui$Element$rgb, 0.25, 0.25, 0.25))
													]) : _List_Nil;
												return A4(author$project$ViewComponents$branchDirectionExtraButton, attributes, onPress, true, direction);
											},
											author$project$Data$Direction$all)),
										A2(
										mdgriffith$elm_ui$Element$column,
										_List_fromArray(
											[
												mdgriffith$elm_ui$Element$spacing(10)
											]),
										A2(
											elm$core$List$map,
											function (direction) {
												var onPress = elm$core$Maybe$Just(
													A2(
														conf.im,
														index,
														A2(author$project$Data$InstructionTool$BranchAnyDirection, trueDirection, direction)));
												var attributes = _Utils_eq(falseDirection, direction) ? _List_fromArray(
													[
														mdgriffith$elm_ui$Element$Background$color(
														A3(mdgriffith$elm_ui$Element$rgb, 0.25, 0.25, 0.25))
													]) : _List_Nil;
												return A4(author$project$ViewComponents$branchDirectionExtraButton, attributes, onPress, false, direction);
											},
											author$project$Data$Direction$all))
									])));
					case 3:
						var value = _n1.a.a;
						return elm$core$Maybe$Just(
							A2(
								author$project$View$Input$numericInput,
								_List_Nil,
								{
									cW: 'Enter value',
									h0: elm$core$Maybe$Just(author$project$Data$Suite$constraints.h0),
									h5: elm$core$Maybe$Just(author$project$Data$Suite$constraints.h5),
									c6: A2(
										elm$core$Basics$composeR,
										author$project$Data$InstructionTool$PushValueToStack,
										conf.im(index)),
									db: elm$core$Maybe$Nothing,
									gi: elm$core$Maybe$Just(1),
									dj: value
								}));
					case 4:
						var exceptionMessage = _n1.a.a;
						return elm$core$Maybe$Just(
							A2(
								author$project$View$Input$textInput,
								_List_Nil,
								{
									cW: 'Enter value',
									c6: A2(
										elm$core$Basics$composeR,
										author$project$Data$InstructionTool$Exception,
										conf.im(index)),
									db: elm$core$Maybe$Nothing,
									dj: exceptionMessage
								}));
					default:
						return elm$core$Maybe$Nothing;
				}
			} else {
				return elm$core$Maybe$Nothing;
			}
		} else {
			return elm$core$Maybe$Nothing;
		}
	}();
	return A2(
		mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				mdgriffith$elm_ui$Element$width(
				mdgriffith$elm_ui$Element$px(262)),
				mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill),
				mdgriffith$elm_ui$Element$Background$color(
				A3(mdgriffith$elm_ui$Element$rgb, 5.0e-2, 5.0e-2, 5.0e-2)),
				mdgriffith$elm_ui$Element$spacing(40),
				mdgriffith$elm_ui$Element$padding(10),
				mdgriffith$elm_ui$Element$scrollbarY
			]),
		elm_community$maybe_extra$Maybe$Extra$values(
			_List_fromArray(
				[
					elm$core$Maybe$Just(toolsView),
					toolExtraView
				])));
};
var mdgriffith$elm_ui$Element$fillPortion = mdgriffith$elm_ui$Internal$Model$Fill;
var mdgriffith$elm_ui$Internal$Model$Max = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var mdgriffith$elm_ui$Element$maximum = F2(
	function (i, l) {
		return A2(mdgriffith$elm_ui$Internal$Model$Max, i, l);
	});
var author$project$View$Scewn$view = function (_n0) {
	var south = _n0.iZ;
	var center = _n0.g5;
	var east = _n0.hp;
	var west = _n0.jz;
	var north = _n0.ie;
	var modal = _n0.h9;
	var top = A2(
		elm$core$Maybe$map,
		mdgriffith$elm_ui$Element$el(
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill)
				])),
		north);
	var middle = function () {
		var toRow = mdgriffith$elm_ui$Element$row(
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
					mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill),
					mdgriffith$elm_ui$Element$scrollbars
				]));
		var _n2 = _Utils_Tuple3(west, center, east);
		if (!_n2.a.$) {
			if (!_n2.b.$) {
				if (!_n2.c.$) {
					var w = _n2.a.a;
					var c = _n2.b.a;
					var e = _n2.c.a;
					return elm$core$Maybe$Just(
						toRow(
							_List_fromArray(
								[
									A2(
									mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											mdgriffith$elm_ui$Element$width(
											A2(
												mdgriffith$elm_ui$Element$maximum,
												500,
												mdgriffith$elm_ui$Element$fillPortion(1))),
											mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill)
										]),
									w),
									A2(
									mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											mdgriffith$elm_ui$Element$width(
											mdgriffith$elm_ui$Element$fillPortion(3)),
											mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill),
											mdgriffith$elm_ui$Element$scrollbars
										]),
									c),
									A2(
									mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											mdgriffith$elm_ui$Element$width(
											A2(mdgriffith$elm_ui$Element$maximum, 500, mdgriffith$elm_ui$Element$shrink)),
											mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill)
										]),
									e)
								])));
				} else {
					var w = _n2.a.a;
					var c = _n2.b.a;
					var _n3 = _n2.c;
					return elm$core$Maybe$Just(
						toRow(
							_List_fromArray(
								[
									A2(
									mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											mdgriffith$elm_ui$Element$width(
											A2(
												mdgriffith$elm_ui$Element$maximum,
												500,
												mdgriffith$elm_ui$Element$fillPortion(1))),
											mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill)
										]),
									w),
									A2(
									mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											mdgriffith$elm_ui$Element$width(
											mdgriffith$elm_ui$Element$fillPortion(3)),
											mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill),
											mdgriffith$elm_ui$Element$scrollbars
										]),
									c)
								])));
				}
			} else {
				if (!_n2.c.$) {
					var w = _n2.a.a;
					var _n4 = _n2.b;
					var e = _n2.c.a;
					return elm$core$Maybe$Just(
						toRow(
							_List_fromArray(
								[
									A2(
									mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											mdgriffith$elm_ui$Element$width(
											A2(
												mdgriffith$elm_ui$Element$maximum,
												500,
												mdgriffith$elm_ui$Element$fillPortion(1))),
											mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill)
										]),
									w),
									A2(
									mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											mdgriffith$elm_ui$Element$width(
											A2(
												mdgriffith$elm_ui$Element$maximum,
												500,
												mdgriffith$elm_ui$Element$fillPortion(1))),
											mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill),
											mdgriffith$elm_ui$Element$scrollbars
										]),
									e)
								])));
				} else {
					var w = _n2.a.a;
					var _n5 = _n2.b;
					var _n6 = _n2.c;
					return elm$core$Maybe$Just(
						A2(
							mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
									mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill)
								]),
							w));
				}
			}
		} else {
			if (!_n2.b.$) {
				if (!_n2.c.$) {
					var _n7 = _n2.a;
					var c = _n2.b.a;
					var e = _n2.c.a;
					return elm$core$Maybe$Just(
						toRow(
							_List_fromArray(
								[
									A2(
									mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											mdgriffith$elm_ui$Element$width(
											mdgriffith$elm_ui$Element$fillPortion(3)),
											mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill)
										]),
									c),
									A2(
									mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											mdgriffith$elm_ui$Element$width(
											A2(
												mdgriffith$elm_ui$Element$maximum,
												500,
												mdgriffith$elm_ui$Element$fillPortion(1))),
											mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill)
										]),
									e)
								])));
				} else {
					var _n8 = _n2.a;
					var c = _n2.b.a;
					var _n9 = _n2.c;
					return elm$core$Maybe$Just(
						A2(
							mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
									mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill),
									mdgriffith$elm_ui$Element$scrollbars
								]),
							c));
				}
			} else {
				if (!_n2.c.$) {
					var _n10 = _n2.a;
					var _n11 = _n2.b;
					var e = _n2.c.a;
					return elm$core$Maybe$Just(
						A2(
							mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
									mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill)
								]),
							e));
				} else {
					var _n12 = _n2.a;
					var _n13 = _n2.b;
					var _n14 = _n2.c;
					return elm$core$Maybe$Just(
						A2(
							mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
									mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill)
								]),
							mdgriffith$elm_ui$Element$none));
				}
			}
		}
	}();
	var bottom = A2(
		elm$core$Maybe$map,
		mdgriffith$elm_ui$Element$el(
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill)
				])),
		south);
	var content = A2(
		mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
				mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill),
				mdgriffith$elm_ui$Element$scrollbars
			]),
		elm_community$maybe_extra$Maybe$Extra$values(
			_List_fromArray(
				[top, middle, bottom])));
	if (!modal.$) {
		var m = modal.a;
		return A2(
			mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
					mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill),
					mdgriffith$elm_ui$Element$inFront(m),
					mdgriffith$elm_ui$Element$scrollbars
				]),
			content);
	} else {
		return content;
	}
};
var mdgriffith$elm_ui$Internal$Model$Paragraph = {$: 9};
var mdgriffith$elm_ui$Element$paragraph = F2(
	function (attrs, children) {
		return A4(
			mdgriffith$elm_ui$Internal$Model$element,
			mdgriffith$elm_ui$Internal$Model$asParagraph,
			mdgriffith$elm_ui$Internal$Model$div,
			A2(
				elm$core$List$cons,
				mdgriffith$elm_ui$Internal$Model$Describe(mdgriffith$elm_ui$Internal$Model$Paragraph),
				A2(
					elm$core$List$cons,
					mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
					A2(
						elm$core$List$cons,
						mdgriffith$elm_ui$Element$spacing(5),
						attrs))),
			mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var author$project$Page$Blueprint$viewBlueprint = F2(
	function (level, model) {
		var west = function () {
			var widthInput = A2(
				author$project$View$Input$numericInput,
				_List_Nil,
				{
					cW: 'Width',
					h0: elm$core$Maybe$Just(author$project$Data$Level$constraints.h4),
					h5: elm$core$Maybe$Just(author$project$Data$Level$constraints.h7),
					c6: author$project$Page$Blueprint$ChangedWidth,
					db: elm$core$Maybe$Nothing,
					gi: elm$core$Maybe$Just(1),
					dj: model.gy
				});
			var outputInput = A2(
				author$project$View$Input$textInput,
				_List_Nil,
				{
					cW: 'Output',
					c6: author$project$Page$Blueprint$ChangedOutput,
					db: elm$core$Maybe$Just('2,4,6'),
					dj: model.iu
				});
			var link = A3(
				author$project$Route$link,
				_List_Nil,
				mdgriffith$elm_ui$Element$text('test'),
				A2(
					author$project$Route$Campaign,
					author$project$Data$CampaignId$blueprints,
					elm$core$Maybe$Just(level.b6)));
			var instructionTools = function () {
				var title = mdgriffith$elm_ui$Element$text('Enabled instructions');
				var enableInstructionToolButton = F2(
					function (index, _n0) {
						var tool = _n0.a;
						var enabled = _n0.b;
						return A3(
							author$project$ViewComponents$imageButton,
							_List_fromArray(
								[
									enabled ? mdgriffith$elm_ui$Element$Background$color(
									A3(mdgriffith$elm_ui$Element$rgb, 0.25, 0.25, 0.25)) : mdgriffith$elm_ui$Element$Background$color(
									A3(mdgriffith$elm_ui$Element$rgb, 0, 0, 0))
								]),
							elm$core$Maybe$Just(
								author$project$Page$Blueprint$InstructionToolEnabled(index)),
							A2(author$project$InstructionToolView$view, _List_Nil, tool));
					});
				var instructionToolRow = A2(
					mdgriffith$elm_ui$Element$wrappedRow,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$spacing(10)
						]),
					elm$core$Array$toList(
						A2(elm$core$Array$indexedMap, enableInstructionToolButton, model.aq)));
				return A2(
					mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[title, instructionToolRow]));
			}();
			var inputInput = A2(
				author$project$View$Input$textInput,
				_List_Nil,
				{
					cW: 'Input',
					c6: author$project$Page$Blueprint$ChangedInput,
					db: elm$core$Maybe$Just('1,2,3,0'),
					dj: model.hU
				});
			var heightInput = A2(
				author$project$View$Input$numericInput,
				_List_Nil,
				{
					cW: 'Height',
					h0: elm$core$Maybe$Just(author$project$Data$Level$constraints.h2),
					h5: elm$core$Maybe$Just(author$project$Data$Level$constraints.h6),
					c6: author$project$Page$Blueprint$ChangedHeight,
					db: elm$core$Maybe$Nothing,
					gi: elm$core$Maybe$Just(1),
					dj: model.fp
				});
			return A2(
				mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
						mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill),
						mdgriffith$elm_ui$Element$padding(20),
						mdgriffith$elm_ui$Element$spacing(20),
						mdgriffith$elm_ui$Element$scrollbars,
						mdgriffith$elm_ui$Element$Background$color(
						A3(mdgriffith$elm_ui$Element$rgb, 5.0e-2, 5.0e-2, 5.0e-2))
					]),
				_List_fromArray(
					[
						A2(
						mdgriffith$elm_ui$Element$paragraph,
						_List_fromArray(
							[
								mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
								mdgriffith$elm_ui$Element$Font$center
							]),
						_List_fromArray(
							[
								mdgriffith$elm_ui$Element$text(level.bA)
							])),
						widthInput,
						heightInput,
						inputInput,
						outputInput,
						instructionTools,
						link
					]));
		}();
		var header = author$project$View$Header$view(model.f);
		var east = author$project$View$InstructionTools$view(
			{
				aO: model.aO,
				im: author$project$Page$Blueprint$InstructionToolReplaced,
				io: elm$core$Maybe$Just(author$project$Page$Blueprint$InstructionToolSelected),
				iS: model.cg
			});
		var center = function () {
			var selectedPosition = elm$core$Maybe$Nothing;
			var onClick = A2(
				elm$core$Maybe$map,
				elm$core$Basics$composeL(author$project$Page$Blueprint$InitialInstructionPlaced),
				A2(
					elm$core$Maybe$map,
					author$project$Data$BoardInstruction$withInstruction,
					A2(
						elm$core$Maybe$map,
						author$project$Data$InstructionTool$getInstruction,
						A2(
							elm$core$Maybe$andThen,
							A2(elm_community$basics_extra$Basics$Extra$flip, elm$core$Array$get, model.aO),
							model.cg))));
			var disabledPositions = _List_Nil;
			var board = level.hT;
			return author$project$View$Board$view(
				{cy: board, ho: disabledPositions, ij: onClick, iT: selectedPosition});
		}();
		return author$project$View$Scewn$view(
			{
				g5: elm$core$Maybe$Just(center),
				hp: elm$core$Maybe$Just(east),
				h9: elm$core$Maybe$Nothing,
				ie: elm$core$Maybe$Just(header),
				iZ: elm$core$Maybe$Nothing,
				jz: elm$core$Maybe$Just(west)
			});
	});
var author$project$View$Constant$color = {
	dv: {
		gX: A3(mdgriffith$elm_ui$Element$rgb, 0, 0, 0),
		hK: A3(mdgriffith$elm_ui$Element$rgb, 0.5, 0.5, 0.5),
		iR: A3(mdgriffith$elm_ui$Element$rgb, 0.25, 0.25, 0.25)
	},
	fk: {
		hi: A3(mdgriffith$elm_ui$Element$rgb, 1, 1, 1),
		ff: A3(mdgriffith$elm_ui$Element$rgb, 1, 0.51, 0.35),
		dZ: A3(mdgriffith$elm_ui$Element$rgb, 0.5, 0.5, 1),
		d_: A3(mdgriffith$elm_ui$Element$rgb, 0.75, 0.75, 1),
		gk: A3(mdgriffith$elm_ui$Element$rgb, 0.5, 0.5, 0.5)
	}
};
var author$project$View$ErrorScreen$view = function (errorMessage) {
	return A2(
		mdgriffith$elm_ui$Element$paragraph,
		_List_fromArray(
			[
				mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
				mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill),
				mdgriffith$elm_ui$Element$centerX,
				mdgriffith$elm_ui$Element$Background$color(
				A3(mdgriffith$elm_ui$Element$rgb, 0.1, 0.1, 0.1)),
				mdgriffith$elm_ui$Element$padding(40),
				mdgriffith$elm_ui$Element$htmlAttribute(
				elm$html$Html$Attributes$class('pre')),
				mdgriffith$elm_ui$Element$scrollbars,
				mdgriffith$elm_ui$Element$Font$color(author$project$View$Constant$color.fk.ff),
				mdgriffith$elm_ui$Element$Font$size(30)
			]),
		_List_fromArray(
			[
				mdgriffith$elm_ui$Element$text(errorMessage)
			]));
};
var mdgriffith$elm_ui$Internal$Model$OnlyDynamic = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var mdgriffith$elm_ui$Internal$Model$StaticRootAndDynamic = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var mdgriffith$elm_ui$Internal$Model$AllowHover = 1;
var mdgriffith$elm_ui$Internal$Model$Layout = 1;
var mdgriffith$elm_ui$Internal$Model$focusDefaultStyle = {
	gT: elm$core$Maybe$Nothing,
	g_: elm$core$Maybe$Nothing,
	iV: elm$core$Maybe$Just(
		{
			bo: 3,
			bp: A4(mdgriffith$elm_ui$Internal$Model$Rgba, 155 / 255, 203 / 255, 1, 1),
			a: _Utils_Tuple2(0, 0),
			ew: 3
		})
};
var mdgriffith$elm_ui$Internal$Model$optionsToRecord = function (options) {
	var combine = F2(
		function (opt, record) {
			switch (opt.$) {
				case 0:
					var hoverable = opt.a;
					var _n4 = record.hJ;
					if (_n4.$ === 1) {
						return _Utils_update(
							record,
							{
								hJ: elm$core$Maybe$Just(hoverable)
							});
					} else {
						return record;
					}
				case 1:
					var focusStyle = opt.a;
					var _n5 = record.hA;
					if (_n5.$ === 1) {
						return _Utils_update(
							record,
							{
								hA: elm$core$Maybe$Just(focusStyle)
							});
					} else {
						return record;
					}
				default:
					var renderMode = opt.a;
					var _n6 = record.ia;
					if (_n6.$ === 1) {
						return _Utils_update(
							record,
							{
								ia: elm$core$Maybe$Just(renderMode)
							});
					} else {
						return record;
					}
			}
		});
	var andFinally = function (record) {
		return {
			hA: function () {
				var _n0 = record.hA;
				if (_n0.$ === 1) {
					return mdgriffith$elm_ui$Internal$Model$focusDefaultStyle;
				} else {
					var focusable = _n0.a;
					return focusable;
				}
			}(),
			hJ: function () {
				var _n1 = record.hJ;
				if (_n1.$ === 1) {
					return 1;
				} else {
					var hoverable = _n1.a;
					return hoverable;
				}
			}(),
			ia: function () {
				var _n2 = record.ia;
				if (_n2.$ === 1) {
					return 1;
				} else {
					var actualMode = _n2.a;
					return actualMode;
				}
			}()
		};
	};
	return andFinally(
		A3(
			elm$core$List$foldr,
			combine,
			{hA: elm$core$Maybe$Nothing, hJ: elm$core$Maybe$Nothing, ia: elm$core$Maybe$Nothing},
			options));
};
var mdgriffith$elm_ui$Internal$Model$toHtml = F2(
	function (mode, el) {
		switch (el.$) {
			case 0:
				var html = el.a;
				return html(mdgriffith$elm_ui$Internal$Model$asEl);
			case 1:
				var styles = el.a.i5;
				var html = el.a.hL;
				return A2(
					html,
					mode(styles),
					mdgriffith$elm_ui$Internal$Model$asEl);
			case 2:
				var text = el.a;
				return mdgriffith$elm_ui$Internal$Model$textElement(text);
			default:
				return mdgriffith$elm_ui$Internal$Model$textElement('');
		}
	});
var mdgriffith$elm_ui$Internal$Model$renderRoot = F3(
	function (optionList, attributes, child) {
		var options = mdgriffith$elm_ui$Internal$Model$optionsToRecord(optionList);
		var embedStyle = function () {
			var _n0 = options.ia;
			if (_n0 === 2) {
				return mdgriffith$elm_ui$Internal$Model$OnlyDynamic(options);
			} else {
				return mdgriffith$elm_ui$Internal$Model$StaticRootAndDynamic(options);
			}
		}();
		return A2(
			mdgriffith$elm_ui$Internal$Model$toHtml,
			embedStyle,
			A4(
				mdgriffith$elm_ui$Internal$Model$element,
				mdgriffith$elm_ui$Internal$Model$asEl,
				mdgriffith$elm_ui$Internal$Model$div,
				attributes,
				mdgriffith$elm_ui$Internal$Model$Unkeyed(
					_List_fromArray(
						[child]))));
	});
var mdgriffith$elm_ui$Internal$Flag$fontFamily = mdgriffith$elm_ui$Internal$Flag$flag(5);
var mdgriffith$elm_ui$Internal$Model$FontFamily = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var mdgriffith$elm_ui$Internal$Model$SansSerif = {$: 1};
var mdgriffith$elm_ui$Internal$Model$Typeface = function (a) {
	return {$: 3, a: a};
};
var elm$core$String$toLower = _String_toLower;
var elm$core$String$words = _String_words;
var mdgriffith$elm_ui$Internal$Model$renderFontClassName = F2(
	function (font, current) {
		return _Utils_ap(
			current,
			function () {
				switch (font.$) {
					case 0:
						return 'serif';
					case 1:
						return 'sans-serif';
					case 2:
						return 'monospace';
					case 3:
						var name = font.a;
						return A2(
							elm$core$String$join,
							'-',
							elm$core$String$words(
								elm$core$String$toLower(name)));
					case 4:
						var name = font.a;
						var url = font.b;
						return A2(
							elm$core$String$join,
							'-',
							elm$core$String$words(
								elm$core$String$toLower(name)));
					default:
						var name = font.a.bA;
						return A2(
							elm$core$String$join,
							'-',
							elm$core$String$words(
								elm$core$String$toLower(name)));
				}
			}());
	});
var mdgriffith$elm_ui$Internal$Model$rootStyle = function () {
	var families = _List_fromArray(
		[
			mdgriffith$elm_ui$Internal$Model$Typeface('Open Sans'),
			mdgriffith$elm_ui$Internal$Model$Typeface('Helvetica'),
			mdgriffith$elm_ui$Internal$Model$Typeface('Verdana'),
			mdgriffith$elm_ui$Internal$Model$SansSerif
		]);
	return _List_fromArray(
		[
			A2(
			mdgriffith$elm_ui$Internal$Model$StyleClass,
			mdgriffith$elm_ui$Internal$Flag$bgColor,
			A3(
				mdgriffith$elm_ui$Internal$Model$Colored,
				'bg-color-' + mdgriffith$elm_ui$Internal$Model$formatColorClass(
					A4(mdgriffith$elm_ui$Internal$Model$Rgba, 1, 1, 1, 0)),
				'background-color',
				A4(mdgriffith$elm_ui$Internal$Model$Rgba, 1, 1, 1, 0))),
			A2(
			mdgriffith$elm_ui$Internal$Model$StyleClass,
			mdgriffith$elm_ui$Internal$Flag$fontColor,
			A3(
				mdgriffith$elm_ui$Internal$Model$Colored,
				'font-color-' + mdgriffith$elm_ui$Internal$Model$formatColorClass(
					A4(mdgriffith$elm_ui$Internal$Model$Rgba, 0, 0, 0, 1)),
				'color',
				A4(mdgriffith$elm_ui$Internal$Model$Rgba, 0, 0, 0, 1))),
			A2(
			mdgriffith$elm_ui$Internal$Model$StyleClass,
			mdgriffith$elm_ui$Internal$Flag$fontSize,
			mdgriffith$elm_ui$Internal$Model$FontSize(20)),
			A2(
			mdgriffith$elm_ui$Internal$Model$StyleClass,
			mdgriffith$elm_ui$Internal$Flag$fontFamily,
			A2(
				mdgriffith$elm_ui$Internal$Model$FontFamily,
				A3(elm$core$List$foldl, mdgriffith$elm_ui$Internal$Model$renderFontClassName, 'font-', families),
				families))
		]);
}();
var mdgriffith$elm_ui$Element$layoutWith = F3(
	function (_n0, attrs, child) {
		var options = _n0.fP;
		return A3(
			mdgriffith$elm_ui$Internal$Model$renderRoot,
			options,
			A2(
				elm$core$List$cons,
				mdgriffith$elm_ui$Internal$Model$htmlClass(
					A2(
						elm$core$String$join,
						' ',
						_List_fromArray(
							[mdgriffith$elm_ui$Internal$Style$classes.iH, mdgriffith$elm_ui$Internal$Style$classes.gQ, mdgriffith$elm_ui$Internal$Style$classes.iW]))),
				_Utils_ap(mdgriffith$elm_ui$Internal$Model$rootStyle, attrs)),
			child);
	});
var mdgriffith$elm_ui$Element$layout = mdgriffith$elm_ui$Element$layoutWith(
	{fP: _List_Nil});
var mdgriffith$elm_ui$Element$Font$family = function (families) {
	return A2(
		mdgriffith$elm_ui$Internal$Model$StyleClass,
		mdgriffith$elm_ui$Internal$Flag$fontFamily,
		A2(
			mdgriffith$elm_ui$Internal$Model$FontFamily,
			A3(elm$core$List$foldl, mdgriffith$elm_ui$Internal$Model$renderFontClassName, 'ff-', families),
			families));
};
var mdgriffith$elm_ui$Internal$Model$Monospace = {$: 2};
var mdgriffith$elm_ui$Element$Font$monospace = mdgriffith$elm_ui$Internal$Model$Monospace;
var author$project$View$Layout$layout = mdgriffith$elm_ui$Element$layout(
	_List_fromArray(
		[
			mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
			mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill),
			mdgriffith$elm_ui$Element$Font$color(
			A3(mdgriffith$elm_ui$Element$rgb, 1, 1, 1)),
			mdgriffith$elm_ui$Element$Font$family(
			_List_fromArray(
				[mdgriffith$elm_ui$Element$Font$monospace]))
		]));
var author$project$View$LoadingScreen$view = function (message) {
	return A2(
		mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				mdgriffith$elm_ui$Element$centerX,
				mdgriffith$elm_ui$Element$centerY,
				mdgriffith$elm_ui$Element$spacing(20)
			]),
		_List_fromArray(
			[
				A2(
				mdgriffith$elm_ui$Element$paragraph,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$shrink),
						mdgriffith$elm_ui$Element$centerX,
						mdgriffith$elm_ui$Element$centerY,
						mdgriffith$elm_ui$Element$Font$size(28),
						mdgriffith$elm_ui$Element$Font$center
					]),
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$text(message)
					])),
				A2(
				mdgriffith$elm_ui$Element$image,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$width(
						mdgriffith$elm_ui$Element$px(36)),
						mdgriffith$elm_ui$Element$centerX
					]),
				{bq: 'Loading animation', bJ: 'assets/spinner.svg'})
			]));
};
var author$project$Page$Blueprint$view = function (model) {
	var content = function () {
		var _n0 = A2(author$project$Data$Session$getLevel, model.a8, model.f);
		switch (_n0.$) {
			case 0:
				return author$project$View$ErrorScreen$view('Not asked :/');
			case 1:
				return author$project$View$LoadingScreen$view('Loading level ' + model.a8);
			case 2:
				var error = _n0.a;
				return author$project$View$ErrorScreen$view(
					author$project$Data$GetError$toString(error));
			default:
				var level = _n0.a;
				return A2(author$project$Page$Blueprint$viewBlueprint, level, model);
		}
	}();
	var body = elm$core$List$singleton(
		author$project$View$Layout$layout(content));
	return {
		bT: body,
		gn: elm$core$String$concat(
			_List_fromArray(
				['Blueprint', ' - ', author$project$ApplicationName$applicationName]))
	};
};
var author$project$Page$Blueprints$ClickedNewLevel = {$: 0};
var author$project$Page$Blueprints$LevelDeleted = function (a) {
	return {$: 4, a: a};
};
var author$project$Page$Blueprints$LevelDescriptionChanged = function (a) {
	return {$: 5, a: a};
};
var author$project$Page$Blueprints$LevelNameChanged = function (a) {
	return {$: 3, a: a};
};
var author$project$Page$Blueprints$SelectedLevelId = function (a) {
	return {$: 2, a: a};
};
var author$project$View$LevelButton$default = {cx: _List_Nil, h_: false, il: elm$core$Maybe$Nothing, iR: false};
var mdgriffith$elm_ui$Element$spaceEvenly = A2(mdgriffith$elm_ui$Internal$Model$Class, mdgriffith$elm_ui$Internal$Flag$spacing, mdgriffith$elm_ui$Internal$Style$classes.i_);
var author$project$View$LevelButton$loading = function (levelId) {
	return A2(
		mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				mdgriffith$elm_ui$Element$padding(20),
				mdgriffith$elm_ui$Element$Border$width(3),
				mdgriffith$elm_ui$Element$Border$color(author$project$View$Constant$color.fk.gk),
				mdgriffith$elm_ui$Element$width(
				mdgriffith$elm_ui$Element$px(256)),
				mdgriffith$elm_ui$Element$spaceEvenly,
				mdgriffith$elm_ui$Element$height(
				mdgriffith$elm_ui$Element$px(181))
			]),
		_List_fromArray(
			[
				A2(
				mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[mdgriffith$elm_ui$Element$centerX]),
				A2(
					mdgriffith$elm_ui$Element$image,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$width(
							mdgriffith$elm_ui$Element$px(30))
						]),
					{bq: '', bJ: 'assets/spinner.svg'})),
				A2(
				mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[mdgriffith$elm_ui$Element$centerX]),
				A2(
					mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$Font$color(
							A3(mdgriffith$elm_ui$Element$rgb, 0.2, 0.2, 0.2))
						]),
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$text(levelId)
						])))
			]));
};
var author$project$View$LevelButton$internal = F3(
	function (parameters, title, subtitle) {
		return A2(
			mdgriffith$elm_ui$Element$Input$button,
			parameters.h_ ? A2(
				elm$core$List$cons,
				mdgriffith$elm_ui$Element$htmlAttribute(
					elm$html$Html$Attributes$class('solved')),
				parameters.cx) : parameters.cx,
			{
				fB: A2(
					mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$padding(20),
							mdgriffith$elm_ui$Element$Border$width(3),
							mdgriffith$elm_ui$Element$width(
							mdgriffith$elm_ui$Element$px(256)),
							mdgriffith$elm_ui$Element$spaceEvenly,
							mdgriffith$elm_ui$Element$height(
							mdgriffith$elm_ui$Element$px(181)),
							mdgriffith$elm_ui$Element$mouseOver(
							_List_fromArray(
								[
									mdgriffith$elm_ui$Element$Background$color(
									A4(mdgriffith$elm_ui$Element$rgba, 1, 1, 1, 0.5))
								])),
							mdgriffith$elm_ui$Element$Background$color(
							parameters.iR ? A4(mdgriffith$elm_ui$Element$rgba, 1, 1, 1, 0.4) : A4(mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0))
						]),
					_List_fromArray(
						[
							A2(
							mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[mdgriffith$elm_ui$Element$centerX, mdgriffith$elm_ui$Element$Font$center]),
							A2(
								mdgriffith$elm_ui$Element$paragraph,
								_List_Nil,
								_List_fromArray(
									[
										mdgriffith$elm_ui$Element$text(title)
									]))),
							A2(
							mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[mdgriffith$elm_ui$Element$centerX]),
							A2(
								mdgriffith$elm_ui$Element$paragraph,
								_List_fromArray(
									[
										mdgriffith$elm_ui$Element$Font$color(
										A3(mdgriffith$elm_ui$Element$rgb, 0.2, 0.2, 0.2))
									]),
								_List_fromArray(
									[
										mdgriffith$elm_ui$Element$text(subtitle)
									])))
						])),
				il: parameters.il
			});
	});
var author$project$View$LevelButton$view = F2(
	function (parameters, level) {
		return A3(author$project$View$LevelButton$internal, parameters, level.bA, level.b6);
	});
var mdgriffith$elm_ui$Internal$Model$Top = 0;
var mdgriffith$elm_ui$Element$alignTop = mdgriffith$elm_ui$Internal$Model$AlignY(0);
var author$project$View$SingleSidebar$view = F3(
	function (sidebarContent, mainContent, session) {
		var sidebar = A2(
			mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$width(
					mdgriffith$elm_ui$Element$fillPortion(1)),
					mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill),
					mdgriffith$elm_ui$Element$padding(20),
					mdgriffith$elm_ui$Element$spacing(20),
					mdgriffith$elm_ui$Element$alignTop,
					mdgriffith$elm_ui$Element$Background$color(
					A3(mdgriffith$elm_ui$Element$rgb, 5.0e-2, 5.0e-2, 5.0e-2)),
					mdgriffith$elm_ui$Element$scrollbarY
				]),
			sidebarContent);
		var main = A2(
			mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$width(
					mdgriffith$elm_ui$Element$fillPortion(3)),
					mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill),
					mdgriffith$elm_ui$Element$scrollbarY,
					mdgriffith$elm_ui$Element$padding(20)
				]),
			mainContent);
		var header = author$project$View$Header$view(session);
		return author$project$View$Scewn$view(
			{
				g5: elm$core$Maybe$Just(main),
				hp: elm$core$Maybe$Nothing,
				h9: elm$core$Maybe$Nothing,
				ie: elm$core$Maybe$Just(header),
				iZ: elm$core$Maybe$Nothing,
				jz: elm$core$Maybe$Just(sidebar)
			});
	});
var author$project$View$SingleSidebar$layout = F3(
	function (a, b, c) {
		return author$project$View$Layout$layout(
			A3(author$project$View$SingleSidebar$view, a, b, c));
	});
var author$project$ViewComponents$textButton = F3(
	function (attributes, onPress, buttonText) {
		return A2(
			mdgriffith$elm_ui$Element$Input$button,
			elm$core$List$concat(
				_List_fromArray(
					[
						_List_fromArray(
						[
							mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
							mdgriffith$elm_ui$Element$Border$width(4),
							mdgriffith$elm_ui$Element$Border$color(
							A3(mdgriffith$elm_ui$Element$rgb, 1, 1, 1)),
							mdgriffith$elm_ui$Element$padding(10),
							mdgriffith$elm_ui$Element$mouseOver(
							_List_fromArray(
								[
									mdgriffith$elm_ui$Element$Background$color(
									A4(mdgriffith$elm_ui$Element$rgba, 1, 1, 1, 0.5))
								]))
						]),
						attributes
					])),
			{
				fB: A2(
					mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[mdgriffith$elm_ui$Element$centerX, mdgriffith$elm_ui$Element$centerY]),
					mdgriffith$elm_ui$Element$text(buttonText)),
				il: onPress
			});
	});
var mdgriffith$elm_ui$Internal$Model$Min = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var mdgriffith$elm_ui$Element$minimum = F2(
	function (i, l) {
		return A2(mdgriffith$elm_ui$Internal$Model$Min, i, l);
	});
var mdgriffith$elm_ui$Element$Input$TextArea = {$: 1};
var mdgriffith$elm_ui$Element$Input$multiline = F2(
	function (attrs, multi) {
		return A3(
			mdgriffith$elm_ui$Element$Input$textHelper,
			{Z: elm$core$Maybe$Nothing, ab: multi.i0, am: mdgriffith$elm_ui$Element$Input$TextArea},
			attrs,
			{fB: multi.fB, c6: multi.c6, db: multi.db, dj: multi.dj});
	});
var author$project$Page$Blueprints$viewCampaign = F2(
	function (campaign, model) {
		var session = model.f;
		var sidebarContent = function () {
			var _n1 = A2(
				elm$core$Maybe$andThen,
				krisajenkins$remotedata$RemoteData$toMaybe,
				A2(
					elm$core$Maybe$map,
					A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Data$Session$getLevel, session),
					model.aY));
			if (!_n1.$) {
				var level = _n1.a;
				var openBlueprint = A3(
					author$project$Route$link,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill)
						]),
					A3(author$project$ViewComponents$textButton, _List_Nil, elm$core$Maybe$Nothing, 'Open'),
					author$project$Route$Blueprint(level.b6));
				var levelName = A2(
					mdgriffith$elm_ui$Element$Input$text,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$Background$color(
							A3(mdgriffith$elm_ui$Element$rgb, 0.1, 0.1, 0.1))
						]),
					{
						fB: A2(
							mdgriffith$elm_ui$Element$Input$labelAbove,
							_List_Nil,
							mdgriffith$elm_ui$Element$text('Level name')),
						c6: author$project$Page$Blueprints$LevelNameChanged,
						db: elm$core$Maybe$Nothing,
						dj: level.bA
					});
				var levelDescription = A2(
					mdgriffith$elm_ui$Element$Input$multiline,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$Background$color(
							A3(mdgriffith$elm_ui$Element$rgb, 0.1, 0.1, 0.1)),
							mdgriffith$elm_ui$Element$height(
							A2(mdgriffith$elm_ui$Element$minimum, 200, mdgriffith$elm_ui$Element$shrink))
						]),
					{
						fB: A2(
							mdgriffith$elm_ui$Element$Input$labelAbove,
							_List_Nil,
							mdgriffith$elm_ui$Element$text('Level description')),
						c6: author$project$Page$Blueprints$LevelDescriptionChanged,
						db: elm$core$Maybe$Nothing,
						i0: true,
						dj: A2(elm$core$String$join, '\n', level.bq)
					});
				var deleteBlueprint = A3(
					author$project$ViewComponents$textButton,
					_List_Nil,
					elm$core$Maybe$Just(
						author$project$Page$Blueprints$LevelDeleted(level.b6)),
					'Delete');
				return _List_fromArray(
					[levelName, levelDescription, openBlueprint, deleteBlueprint]);
			} else {
				return _List_fromArray(
					[
						A2(
						mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[mdgriffith$elm_ui$Element$centerX]),
						mdgriffith$elm_ui$Element$text('Blueprints'))
					]);
			}
		}();
		var mainContent = function () {
			var plusButton = A3(
				author$project$ViewComponents$textButton,
				_List_Nil,
				elm$core$Maybe$Just(author$project$Page$Blueprints$ClickedNewLevel),
				'Create new level');
			var _default = author$project$View$LevelButton$default;
			var levelButton = function (levelId) {
				var _n0 = krisajenkins$remotedata$RemoteData$toMaybe(
					A2(author$project$Data$Session$getLevel, levelId, session));
				if (!_n0.$) {
					var level = _n0.a;
					return A2(
						author$project$View$LevelButton$view,
						_Utils_update(
							_default,
							{
								il: elm$core$Maybe$Just(
									author$project$Page$Blueprints$SelectedLevelId(levelId)),
								iR: A2(
									elm$core$Maybe$withDefault,
									false,
									A2(
										elm$core$Maybe$map,
										elm$core$Basics$eq(levelId),
										model.aY))
							}),
						level);
				} else {
					return author$project$View$LevelButton$loading(levelId);
				}
			};
			var levelButtons = A2(elm$core$List$map, levelButton, campaign.aQ);
			return A2(
				mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
						mdgriffith$elm_ui$Element$spacing(30)
					]),
				_List_fromArray(
					[
						plusButton,
						A2(
						mdgriffith$elm_ui$Element$wrappedRow,
						_List_fromArray(
							[
								mdgriffith$elm_ui$Element$spacing(20)
							]),
						levelButtons)
					]));
		}();
		return A3(author$project$View$SingleSidebar$layout, sidebarContent, mainContent, model.f);
	});
var author$project$View$ErrorScreen$layout = A2(elm$core$Basics$composeR, author$project$View$ErrorScreen$view, author$project$View$Layout$layout);
var author$project$View$LoadingScreen$layout = A2(elm$core$Basics$composeR, author$project$View$LoadingScreen$view, author$project$View$Layout$layout);
var author$project$Page$Blueprints$view = function (model) {
	var session = model.f;
	var content = function () {
		var _n0 = model.ff;
		if (!_n0.$) {
			var error = _n0.a;
			return author$project$View$ErrorScreen$layout(error);
		} else {
			var _n1 = A2(author$project$Data$Session$getCampaign, author$project$Page$Blueprints$campaignId, session);
			switch (_n1.$) {
				case 0:
					return author$project$View$ErrorScreen$layout('Not asked :/');
				case 1:
					return author$project$View$LoadingScreen$layout('Loading ' + author$project$Page$Blueprints$campaignId);
				case 2:
					var error = _n1.a;
					return author$project$View$ErrorScreen$layout(
						author$project$Data$GetError$toString(error));
				default:
					var campaign = _n1.a;
					return A2(author$project$Page$Blueprints$viewCampaign, campaign, model);
			}
		}
	}();
	return {
		bT: _List_fromArray(
			[content]),
		gn: elm$core$String$concat(
			_List_fromArray(
				['Blueprints', ' - ', author$project$ApplicationName$applicationName]))
	};
};
var author$project$Data$Session$getSolutionBook = F2(
	function (levelId, session) {
		return A2(author$project$Data$Cache$get, levelId, session.aZ);
	});
var author$project$Page$Campaign$SelectLevel = function (a) {
	return {$: 0, a: a};
};
var author$project$View$Box$gray = A3(mdgriffith$elm_ui$Element$rgb, 0.2, 0.2, 0.2);
var author$project$View$Box$nonInteractive = function (element) {
	return A2(
		mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				mdgriffith$elm_ui$Element$Border$color(author$project$View$Box$gray),
				mdgriffith$elm_ui$Element$Border$width(3),
				mdgriffith$elm_ui$Element$padding(10),
				mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill)
			]),
		element);
};
var author$project$View$Box$simpleError = function (message) {
	return author$project$View$Box$nonInteractive(
		A2(
			mdgriffith$elm_ui$Element$paragraph,
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$Font$color(author$project$View$Constant$color.fk.ff),
					mdgriffith$elm_ui$Element$Font$center
				]),
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$text(message)
				])));
};
var author$project$View$Box$simpleLoading = function (message) {
	return author$project$View$Box$nonInteractive(
		A2(
			mdgriffith$elm_ui$Element$paragraph,
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$Font$color(author$project$View$Constant$color.fk.gk),
					mdgriffith$elm_ui$Element$Font$center
				]),
			_List_fromArray(
				[
					A2(
					mdgriffith$elm_ui$Element$image,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$width(
							mdgriffith$elm_ui$Element$px(20)),
							mdgriffith$elm_ui$Element$alignRight
						]),
					{bq: '', bJ: 'assets/spinner.svg'}),
					mdgriffith$elm_ui$Element$text(message)
				])));
};
var elm$core$List$sortWith = _List_sortWith;
var elm$core$Set$isEmpty = function (_n0) {
	var dict = _n0;
	return elm$core$Dict$isEmpty(dict);
};
var author$project$Page$Campaign$viewLevels = F2(
	function (campaign, model) {
		var viewLevel = function (level) {
			var solved = !A2(
				krisajenkins$remotedata$RemoteData$withDefault,
				true,
				A2(
					krisajenkins$remotedata$RemoteData$map,
					elm$core$Set$isEmpty,
					A2(
						krisajenkins$remotedata$RemoteData$map,
						function ($) {
							return $.iY;
						},
						A3(elm_community$basics_extra$Basics$Extra$flip, author$project$Data$Session$getSolutionBook, model.f, level.b6))));
			var selected = A2(
				elm$core$Maybe$withDefault,
				false,
				A2(
					elm$core$Maybe$map,
					elm$core$Basics$eq(level.b6),
					model.aY));
			var onPress = elm$core$Maybe$Just(
				author$project$Page$Campaign$SelectLevel(level.b6));
			var _default = author$project$View$LevelButton$default;
			var parameters = _Utils_update(
				_default,
				{h_: solved, il: onPress, iR: selected});
			return A2(author$project$View$LevelButton$view, parameters, level);
		};
		var viewLevelWebData = function (webData) {
			switch (webData.$) {
				case 0:
					return author$project$View$Box$simpleLoading('Not asked :/');
				case 1:
					return author$project$View$Box$simpleLoading('Loading level...');
				case 2:
					var error = webData.a;
					return author$project$View$Box$simpleError(
						author$project$Data$GetError$toString(error));
				default:
					var level = webData.a;
					return viewLevel(level);
			}
		};
		var sort = F2(
			function (d1, d2) {
				var _n0 = _Utils_Tuple2(d1, d2);
				if (_n0.a.$ === 3) {
					if (_n0.b.$ === 3) {
						var a = _n0.a.a;
						var b = _n0.b.a;
						return A2(elm$core$Basics$compare, a.b7, b.b7);
					} else {
						return 0;
					}
				} else {
					if (_n0.b.$ === 3) {
						return 2;
					} else {
						return 1;
					}
				}
			});
		return A2(
			mdgriffith$elm_ui$Element$el,
			_List_Nil,
			A2(
				mdgriffith$elm_ui$Element$wrappedRow,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$spacing(20)
					]),
				A2(
					elm$core$List$map,
					viewLevelWebData,
					A2(
						elm$core$List$sortWith,
						sort,
						A2(
							elm$core$List$map,
							A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Data$Session$getLevel, model.f),
							campaign.aQ)))));
	});
var author$project$Data$Draft$getInstructionCount = F2(
	function (initialBoard, draft) {
		var count = author$project$Data$Board$count(
			elm$core$Basics$neq(author$project$Data$Instruction$NoOp));
		var currentInstructionCount = count(
			author$project$Data$History$current(draft.G));
		var initialInstructionCount = count(initialBoard);
		return currentInstructionCount - initialInstructionCount;
	});
var author$project$Data$Session$getDraftBook = F2(
	function (levelId, session) {
		return A2(author$project$Data$Cache$get, levelId, session.aJ);
	});
var author$project$Page$Campaign$ClickedGenerateDraft = {$: 2};
var author$project$Page$Campaign$viewDrafts = F2(
	function (level, session) {
		var _n0 = A2(author$project$Data$Session$getDraftBook, level.b6, session);
		switch (_n0.$) {
			case 0:
				return author$project$View$Box$simpleLoading('Not asked');
			case 1:
				return author$project$View$Box$simpleLoading('Loading drafts');
			case 2:
				var error = _n0.a;
				return author$project$View$Box$simpleError(
					author$project$Data$GetError$toString(error));
			default:
				var draftBook = _n0.a;
				var viewDraft = F2(
					function (index, draftId) {
						var _n1 = A2(author$project$Data$Cache$get, draftId, session.dC.aj);
						switch (_n1.$) {
							case 0:
								return author$project$View$Box$simpleLoading('Not asked');
							case 1:
								return author$project$View$Box$simpleLoading(
									'Loading draft ' + elm$core$String$fromInt(index + 1));
							case 2:
								var error = _n1.a;
								return author$project$View$Box$simpleError(
									elm$json$Json$Decode$errorToString(error));
							default:
								if (_n1.a.$ === 1) {
									var _n2 = _n1.a;
									return author$project$View$Box$simpleError('Not found');
								} else {
									var draft = _n1.a.a;
									var maybeSolution = elm$core$List$head(
										A2(
											elm$core$List$filter,
											A2(
												elm$core$Basics$composeR,
												function ($) {
													return $.cy;
												},
												elm$core$Basics$eq(
													author$project$Data$History$current(draft.G))),
											elm_community$maybe_extra$Maybe$Extra$values(
												A2(
													elm$core$List$filterMap,
													krisajenkins$remotedata$RemoteData$toMaybe,
													A2(
														elm$core$List$map,
														A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Data$Cache$get, session.ex.aj),
														A2(
															krisajenkins$remotedata$RemoteData$withDefault,
															_List_Nil,
															A2(
																krisajenkins$remotedata$RemoteData$map,
																elm$core$Set$toList,
																A2(
																	krisajenkins$remotedata$RemoteData$map,
																	function ($) {
																		return $.iY;
																	},
																	A2(author$project$Data$Cache$get, level.b6, session.aZ)))))))));
									var draftName = 'Draft ' + elm$core$String$fromInt(index + 1);
									var attrs = _List_fromArray(
										[
											mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
											mdgriffith$elm_ui$Element$padding(10),
											mdgriffith$elm_ui$Element$spacing(15),
											mdgriffith$elm_ui$Element$Border$width(3),
											mdgriffith$elm_ui$Element$Border$color(
											A3(mdgriffith$elm_ui$Element$rgb, 1, 1, 1)),
											mdgriffith$elm_ui$Element$centerX,
											mdgriffith$elm_ui$Element$mouseOver(
											_List_fromArray(
												[
													mdgriffith$elm_ui$Element$Background$color(
													A3(mdgriffith$elm_ui$Element$rgb, 0.5, 0.5, 0.5))
												])),
											mdgriffith$elm_ui$Element$htmlAttribute(
											elm$html$Html$Attributes$class(
												elm_community$maybe_extra$Maybe$Extra$isJust(maybeSolution) ? 'solved' : ''))
										]);
									var label = A2(
										mdgriffith$elm_ui$Element$column,
										attrs,
										_List_fromArray(
											[
												A2(
												mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[
														mdgriffith$elm_ui$Element$centerX,
														mdgriffith$elm_ui$Element$Font$size(24)
													]),
												mdgriffith$elm_ui$Element$text(draftName)),
												A2(
												mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[
														mdgriffith$elm_ui$Element$centerX,
														mdgriffith$elm_ui$Element$Font$color(
														A3(mdgriffith$elm_ui$Element$rgb, 0.2, 0.2, 0.2))
													]),
												mdgriffith$elm_ui$Element$text(draft.b6)),
												A2(
												mdgriffith$elm_ui$Element$row,
												_List_fromArray(
													[
														mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
														mdgriffith$elm_ui$Element$spaceEvenly
													]),
												_List_fromArray(
													[
														mdgriffith$elm_ui$Element$text('Instructions: '),
														mdgriffith$elm_ui$Element$text(
														elm$core$String$fromInt(
															A2(author$project$Data$Draft$getInstructionCount, level.hT, draft)))
													])),
												A2(
												elm$core$Maybe$withDefault,
												mdgriffith$elm_ui$Element$none,
												A2(
													elm$core$Maybe$map,
													function (solution) {
														return A2(
															mdgriffith$elm_ui$Element$row,
															_List_fromArray(
																[
																	mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
																	mdgriffith$elm_ui$Element$spaceEvenly
																]),
															_List_fromArray(
																[
																	mdgriffith$elm_ui$Element$text('Steps: '),
																	mdgriffith$elm_ui$Element$text(
																	elm$core$String$fromInt(solution.df.bd))
																]));
													},
													maybeSolution))
											]));
									return A3(
										author$project$Route$link,
										_List_fromArray(
											[
												mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill)
											]),
										label,
										author$project$Route$EditDraft(draft.b6));
								}
						}
					});
				var newDraftButton = A3(
					author$project$ViewComponents$textButton,
					_List_Nil,
					elm$core$Maybe$Just(author$project$Page$Campaign$ClickedGenerateDraft),
					'New draft');
				return A2(
					mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
							mdgriffith$elm_ui$Element$spacing(20)
						]),
					A2(
						elm$core$List$cons,
						newDraftButton,
						A2(
							elm$core$List$indexedMap,
							viewDraft,
							elm$core$Set$toList(draftBook.aK))));
		}
	});
var author$project$View$Box$simpleNonInteractive = function (message) {
	return author$project$View$Box$nonInteractive(
		A2(
			mdgriffith$elm_ui$Element$paragraph,
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$Font$color(author$project$View$Constant$color.fk.gk),
					mdgriffith$elm_ui$Element$Font$center
				]),
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$text(message)
				])));
};
var author$project$View$HighScore$failure = function (error) {
	var errorMessage = author$project$Data$GetError$toString(error);
	return author$project$View$Box$nonInteractive(
		A2(
			mdgriffith$elm_ui$Element$paragraph,
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$Font$color(author$project$View$Constant$color.fk.ff),
					mdgriffith$elm_ui$Element$Font$center
				]),
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$text(errorMessage)
				])));
};
var author$project$Extra$Maybe$update = F2(
	function (maybe, _function) {
		if (!maybe.$) {
			var key = maybe.a;
			return A2(elm$core$Dict$update, key, _function);
		} else {
			return elm$core$Basics$identity;
		}
	});
var author$project$View$BarChart$dateFormat = elm$core$String$fromInt;
var author$project$View$BarChart$scaleFactor = 0.5;
var author$project$View$BarChart$h = 450 * author$project$View$BarChart$scaleFactor;
var author$project$View$BarChart$paddingBottom = 70 * author$project$View$BarChart$scaleFactor;
var author$project$View$BarChart$paddingTop = 20 * author$project$View$BarChart$scaleFactor;
var gampleman$elm_visualization$Scale$Scale = elm$core$Basics$identity;
var gampleman$elm_visualization$Scale$Internal$bimap = F4(
	function (_n0, _n1, deinterpolate, reinterpolate) {
		var d0 = _n0.a;
		var d1 = _n0.b;
		var r0 = _n1.a;
		var r1 = _n1.b;
		var _n2 = (_Utils_cmp(d1, d0) < 0) ? _Utils_Tuple2(
			A2(deinterpolate, d1, d0),
			A2(reinterpolate, r1, r0)) : _Utils_Tuple2(
			A2(deinterpolate, d0, d1),
			A2(reinterpolate, r0, r1));
		var de = _n2.a;
		var re = _n2.b;
		return A2(elm$core$Basics$composeL, re, de);
	});
var gampleman$elm_visualization$Scale$Internal$interpolateFloat = F3(
	function (from, to, time) {
		return from + ((to - from) * time);
	});
var gampleman$elm_visualization$Scale$Linear$deinterpolate = F3(
	function (a, b, x) {
		var normalizedB = b - a;
		return (!normalizedB) ? 0 : ((x - a) / normalizedB);
	});
var gampleman$elm_visualization$Scale$Linear$convert = F2(
	function (domain, range) {
		return A4(gampleman$elm_visualization$Scale$Internal$bimap, domain, range, gampleman$elm_visualization$Scale$Linear$deinterpolate, gampleman$elm_visualization$Scale$Internal$interpolateFloat);
	});
var gampleman$elm_visualization$Scale$Linear$interpolate = gampleman$elm_visualization$Scale$Internal$interpolateFloat;
var gampleman$elm_visualization$Scale$Linear$invert = F2(
	function (domain, range) {
		return A4(gampleman$elm_visualization$Scale$Internal$bimap, range, domain, gampleman$elm_visualization$Scale$Linear$deinterpolate, gampleman$elm_visualization$Scale$Linear$interpolate);
	});
var elm$core$Basics$e = _Basics_e;
var elm$core$Basics$sqrt = _Basics_sqrt;
var gampleman$elm_visualization$Statistics$tickStep = F3(
	function (start, stop, count) {
		var step0 = elm$core$Basics$abs(stop - start) / A2(elm$core$Basics$max, 0, count);
		var step1 = A2(
			elm$core$Basics$pow,
			10,
			elm$core$Basics$floor(
				A2(elm$core$Basics$logBase, elm$core$Basics$e, step0) / A2(elm$core$Basics$logBase, elm$core$Basics$e, 10)));
		var error = step0 / step1;
		var step2 = (_Utils_cmp(
			error,
			elm$core$Basics$sqrt(50)) > -1) ? (step1 * 10) : ((_Utils_cmp(
			error,
			elm$core$Basics$sqrt(10)) > -1) ? (step1 * 5) : ((_Utils_cmp(
			error,
			elm$core$Basics$sqrt(2)) > -1) ? (step1 * 2) : step1));
		return (_Utils_cmp(stop, start) < 0) ? (-step2) : step2;
	});
var gampleman$elm_visualization$Scale$Linear$nice = F2(
	function (_n0, count) {
		var start = _n0.a;
		var stop = _n0.b;
		var step0 = A3(gampleman$elm_visualization$Statistics$tickStep, start, stop, count);
		var step1 = A3(
			gampleman$elm_visualization$Statistics$tickStep,
			elm$core$Basics$floor(start / step0) * step0,
			elm$core$Basics$ceiling(stop / step0) * step0,
			count);
		return _Utils_Tuple2(
			elm$core$Basics$floor(start / step1) * step1,
			elm$core$Basics$ceiling(stop / step1) * step1);
	});
var gampleman$elm_visualization$Scale$Linear$rangeExtent = F2(
	function (d, r) {
		return r;
	});
var elm$core$String$cons = _String_cons;
var elm$core$String$fromChar = function (_char) {
	return A2(elm$core$String$cons, _char, '');
};
var elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3(elm$core$String$repeatHelp, n, chunk, '');
	});
var elm$core$String$padRight = F3(
	function (n, _char, string) {
		return _Utils_ap(
			string,
			A2(
				elm$core$String$repeat,
				n - elm$core$String$length(string),
				elm$core$String$fromChar(_char)));
	});
var gampleman$elm_visualization$Scale$Internal$toFixed = F2(
	function (precision, value) {
		var power = A2(elm$core$Basics$pow, 10, precision);
		var pad = function (num) {
			_n0$2:
			while (true) {
				if (num.b) {
					if (num.b.b) {
						if (!num.b.b.b) {
							var x = num.a;
							var _n1 = num.b;
							var y = _n1.a;
							return _List_fromArray(
								[
									x,
									A3(elm$core$String$padRight, precision, '0', y)
								]);
						} else {
							break _n0$2;
						}
					} else {
						var val = num.a;
						return (precision > 0) ? _List_fromArray(
							[
								val,
								A3(elm$core$String$padRight, precision, '0', '')
							]) : _List_fromArray(
							[val]);
					}
				} else {
					break _n0$2;
				}
			}
			var val = num;
			return val;
		};
		return A2(
			elm$core$String$join,
			'.',
			pad(
				A2(
					elm$core$String$split,
					'.',
					elm$core$String$fromFloat(
						elm$core$Basics$round(value * power) / power))));
	});
var gampleman$elm_visualization$Scale$Linear$exponent = function (x) {
	return (!x) ? 0 : ((x < 1) ? (1 + gampleman$elm_visualization$Scale$Linear$exponent(x * 10)) : 0);
};
var gampleman$elm_visualization$Scale$Linear$precisionFixed = function (step) {
	return A2(
		elm$core$Basics$max,
		0,
		gampleman$elm_visualization$Scale$Linear$exponent(
			elm$core$Basics$abs(step)));
};
var gampleman$elm_visualization$Scale$Linear$tickFormat = F2(
	function (_n0, count) {
		var start = _n0.a;
		var stop = _n0.b;
		return gampleman$elm_visualization$Scale$Internal$toFixed(
			gampleman$elm_visualization$Scale$Linear$precisionFixed(
				A3(gampleman$elm_visualization$Statistics$tickStep, start, stop, count)));
	});
var gampleman$elm_visualization$Statistics$range = F3(
	function (start, stop, step) {
		var n = A2(
			elm$core$Basics$max,
			0,
			0 | elm$core$Basics$ceiling((stop - start) / step));
		var helper = F2(
			function (i, list) {
				return (i >= 0) ? A2(
					helper,
					i - 1,
					A2(elm$core$List$cons, start + (step * i), list)) : list;
			});
		return A2(helper, n - 1, _List_Nil);
	});
var gampleman$elm_visualization$Statistics$ticks = F3(
	function (start, stop, count) {
		var step = A3(gampleman$elm_visualization$Statistics$tickStep, start, stop, count);
		var end = (elm$core$Basics$floor(stop / step) * step) + (step / 2);
		var beg = elm$core$Basics$ceiling(start / step) * step;
		return A3(gampleman$elm_visualization$Statistics$range, beg, end, step);
	});
var gampleman$elm_visualization$Scale$Linear$ticks = F2(
	function (_n0, count) {
		var start = _n0.a;
		var end = _n0.b;
		return A3(gampleman$elm_visualization$Statistics$ticks, start, end, count);
	});
var gampleman$elm_visualization$Scale$linear = F2(
	function (range_, domain_) {
		return {P: gampleman$elm_visualization$Scale$Linear$convert, cK: domain_, cV: gampleman$elm_visualization$Scale$Linear$invert, ca: gampleman$elm_visualization$Scale$Linear$nice, F: range_, bF: gampleman$elm_visualization$Scale$Linear$rangeExtent, eG: gampleman$elm_visualization$Scale$Linear$tickFormat, eH: gampleman$elm_visualization$Scale$Linear$ticks};
	});
var author$project$View$BarChart$yScale = gampleman$elm_visualization$Scale$linear(
	_Utils_Tuple2((author$project$View$BarChart$h - author$project$View$BarChart$paddingBottom) - author$project$View$BarChart$paddingTop, 0));
var elm$virtual_dom$VirtualDom$nodeNS = function (tag) {
	return _VirtualDom_nodeNS(
		_VirtualDom_noScript(tag));
};
var elm_community$typed_svg$TypedSvg$Core$node = elm$virtual_dom$VirtualDom$nodeNS('http://www.w3.org/2000/svg');
var elm_community$typed_svg$TypedSvg$g = elm_community$typed_svg$TypedSvg$Core$node('g');
var elm_community$typed_svg$TypedSvg$polygon = elm_community$typed_svg$TypedSvg$Core$node('polygon');
var elm_community$typed_svg$TypedSvg$rect = elm_community$typed_svg$TypedSvg$Core$node('rect');
var elm_community$typed_svg$TypedSvg$text_ = elm_community$typed_svg$TypedSvg$Core$node('text');
var elm_community$typed_svg$TypedSvg$Core$attribute = elm$virtual_dom$VirtualDom$attribute;
var elm_community$typed_svg$TypedSvg$Attributes$class = function (names) {
	return A2(
		elm_community$typed_svg$TypedSvg$Core$attribute,
		'class',
		A2(elm$core$String$join, ' ', names));
};
var elm_community$typed_svg$TypedSvg$Attributes$points = function (pts) {
	var pointToString = function (_n0) {
		var xx = _n0.a;
		var yy = _n0.b;
		return elm$core$String$fromFloat(xx) + (', ' + elm$core$String$fromFloat(yy));
	};
	return A2(
		elm_community$typed_svg$TypedSvg$Core$attribute,
		'points',
		A2(
			elm$core$String$join,
			' ',
			A2(elm$core$List$map, pointToString, pts)));
};
var elm_community$typed_svg$TypedSvg$TypesToStrings$anchorAlignmentToString = function (anchorAlignment) {
	switch (anchorAlignment) {
		case 0:
			return 'inherit';
		case 1:
			return 'start';
		case 2:
			return 'middle';
		default:
			return 'end';
	}
};
var elm_community$typed_svg$TypedSvg$Attributes$textAnchor = function (anchorAlignment) {
	return A2(
		elm_community$typed_svg$TypedSvg$Core$attribute,
		'text-anchor',
		elm_community$typed_svg$TypedSvg$TypesToStrings$anchorAlignmentToString(anchorAlignment));
};
var elm_community$typed_svg$TypedSvg$TypesToStrings$lengthToString = function (length) {
	switch (length.$) {
		case 0:
			var x = length.a;
			return elm$core$String$fromFloat(x) + 'cm';
		case 1:
			var x = length.a;
			return elm$core$String$fromFloat(x) + 'em';
		case 2:
			var x = length.a;
			return elm$core$String$fromFloat(x) + 'ex';
		case 3:
			var x = length.a;
			return elm$core$String$fromFloat(x) + 'in';
		case 4:
			var x = length.a;
			return elm$core$String$fromFloat(x) + 'mm';
		case 5:
			var x = length.a;
			return elm$core$String$fromFloat(x);
		case 6:
			var x = length.a;
			return elm$core$String$fromFloat(x) + 'pc';
		case 7:
			var x = length.a;
			return elm$core$String$fromFloat(x) + '%';
		case 8:
			var x = length.a;
			return elm$core$String$fromFloat(x) + 'pt';
		default:
			var x = length.a;
			return elm$core$String$fromFloat(x) + 'px';
	}
};
var elm_community$typed_svg$TypedSvg$Attributes$height = function (length) {
	return A2(
		elm_community$typed_svg$TypedSvg$Core$attribute,
		'height',
		elm_community$typed_svg$TypedSvg$TypesToStrings$lengthToString(length));
};
var elm_community$typed_svg$TypedSvg$Types$Px = function (a) {
	return {$: 9, a: a};
};
var elm_community$typed_svg$TypedSvg$Types$px = elm_community$typed_svg$TypedSvg$Types$Px;
var elm_community$typed_svg$TypedSvg$Attributes$InPx$height = function (value) {
	return elm_community$typed_svg$TypedSvg$Attributes$height(
		elm_community$typed_svg$TypedSvg$Types$px(value));
};
var elm_community$typed_svg$TypedSvg$Attributes$width = function (length) {
	return A2(
		elm_community$typed_svg$TypedSvg$Core$attribute,
		'width',
		elm_community$typed_svg$TypedSvg$TypesToStrings$lengthToString(length));
};
var elm_community$typed_svg$TypedSvg$Attributes$InPx$width = function (value) {
	return elm_community$typed_svg$TypedSvg$Attributes$width(
		elm_community$typed_svg$TypedSvg$Types$px(value));
};
var elm_community$typed_svg$TypedSvg$Attributes$x = function (length) {
	return A2(
		elm_community$typed_svg$TypedSvg$Core$attribute,
		'x',
		elm_community$typed_svg$TypedSvg$TypesToStrings$lengthToString(length));
};
var elm_community$typed_svg$TypedSvg$Attributes$InPx$x = function (value) {
	return elm_community$typed_svg$TypedSvg$Attributes$x(
		elm_community$typed_svg$TypedSvg$Types$px(value));
};
var elm_community$typed_svg$TypedSvg$Attributes$y = function (length) {
	return A2(
		elm_community$typed_svg$TypedSvg$Core$attribute,
		'y',
		elm_community$typed_svg$TypedSvg$TypesToStrings$lengthToString(length));
};
var elm_community$typed_svg$TypedSvg$Attributes$InPx$y = function (value) {
	return elm_community$typed_svg$TypedSvg$Attributes$y(
		elm_community$typed_svg$TypedSvg$Types$px(value));
};
var elm_community$typed_svg$TypedSvg$Core$text = elm$virtual_dom$VirtualDom$text;
var elm_community$typed_svg$TypedSvg$Types$AnchorMiddle = 2;
var gampleman$elm_visualization$Scale$bandwidth = function (_n0) {
	var scale = _n0;
	return scale.dw;
};
var gampleman$elm_visualization$Scale$convert = F2(
	function (_n0, value) {
		var scale = _n0;
		return A3(scale.P, scale.cK, scale.F, value);
	});
var gampleman$elm_visualization$Scale$toRenderable = F2(
	function (toString, _n0) {
		var scale = _n0;
		return {
			P: F3(
				function (dmn, rng, value) {
					return A3(scale.P, dmn, rng, value) + (A2(elm$core$Basics$max, scale.dw - 1, 0) / 2);
				}),
			cK: scale.cK,
			F: scale.F,
			bF: F2(
				function (_n1, rng) {
					return rng;
				}),
			eG: F2(
				function (_n2, _n3) {
					return toString;
				}),
			eH: F2(
				function (dmn, _n4) {
					return dmn;
				})
		};
	});
var author$project$View$BarChart$column = F3(
	function (range, scale, _n0) {
		var xValue = _n0.a;
		var _n1 = _n0.b;
		var yValue = _n1.a;
		var marked = _n1.b;
		return A2(
			elm_community$typed_svg$TypedSvg$g,
			_List_fromArray(
				[
					elm_community$typed_svg$TypedSvg$Attributes$class(
					_List_fromArray(
						['column']))
				]),
			_List_fromArray(
				[
					A2(
					elm_community$typed_svg$TypedSvg$rect,
					_List_fromArray(
						[
							elm_community$typed_svg$TypedSvg$Attributes$InPx$x(
							A2(gampleman$elm_visualization$Scale$convert, scale, xValue)),
							elm_community$typed_svg$TypedSvg$Attributes$InPx$y(
							A2(
								gampleman$elm_visualization$Scale$convert,
								author$project$View$BarChart$yScale(range),
								yValue)),
							elm_community$typed_svg$TypedSvg$Attributes$InPx$width(
							gampleman$elm_visualization$Scale$bandwidth(scale)),
							elm_community$typed_svg$TypedSvg$Attributes$InPx$height(
							((author$project$View$BarChart$h - A2(
								gampleman$elm_visualization$Scale$convert,
								author$project$View$BarChart$yScale(range),
								yValue)) - author$project$View$BarChart$paddingBottom) - author$project$View$BarChart$paddingTop)
						]),
					_List_Nil),
					A2(
					elm_community$typed_svg$TypedSvg$text_,
					_List_fromArray(
						[
							elm_community$typed_svg$TypedSvg$Attributes$InPx$x(
							A2(
								gampleman$elm_visualization$Scale$convert,
								A2(gampleman$elm_visualization$Scale$toRenderable, author$project$View$BarChart$dateFormat, scale),
								xValue)),
							elm_community$typed_svg$TypedSvg$Attributes$InPx$y(
							A2(
								gampleman$elm_visualization$Scale$convert,
								author$project$View$BarChart$yScale(range),
								yValue) - 8),
							elm_community$typed_svg$TypedSvg$Attributes$textAnchor(2)
						]),
					_List_fromArray(
						[
							elm_community$typed_svg$TypedSvg$Core$text(
							elm$core$String$fromInt(yValue))
						])),
					marked ? A2(
					elm_community$typed_svg$TypedSvg$polygon,
					_List_fromArray(
						[
							elm_community$typed_svg$TypedSvg$Attributes$class(
							_List_fromArray(
								['me'])),
							elm_community$typed_svg$TypedSvg$Attributes$points(
							_List_fromArray(
								[
									_Utils_Tuple2(
									(A2(gampleman$elm_visualization$Scale$convert, scale, xValue) + (gampleman$elm_visualization$Scale$bandwidth(scale) / 2)) - 7.071,
									A2(
										gampleman$elm_visualization$Scale$convert,
										author$project$View$BarChart$yScale(range),
										yValue) - 20),
									_Utils_Tuple2(
									(A2(gampleman$elm_visualization$Scale$convert, scale, xValue) + (gampleman$elm_visualization$Scale$bandwidth(scale) / 2)) + 7.071,
									A2(
										gampleman$elm_visualization$Scale$convert,
										author$project$View$BarChart$yScale(range),
										yValue) - 20),
									_Utils_Tuple2(
									A2(gampleman$elm_visualization$Scale$convert, scale, xValue) + (gampleman$elm_visualization$Scale$bandwidth(scale) / 2),
									A2(
										gampleman$elm_visualization$Scale$convert,
										author$project$View$BarChart$yScale(range),
										yValue) - 10)
								]))
						]),
					_List_Nil) : elm_community$typed_svg$TypedSvg$Core$text('')
				]));
	});
var author$project$View$BarChart$maxNumberOfColumns = 17;
var author$project$View$BarChart$paddingLeft = function (maxRange) {
	return author$project$View$BarChart$scaleFactor * (40 + (26 * elm$core$String$length(
		elm$core$String$fromInt(
			elm$core$Basics$floor(maxRange)))));
};
var author$project$View$BarChart$paddingRight = 20 * author$project$View$BarChart$scaleFactor;
var author$project$View$BarChart$w = 900 * author$project$View$BarChart$scaleFactor;
var elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var gampleman$elm_visualization$Scale$Band$normalizeConfig = function (_n0) {
	var paddingInner = _n0.fR;
	var paddingOuter = _n0.fS;
	var align = _n0.eW;
	return {
		eW: A3(elm$core$Basics$clamp, 0, 1, align),
		fR: A3(elm$core$Basics$clamp, 0, 1, paddingInner),
		fS: A3(elm$core$Basics$clamp, 0, 1, paddingOuter)
	};
};
var gampleman$elm_visualization$Scale$Band$bandwidth = F3(
	function (cfg, domain, _n0) {
		var d0 = _n0.a;
		var d1 = _n0.b;
		var n = elm$core$List$length(domain);
		var _n1 = (_Utils_cmp(d0, d1) < 0) ? _Utils_Tuple2(d0, d1) : _Utils_Tuple2(d1, d0);
		var start = _n1.a;
		var stop = _n1.b;
		var _n2 = gampleman$elm_visualization$Scale$Band$normalizeConfig(cfg);
		var paddingInner = _n2.fR;
		var paddingOuter = _n2.fS;
		var align = _n2.eW;
		var step = (stop - start) / A2(elm$core$Basics$max, 1, (n - paddingInner) + (paddingOuter * 2));
		return step * (1 - paddingInner);
	});
var gampleman$elm_visualization$Scale$Band$computePositions = F4(
	function (index, cfg, n, _n0) {
		var start = _n0.a;
		var stop = _n0.b;
		var _n1 = gampleman$elm_visualization$Scale$Band$normalizeConfig(cfg);
		var paddingInner = _n1.fR;
		var paddingOuter = _n1.fS;
		var align = _n1.eW;
		var step = (stop - start) / A2(elm$core$Basics$max, 1, (n - paddingInner) + (paddingOuter * 2));
		var start2 = start + (((stop - start) - (step * (n - paddingInner))) * align);
		return _Utils_Tuple2(start2, step);
	});
var gampleman$elm_visualization$Scale$Band$indexOfHelp = F3(
	function (index, value, list) {
		indexOfHelp:
		while (true) {
			if (!list.b) {
				return elm$core$Maybe$Nothing;
			} else {
				var x = list.a;
				var xs = list.b;
				if (_Utils_eq(value, x)) {
					return elm$core$Maybe$Just(index);
				} else {
					var $temp$index = index + 1,
						$temp$value = value,
						$temp$list = xs;
					index = $temp$index;
					value = $temp$value;
					list = $temp$list;
					continue indexOfHelp;
				}
			}
		}
	});
var gampleman$elm_visualization$Scale$Band$indexOf = gampleman$elm_visualization$Scale$Band$indexOfHelp(0);
var gampleman$elm_visualization$Scale$Band$convert = F4(
	function (cfg, domain, _n0, value) {
		var start = _n0.a;
		var stop = _n0.b;
		var _n1 = A2(gampleman$elm_visualization$Scale$Band$indexOf, value, domain);
		if (!_n1.$) {
			var index = _n1.a;
			var n = elm$core$List$length(domain);
			if (_Utils_cmp(start, stop) < 0) {
				var _n2 = A4(
					gampleman$elm_visualization$Scale$Band$computePositions,
					index,
					cfg,
					n,
					_Utils_Tuple2(start, stop));
				var start2 = _n2.a;
				var step = _n2.b;
				return start2 + (step * index);
			} else {
				var _n3 = A4(
					gampleman$elm_visualization$Scale$Band$computePositions,
					index,
					cfg,
					n,
					_Utils_Tuple2(stop, start));
				var stop2 = _n3.a;
				var step = _n3.b;
				return stop2 + (step * ((n - index) - 1));
			}
		} else {
			return 0 / 0;
		}
	});
var gampleman$elm_visualization$Scale$band = F3(
	function (config, range_, domain_) {
		return {
			dw: A3(gampleman$elm_visualization$Scale$Band$bandwidth, config, domain_, range_),
			P: gampleman$elm_visualization$Scale$Band$convert(config),
			cK: domain_,
			F: range_
		};
	});
var author$project$View$BarChart$xScale = function (p) {
	var config = (elm$core$List$length(p.cK) > 8) ? {eW: 0.5, fR: 0.0, fS: 0.2} : {eW: 0.5, fR: 0.3, fS: 0.2};
	return A3(
		gampleman$elm_visualization$Scale$band,
		config,
		_Utils_Tuple2(0, (author$project$View$BarChart$w - p.ed) - p.ee),
		p.cK);
};
var elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var elm$svg$Svg$g = elm$svg$Svg$trustedNode('g');
var elm$svg$Svg$line = elm$svg$Svg$trustedNode('line');
var elm$svg$Svg$path = elm$svg$Svg$trustedNode('path');
var elm$svg$Svg$text = elm$virtual_dom$VirtualDom$text;
var elm$svg$Svg$text_ = elm$svg$Svg$trustedNode('text');
var elm$svg$Svg$Attributes$class = _VirtualDom_attribute('class');
var elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var elm$svg$Svg$Attributes$dy = _VirtualDom_attribute('dy');
var elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var elm$svg$Svg$Attributes$fontFamily = _VirtualDom_attribute('font-family');
var elm$svg$Svg$Attributes$fontSize = _VirtualDom_attribute('font-size');
var elm$svg$Svg$Attributes$stroke = _VirtualDom_attribute('stroke');
var elm$svg$Svg$Attributes$textAnchor = _VirtualDom_attribute('text-anchor');
var elm$svg$Svg$Attributes$transform = _VirtualDom_attribute('transform');
var gampleman$elm_visualization$Scale$tickFormat = function (_n0) {
	var opts = _n0;
	return opts.eG(opts.cK);
};
var gampleman$elm_visualization$Scale$ticks = F2(
	function (_n0, count) {
		var scale = _n0;
		return A2(scale.eH, scale.cK, count);
	});
var gampleman$elm_visualization$Axis$computeOptions = F2(
	function (attrs, scale) {
		var _n0 = A3(
			elm$core$List$foldl,
			F2(
				function (attr, _n1) {
					var babyOpts = _n1.a;
					var post = _n1.b;
					switch (attr.$) {
						case 2:
							var val = attr.a;
							return _Utils_Tuple2(
								_Utils_update(
									babyOpts,
									{bN: val}),
								post);
						case 3:
							var val = attr.a;
							return _Utils_Tuple2(
								_Utils_update(
									babyOpts,
									{bO: val}),
								post);
						case 4:
							var val = attr.a;
							return _Utils_Tuple2(
								_Utils_update(
									babyOpts,
									{a$: val}),
								post);
						case 5:
							var val = attr.a;
							return _Utils_Tuple2(
								_Utils_update(
									babyOpts,
									{cl: val}),
								post);
						default:
							return _Utils_Tuple2(
								babyOpts,
								A2(elm$core$List$cons, attr, post));
					}
				}),
			_Utils_Tuple2(
				{bN: 10, cl: 3, bO: 6, a$: 6},
				_List_Nil),
			attrs);
		var opts = _n0.a;
		var postList = _n0.b;
		return A3(
			elm$core$List$foldl,
			F2(
				function (attr, options) {
					switch (attr.$) {
						case 0:
							var val = attr.a;
							return _Utils_update(
								options,
								{eH: val});
						case 1:
							var val = attr.a;
							return _Utils_update(
								options,
								{eG: val});
						default:
							return options;
					}
				}),
			{
				bN: opts.bN,
				eG: A2(gampleman$elm_visualization$Scale$tickFormat, scale, opts.bN),
				cl: opts.cl,
				bO: opts.bO,
				a$: opts.a$,
				eH: A2(gampleman$elm_visualization$Scale$ticks, scale, opts.bN)
			},
			postList);
	});
var gampleman$elm_visualization$Scale$rangeExtent = function (_n0) {
	var options = _n0;
	return A2(options.bF, options.cK, options.F);
};
var gampleman$elm_visualization$Axis$element = F4(
	function (_n0, k, displacement, textAnchorPosition) {
		var x = _n0.eR;
		var y = _n0.eS;
		var x1 = _n0.jB;
		var x2 = _n0.jC;
		var y1 = _n0.jF;
		var y2 = _n0.jG;
		var translate = _n0.eJ;
		var horizontal = _n0.dH;
		return F2(
			function (attrs, scale) {
				var rangeExtent = gampleman$elm_visualization$Scale$rangeExtent(scale);
				var range1 = rangeExtent.b + 0.5;
				var range0 = rangeExtent.a + 0.5;
				var position = gampleman$elm_visualization$Scale$convert(scale);
				var opts = A2(gampleman$elm_visualization$Axis$computeOptions, attrs, scale);
				var spacing = A2(elm$core$Basics$max, opts.bO, 0) + opts.cl;
				var drawTick = function (tick) {
					return A2(
						elm$svg$Svg$g,
						_List_fromArray(
							[
								elm$svg$Svg$Attributes$class('tick'),
								elm$svg$Svg$Attributes$transform(
								translate(
									position(tick)))
							]),
						_List_fromArray(
							[
								A2(
								elm$svg$Svg$line,
								_List_fromArray(
									[
										elm$svg$Svg$Attributes$stroke('#000'),
										x2(k * opts.bO),
										y1(0.5),
										y2(0.5)
									]),
								_List_Nil),
								A2(
								elm$svg$Svg$text_,
								_List_fromArray(
									[
										elm$svg$Svg$Attributes$fill('#000'),
										x(k * spacing),
										y(0.5),
										elm$svg$Svg$Attributes$dy(displacement)
									]),
								_List_fromArray(
									[
										elm$svg$Svg$text(
										opts.eG(tick))
									]))
							]));
				};
				var domainLine = horizontal ? ('M' + (elm$core$String$fromFloat(k * opts.a$) + (',' + (elm$core$String$fromFloat(range0) + ('H0.5V' + (elm$core$String$fromFloat(range1) + ('H' + elm$core$String$fromFloat(k * opts.a$)))))))) : ('M' + (elm$core$String$fromFloat(range0) + (',' + (elm$core$String$fromFloat(k * opts.a$) + ('V0.5H' + (elm$core$String$fromFloat(range1) + ('V' + elm$core$String$fromFloat(k * opts.a$))))))));
				return A2(
					elm$svg$Svg$g,
					_List_fromArray(
						[
							elm$svg$Svg$Attributes$fill('none'),
							elm$svg$Svg$Attributes$fontSize('10'),
							elm$svg$Svg$Attributes$fontFamily('sans-serif'),
							elm$svg$Svg$Attributes$textAnchor(textAnchorPosition)
						]),
					A2(
						elm$core$List$cons,
						A2(
							elm$svg$Svg$path,
							_List_fromArray(
								[
									elm$svg$Svg$Attributes$class('domain'),
									elm$svg$Svg$Attributes$stroke('#000'),
									elm$svg$Svg$Attributes$d(domainLine)
								]),
							_List_Nil),
						A2(elm$core$List$map, drawTick, opts.eH)));
			});
	});
var elm$svg$Svg$Attributes$x = _VirtualDom_attribute('x');
var elm$svg$Svg$Attributes$x1 = _VirtualDom_attribute('x1');
var elm$svg$Svg$Attributes$x2 = _VirtualDom_attribute('x2');
var elm$svg$Svg$Attributes$y = _VirtualDom_attribute('y');
var elm$svg$Svg$Attributes$y1 = _VirtualDom_attribute('y1');
var elm$svg$Svg$Attributes$y2 = _VirtualDom_attribute('y2');
var gampleman$elm_visualization$Axis$verticalAttrs = {
	dH: false,
	eJ: function (x) {
		return 'translate(' + (elm$core$String$fromFloat(x) + ', 0)');
	},
	eR: A2(elm$core$Basics$composeL, elm$svg$Svg$Attributes$y, elm$core$String$fromFloat),
	jB: A2(elm$core$Basics$composeL, elm$svg$Svg$Attributes$y1, elm$core$String$fromFloat),
	jC: A2(elm$core$Basics$composeL, elm$svg$Svg$Attributes$y2, elm$core$String$fromFloat),
	eS: A2(elm$core$Basics$composeL, elm$svg$Svg$Attributes$x, elm$core$String$fromFloat),
	jF: A2(elm$core$Basics$composeL, elm$svg$Svg$Attributes$x1, elm$core$String$fromFloat),
	jG: A2(elm$core$Basics$composeL, elm$svg$Svg$Attributes$x2, elm$core$String$fromFloat)
};
var gampleman$elm_visualization$Axis$bottom = A4(gampleman$elm_visualization$Axis$element, gampleman$elm_visualization$Axis$verticalAttrs, 1, '0.71em', 'middle');
var author$project$View$BarChart$xAxis = function (p) {
	return A2(
		gampleman$elm_visualization$Axis$bottom,
		_List_Nil,
		A2(
			gampleman$elm_visualization$Scale$toRenderable,
			author$project$View$BarChart$dateFormat,
			author$project$View$BarChart$xScale(p)));
};
var gampleman$elm_visualization$Axis$horizontalAttrs = {
	dH: true,
	eJ: function (y) {
		return 'translate(0, ' + (elm$core$String$fromFloat(y) + ')');
	},
	eR: A2(elm$core$Basics$composeL, elm$svg$Svg$Attributes$x, elm$core$String$fromFloat),
	jB: A2(elm$core$Basics$composeL, elm$svg$Svg$Attributes$x1, elm$core$String$fromFloat),
	jC: A2(elm$core$Basics$composeL, elm$svg$Svg$Attributes$x2, elm$core$String$fromFloat),
	eS: A2(elm$core$Basics$composeL, elm$svg$Svg$Attributes$y, elm$core$String$fromFloat),
	jF: A2(elm$core$Basics$composeL, elm$svg$Svg$Attributes$y1, elm$core$String$fromFloat),
	jG: A2(elm$core$Basics$composeL, elm$svg$Svg$Attributes$y2, elm$core$String$fromFloat)
};
var gampleman$elm_visualization$Axis$left = A4(gampleman$elm_visualization$Axis$element, gampleman$elm_visualization$Axis$horizontalAttrs, -1, '0.32em', 'end');
var gampleman$elm_visualization$Axis$TickCount = function (a) {
	return {$: 2, a: a};
};
var gampleman$elm_visualization$Axis$tickCount = gampleman$elm_visualization$Axis$TickCount;
var gampleman$elm_visualization$Axis$TickFormat = function (a) {
	return {$: 1, a: a};
};
var gampleman$elm_visualization$Axis$tickFormat = gampleman$elm_visualization$Axis$TickFormat;
var author$project$View$BarChart$yAxis = function (range) {
	return A2(
		gampleman$elm_visualization$Axis$left,
		_List_fromArray(
			[
				gampleman$elm_visualization$Axis$tickCount(
				A2(
					elm$core$Basics$min,
					5,
					elm$core$Basics$floor(range.b))),
				gampleman$elm_visualization$Axis$tickFormat(
				A2(elm$core$Basics$composeR, elm$core$Basics$floor, elm$core$String$fromInt))
			]),
		author$project$View$BarChart$yScale(range));
};
var avh4$elm_color$Color$RgbaSpace = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var avh4$elm_color$Color$white = A4(avh4$elm_color$Color$RgbaSpace, 255 / 255, 255 / 255, 255 / 255, 1.0);
var elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var elm$core$List$sortBy = _List_sortBy;
var elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2(elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var elm$core$List$takeTailRec = F2(
	function (n, list) {
		return elm$core$List$reverse(
			A3(elm$core$List$takeReverse, n, list, _List_Nil));
	});
var elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _n0 = _Utils_Tuple2(n, list);
			_n0$1:
			while (true) {
				_n0$5:
				while (true) {
					if (!_n0.b.b) {
						return list;
					} else {
						if (_n0.b.b.b) {
							switch (_n0.a) {
								case 1:
									break _n0$1;
								case 2:
									var _n2 = _n0.b;
									var x = _n2.a;
									var _n3 = _n2.b;
									var y = _n3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_n0.b.b.b.b) {
										var _n4 = _n0.b;
										var x = _n4.a;
										var _n5 = _n4.b;
										var y = _n5.a;
										var _n6 = _n5.b;
										var z = _n6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _n0$5;
									}
								default:
									if (_n0.b.b.b.b && _n0.b.b.b.b.b) {
										var _n7 = _n0.b;
										var x = _n7.a;
										var _n8 = _n7.b;
										var y = _n8.a;
										var _n9 = _n8.b;
										var z = _n9.a;
										var _n10 = _n9.b;
										var w = _n10.a;
										var tl = _n10.b;
										return (ctr > 1000) ? A2(
											elm$core$List$cons,
											x,
											A2(
												elm$core$List$cons,
												y,
												A2(
													elm$core$List$cons,
													z,
													A2(
														elm$core$List$cons,
														w,
														A2(elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											elm$core$List$cons,
											x,
											A2(
												elm$core$List$cons,
												y,
												A2(
													elm$core$List$cons,
													z,
													A2(
														elm$core$List$cons,
														w,
														A3(elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _n0$5;
									}
							}
						} else {
							if (_n0.a === 1) {
								break _n0$1;
							} else {
								break _n0$5;
							}
						}
					}
				}
				return list;
			}
			var _n1 = _n0.b;
			var x = _n1.a;
			return _List_fromArray(
				[x]);
		}
	});
var elm$core$List$take = F2(
	function (n, list) {
		return A3(elm$core$List$takeFast, 0, n, list);
	});
var elm_community$list_extra$List$Extra$findIndexHelp = F3(
	function (index, predicate, list) {
		findIndexHelp:
		while (true) {
			if (!list.b) {
				return elm$core$Maybe$Nothing;
			} else {
				var x = list.a;
				var xs = list.b;
				if (predicate(x)) {
					return elm$core$Maybe$Just(index);
				} else {
					var $temp$index = index + 1,
						$temp$predicate = predicate,
						$temp$list = xs;
					index = $temp$index;
					predicate = $temp$predicate;
					list = $temp$list;
					continue findIndexHelp;
				}
			}
		}
	});
var elm_community$list_extra$List$Extra$findIndex = elm_community$list_extra$List$Extra$findIndexHelp(0);
var elm_community$typed_svg$TypedSvg$style = elm_community$typed_svg$TypedSvg$Core$node('style');
var elm_community$typed_svg$TypedSvg$svg = elm_community$typed_svg$TypedSvg$Core$node('svg');
var avh4$elm_color$Color$toCssString = function (_n0) {
	var r = _n0.a;
	var g = _n0.b;
	var b = _n0.c;
	var a = _n0.d;
	var roundTo = function (x) {
		return elm$core$Basics$round(x * 1000) / 1000;
	};
	var pct = function (x) {
		return elm$core$Basics$round(x * 10000) / 100;
	};
	return elm$core$String$concat(
		_List_fromArray(
			[
				'rgba(',
				elm$core$String$fromFloat(
				pct(r)),
				'%,',
				elm$core$String$fromFloat(
				pct(g)),
				'%,',
				elm$core$String$fromFloat(
				pct(b)),
				'%,',
				elm$core$String$fromFloat(
				roundTo(a)),
				')'
			]));
};
var elm_community$typed_svg$TypedSvg$Attributes$color = function (c) {
	return A2(
		elm_community$typed_svg$TypedSvg$Core$attribute,
		'color',
		avh4$elm_color$Color$toCssString(c));
};
var elm_community$typed_svg$TypedSvg$TypesToStrings$transformToString = function (xform) {
	var tr = F2(
		function (name, args) {
			return elm$core$String$concat(
				_List_fromArray(
					[
						name,
						'(',
						A2(
						elm$core$String$join,
						' ',
						A2(elm$core$List$map, elm$core$String$fromFloat, args)),
						')'
					]));
		});
	switch (xform.$) {
		case 0:
			var a = xform.a;
			var b = xform.b;
			var c = xform.c;
			var d = xform.d;
			var e = xform.e;
			var f = xform.f;
			return A2(
				tr,
				'matrix',
				_List_fromArray(
					[a, b, c, d, e, f]));
		case 1:
			var a = xform.a;
			var x = xform.b;
			var y = xform.c;
			return A2(
				tr,
				'rotate',
				_List_fromArray(
					[a, x, y]));
		case 2:
			var x = xform.a;
			var y = xform.b;
			return A2(
				tr,
				'scale',
				_List_fromArray(
					[x, y]));
		case 3:
			var x = xform.a;
			return A2(
				tr,
				'skewX',
				_List_fromArray(
					[x]));
		case 4:
			var y = xform.a;
			return A2(
				tr,
				'skewY',
				_List_fromArray(
					[y]));
		default:
			var x = xform.a;
			var y = xform.b;
			return A2(
				tr,
				'translate',
				_List_fromArray(
					[x, y]));
	}
};
var elm_community$typed_svg$TypedSvg$Attributes$transform = function (transforms) {
	return A2(
		elm_community$typed_svg$TypedSvg$Core$attribute,
		'transform',
		A2(
			elm$core$String$join,
			' ',
			A2(elm$core$List$map, elm_community$typed_svg$TypedSvg$TypesToStrings$transformToString, transforms)));
};
var elm_community$typed_svg$TypedSvg$Attributes$viewBox = F4(
	function (minX, minY, vWidth, vHeight) {
		return A2(
			elm_community$typed_svg$TypedSvg$Core$attribute,
			'viewBox',
			A2(
				elm$core$String$join,
				' ',
				A2(
					elm$core$List$map,
					elm$core$String$fromFloat,
					_List_fromArray(
						[minX, minY, vWidth, vHeight]))));
	});
var elm_community$typed_svg$TypedSvg$Types$Translate = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var author$project$View$BarChart$view = F2(
	function (mine, scores) {
		var markedScores = function (list) {
			if (_Utils_cmp(
				elm$core$List$length(list),
				author$project$View$BarChart$maxNumberOfColumns) < 1) {
				return list;
			} else {
				var _n2 = A2(
					elm_community$list_extra$List$Extra$findIndex,
					A2(elm$core$Basics$composeR, elm$core$Tuple$second, elm$core$Tuple$second),
					list);
				if (!_n2.$) {
					var index = _n2.a;
					return A2(
						elm$core$List$take,
						author$project$View$BarChart$maxNumberOfColumns,
						A2(
							elm$core$List$drop,
							(index - ((author$project$View$BarChart$maxNumberOfColumns / 2) | 0)) - A2(
								elm$core$Basics$max,
								0,
								((((author$project$View$BarChart$maxNumberOfColumns / 2) | 0) - elm$core$List$length(list)) + index) + 1),
							list));
				} else {
					return A2(elm$core$List$take, author$project$View$BarChart$maxNumberOfColumns, list);
				}
			}
		}(
			A2(
				elm$core$List$map,
				function (_n1) {
					var k = _n1.a;
					var v = _n1.b;
					return _Utils_Tuple2(
						k,
						_Utils_Tuple2(
							v,
							A2(
								elm$core$Maybe$withDefault,
								false,
								A2(
									elm$core$Maybe$map,
									elm$core$Basics$eq(k),
									mine))));
				},
				A2(
					elm$core$List$sortBy,
					elm$core$Tuple$first,
					elm$core$Dict$toList(
						A3(
							author$project$Extra$Maybe$update,
							mine,
							A2(
								elm$core$Basics$composeR,
								elm$core$Maybe$withDefault(1),
								elm$core$Maybe$Just),
							A3(
								elm$core$List$foldl,
								F2(
									function (_n0, dict) {
										var x = _n0.a;
										var y = _n0.b;
										return A3(
											elm$core$Dict$update,
											x,
											A2(
												elm$core$Basics$composeR,
												elm$core$Maybe$withDefault(0),
												A2(
													elm$core$Basics$composeR,
													elm$core$Basics$add(y),
													elm$core$Maybe$Just)),
											dict);
									}),
								elm$core$Dict$empty,
								scores))))));
		var range = _Utils_Tuple2(
			0,
			1.1 * A2(
				elm$core$Maybe$withDefault,
				0,
				elm$core$List$maximum(
					A2(
						elm$core$List$map,
						A2(elm$core$Basics$composeR, elm$core$Tuple$second, elm$core$Tuple$first),
						markedScores))));
		var padLeft = author$project$View$BarChart$paddingLeft(range.b);
		var domain = A2(elm$core$List$map, elm$core$Tuple$first, markedScores);
		return A2(
			elm_community$typed_svg$TypedSvg$svg,
			_List_fromArray(
				[
					A4(elm_community$typed_svg$TypedSvg$Attributes$viewBox, 0, 0, author$project$View$BarChart$w, author$project$View$BarChart$h)
				]),
			_List_fromArray(
				[
					A2(
					elm_community$typed_svg$TypedSvg$style,
					_List_Nil,
					_List_fromArray(
						[
							elm_community$typed_svg$TypedSvg$Core$text('\n            .column rect { fill: transparent; stroke: white; stroke-width: 3; }\n            .column:hover rect { fill: white; }\n            .column text { display: none; fill: white; }\n            .column:hover text { display: inline; }\n            .domain { stroke: white; stroke-width: 3; shape-rendering: crispEdges; }\n            .tick line { stroke: white; stroke-width: 2; }\n            .tick text { font-family: monospace; fill: white; font-size: 20px }\n            .column .me { fill: white }\n            .column:hover .me { display: none }\n          ')
						])),
					A2(
					elm_community$typed_svg$TypedSvg$g,
					_List_fromArray(
						[
							elm_community$typed_svg$TypedSvg$Attributes$transform(
							_List_fromArray(
								[
									A2(elm_community$typed_svg$TypedSvg$Types$Translate, padLeft - 1, author$project$View$BarChart$h - author$project$View$BarChart$paddingBottom)
								]))
						]),
					_List_fromArray(
						[
							author$project$View$BarChart$xAxis(
							{cK: domain, ed: padLeft, ee: author$project$View$BarChart$paddingRight})
						])),
					A2(
					elm_community$typed_svg$TypedSvg$g,
					_List_fromArray(
						[
							elm_community$typed_svg$TypedSvg$Attributes$transform(
							_List_fromArray(
								[
									A2(elm_community$typed_svg$TypedSvg$Types$Translate, padLeft - 1, author$project$View$BarChart$paddingTop)
								])),
							elm_community$typed_svg$TypedSvg$Attributes$color(avh4$elm_color$Color$white)
						]),
					_List_fromArray(
						[
							author$project$View$BarChart$yAxis(range)
						])),
					A2(
					elm_community$typed_svg$TypedSvg$g,
					_List_fromArray(
						[
							elm_community$typed_svg$TypedSvg$Attributes$transform(
							_List_fromArray(
								[
									A2(elm_community$typed_svg$TypedSvg$Types$Translate, padLeft - 0.5, author$project$View$BarChart$paddingTop + 0.5)
								])),
							elm_community$typed_svg$TypedSvg$Attributes$class(
							_List_fromArray(
								['series']))
						]),
					A2(
						elm$core$List$map,
						A2(
							author$project$View$BarChart$column,
							range,
							author$project$View$BarChart$xScale(
								{cK: domain, ed: padLeft, ee: author$project$View$BarChart$paddingRight})),
						markedScores))
				]));
	});
var mdgriffith$elm_ui$Element$html = mdgriffith$elm_ui$Internal$Model$unstyled;
var author$project$View$HighScore$graph = function (_n0) {
	var title = _n0.gn;
	var personalBest = _n0.en;
	var data = _n0.dz;
	return A2(
		mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
				mdgriffith$elm_ui$Element$spacing(10),
				mdgriffith$elm_ui$Element$paddingEach(
				{e$: 0, fD: 0, f5: 0, go: 10}),
				mdgriffith$elm_ui$Element$Border$width(3),
				mdgriffith$elm_ui$Element$Border$color(
				A3(mdgriffith$elm_ui$Element$rgb, 1, 1, 1))
			]),
		_List_fromArray(
			[
				A2(
				mdgriffith$elm_ui$Element$paragraph,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
						mdgriffith$elm_ui$Element$Font$center
					]),
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$text(title)
					])),
				mdgriffith$elm_ui$Element$html(
				A2(author$project$View$BarChart$view, personalBest, data))
			]));
};
var author$project$View$HighScore$loading = author$project$View$Box$simpleLoading('Loading high scores');
var author$project$View$HighScore$notAsked = author$project$View$Box$simpleNonInteractive('Not asked');
var author$project$View$HighScore$view = F2(
	function (solutions, remoteData) {
		switch (remoteData.$) {
			case 0:
				return author$project$View$HighScore$notAsked;
			case 1:
				return author$project$View$HighScore$loading;
			case 2:
				var error = remoteData.a;
				return author$project$View$HighScore$failure(error);
			default:
				var highScore = remoteData.a;
				return (elm$core$List$isEmpty(solutions) && elm$core$Dict$isEmpty(highScore.bc)) ? author$project$View$Box$simpleNonInteractive('No high scores') : A2(
					mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
							mdgriffith$elm_ui$Element$spacing(20)
						]),
					_List_fromArray(
						[
							author$project$View$HighScore$graph(
							{
								dz: elm$core$Dict$toList(highScore.bd),
								en: elm$core$List$minimum(
									A2(
										elm$core$List$map,
										A2(
											elm$core$Basics$composeR,
											function ($) {
												return $.df;
											},
											function ($) {
												return $.bd;
											}),
										solutions)),
								gn: 'Number of steps'
							}),
							author$project$View$HighScore$graph(
							{
								dz: elm$core$Dict$toList(highScore.bc),
								en: elm$core$List$minimum(
									A2(
										elm$core$List$map,
										A2(
											elm$core$Basics$composeR,
											function ($) {
												return $.df;
											},
											function ($) {
												return $.bc;
											}),
										solutions)),
								gn: 'Number of instructions'
							})
						]));
		}
	});
var author$project$ViewComponents$descriptionTextbox = F2(
	function (attributes, description) {
		var attrs = elm$core$List$concat(
			_List_fromArray(
				[
					_List_fromArray(
					[
						mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
						mdgriffith$elm_ui$Element$padding(10),
						mdgriffith$elm_ui$Element$spacing(15),
						mdgriffith$elm_ui$Element$Border$width(3),
						mdgriffith$elm_ui$Element$Border$color(
						A3(mdgriffith$elm_ui$Element$rgb, 1, 1, 1))
					]),
					attributes
				]));
		return A2(
			mdgriffith$elm_ui$Element$column,
			attrs,
			A2(
				elm$core$List$map,
				A2(
					elm$core$Basics$composeL,
					A2(
						elm$core$Basics$composeL,
						mdgriffith$elm_ui$Element$paragraph(_List_Nil),
						elm$core$List$singleton),
					mdgriffith$elm_ui$Element$text),
				description));
	});
var author$project$ViewComponents$viewTitle = F2(
	function (attributes, title) {
		return A2(
			mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
					mdgriffith$elm_ui$Element$Font$center,
					mdgriffith$elm_ui$Element$Font$size(24)
				]),
			A2(
				mdgriffith$elm_ui$Element$paragraph,
				_List_Nil,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$text(title)
					])));
	});
var krisajenkins$remotedata$RemoteData$isLoading = function (data) {
	if (data.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var author$project$Page$Campaign$viewSidebar = F2(
	function (level, model) {
		var solvedStatusView = function () {
			var solvedText = 'Solved';
			var notSolvedText = 'Not solved';
			var loadingText = 'Loading solutions...';
			var solvedStatus = function () {
				var _n0 = A2(author$project$Data$Session$getSolutionBook, level.b6, model.f);
				if (_n0.$ === 3) {
					var solutionBook = _n0.a;
					return elm$core$Set$isEmpty(solutionBook.iY) ? notSolvedText : solvedText;
				} else {
					return loadingText;
				}
			}();
			return A2(
				mdgriffith$elm_ui$Element$paragraph,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
						mdgriffith$elm_ui$Element$Font$center
					]),
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$text(solvedStatus)
					]));
		}();
		var solutionsAreLoading = krisajenkins$remotedata$RemoteData$isLoading(
			A2(author$project$Data$Cache$get, level.b6, model.f.aZ));
		var levelSolved = A2(
			elm$core$Maybe$withDefault,
			false,
			A2(
				elm$core$Maybe$map,
				A2(
					elm$core$Basics$composeR,
					function ($) {
						return $.iY;
					},
					A2(elm$core$Basics$composeR, elm$core$Set$isEmpty, elm$core$Basics$not)),
				krisajenkins$remotedata$RemoteData$toMaybe(
					A2(author$project$Data$Cache$get, level.b6, model.f.aZ))));
		var levelNameView = A2(author$project$ViewComponents$viewTitle, _List_Nil, level.bA);
		var highScoreView = function () {
			if (elm_community$maybe_extra$Maybe$Extra$isNothing(
				author$project$Data$Session$getAccessToken(model.f))) {
				return author$project$View$Box$simpleNonInteractive('Sign in to enable high scores');
			} else {
				if (solutionsAreLoading) {
					return author$project$View$Box$simpleNonInteractive('Loading solutions');
				} else {
					if (!levelSolved) {
						return author$project$View$Box$simpleNonInteractive('High scores hidden');
					} else {
						var solutions = elm_community$maybe_extra$Maybe$Extra$values(
							A2(
								elm$core$List$filterMap,
								A2(
									elm$core$Basics$composeR,
									A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Data$Cache$get, model.f.ex.aj),
									krisajenkins$remotedata$RemoteData$toMaybe),
								A2(
									krisajenkins$remotedata$RemoteData$withDefault,
									_List_Nil,
									A2(
										krisajenkins$remotedata$RemoteData$map,
										A2(
											elm$core$Basics$composeR,
											function ($) {
												return $.iY;
											},
											elm$core$Set$toList),
										A2(author$project$Data$Cache$get, level.b6, model.f.aZ)))));
						var highScore = A2(author$project$Data$Cache$get, level.b6, model.f.U);
						return A2(author$project$View$HighScore$view, solutions, highScore);
					}
				}
			}
		}();
		var draftsView = A2(author$project$Page$Campaign$viewDrafts, level, model.f);
		var descriptionView = A2(author$project$ViewComponents$descriptionTextbox, _List_Nil, level.bq);
		return _List_fromArray(
			[levelNameView, solvedStatusView, descriptionView, highScoreView, draftsView]);
	});
var elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var elm_community$list_extra$List$Extra$count = function (predicate) {
	return A2(
		elm$core$List$foldl,
		F2(
			function (x, acc) {
				return predicate(x) ? (acc + 1) : acc;
			}),
		0);
};
var elm$core$Char$toUpper = _Char_toUpper;
var elm_community$string_extra$String$Extra$changeCase = F2(
	function (mutator, word) {
		return A2(
			elm$core$Maybe$withDefault,
			'',
			A2(
				elm$core$Maybe$map,
				function (_n0) {
					var head = _n0.a;
					var tail = _n0.b;
					return A2(
						elm$core$String$cons,
						mutator(head),
						tail);
				},
				elm$core$String$uncons(word)));
	});
var elm_community$string_extra$String$Extra$toSentenceCase = function (word) {
	return A2(elm_community$string_extra$String$Extra$changeCase, elm$core$Char$toUpper, word);
};
var author$project$Page$Campaign$viewCampaign = F2(
	function (campaign, model) {
		var viewTemporarySidebar = function (elements) {
			return _List_fromArray(
				[
					A2(
					mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$centerX,
							mdgriffith$elm_ui$Element$Font$size(32)
						]),
					mdgriffith$elm_ui$Element$text(
						elm_community$string_extra$String$Extra$toSentenceCase(model.bW))),
					A2(
					mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
							mdgriffith$elm_ui$Element$Font$center
						]),
					elements)
				]);
		};
		var solutionBookRemoteData = A2(
			elm$core$List$map,
			A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Data$Cache$get, model.f.aZ),
			campaign.aQ);
		var numberOfSolvedLevels = A2(
			elm_community$list_extra$List$Extra$count,
			A2(
				elm$core$Basics$composeL,
				A2(elm$core$Basics$composeL, elm$core$Basics$not, elm$core$Set$isEmpty),
				function ($) {
					return $.iY;
				}),
			A2(elm$core$List$filterMap, krisajenkins$remotedata$RemoteData$toMaybe, solutionBookRemoteData));
		var numberOfLevels = elm$core$List$length(campaign.aQ);
		var mainContent = A2(author$project$Page$Campaign$viewLevels, campaign, model);
		var allSolutionsLoaded = A2(
			elm$core$List$all,
			elm_community$maybe_extra$Maybe$Extra$isJust,
			A2(elm$core$List$map, krisajenkins$remotedata$RemoteData$toMaybe, solutionBookRemoteData));
		var sidebar = function () {
			var _n0 = A2(
				elm$core$Maybe$map,
				A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Data$Session$getLevel, model.f),
				A2(
					elm_community$maybe_extra$Maybe$Extra$filter,
					A2(elm_community$basics_extra$Basics$Extra$flip, elm$core$List$member, campaign.aQ),
					model.aY));
			if (!_n0.$) {
				switch (_n0.a.$) {
					case 3:
						var level = _n0.a.a;
						return A2(author$project$Page$Campaign$viewSidebar, level, model);
					case 0:
						var _n1 = _n0.a;
						return viewTemporarySidebar(
							_List_fromArray(
								[
									mdgriffith$elm_ui$Element$text('Not asked :/')
								]));
					case 1:
						var _n2 = _n0.a;
						return viewTemporarySidebar(
							_List_fromArray(
								[
									mdgriffith$elm_ui$Element$text('Loading level...')
								]));
					default:
						var error = _n0.a.a;
						return viewTemporarySidebar(
							_List_fromArray(
								[
									mdgriffith$elm_ui$Element$text(
									author$project$Data$GetError$toString(error))
								]));
				}
			} else {
				return viewTemporarySidebar(
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$text(
							elm$core$String$concat(
								_List_fromArray(
									[
										allSolutionsLoaded ? '' : 'at least ',
										elm$core$String$fromInt(numberOfSolvedLevels),
										'/',
										elm$core$String$fromInt(numberOfLevels),
										' solved'
									])))
						]));
			}
		}();
		return A3(author$project$View$SingleSidebar$view, sidebar, mainContent, model.f);
	});
var author$project$Page$Campaign$viewError = function (error) {
	return author$project$View$ErrorScreen$view(error);
};
var elm$html$Html$map = elm$virtual_dom$VirtualDom$map;
var author$project$Page$Campaign$view = function (model) {
	var session = model.f;
	var content = function () {
		var _n0 = model.ff;
		if (!_n0.$) {
			var error = _n0.a;
			return author$project$Page$Campaign$viewError(error);
		} else {
			var _n1 = A2(author$project$Data$Session$getCampaign, model.bW, session);
			switch (_n1.$) {
				case 0:
					return author$project$View$LoadingScreen$view('Not asked for campaign');
				case 1:
					return author$project$View$LoadingScreen$view('Loading campaign');
				case 2:
					var error = _n1.a;
					return author$project$View$ErrorScreen$view(
						author$project$Data$GetError$toString(error));
				default:
					var campaign = _n1.a;
					return A2(author$project$Page$Campaign$viewCampaign, campaign, model);
			}
		}
	}();
	return {
		bT: elm$core$List$singleton(
			A2(
				elm$html$Html$map,
				author$project$Page$Campaign$InternalMsg,
				A2(
					mdgriffith$elm_ui$Element$layout,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$Background$color(
							A3(mdgriffith$elm_ui$Element$rgb, 0, 0, 0)),
							mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
							mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill),
							mdgriffith$elm_ui$Element$Font$family(
							_List_fromArray(
								[mdgriffith$elm_ui$Element$Font$monospace])),
							mdgriffith$elm_ui$Element$Font$color(
							A3(mdgriffith$elm_ui$Element$rgb, 1, 1, 1))
						]),
					content))),
		gn: elm$core$String$concat(
			_List_fromArray(
				['Campaign', ' - ', author$project$ApplicationName$applicationName]))
	};
};
var author$project$Page$Campaigns$InternalMsg = function (a) {
	return {$: 0, a: a};
};
var elm$html$Html$Attributes$classList = function (classes) {
	return elm$html$Html$Attributes$class(
		A2(
			elm$core$String$join,
			' ',
			A2(
				elm$core$List$map,
				elm$core$Tuple$first,
				A2(elm$core$List$filter, elm$core$Tuple$second, classes))));
};
var author$project$View$Card$link = function (parameters) {
	var label = parameters.cF;
	var _class = mdgriffith$elm_ui$Element$htmlAttribute(
		elm$html$Html$Attributes$classList(
			_List_fromArray(
				[
					_Utils_Tuple2('solved', parameters.h_)
				])));
	var attributes = _List_fromArray(
		[
			mdgriffith$elm_ui$Element$padding(20),
			mdgriffith$elm_ui$Element$Border$width(3),
			mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
			_class,
			mdgriffith$elm_ui$Element$mouseOver(
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$Background$color(author$project$View$Constant$color.dv.hK)
				])),
			mdgriffith$elm_ui$Element$Background$color(
			parameters.iR ? author$project$View$Constant$color.dv.iR : author$project$View$Constant$color.dv.gX)
		]);
	return A2(
		mdgriffith$elm_ui$Element$link,
		attributes,
		{fB: label, jw: parameters.jw});
};
var author$project$View$Constant$icons = {
	e3: {hE: 'assets/misc/circle-green.svg', iC: 'assets/misc/circle-red.svg'},
	ht: 'assets/exception-orange.svg',
	iy: 'assets/execution-control-images/pause.svg',
	gf: 'assets/spinner.svg'
};
var author$project$View$Constant$size = {
	fk: {
		g4: {
			gn: mdgriffith$elm_ui$Element$Font$size(24)
		},
		iw: {
			gn: mdgriffith$elm_ui$Element$Font$size(42)
		},
		iQ: {
			gn: mdgriffith$elm_ui$Element$Font$size(28)
		}
	}
};
var author$project$Page$Campaigns$viewCampaign = F2(
	function (model, campaignId) {
		var title = A2(
			mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[author$project$View$Constant$size.fk.g4.gn, mdgriffith$elm_ui$Element$centerX]),
			mdgriffith$elm_ui$Element$text(
				elm_community$string_extra$String$Extra$toSentenceCase(campaignId)));
		var _n0 = A2(author$project$Data$Cache$get, campaignId, model.f.aG);
		switch (_n0.$) {
			case 0:
				return author$project$View$Card$link(
					{
						cF: A2(
							mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
									mdgriffith$elm_ui$Element$spacing(20)
								]),
							_List_fromArray(
								[
									title,
									mdgriffith$elm_ui$Element$text('Request not sent')
								])),
						h_: false,
						iR: false,
						jw: author$project$Route$toString(
							A2(author$project$Route$Campaign, campaignId, elm$core$Maybe$Nothing))
					});
			case 1:
				return author$project$View$Card$link(
					{
						cF: A2(
							mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
									mdgriffith$elm_ui$Element$spacing(20)
								]),
							_List_fromArray(
								[
									title,
									A2(
									mdgriffith$elm_ui$Element$image,
									_List_fromArray(
										[
											mdgriffith$elm_ui$Element$width(
											mdgriffith$elm_ui$Element$px(20)),
											mdgriffith$elm_ui$Element$centerX
										]),
									{bq: 'Loading', bJ: author$project$View$Constant$icons.gf})
								])),
						h_: false,
						iR: false,
						jw: author$project$Route$toString(
							A2(author$project$Route$Campaign, campaignId, elm$core$Maybe$Nothing))
					});
			case 2:
				var error = _n0.a;
				return author$project$View$Card$link(
					{
						cF: A2(
							mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
									mdgriffith$elm_ui$Element$spacing(20)
								]),
							_List_fromArray(
								[
									title,
									mdgriffith$elm_ui$Element$text(
									author$project$Data$GetError$toString(error))
								])),
						h_: false,
						iR: false,
						jw: author$project$Route$toString(
							A2(author$project$Route$Campaign, campaignId, elm$core$Maybe$Nothing))
					});
			default:
				var campaign = _n0.a;
				var solutionBooks = A2(
					elm$core$List$map,
					A2(elm_community$basics_extra$Basics$Extra$flip, author$project$Data$Cache$get, model.f.aZ),
					campaign.aQ);
				var numberOfSolvedLevels = A2(
					elm_community$list_extra$List$Extra$count,
					A2(
						elm$core$Basics$composeR,
						function ($) {
							return $.iY;
						},
						A2(elm$core$Basics$composeR, elm$core$Set$isEmpty, elm$core$Basics$not)),
					A2(elm$core$List$filterMap, krisajenkins$remotedata$RemoteData$toMaybe, solutionBooks));
				var numberOfLoadingLevels = A2(elm_community$list_extra$List$Extra$count, krisajenkins$remotedata$RemoteData$isLoading, solutionBooks);
				var numberOfLevels = elm$core$List$length(campaign.aQ);
				var content = (numberOfLoadingLevels > 0) ? A2(
					mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
							mdgriffith$elm_ui$Element$spacing(20)
						]),
					_List_fromArray(
						[
							title,
							A2(
							mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[mdgriffith$elm_ui$Element$centerX]),
							_List_fromArray(
								[
									A2(
									mdgriffith$elm_ui$Element$image,
									_List_fromArray(
										[
											mdgriffith$elm_ui$Element$width(
											mdgriffith$elm_ui$Element$px(20))
										]),
									{bq: 'Loading', bJ: author$project$View$Constant$icons.gf}),
									mdgriffith$elm_ui$Element$text(' /'),
									mdgriffith$elm_ui$Element$text(
									elm$core$String$fromInt(numberOfLevels))
								]))
						])) : A2(
					mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
							mdgriffith$elm_ui$Element$spacing(20)
						]),
					_List_fromArray(
						[
							title,
							A2(
							mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[mdgriffith$elm_ui$Element$centerX]),
							_List_fromArray(
								[
									mdgriffith$elm_ui$Element$text(
									elm$core$String$fromInt(numberOfSolvedLevels)),
									mdgriffith$elm_ui$Element$text('/'),
									mdgriffith$elm_ui$Element$text(
									elm$core$String$fromInt(numberOfLevels))
								]))
						]));
				return author$project$View$Card$link(
					{
						cF: content,
						h_: _Utils_eq(numberOfLevels, numberOfSolvedLevels),
						iR: false,
						jw: author$project$Route$toString(
							A2(author$project$Route$Campaign, campaignId, elm$core$Maybe$Nothing))
					});
		}
	});
var author$project$Page$Campaigns$viewCampaigns = function (model) {
	var title = A2(
		mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				author$project$View$Constant$size.fk.iw.gn,
				mdgriffith$elm_ui$Element$centerX,
				mdgriffith$elm_ui$Element$padding(20)
			]),
		mdgriffith$elm_ui$Element$text('Campaigns'));
	var campaigns = A2(
		elm$core$List$map,
		author$project$Page$Campaigns$viewCampaign(model),
		author$project$Data$CampaignId$all);
	var elements = elm$core$List$concat(
		_List_fromArray(
			[
				_List_fromArray(
				[title]),
				campaigns
			]));
	return A2(
		mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				mdgriffith$elm_ui$Element$width(
				A2(mdgriffith$elm_ui$Element$maximum, 1000, mdgriffith$elm_ui$Element$fill)),
				mdgriffith$elm_ui$Element$centerX,
				mdgriffith$elm_ui$Element$spacing(20),
				mdgriffith$elm_ui$Element$padding(60)
			]),
		elements);
};
var author$project$View$Scewn$layout = A2(elm$core$Basics$composeR, author$project$View$Scewn$view, author$project$View$Layout$layout);
var author$project$Page$Campaigns$view = function (model) {
	var content = author$project$View$Scewn$layout(
		{
			g5: elm$core$Maybe$Just(
				author$project$Page$Campaigns$viewCampaigns(model)),
			hp: elm$core$Maybe$Nothing,
			h9: elm$core$Maybe$Nothing,
			ie: elm$core$Maybe$Just(
				author$project$View$Header$view(model.f)),
			iZ: elm$core$Maybe$Nothing,
			jz: elm$core$Maybe$Nothing
		});
	return {
		bT: A2(
			elm$core$List$map,
			elm$html$Html$map(author$project$Page$Campaigns$InternalMsg),
			_List_fromArray(
				[content])),
		gn: elm$core$String$concat(
			_List_fromArray(
				['Campaigns', ' - ', author$project$ApplicationName$applicationName]))
	};
};
var author$project$Page$Credits$section = F2(
	function (title, element) {
		return A2(
			mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
					mdgriffith$elm_ui$Element$spacing(40)
				]),
			_List_fromArray(
				[
					A2(
					mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$Font$center,
							author$project$View$Constant$size.fk.iQ.gn,
							mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill)
						]),
					mdgriffith$elm_ui$Element$text(title)),
					element
				]));
	});
var elm$core$List$sort = function (xs) {
	return A2(elm$core$List$sortBy, elm$core$Basics$identity, xs);
};
var mdgriffith$elm_ui$Element$Font$alignRight = A2(mdgriffith$elm_ui$Internal$Model$Class, mdgriffith$elm_ui$Internal$Flag$fontAlignment, mdgriffith$elm_ui$Internal$Style$classes.jj);
var author$project$Page$Credits$taskAndAuthor = function (tasks) {
	var top = F2(
		function (task, author) {
			return A2(
				mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
						mdgriffith$elm_ui$Element$htmlAttribute(
						elm$html$Html$Attributes$class('dotted'))
					]),
				_List_fromArray(
					[
						A2(
						mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								mdgriffith$elm_ui$Element$alignLeft,
								mdgriffith$elm_ui$Element$Background$color(
								A3(mdgriffith$elm_ui$Element$rgb, 0, 0, 0)),
								mdgriffith$elm_ui$Element$paddingEach(
								{e$: 0, fD: 0, f5: 11, go: 0})
							]),
						mdgriffith$elm_ui$Element$text(task)),
						A2(
						mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								mdgriffith$elm_ui$Element$mouseOver(
								_List_fromArray(
									[
										mdgriffith$elm_ui$Element$Font$color(author$project$View$Constant$color.fk.gk)
									])),
								mdgriffith$elm_ui$Element$alignRight,
								mdgriffith$elm_ui$Element$Background$color(
								A3(mdgriffith$elm_ui$Element$rgb, 0, 0, 0)),
								mdgriffith$elm_ui$Element$paddingEach(
								{e$: 0, fD: 11, f5: 0, go: 0})
							]),
						mdgriffith$elm_ui$Element$text(author))
					]));
		});
	return A2(
		mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
				mdgriffith$elm_ui$Element$centerX,
				mdgriffith$elm_ui$Element$spacing(20)
			]),
		A2(
			elm$core$List$map,
			function (_n0) {
				var task = _n0.a;
				var authors = _n0.b;
				if (!authors.b) {
					return A2(top, task, 'null');
				} else {
					if (!authors.b.b) {
						var author = authors.a;
						return A2(top, task, author);
					} else {
						var author = authors.a;
						var tail = authors.b;
						return A2(
							mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill)
								]),
							A2(
								elm$core$List$cons,
								A2(top, task, author),
								A2(
									elm$core$List$map,
									A2(
										elm$core$Basics$composeR,
										mdgriffith$elm_ui$Element$text,
										mdgriffith$elm_ui$Element$el(
											_List_fromArray(
												[
													mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
													mdgriffith$elm_ui$Element$Font$alignRight,
													mdgriffith$elm_ui$Element$mouseOver(
													_List_fromArray(
														[
															mdgriffith$elm_ui$Element$Font$color(author$project$View$Constant$color.fk.gk)
														]))
												]))),
									tail)));
					}
				}
			},
			A2(
				elm$core$List$map,
				elm$core$Tuple$mapSecond(elm$core$List$sort),
				tasks)));
};
var avh4$elm_color$Color$hsla = F4(
	function (hue, sat, light, alpha) {
		var _n0 = _Utils_Tuple3(hue, sat, light);
		var h = _n0.a;
		var s = _n0.b;
		var l = _n0.c;
		var m2 = (l <= 0.5) ? (l * (s + 1)) : ((l + s) - (l * s));
		var m1 = (l * 2) - m2;
		var hueToRgb = function (h__) {
			var h_ = (h__ < 0) ? (h__ + 1) : ((h__ > 1) ? (h__ - 1) : h__);
			return ((h_ * 6) < 1) ? (m1 + (((m2 - m1) * h_) * 6)) : (((h_ * 2) < 1) ? m2 : (((h_ * 3) < 2) ? (m1 + (((m2 - m1) * ((2 / 3) - h_)) * 6)) : m1));
		};
		var b = hueToRgb(h - (1 / 3));
		var g = hueToRgb(h);
		var r = hueToRgb(h + (1 / 3));
		return A4(avh4$elm_color$Color$RgbaSpace, r, g, b, alpha);
	});
var avh4$elm_color$Color$hsl = F3(
	function (h, s, l) {
		return A4(avh4$elm_color$Color$hsla, h, s, l, 1.0);
	});
var avh4$elm_color$Color$toRgba = function (_n0) {
	var r = _n0.a;
	var g = _n0.b;
	var b = _n0.c;
	var a = _n0.d;
	return {gO: a, gY: b, hE: g, iC: r};
};
var elm$core$List$intersperse = F2(
	function (sep, xs) {
		if (!xs.b) {
			return _List_Nil;
		} else {
			var hd = xs.a;
			var tl = xs.b;
			var step = F2(
				function (x, rest) {
					return A2(
						elm$core$List$cons,
						sep,
						A2(elm$core$List$cons, x, rest));
				});
			var spersed = A3(elm$core$List$foldr, step, _List_Nil, tl);
			return A2(elm$core$List$cons, hd, spersed);
		}
	});
var elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			elm$core$String$join,
			after,
			A2(elm$core$String$split, before, string));
	});
var author$project$Page$Credits$view = function (model) {
	var body = A2(
		mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				mdgriffith$elm_ui$Element$width(
				A2(mdgriffith$elm_ui$Element$maximum, 1000, mdgriffith$elm_ui$Element$fill)),
				mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill),
				mdgriffith$elm_ui$Element$padding(60),
				mdgriffith$elm_ui$Element$spacing(80),
				mdgriffith$elm_ui$Element$centerX
			]),
		_List_fromArray(
			[
				A2(
				author$project$Page$Credits$section,
				'Credits',
				author$project$Page$Credits$taskAndAuthor(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'Development',
							_List_fromArray(
								['Simon Olander Sahlén'])),
							_Utils_Tuple2(
							'Icons',
							_List_fromArray(
								['Simon Olander Sahlén'])),
							_Utils_Tuple2(
							'UX counselling',
							_List_fromArray(
								['Anita Chainiau', 'Anton Håkanson'])),
							_Utils_Tuple2('Music', _List_Nil)
						]))),
				A2(
				author$project$Page$Credits$section,
				'Source',
				A2(
					mdgriffith$elm_ui$Element$link,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$centerX,
							mdgriffith$elm_ui$Element$mouseOver(
							_List_fromArray(
								[
									mdgriffith$elm_ui$Element$Font$color(author$project$View$Constant$color.fk.d_)
								])),
							mdgriffith$elm_ui$Element$Font$color(author$project$View$Constant$color.fk.dZ)
						]),
					{
						fB: mdgriffith$elm_ui$Element$text('https://github.com/simonolander/elm-funge'),
						jw: 'https://github.com/simonolander/elm-funge'
					})),
				function () {
				var playTesters = _List_fromArray(
					['Anita Chainiau', 'Anton Håkanson', 'Isac Olander Sahlén', 'Adelie Fournier', 'Benjamin Becquet', 'Erik Bodin', 'Anton Pervorsek', 'Edvin Wallin', 'Carl-Henrik Klåvus', 'Johan von Konow', 'Harald Nicander', 'Karin Aldheimer', 'Malin Molin']);
				var color = function (index) {
					var t = index / elm$core$List$length(playTesters);
					var _n0 = avh4$elm_color$Color$toRgba(
						A3(avh4$elm_color$Color$hsl, t, 1, 0.75));
					var red = _n0.iC;
					var green = _n0.hE;
					var blue = _n0.gY;
					var alpha = _n0.gO;
					return mdgriffith$elm_ui$Element$Font$color(
						A4(mdgriffith$elm_ui$Element$rgba, red, green, blue, alpha));
				};
				return A2(
					author$project$Page$Credits$section,
					'Play testing',
					A2(
						mdgriffith$elm_ui$Element$paragraph,
						_List_fromArray(
							[mdgriffith$elm_ui$Element$Font$center]),
						A2(
							elm$core$List$intersperse,
							mdgriffith$elm_ui$Element$text(' '),
							A2(
								elm$core$List$indexedMap,
								function (index) {
									return mdgriffith$elm_ui$Element$el(
										_List_fromArray(
											[
												mdgriffith$elm_ui$Element$mouseOver(
												_List_fromArray(
													[
														color(index)
													])),
												mdgriffith$elm_ui$Element$padding(10)
											]));
								},
								A2(
									elm$core$List$map,
									mdgriffith$elm_ui$Element$text,
									elm$core$List$sort(
										A2(
											elm$core$List$map,
											A2(elm$core$String$replace, ' ', '\u00a0'),
											playTesters)))))));
			}(),
				A2(
				author$project$Page$Credits$section,
				'Special thanks',
				A2(
					mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
							mdgriffith$elm_ui$Element$spacing(30)
						]),
					A2(
						elm$core$List$map,
						mdgriffith$elm_ui$Element$column(
							_List_fromArray(
								[
									mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
									mdgriffith$elm_ui$Element$spacing(20)
								])),
						A2(
							elm$core$List$map,
							function (_n1) {
								var a = _n1.a;
								var b = _n1.b;
								return _List_fromArray(
									[a, b]);
							},
							A2(
								elm$core$List$map,
								elm$core$Tuple$mapSecond(
									mdgriffith$elm_ui$Element$paragraph(
										_List_fromArray(
											[
												mdgriffith$elm_ui$Element$Font$size(20),
												mdgriffith$elm_ui$Element$Font$center,
												mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill)
											]))),
								A2(
									elm$core$List$map,
									elm$core$Tuple$mapFirst(
										A2(
											elm$core$Basics$composeR,
											mdgriffith$elm_ui$Element$text,
											mdgriffith$elm_ui$Element$el(
												_List_fromArray(
													[
														mdgriffith$elm_ui$Element$Font$size(24),
														mdgriffith$elm_ui$Element$Font$center,
														mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill)
													])))),
									_List_fromArray(
										[
											_Utils_Tuple2(
											'Zachtronics',
											_List_fromArray(
												[
													mdgriffith$elm_ui$Element$text('For making '),
													A2(
													mdgriffith$elm_ui$Element$link,
													_List_fromArray(
														[
															mdgriffith$elm_ui$Element$Font$color(author$project$View$Constant$color.fk.dZ),
															mdgriffith$elm_ui$Element$mouseOver(
															_List_fromArray(
																[
																	mdgriffith$elm_ui$Element$Font$color(author$project$View$Constant$color.fk.d_)
																]))
														]),
													{
														fB: mdgriffith$elm_ui$Element$text('TIS-100'),
														jw: 'http://www.zachtronics.com/tis-100/'
													}),
													mdgriffith$elm_ui$Element$text(' and '),
													A2(
													mdgriffith$elm_ui$Element$link,
													_List_fromArray(
														[
															mdgriffith$elm_ui$Element$Font$color(author$project$View$Constant$color.fk.dZ),
															mdgriffith$elm_ui$Element$mouseOver(
															_List_fromArray(
																[
																	mdgriffith$elm_ui$Element$Font$color(author$project$View$Constant$color.fk.d_)
																]))
														]),
													{
														fB: mdgriffith$elm_ui$Element$text('Shenzhen I/O'),
														jw: 'http://www.zachtronics.com/shenzhen-io/'
													}),
													mdgriffith$elm_ui$Element$text(', games I enjoyed and that inspired this work.')
												])),
											_Utils_Tuple2(
											'Befunge',
											_List_fromArray(
												[
													mdgriffith$elm_ui$Element$text('For inspiring the computer language of this game.')
												]))
										])))))))
			]));
	var content = author$project$View$Layout$layout(
		author$project$View$Scewn$view(
			{
				g5: elm$core$Maybe$Just(body),
				hp: elm$core$Maybe$Nothing,
				h9: elm$core$Maybe$Nothing,
				ie: elm$core$Maybe$Just(
					author$project$View$Header$view(model.f)),
				iZ: elm$core$Maybe$Nothing,
				jz: elm$core$Maybe$Nothing
			}));
	return {
		bT: _List_fromArray(
			[content]),
		gn: elm$core$String$concat(
			_List_fromArray(
				['Credits', ' - ', author$project$ApplicationName$applicationName]))
	};
};
var author$project$Page$Draft$InternalMsg = function (a) {
	return {$: 0, a: a};
};
var author$project$Page$Draft$ClickedCancelDeleteDraft = {$: 11};
var author$project$Page$Draft$ClickedConfirmDeleteDraft = {$: 10};
var author$project$Page$Draft$Import = function (a) {
	return {$: 1, a: a};
};
var author$project$Page$Draft$ImportClosed = {$: 3};
var author$project$Page$Draft$ImportDataChanged = function (a) {
	return {$: 0, a: a};
};
var author$project$Page$Draft$InstructionPlaced = F2(
	function (a, b) {
		return {$: 14, a: a, b: b};
	});
var author$project$Page$Draft$InstructionToolReplaced = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var author$project$Page$Draft$InstructionToolSelected = function (a) {
	return {$: 13, a: a};
};
var author$project$Page$Draft$ClickedDeleteDraft = {$: 9};
var author$project$Page$Draft$ClickedExecute = {$: 8};
var author$project$Page$Draft$EditClear = {$: 6};
var author$project$Page$Draft$EditRedo = {$: 5};
var author$project$Page$Draft$EditUndo = {$: 4};
var author$project$Page$Draft$ImportOpen = {$: 2};
var author$project$Page$Draft$viewSidebar = function (model) {
	var undoButtonView = A3(
		author$project$ViewComponents$textButton,
		_List_Nil,
		elm$core$Maybe$Just(author$project$Page$Draft$EditUndo),
		'Undo');
	var redoButtonView = A3(
		author$project$ViewComponents$textButton,
		_List_Nil,
		elm$core$Maybe$Just(author$project$Page$Draft$EditRedo),
		'Redo');
	var level = model.bv;
	var titleView = A2(author$project$ViewComponents$viewTitle, _List_Nil, level.bA);
	var importExportButtonView = A3(
		author$project$ViewComponents$textButton,
		_List_Nil,
		elm$core$Maybe$Just(author$project$Page$Draft$ImportOpen),
		'Import / Export');
	var executeButtonView = A3(
		author$project$ViewComponents$textButton,
		_List_Nil,
		elm$core$Maybe$Just(author$project$Page$Draft$ClickedExecute),
		'Execute');
	var descriptionView = A2(author$project$ViewComponents$descriptionTextbox, _List_Nil, level.bq);
	var deleteDraftButtonView = A3(
		author$project$ViewComponents$textButton,
		_List_Nil,
		elm$core$Maybe$Just(author$project$Page$Draft$ClickedDeleteDraft),
		'Delete draft');
	var clearButtonView = A3(
		author$project$ViewComponents$textButton,
		_List_Nil,
		elm$core$Maybe$Just(author$project$Page$Draft$EditClear),
		'Clear');
	return A2(
		mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				mdgriffith$elm_ui$Element$width(
				mdgriffith$elm_ui$Element$px(350)),
				mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill),
				mdgriffith$elm_ui$Element$alignTop,
				mdgriffith$elm_ui$Element$Background$color(
				A3(mdgriffith$elm_ui$Element$rgb, 8.0e-2, 8.0e-2, 8.0e-2)),
				mdgriffith$elm_ui$Element$spacing(10),
				mdgriffith$elm_ui$Element$padding(10),
				mdgriffith$elm_ui$Element$scrollbarY
			]),
		_List_fromArray(
			[
				A2(
				mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
						mdgriffith$elm_ui$Element$spacing(20),
						mdgriffith$elm_ui$Element$paddingEach(
						{e$: 30, fD: 0, f5: 0, go: 20})
					]),
				_List_fromArray(
					[titleView, descriptionView, executeButtonView])),
				A2(
				mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
						mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill),
						mdgriffith$elm_ui$Element$spacing(10)
					]),
				_List_fromArray(
					[undoButtonView, redoButtonView, clearButtonView, importExportButtonView])),
				deleteDraftButtonView
			]));
};
var author$project$Page$Draft$viewLoaded = function (model) {
	var toolSidebarView = author$project$View$InstructionTools$view(
		{
			aO: model.bv.aO,
			im: F2(
				function (index, tool) {
					return A2(author$project$Page$Draft$InstructionToolReplaced, index, tool);
				}),
			io: elm$core$Maybe$Just(author$project$Page$Draft$InstructionToolSelected),
			iS: model.cg
		});
	var sidebarView = author$project$Page$Draft$viewSidebar(model);
	var maybeSelectedInstructionTool = A2(
		elm$core$Maybe$andThen,
		A2(elm_community$basics_extra$Basics$Extra$flip, elm$core$Array$get, model.bv.aO),
		model.cg);
	var importModal = function () {
		var _n1 = model.B;
		switch (_n1.$) {
			case 0:
				return elm$core$Maybe$Nothing;
			case 1:
				return elm$core$Maybe$Just(
					A2(
						mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								mdgriffith$elm_ui$Element$centerX,
								mdgriffith$elm_ui$Element$centerY,
								mdgriffith$elm_ui$Element$Background$color(
								A3(mdgriffith$elm_ui$Element$rgb, 0, 0, 0)),
								mdgriffith$elm_ui$Element$padding(20),
								mdgriffith$elm_ui$Element$Font$family(
								_List_fromArray(
									[mdgriffith$elm_ui$Element$Font$monospace])),
								mdgriffith$elm_ui$Element$Font$color(
								A3(mdgriffith$elm_ui$Element$rgb, 1, 1, 1)),
								mdgriffith$elm_ui$Element$spacing(10),
								mdgriffith$elm_ui$Element$Border$width(3),
								mdgriffith$elm_ui$Element$Border$color(
								A3(mdgriffith$elm_ui$Element$rgb, 1, 1, 1)),
								mdgriffith$elm_ui$Element$width(
								A2(mdgriffith$elm_ui$Element$maximum, 400, mdgriffith$elm_ui$Element$shrink))
							]),
						_List_fromArray(
							[
								A2(
								mdgriffith$elm_ui$Element$paragraph,
								_List_fromArray(
									[mdgriffith$elm_ui$Element$Font$center]),
								_List_fromArray(
									[
										mdgriffith$elm_ui$Element$text('Do you really want to delete this draft?')
									])),
								A3(
								author$project$ViewComponents$textButton,
								_List_Nil,
								elm$core$Maybe$Just(author$project$Page$Draft$ClickedConfirmDeleteDraft),
								'Delete'),
								A3(
								author$project$ViewComponents$textButton,
								_List_Nil,
								elm$core$Maybe$Just(author$project$Page$Draft$ClickedCancelDeleteDraft),
								'Cancel')
							])));
			default:
				var importData = _n1.a.cT;
				var errorMessage = _n1.a.cN;
				return elm$core$Maybe$Just(
					A2(
						mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								mdgriffith$elm_ui$Element$centerX,
								mdgriffith$elm_ui$Element$centerY,
								mdgriffith$elm_ui$Element$Background$color(
								A3(mdgriffith$elm_ui$Element$rgb, 0, 0, 0)),
								mdgriffith$elm_ui$Element$padding(20),
								mdgriffith$elm_ui$Element$Font$family(
								_List_fromArray(
									[mdgriffith$elm_ui$Element$Font$monospace])),
								mdgriffith$elm_ui$Element$Font$color(
								A3(mdgriffith$elm_ui$Element$rgb, 1, 1, 1)),
								mdgriffith$elm_ui$Element$spacing(10),
								mdgriffith$elm_ui$Element$Border$width(3),
								mdgriffith$elm_ui$Element$Border$color(
								A3(mdgriffith$elm_ui$Element$rgb, 1, 1, 1))
							]),
						_List_fromArray(
							[
								A2(
								mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										mdgriffith$elm_ui$Element$Font$size(32),
										mdgriffith$elm_ui$Element$centerX
									]),
								mdgriffith$elm_ui$Element$text('Import / Export')),
								A2(
								mdgriffith$elm_ui$Element$Input$multiline,
								_List_fromArray(
									[
										mdgriffith$elm_ui$Element$Background$color(
										A3(mdgriffith$elm_ui$Element$rgb, 0, 0, 0)),
										mdgriffith$elm_ui$Element$width(
										mdgriffith$elm_ui$Element$px(500)),
										mdgriffith$elm_ui$Element$height(
										mdgriffith$elm_ui$Element$px(500))
									]),
								{
									fB: A2(
										mdgriffith$elm_ui$Element$Input$labelAbove,
										_List_Nil,
										mdgriffith$elm_ui$Element$text('Copy or paste from here')),
									c6: author$project$Page$Draft$ImportDataChanged,
									db: elm$core$Maybe$Nothing,
									i0: false,
									dj: importData
								}),
								A2(
								elm$core$Maybe$withDefault,
								mdgriffith$elm_ui$Element$none,
								A2(
									elm$core$Maybe$map,
									mdgriffith$elm_ui$Element$paragraph(_List_Nil),
									A2(
										elm$core$Maybe$map,
										elm$core$List$singleton,
										A2(elm$core$Maybe$map, mdgriffith$elm_ui$Element$text, errorMessage)))),
								A3(
								author$project$ViewComponents$textButton,
								_List_Nil,
								elm$core$Maybe$Just(
									author$project$Page$Draft$Import(importData)),
								'Import'),
								A3(
								author$project$ViewComponents$textButton,
								_List_Nil,
								elm$core$Maybe$Just(author$project$Page$Draft$ImportClosed),
								'Close')
							])));
		}
	}();
	var disabledPositions = A2(
		elm$core$List$map,
		function ($) {
			return $.ep;
		},
		A2(
			elm$core$List$filter,
			A2(
				elm$core$Basics$composeR,
				function ($) {
					return $.cU;
				},
				elm$core$Basics$neq(author$project$Data$Instruction$NoOp)),
			author$project$Data$Board$instructions(model.bv.hT)));
	var boardOnClick = A2(
		elm$core$Maybe$map,
		F2(
			function (instruction, _n0) {
				var position = _n0.ep;
				return A2(author$project$Page$Draft$InstructionPlaced, position, instruction);
			}),
		A2(elm$core$Maybe$map, author$project$Data$InstructionTool$getInstruction, maybeSelectedInstructionTool));
	var board = author$project$Data$History$current(model.dB.G);
	var boardView = author$project$View$Board$view(
		{cy: board, ho: disabledPositions, ij: boardOnClick, iT: elm$core$Maybe$Nothing});
	var element = author$project$View$Scewn$view(
		{
			g5: elm$core$Maybe$Just(boardView),
			hp: elm$core$Maybe$Just(toolSidebarView),
			h9: importModal,
			ie: elm$core$Maybe$Just(
				author$project$View$Header$view(model.f)),
			iZ: elm$core$Maybe$Nothing,
			jz: elm$core$Maybe$Just(sidebarView)
		});
	return element;
};
var author$project$View$Info$view = function (_n0) {
	var title = _n0.gn;
	var icon = _n0.dJ;
	var elements = _n0.b1;
	return A2(
		mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				mdgriffith$elm_ui$Element$centerX,
				mdgriffith$elm_ui$Element$centerY,
				mdgriffith$elm_ui$Element$spacing(20),
				mdgriffith$elm_ui$Element$padding(40)
			]),
		_Utils_ap(
			_List_fromArray(
				[
					A2(
					mdgriffith$elm_ui$Element$image,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$width(
							mdgriffith$elm_ui$Element$px(72)),
							mdgriffith$elm_ui$Element$centerX
						]),
					icon),
					A2(
					mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$Font$size(28),
							mdgriffith$elm_ui$Element$Font$center
						]),
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$text(title)
						]))
				]),
			elements));
};
var author$project$View$NotFound$view = function (_n0) {
	var noun = _n0.$8;
	var id = _n0.b6;
	return author$project$View$Info$view(
		{
			b1: _List_fromArray(
				[
					A2(
					mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[mdgriffith$elm_ui$Element$Font$center]),
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$text('Could not find '),
							mdgriffith$elm_ui$Element$text(noun),
							mdgriffith$elm_ui$Element$text(' '),
							mdgriffith$elm_ui$Element$text(id)
						]))
				]),
			dJ: {bq: 'Alert icon', bJ: author$project$View$Constant$icons.ht},
			gn: 'Resource not found'
		});
};
var author$project$Page$Draft$view = function (model) {
	var session = model.f;
	var content = function () {
		var _n0 = model.ff;
		if (!_n0.$) {
			var error = _n0.a;
			return author$project$View$ErrorScreen$view(error);
		} else {
			var _n1 = A2(author$project$Data$Cache$get, model.o, model.f.dC.aj);
			switch (_n1.$) {
				case 0:
					return krisajenkins$remotedata$RemoteData$isLoading(
						A2(author$project$Data$Cache$get, model.o, model.f.dC.ae)) ? author$project$View$LoadingScreen$view('Loading draft ' + (model.o + ' from server')) : author$project$View$ErrorScreen$view('Not asked for draft: ' + model.o);
				case 1:
					return author$project$View$LoadingScreen$view('Loading draft ' + (model.o + ' from local storage'));
				case 2:
					var error = _n1.a;
					return author$project$View$ErrorScreen$view(
						elm$json$Json$Decode$errorToString(error));
				default:
					if (_n1.a.$ === 1) {
						var _n2 = _n1.a;
						return author$project$View$Scewn$view(
							{
								g5: elm$core$Maybe$Just(
									author$project$View$NotFound$view(
										{b6: model.o, $8: 'draft'})),
								hp: elm$core$Maybe$Nothing,
								h9: elm$core$Maybe$Nothing,
								ie: elm$core$Maybe$Just(
									author$project$View$Header$view(session)),
								iZ: elm$core$Maybe$Nothing,
								jz: elm$core$Maybe$Nothing
							});
					} else {
						var draft = _n1.a.a;
						var _n3 = A2(author$project$Data$Session$getLevel, draft.a8, session);
						switch (_n3.$) {
							case 0:
								return author$project$View$ErrorScreen$view('Not asked for level: ' + draft.a8);
							case 1:
								return author$project$View$LoadingScreen$view('Loading level ' + draft.a8);
							case 2:
								var error = _n3.a;
								return author$project$View$ErrorScreen$view(
									author$project$Data$GetError$toString(error));
							default:
								var level = _n3.a;
								return author$project$Page$Draft$viewLoaded(
									{dB: draft, bv: level, cg: model.cg, f: session, B: model.B});
						}
					}
			}
		}
	}();
	var body = elm$core$List$singleton(
		A2(
			elm$html$Html$map,
			author$project$Page$Draft$InternalMsg,
			author$project$View$Layout$layout(content)));
	return {
		bT: body,
		gn: elm$core$String$concat(
			_List_fromArray(
				['Draft', ' - ', author$project$ApplicationName$applicationName]))
	};
};
var author$project$Page$Execution$instructionSize = 100;
var author$project$Page$Execution$instructionSpacing = 10;
var author$project$Page$Execution$viewBoard = F2(
	function (execution, model) {
		var executionSuite = author$project$Data$History$current(execution.l);
		var executionStep = author$project$Data$History$current(executionSuite.r);
		var instructionPointer = executionStep.aN;
		var viewInstruction = F3(
			function (rowIndex, columnIndex, instruction) {
				var backgroundColor = function () {
					if (instruction.$ === 26) {
						return (_Utils_eq(instructionPointer.ep.eR, columnIndex) && _Utils_eq(instructionPointer.ep.eS, rowIndex)) ? A3(mdgriffith$elm_ui$Element$rgb, 0.4, 0, 0) : A3(mdgriffith$elm_ui$Element$rgb, 0.1, 0, 0);
					} else {
						return (_Utils_eq(instructionPointer.ep.eR, columnIndex) && _Utils_eq(instructionPointer.ep.eS, rowIndex)) ? A3(mdgriffith$elm_ui$Element$rgb, 0.4, 0.4, 0.4) : A3(mdgriffith$elm_ui$Element$rgb, 0, 0, 0);
					}
				}();
				var instructionLabel = A2(
					mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$width(
							mdgriffith$elm_ui$Element$px(author$project$Page$Execution$instructionSize)),
							mdgriffith$elm_ui$Element$height(
							mdgriffith$elm_ui$Element$px(author$project$Page$Execution$instructionSize)),
							mdgriffith$elm_ui$Element$Background$color(backgroundColor),
							mdgriffith$elm_ui$Element$Font$center,
							mdgriffith$elm_ui$Element$padding(10)
						]),
					A2(
						author$project$InstructionView$view,
						_List_fromArray(
							[
								mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
								mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill)
							]),
						instruction));
				return A2(
					mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$Border$width(3),
							mdgriffith$elm_ui$Element$Border$color(
							A3(mdgriffith$elm_ui$Element$rgb, 1, 1, 1))
						]),
					{fB: instructionLabel, il: elm$core$Maybe$Nothing});
			});
		var viewRow = F2(
			function (rowIndex, boardRow) {
				return A2(
					mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$spacing(author$project$Page$Execution$instructionSpacing)
						]),
					elm$core$Array$toList(
						A2(
							elm$core$Array$indexedMap,
							viewInstruction(rowIndex),
							boardRow)));
			});
		var board = executionStep.cy;
		var boardView = A2(
			mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$spacing(author$project$Page$Execution$instructionSpacing),
					mdgriffith$elm_ui$Element$scrollbars,
					mdgriffith$elm_ui$Element$width(
					mdgriffith$elm_ui$Element$fillPortion(3)),
					mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill),
					mdgriffith$elm_ui$Element$Background$color(
					A3(mdgriffith$elm_ui$Element$rgb, 0, 0, 0)),
					mdgriffith$elm_ui$Element$padding(10)
				]),
			elm$core$Array$toList(
				A2(elm$core$Array$indexedMap, viewRow, board)));
		return boardView;
	});
var author$project$View$Box$interactive = function (element) {
	return A2(
		mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
				mdgriffith$elm_ui$Element$Border$width(4),
				mdgriffith$elm_ui$Element$Border$color(
				A3(mdgriffith$elm_ui$Element$rgb, 1, 1, 1)),
				mdgriffith$elm_ui$Element$padding(10),
				mdgriffith$elm_ui$Element$mouseOver(
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$Background$color(
						A4(mdgriffith$elm_ui$Element$rgba, 1, 1, 1, 0.5))
					])),
				mdgriffith$elm_ui$Element$Background$color(
				A3(mdgriffith$elm_ui$Element$rgb, 0, 0, 0)),
				mdgriffith$elm_ui$Element$Font$color(
				A3(mdgriffith$elm_ui$Element$rgb, 1, 1, 1))
			]),
		element);
};
var author$project$View$Box$simpleInteractive = function (message) {
	return author$project$View$Box$interactive(
		A2(
			mdgriffith$elm_ui$Element$paragraph,
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$Font$center,
					mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$text(message)
				])));
};
var author$project$View$Box$link = F2(
	function (message, route) {
		return A3(
			author$project$Route$link,
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill)
				]),
			author$project$View$Box$simpleInteractive(message),
			route);
	});
var author$project$Page$Execution$viewExceptionModal = F3(
	function (execution, model, exceptionMessage) {
		return A2(
			mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$centerX,
					mdgriffith$elm_ui$Element$centerY,
					mdgriffith$elm_ui$Element$Background$color(
					A3(mdgriffith$elm_ui$Element$rgb, 0.1, 0, 0)),
					mdgriffith$elm_ui$Element$padding(20),
					mdgriffith$elm_ui$Element$Font$color(
					A3(mdgriffith$elm_ui$Element$rgb, 1, 0, 0)),
					mdgriffith$elm_ui$Element$spacing(10),
					mdgriffith$elm_ui$Element$Border$width(3),
					mdgriffith$elm_ui$Element$Border$color(
					A3(mdgriffith$elm_ui$Element$rgb, 0.5, 0, 0))
				]),
			_List_fromArray(
				[
					A2(
					mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$Font$size(32)
						]),
					mdgriffith$elm_ui$Element$text('Exception')),
					A2(
					mdgriffith$elm_ui$Element$paragraph,
					_List_Nil,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$text(exceptionMessage)
						])),
					A2(
					author$project$View$Box$link,
					'Back to editor',
					author$project$Route$EditDraft(model.o))
				]));
	});
var author$project$Data$History$toPastPresentFuture = function (history) {
	return {
		y: history.y,
		u: elm$core$List$reverse(history.u),
		iz: history.x
	};
};
var author$project$ExecutionControlView$FastForward = 3;
var author$project$ExecutionControlView$Home = 5;
var author$project$ExecutionControlView$Pause = 4;
var author$project$ExecutionControlView$Play = 2;
var author$project$ExecutionControlView$Step = 0;
var author$project$ExecutionControlView$Undo = 1;
var author$project$ExecutionControlView$view = F2(
	function (attributes, instruction) {
		switch (instruction) {
			case 0:
				return A2(
					mdgriffith$elm_ui$Element$image,
					attributes,
					{bq: 'Step', bJ: 'assets/execution-control-images/step.svg'});
			case 1:
				return A2(
					mdgriffith$elm_ui$Element$image,
					attributes,
					{bq: 'Step Back', bJ: 'assets/execution-control-images/undo.svg'});
			case 2:
				return A2(
					mdgriffith$elm_ui$Element$image,
					attributes,
					{bq: 'Play', bJ: 'assets/execution-control-images/play.svg'});
			case 3:
				return A2(
					mdgriffith$elm_ui$Element$image,
					attributes,
					{bq: 'Fast Forward', bJ: 'assets/execution-control-images/fast-forward.svg'});
			case 4:
				return A2(
					mdgriffith$elm_ui$Element$image,
					attributes,
					{bq: 'Pause', bJ: 'assets/execution-control-images/pause.svg'});
			default:
				return A2(
					mdgriffith$elm_ui$Element$image,
					attributes,
					{bq: 'Home', bJ: 'assets/execution-control-images/home.svg'});
		}
	});
var author$project$Page$Execution$ClickedFastForward = {$: 3};
var author$project$Page$Execution$ClickedHome = {$: 5};
var author$project$Page$Execution$ClickedPause = {$: 4};
var author$project$Page$Execution$ClickedRun = {$: 2};
var author$project$Page$Execution$ClickedStep = {$: 0};
var author$project$Page$Execution$ClickedUndo = {$: 1};
var mdgriffith$elm_ui$Element$Border$widthXY = F2(
	function (x, y) {
		return A2(
			mdgriffith$elm_ui$Internal$Model$StyleClass,
			mdgriffith$elm_ui$Internal$Flag$borderWidth,
			A5(
				mdgriffith$elm_ui$Internal$Model$BorderWidth,
				'b-' + (elm$core$String$fromInt(x) + ('-' + elm$core$String$fromInt(y))),
				y,
				x,
				y,
				x));
	});
var mdgriffith$elm_ui$Element$Border$widthEach = function (_n0) {
	var bottom = _n0.e$;
	var top = _n0.go;
	var left = _n0.fD;
	var right = _n0.f5;
	return (_Utils_eq(top, bottom) && _Utils_eq(left, right)) ? (_Utils_eq(top, right) ? mdgriffith$elm_ui$Element$Border$width(top) : A2(mdgriffith$elm_ui$Element$Border$widthXY, left, top)) : A2(
		mdgriffith$elm_ui$Internal$Model$StyleClass,
		mdgriffith$elm_ui$Internal$Flag$borderWidth,
		A5(
			mdgriffith$elm_ui$Internal$Model$BorderWidth,
			'b-' + (elm$core$String$fromInt(top) + ('-' + (elm$core$String$fromInt(right) + ('-' + (elm$core$String$fromInt(bottom) + ('-' + elm$core$String$fromInt(left))))))),
			top,
			right,
			bottom,
			left));
};
var author$project$Page$Execution$viewExecutionSidebar = F2(
	function (execution, model) {
		var titleView = A2(author$project$ViewComponents$viewTitle, _List_Nil, execution.bv.bA);
		var suiteView = function () {
			var size = {e$: 0, fD: 0, f5: 0, go: 0};
			var totalView = A2(
				mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
						mdgriffith$elm_ui$Element$Border$widthEach(
						_Utils_update(
							size,
							{go: 2})),
						mdgriffith$elm_ui$Element$Border$color(author$project$View$Constant$color.fk.gk),
						mdgriffith$elm_ui$Element$paddingEach(
						_Utils_update(
							size,
							{go: 6}))
					]),
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$text('Total '),
						A2(
						mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[mdgriffith$elm_ui$Element$alignRight]),
						mdgriffith$elm_ui$Element$text(
							elm$core$String$fromInt(
								author$project$Page$Execution$getScore(execution).bd)))
					]));
			var running = A2(
				mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$alignLeft,
						mdgriffith$elm_ui$Element$paddingEach(
						_Utils_update(
							size,
							{fD: 10}))
					]),
				A2(
					mdgriffith$elm_ui$Element$image,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$width(
							mdgriffith$elm_ui$Element$px(10))
						]),
					{bq: 'Running', bJ: author$project$View$Constant$icons.gf}));
			var paused = A2(
				mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$alignLeft,
						mdgriffith$elm_ui$Element$paddingEach(
						_Utils_update(
							size,
							{fD: 10}))
					]),
				A2(
					mdgriffith$elm_ui$Element$image,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$width(
							mdgriffith$elm_ui$Element$px(10))
						]),
					{bq: 'Paused', bJ: author$project$View$Constant$icons.iy}));
			var passed = A2(
				mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$alignLeft,
						mdgriffith$elm_ui$Element$paddingEach(
						_Utils_update(
							size,
							{fD: 10}))
					]),
				A2(
					mdgriffith$elm_ui$Element$image,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$width(
							mdgriffith$elm_ui$Element$px(10))
						]),
					{bq: 'Passed', bJ: author$project$View$Constant$icons.e3.hE}));
			var failed = A2(
				mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$alignLeft,
						mdgriffith$elm_ui$Element$paddingEach(
						_Utils_update(
							size,
							{fD: 10}))
					]),
				A2(
					mdgriffith$elm_ui$Element$image,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$width(
							mdgriffith$elm_ui$Element$px(10))
						]),
					{bq: 'Failed', bJ: author$project$View$Constant$icons.e3.iC}));
			var _n0 = function () {
				var _n1 = author$project$Data$History$toPastPresentFuture(execution.l);
				var past = _n1.u;
				var present = _n1.iz;
				var future = _n1.y;
				return {e8: present, fn: future, fV: past};
			}();
			var passedSuites = _n0.fV;
			var currentSuite = _n0.e8;
			var futureSuites = _n0.fn;
			var currentView = A2(
				mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						A2(
						mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[mdgriffith$elm_ui$Element$alignLeft]),
						mdgriffith$elm_ui$Element$text(
							'Suite ' + elm$core$String$fromInt(
								elm$core$List$length(passedSuites) + 1))),
						author$project$Page$Execution$isSuiteSolved(currentSuite) ? passed : (author$project$Page$Execution$isSuiteFailed(currentSuite) ? failed : ((!model.B) ? paused : running)),
						A2(
						mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[mdgriffith$elm_ui$Element$alignRight]),
						mdgriffith$elm_ui$Element$text(
							elm$core$String$fromInt(
								author$project$Page$Execution$getNumberOfStepsForSuite(currentSuite))))
					]));
			var futureView = A2(
				elm$core$List$indexedMap,
				F2(
					function (index, _n2) {
						return mdgriffith$elm_ui$Element$text(
							'Suite ' + elm$core$String$fromInt(
								(index + elm$core$List$length(passedSuites)) + 2));
					}),
				futureSuites);
			var passedView = A2(
				elm$core$List$indexedMap,
				F2(
					function (index, suite) {
						return A2(
							mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill)
								]),
							_List_fromArray(
								[
									A2(
									mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[mdgriffith$elm_ui$Element$alignLeft]),
									mdgriffith$elm_ui$Element$text(
										'Suite ' + elm$core$String$fromInt(index + 1))),
									passed,
									A2(
									mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[mdgriffith$elm_ui$Element$alignRight]),
									mdgriffith$elm_ui$Element$text(
										elm$core$String$fromInt(
											author$project$Page$Execution$getNumberOfStepsForSuite(suite))))
								]));
					}),
				passedSuites);
			return A2(
				mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
						mdgriffith$elm_ui$Element$spacing(5),
						mdgriffith$elm_ui$Element$padding(10),
						mdgriffith$elm_ui$Element$Border$width(3)
					]),
				elm$core$List$concat(
					_List_fromArray(
						[
							passedView,
							_List_fromArray(
							[currentView]),
							futureView,
							_List_fromArray(
							[totalView])
						])));
		}();
		var descriptionView = A2(author$project$ViewComponents$descriptionTextbox, _List_Nil, execution.bv.bq);
		var controlSize = 50;
		var viewButton = F2(
			function (executionControlInstruction, onPress) {
				return A2(
					mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$width(
							mdgriffith$elm_ui$Element$px(controlSize)),
							mdgriffith$elm_ui$Element$height(
							mdgriffith$elm_ui$Element$px(controlSize)),
							mdgriffith$elm_ui$Element$padding(10),
							mdgriffith$elm_ui$Element$Border$width(3),
							mdgriffith$elm_ui$Element$Border$color(
							A3(mdgriffith$elm_ui$Element$rgb, 1, 1, 1)),
							mdgriffith$elm_ui$Element$mouseOver(
							_List_fromArray(
								[
									mdgriffith$elm_ui$Element$Background$color(
									A3(mdgriffith$elm_ui$Element$rgb, 0.5, 0.5, 0.5))
								]))
						]),
					{
						fB: A2(
							author$project$ExecutionControlView$view,
							_List_fromArray(
								[
									mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
									mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill)
								]),
							executionControlInstruction),
						il: onPress
					});
			});
		var fastForwardButtonView = A2(
			viewButton,
			3,
			elm$core$Maybe$Just(
				author$project$Page$Execution$InternalMsg(author$project$Page$Execution$ClickedFastForward)));
		var homeButtonView = A2(
			viewButton,
			5,
			elm$core$Maybe$Just(
				author$project$Page$Execution$InternalMsg(author$project$Page$Execution$ClickedHome)));
		var pauseButtonView = A2(
			viewButton,
			4,
			elm$core$Maybe$Just(
				author$project$Page$Execution$InternalMsg(author$project$Page$Execution$ClickedPause)));
		var runButtonView = A2(
			viewButton,
			2,
			elm$core$Maybe$Just(
				author$project$Page$Execution$InternalMsg(author$project$Page$Execution$ClickedRun)));
		var stepButtonView = A2(
			viewButton,
			0,
			elm$core$Maybe$Just(
				author$project$Page$Execution$InternalMsg(author$project$Page$Execution$ClickedStep)));
		var undoButtonView = A2(
			viewButton,
			1,
			elm$core$Maybe$Just(
				author$project$Page$Execution$InternalMsg(author$project$Page$Execution$ClickedUndo)));
		var executionControlInstructionsView = A2(
			mdgriffith$elm_ui$Element$wrappedRow,
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$spacing(10),
					mdgriffith$elm_ui$Element$centerX
				]),
			_List_fromArray(
				[homeButtonView, undoButtonView, stepButtonView, runButtonView, fastForwardButtonView, pauseButtonView]));
		return A2(
			mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$width(
					mdgriffith$elm_ui$Element$px(350)),
					mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill),
					mdgriffith$elm_ui$Element$Background$color(
					A3(mdgriffith$elm_ui$Element$rgb, 8.0e-2, 8.0e-2, 8.0e-2)),
					mdgriffith$elm_ui$Element$alignTop,
					mdgriffith$elm_ui$Element$padding(10),
					mdgriffith$elm_ui$Element$spacing(20),
					mdgriffith$elm_ui$Element$scrollbarY
				]),
			_List_fromArray(
				[
					A2(
					mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
							mdgriffith$elm_ui$Element$spacing(20),
							mdgriffith$elm_ui$Element$paddingEach(
							{e$: 30, fD: 0, f5: 0, go: 20})
						]),
					_List_fromArray(
						[titleView, descriptionView])),
					executionControlInstructionsView,
					suiteView
				]));
	});
var author$project$Page$Execution$noPadding = {e$: 0, fD: 0, f5: 0, go: 0};
var author$project$Page$Execution$viewIOSidebar = F2(
	function (execution, model) {
		var paddingWidth = 5;
		var maxCharacters = 12;
		var executionSuite = author$project$Data$History$current(execution.l);
		var executionStep = author$project$Data$History$current(executionSuite.r);
		var characterWidth = 6;
		var borderWidth = 3;
		var columnWidth = ((characterWidth * maxCharacters) + (paddingWidth * 2)) + (borderWidth * 2);
		var totalWidth = (4 * columnWidth) + (10 * paddingWidth);
		var viewDouble = F3(
			function (label, expected, actual) {
				var labelView = A2(
					mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$paddingEach(
							_Utils_update(
								author$project$Page$Execution$noPadding,
								{e$: 10})),
							mdgriffith$elm_ui$Element$centerX
						]),
					mdgriffith$elm_ui$Element$text(label));
				var expectedView = A2(
					mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$width(
							mdgriffith$elm_ui$Element$px(columnWidth)),
							mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill),
							mdgriffith$elm_ui$Element$Font$alignRight,
							mdgriffith$elm_ui$Element$padding(paddingWidth),
							mdgriffith$elm_ui$Element$spacing(2),
							mdgriffith$elm_ui$Element$scrollbars,
							mdgriffith$elm_ui$Element$Border$width(borderWidth)
						]),
					A2(
						elm$core$List$map,
						function (value) {
							return A2(
								mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										mdgriffith$elm_ui$Element$Font$alignRight,
										mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill)
									]),
								mdgriffith$elm_ui$Element$text(value));
						},
						A2(elm$core$List$map, author$project$Data$Int16$toString, expected)));
				var correctedActual = function () {
					var correct = F2(
						function (a, b) {
							var _n1 = _Utils_Tuple2(a, b);
							_n1$1:
							while (true) {
								if (_n1.a.b) {
									if (_n1.b.b) {
										var _n2 = _n1.a;
										var ha = _n2.a;
										var ta = _n2.b;
										var _n3 = _n1.b;
										var hb = _n3.a;
										var tb = _n3.b;
										return A2(
											elm$core$List$cons,
											_Utils_Tuple2(
												author$project$Data$Int16$toString(hb),
												_Utils_eq(ha, hb)),
											A2(correct, ta, tb));
									} else {
										break _n1$1;
									}
								} else {
									if (!_n1.b.b) {
										break _n1$1;
									} else {
										var bb = _n1.b;
										return A2(
											elm$core$List$map,
											function (h) {
												return _Utils_Tuple2(
													author$project$Data$Int16$toString(h),
													false);
											},
											bb);
									}
								}
							}
							return _List_Nil;
						});
					return A2(
						correct,
						expected,
						elm$core$List$reverse(actual));
				}();
				var actualView = A2(
					mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$width(
							mdgriffith$elm_ui$Element$px(columnWidth)),
							mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill),
							mdgriffith$elm_ui$Element$Font$alignRight,
							mdgriffith$elm_ui$Element$padding(paddingWidth),
							mdgriffith$elm_ui$Element$spacing(2),
							mdgriffith$elm_ui$Element$scrollbars,
							mdgriffith$elm_ui$Element$Border$widthEach(
							{e$: borderWidth, fD: 0, f5: borderWidth, go: borderWidth})
						]),
					A2(
						elm$core$List$map,
						function (_n0) {
							var value = _n0.a;
							var correct = _n0.b;
							return A2(
								mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										mdgriffith$elm_ui$Element$Font$alignRight,
										mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
										mdgriffith$elm_ui$Element$alignRight,
										mdgriffith$elm_ui$Element$Background$color(
										correct ? A4(mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0) : A3(mdgriffith$elm_ui$Element$rgb, 0.5, 0, 0))
									]),
								mdgriffith$elm_ui$Element$text(value));
						},
						correctedActual));
				return A2(
					mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill),
							mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$shrink),
							mdgriffith$elm_ui$Element$padding(5)
						]),
					_List_fromArray(
						[
							labelView,
							A2(
							mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill),
									mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$shrink)
								]),
							_List_fromArray(
								[expectedView, actualView]))
						]));
			});
		var outputView = A3(viewDouble, 'Output', executionSuite.cO, executionStep.iu);
		var viewSingle = F2(
			function (label, values) {
				var valuesView = A2(
					mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$width(
							mdgriffith$elm_ui$Element$px(columnWidth)),
							mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill),
							mdgriffith$elm_ui$Element$Font$alignRight,
							mdgriffith$elm_ui$Element$padding(paddingWidth),
							mdgriffith$elm_ui$Element$spacing(2),
							mdgriffith$elm_ui$Element$scrollbars,
							mdgriffith$elm_ui$Element$Border$width(borderWidth)
						]),
					A2(
						elm$core$List$map,
						function (value) {
							return A2(
								mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										mdgriffith$elm_ui$Element$Font$alignRight,
										mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill)
									]),
								mdgriffith$elm_ui$Element$text(value));
						},
						A2(elm$core$List$map, author$project$Data$Int16$toString, values)));
				var labelView = A2(
					mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$paddingEach(
							_Utils_update(
								author$project$Page$Execution$noPadding,
								{e$: 10})),
							mdgriffith$elm_ui$Element$centerX
						]),
					mdgriffith$elm_ui$Element$text(label));
				return A2(
					mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill),
							mdgriffith$elm_ui$Element$padding(paddingWidth)
						]),
					_List_fromArray(
						[labelView, valuesView]));
			});
		var inputView = A2(viewSingle, 'Input', executionStep.hU);
		var stackView = A2(viewSingle, 'Stack', executionStep.k);
		return A2(
			mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$width(
					mdgriffith$elm_ui$Element$px(totalWidth)),
					mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill),
					mdgriffith$elm_ui$Element$Background$color(
					A3(mdgriffith$elm_ui$Element$rgb, 8.0e-2, 8.0e-2, 8.0e-2)),
					mdgriffith$elm_ui$Element$spacing(paddingWidth),
					mdgriffith$elm_ui$Element$Font$color(
					A3(mdgriffith$elm_ui$Element$rgb, 1, 1, 1)),
					mdgriffith$elm_ui$Element$padding(paddingWidth),
					mdgriffith$elm_ui$Element$scrollbars
				]),
			_List_fromArray(
				[inputView, stackView, outputView]));
	});
var author$project$Page$Execution$ClickedContinueEditing = {$: 7};
var author$project$Page$Execution$ClickedNavigateBrowseLevels = {$: 6};
var author$project$Page$Execution$viewVictoryModal = function (execution) {
	var viewRow = function (_n1) {
		var label = _n1.a;
		var value = _n1.b;
		return A2(
			mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
					mdgriffith$elm_ui$Element$spaceEvenly
				]),
			_List_fromArray(
				[
					A2(
					mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$paddingEach(
							_Utils_update(
								author$project$Page$Execution$noPadding,
								{f5: 30}))
						]),
					mdgriffith$elm_ui$Element$text(label)),
					mdgriffith$elm_ui$Element$text(
					elm$core$String$fromInt(value))
				]));
	};
	var _n0 = author$project$Page$Execution$getScore(execution);
	var numberOfSteps = _n0.bd;
	var numberOfInstructions = _n0.bc;
	return A2(
		mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				mdgriffith$elm_ui$Element$centerX,
				mdgriffith$elm_ui$Element$centerY,
				mdgriffith$elm_ui$Element$Background$color(
				A3(mdgriffith$elm_ui$Element$rgb, 0, 0, 0)),
				mdgriffith$elm_ui$Element$padding(20),
				mdgriffith$elm_ui$Element$Font$family(
				_List_fromArray(
					[mdgriffith$elm_ui$Element$Font$monospace])),
				mdgriffith$elm_ui$Element$Font$color(
				A3(mdgriffith$elm_ui$Element$rgb, 1, 1, 1)),
				mdgriffith$elm_ui$Element$spacing(10),
				mdgriffith$elm_ui$Element$Border$width(3),
				mdgriffith$elm_ui$Element$Border$color(
				A3(mdgriffith$elm_ui$Element$rgb, 1, 1, 1))
			]),
		_List_fromArray(
			[
				A2(
				mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$Font$size(32)
					]),
				mdgriffith$elm_ui$Element$text('Solved')),
				viewRow(
				_Utils_Tuple2('Number of steps', numberOfSteps)),
				viewRow(
				_Utils_Tuple2('Number of instructions', numberOfInstructions)),
				A2(
				mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
						mdgriffith$elm_ui$Element$Border$width(4),
						mdgriffith$elm_ui$Element$Border$color(
						A3(mdgriffith$elm_ui$Element$rgb, 1, 1, 1)),
						mdgriffith$elm_ui$Element$padding(10),
						mdgriffith$elm_ui$Element$mouseOver(
						_List_fromArray(
							[
								mdgriffith$elm_ui$Element$Background$color(
								A4(mdgriffith$elm_ui$Element$rgba, 1, 1, 1, 0.5))
							]))
					]),
				{
					fB: A2(
						mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[mdgriffith$elm_ui$Element$centerX, mdgriffith$elm_ui$Element$centerY]),
						mdgriffith$elm_ui$Element$text('Back to levels')),
					il: elm$core$Maybe$Just(
						author$project$Page$Execution$InternalMsg(author$project$Page$Execution$ClickedNavigateBrowseLevels))
				}),
				A2(
				mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill),
						mdgriffith$elm_ui$Element$Border$width(4),
						mdgriffith$elm_ui$Element$Border$color(
						A3(mdgriffith$elm_ui$Element$rgb, 1, 1, 1)),
						mdgriffith$elm_ui$Element$padding(10),
						mdgriffith$elm_ui$Element$mouseOver(
						_List_fromArray(
							[
								mdgriffith$elm_ui$Element$Background$color(
								A4(mdgriffith$elm_ui$Element$rgba, 1, 1, 1, 0.5))
							]))
					]),
				{
					fB: A2(
						mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[mdgriffith$elm_ui$Element$centerX, mdgriffith$elm_ui$Element$centerY]),
						mdgriffith$elm_ui$Element$text('Continue editing')),
					il: elm$core$Maybe$Just(
						author$project$Page$Execution$InternalMsg(author$project$Page$Execution$ClickedContinueEditing))
				})
			]));
};
var author$project$Page$Execution$viewWrongOutputModal = F2(
	function (model, execution) {
		return A2(
			mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$centerX,
					mdgriffith$elm_ui$Element$centerY,
					mdgriffith$elm_ui$Element$Background$color(
					A3(mdgriffith$elm_ui$Element$rgb, 0, 0, 0)),
					mdgriffith$elm_ui$Element$padding(20),
					mdgriffith$elm_ui$Element$Font$family(
					_List_fromArray(
						[mdgriffith$elm_ui$Element$Font$monospace])),
					mdgriffith$elm_ui$Element$Font$color(
					A3(mdgriffith$elm_ui$Element$rgb, 1, 1, 1)),
					mdgriffith$elm_ui$Element$spacing(10),
					mdgriffith$elm_ui$Element$Border$width(3),
					mdgriffith$elm_ui$Element$Border$color(
					A3(mdgriffith$elm_ui$Element$rgb, 1, 1, 1))
				]),
			_List_fromArray(
				[
					A2(
					mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$Font$size(32)
						]),
					mdgriffith$elm_ui$Element$text('Wrong output')),
					A2(
					mdgriffith$elm_ui$Element$paragraph,
					_List_Nil,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$text('The program terminated, but the output is incorrect.')
						])),
					A2(
					author$project$View$Box$link,
					'Back to editor',
					author$project$Route$EditDraft(model.o))
				]));
	});
var author$project$Page$Execution$viewLoaded = F2(
	function (execution, model) {
		var west = A2(author$project$Page$Execution$viewExecutionSidebar, execution, model);
		var modal = function () {
			var _n0 = author$project$Data$History$current(
				author$project$Data$History$current(execution.l).r).b2;
			if (!_n0.$) {
				var message = _n0.a;
				return elm$core$Maybe$Just(
					A3(author$project$Page$Execution$viewExceptionModal, execution, model, message));
			} else {
				return author$project$Page$Execution$canStepExecution(execution) ? elm$core$Maybe$Nothing : (author$project$Page$Execution$isExecutionSolved(execution) ? elm$core$Maybe$Just(
					author$project$Page$Execution$viewVictoryModal(execution)) : elm$core$Maybe$Just(
					A2(author$project$Page$Execution$viewWrongOutputModal, model, execution)));
			}
		}();
		var main = A2(author$project$Page$Execution$viewBoard, execution, model);
		var header = author$project$View$Header$view(model.f);
		var east = A2(author$project$Page$Execution$viewIOSidebar, execution, model);
		return author$project$View$Scewn$view(
			{
				g5: elm$core$Maybe$Just(main),
				hp: elm$core$Maybe$Just(east),
				h9: modal,
				ie: elm$core$Maybe$Just(header),
				iZ: elm$core$Maybe$Nothing,
				jz: elm$core$Maybe$Just(west)
			});
	});
var mdgriffith$elm_ui$Element$clip = A2(mdgriffith$elm_ui$Internal$Model$Class, mdgriffith$elm_ui$Internal$Flag$overflow, mdgriffith$elm_ui$Internal$Style$classes.g9);
var author$project$Page$Execution$view = function (model) {
	var content = function () {
		var _n0 = A2(author$project$Data$Cache$get, model.o, model.f.dC.aj);
		switch (_n0.$) {
			case 0:
				var _n1 = A2(author$project$Data$Cache$get, model.o, model.f.dC.ae);
				if (_n1.$ === 1) {
					return author$project$View$LoadingScreen$view('Loading draft ' + (model.o + ' from server'));
				} else {
					return author$project$View$ErrorScreen$view('Draft ' + (model.o + ' not asked :/'));
				}
			case 1:
				return author$project$View$LoadingScreen$view('Loading draft ' + (model.o + ' from local storage'));
			case 2:
				var error = _n0.a;
				return author$project$View$ErrorScreen$view(
					elm$json$Json$Decode$errorToString(error));
			default:
				if (_n0.a.$ === 1) {
					var _n2 = _n0.a;
					return author$project$View$ErrorScreen$view('Draft ' + (model.o + ' not found'));
				} else {
					var draft = _n0.a.a;
					var _n3 = A2(author$project$Data$Session$getLevel, draft.a8, model.f);
					switch (_n3.$) {
						case 0:
							return author$project$View$ErrorScreen$view('Level ' + (draft.a8 + ' not asked :/'));
						case 1:
							return author$project$View$LoadingScreen$view('Loading level ' + draft.a8);
						case 2:
							var error = _n3.a;
							return author$project$View$ErrorScreen$view(
								author$project$Data$GetError$toString(error));
						default:
							var _n4 = model.ar;
							if (!_n4.$) {
								var execution = _n4.a;
								return A2(author$project$Page$Execution$viewLoaded, execution, model);
							} else {
								return author$project$View$LoadingScreen$view('Initializing execution');
							}
					}
				}
		}
	}();
	var body = elm$core$List$singleton(
		A2(
			mdgriffith$elm_ui$Element$layout,
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$height(mdgriffith$elm_ui$Element$fill),
					mdgriffith$elm_ui$Element$clip,
					mdgriffith$elm_ui$Element$Font$family(
					_List_fromArray(
						[mdgriffith$elm_ui$Element$Font$monospace])),
					mdgriffith$elm_ui$Element$Font$color(
					A3(mdgriffith$elm_ui$Element$rgb, 1, 1, 1))
				]),
			content));
	return {
		bT: body,
		gn: elm$core$String$concat(
			_List_fromArray(
				['Execution', ' - ', author$project$ApplicationName$applicationName]))
	};
};
var author$project$Version$version = '1.0.8';
var author$project$Page$Home$view = function (model) {
	var titleView = A2(
		mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				mdgriffith$elm_ui$Element$centerX,
				author$project$View$Constant$size.fk.iw.gn,
				mdgriffith$elm_ui$Element$padding(20)
			]),
		mdgriffith$elm_ui$Element$text(author$project$ApplicationName$applicationName));
	var link = F2(
		function (text, route) {
			return A3(
				author$project$Route$link,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill)
					]),
				A3(author$project$ViewComponents$textButton, _List_Nil, elm$core$Maybe$Nothing, text),
				route);
		});
	var main = A2(
		mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				mdgriffith$elm_ui$Element$padding(60),
				mdgriffith$elm_ui$Element$spacing(20),
				mdgriffith$elm_ui$Element$centerX,
				mdgriffith$elm_ui$Element$width(
				A2(mdgriffith$elm_ui$Element$maximum, 1000, mdgriffith$elm_ui$Element$fill))
			]),
		_List_fromArray(
			[
				titleView,
				A2(link, 'Campaigns', author$project$Route$Campaigns),
				A2(link, 'Credits', author$project$Route$Credits),
				A2(
				mdgriffith$elm_ui$Element$link,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill)
					]),
				{
					fB: author$project$View$Box$simpleInteractive('Documentation'),
					jw: 'https://github.com/simonolander/elm-funge/blob/master/documentation/docs.md'
				})
			]));
	var header = author$project$View$Header$view(model.f);
	var footer = A2(
		mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill)
			]),
		_List_fromArray(
			[
				A2(
				mdgriffith$elm_ui$Element$link,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$padding(20),
						mdgriffith$elm_ui$Element$alignRight,
						mdgriffith$elm_ui$Element$Font$color(
						A3(mdgriffith$elm_ui$Element$rgb, 0.25, 0.25, 0.25)),
						mdgriffith$elm_ui$Element$mouseOver(
						_List_fromArray(
							[
								mdgriffith$elm_ui$Element$Font$color(
								A3(mdgriffith$elm_ui$Element$rgb, 0.25, 0.25, 0.5))
							]))
					]),
				{
					fB: mdgriffith$elm_ui$Element$text(author$project$Version$version),
					jw: 'https://github.com/simonolander/elm-funge'
				})
			]));
	var body = elm$core$List$singleton(
		author$project$View$Layout$layout(
			author$project$View$Scewn$view(
				{
					g5: elm$core$Maybe$Just(main),
					hp: elm$core$Maybe$Nothing,
					h9: elm$core$Maybe$Nothing,
					ie: elm$core$Maybe$Just(header),
					iZ: elm$core$Maybe$Just(footer),
					jz: elm$core$Maybe$Nothing
				})));
	return {
		bT: body,
		gn: elm$core$String$concat(
			_List_fromArray(
				['Home', ' - ', author$project$ApplicationName$applicationName]))
	};
};
var author$project$Page$Initialize$ClickedDeleteLocalData = {$: 10};
var author$project$Page$Initialize$ClickedImportLocalData = {$: 9};
var author$project$Page$Initialize$ClickedSignInToOtherAccount = {$: 11};
var author$project$Page$Initialize$ClickedContinueOffline = {$: 5};
var author$project$Page$Initialize$viewExpiredAccessToken = F2(
	function (model, message) {
		return A2(
			mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$centerX,
					mdgriffith$elm_ui$Element$centerY,
					mdgriffith$elm_ui$Element$spacing(20)
				]),
			_List_fromArray(
				[
					A2(
					mdgriffith$elm_ui$Element$image,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$width(
							mdgriffith$elm_ui$Element$px(36)),
							mdgriffith$elm_ui$Element$centerX
						]),
					{bq: 'Loading animation', bJ: 'assets/instruction-images/exception.svg'}),
					A2(
					mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$shrink),
							mdgriffith$elm_ui$Element$centerX,
							mdgriffith$elm_ui$Element$centerY,
							mdgriffith$elm_ui$Element$Font$center,
							mdgriffith$elm_ui$Element$Font$size(28),
							mdgriffith$elm_ui$Element$Font$color(author$project$View$Constant$color.fk.ff)
						]),
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$text('Your credentials are either expired or invalid. '),
							mdgriffith$elm_ui$Element$text(message)
						])),
					A2(
					mdgriffith$elm_ui$Element$link,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$width(
							mdgriffith$elm_ui$Element$px(300)),
							mdgriffith$elm_ui$Element$centerX
						]),
					{
						fB: A3(author$project$ViewComponents$textButton, _List_Nil, elm$core$Maybe$Nothing, 'Sign in'),
						jw: author$project$Api$Auth0$login(
							author$project$Route$toUrl(model.aX))
					}),
					A3(
					author$project$ViewComponents$textButton,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$width(
							mdgriffith$elm_ui$Element$px(300)),
							mdgriffith$elm_ui$Element$centerX
						]),
					elm$core$Maybe$Just(author$project$Page$Initialize$ClickedContinueOffline),
					'Continue offline')
				]));
	});
var author$project$Page$Initialize$viewHttpError = F2(
	function (model, error) {
		switch (error.$) {
			case 0:
				return author$project$View$Info$view(
					{
						b1: _List_fromArray(
							[
								A3(
								author$project$ViewComponents$textButton,
								_List_Nil,
								elm$core$Maybe$Just(author$project$Page$Initialize$ClickedContinueOffline),
								'Continue offline')
							]),
						dJ: {bq: 'Alert icon', bJ: 'assets/exception-orange.svg'},
						gn: 'Unable to connect to server'
					});
			case 1:
				var message = error.a;
				return A2(author$project$Page$Initialize$viewExpiredAccessToken, model, message);
			default:
				return author$project$View$ErrorScreen$view(
					author$project$Data$GetError$toString(error));
		}
	});
var author$project$Page$Initialize$viewLoading = function (_n0) {
	var title = _n0.gn;
	var elements = _n0.b1;
	return A2(
		mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				mdgriffith$elm_ui$Element$centerX,
				mdgriffith$elm_ui$Element$centerY,
				mdgriffith$elm_ui$Element$spacing(20),
				mdgriffith$elm_ui$Element$padding(40)
			]),
		_Utils_ap(
			_List_fromArray(
				[
					A2(
					mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$Font$size(28),
							mdgriffith$elm_ui$Element$Font$center
						]),
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$text(title)
						])),
					A2(
					mdgriffith$elm_ui$Element$image,
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$width(
							mdgriffith$elm_ui$Element$px(36)),
							mdgriffith$elm_ui$Element$centerX
						]),
					{bq: 'Loading spinner', bJ: 'assets/spinner.svg'})
				]),
			elements));
};
var author$project$Page$Initialize$ClickedDraftDeleteLocal = function (a) {
	return {$: 6, a: a};
};
var author$project$Page$Initialize$ClickedDraftKeepLocal = function (a) {
	return {$: 7, a: a};
};
var author$project$Page$Initialize$ClickedDraftKeepServer = function (a) {
	return {$: 8, a: a};
};
var author$project$Page$Initialize$viewResolveDraftConflict = function (_n0) {
	var local = _n0.aj;
	var expected = _n0.ai;
	var actual = _n0.ae;
	var elements = function () {
		if (!actual.$) {
			var justActual = actual.a;
			return _List_fromArray(
				[
					A2(
					mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[mdgriffith$elm_ui$Element$Font$center]),
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$text('Your local changes on draft '),
							mdgriffith$elm_ui$Element$text(local.b6),
							mdgriffith$elm_ui$Element$text(' have diverged from the server version. You need to choose which version you want to keep.')
						])),
					A3(
					author$project$ViewComponents$textButton,
					_List_Nil,
					elm$core$Maybe$Just(
						author$project$Page$Initialize$ClickedDraftKeepLocal(local.b6)),
					'Keep my local changes'),
					A3(
					author$project$ViewComponents$textButton,
					_List_Nil,
					elm$core$Maybe$Just(
						author$project$Page$Initialize$ClickedDraftKeepServer(justActual.b6)),
					'Keep the server changes')
				]);
		} else {
			return _List_fromArray(
				[
					A2(
					mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[mdgriffith$elm_ui$Element$Font$center]),
					_List_fromArray(
						[
							mdgriffith$elm_ui$Element$text('Draft '),
							mdgriffith$elm_ui$Element$text(local.b6),
							mdgriffith$elm_ui$Element$text(' have been deleted from the server but there is still a copy stored locally. '),
							mdgriffith$elm_ui$Element$text('You need to choose whether you want to keep it or not.')
						])),
					A3(
					author$project$ViewComponents$textButton,
					_List_Nil,
					elm$core$Maybe$Just(
						author$project$Page$Initialize$ClickedDraftKeepLocal(local.b6)),
					'Keep it'),
					A3(
					author$project$ViewComponents$textButton,
					_List_Nil,
					elm$core$Maybe$Just(
						author$project$Page$Initialize$ClickedDraftDeleteLocal(local.b6)),
					'Discard it')
				]);
		}
	}();
	return author$project$View$Info$view(
		{
			b1: elements,
			dJ: {bq: 'Alert icon', bJ: 'assets/exception-orange.svg'},
			gn: 'Conflict in draft ' + local.b6
		});
};
var author$project$Page$Initialize$viewProgress = function (model) {
	var progressRow = F3(
		function (description, current, total) {
			return A2(
				mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						mdgriffith$elm_ui$Element$width(mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						A2(
						mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[mdgriffith$elm_ui$Element$alignLeft]),
						mdgriffith$elm_ui$Element$text(description)),
						A2(
						mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[mdgriffith$elm_ui$Element$alignRight]),
						mdgriffith$elm_ui$Element$text(
							elm$core$String$concat(
								_List_fromArray(
									[
										elm$core$String$fromInt(current),
										'/',
										elm$core$String$fromInt(total)
									]))))
					]));
		});
	var numberOfSolutionsSaving = elm$core$List$length(
		A2(
			elm$core$List$filter,
			author$project$Page$Initialize$isSaving,
			elm$core$Dict$values(model.al)));
	var numberOfSolutionsSaved = elm$core$List$length(
		A2(
			elm$core$List$filter,
			A2(elm$core$Basics$composeL, elm$core$Basics$not, author$project$Page$Initialize$isSaving),
			elm$core$Dict$values(model.al)));
	var numberOfDraftsSaving = elm$core$List$length(
		A2(
			elm$core$List$filter,
			author$project$Page$Initialize$isSaving,
			elm$core$Dict$values(model.aa)));
	var numberOfDraftsSaved = elm$core$List$length(
		A2(
			elm$core$List$filter,
			A2(elm$core$Basics$composeL, elm$core$Basics$not, author$project$Page$Initialize$isSaving),
			elm$core$Dict$values(model.aa)));
	var numberOfDraftsLoading = elm$core$List$length(
		A2(
			elm$core$List$filter,
			krisajenkins$remotedata$RemoteData$isLoading,
			author$project$Data$Cache$values(model.C)));
	var numberOfDraftsLoaded = elm$core$List$length(
		A2(
			elm$core$List$filter,
			A2(elm$core$Basics$composeL, elm$core$Basics$not, krisajenkins$remotedata$RemoteData$isLoading),
			author$project$Data$Cache$values(model.C)));
	var elements = _List_fromArray(
		[
			A2(
			mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					mdgriffith$elm_ui$Element$centerX,
					mdgriffith$elm_ui$Element$spacing(10)
				]),
			_List_fromArray(
				[
					A3(progressRow, 'Loading drafts: ', numberOfDraftsLoaded, numberOfDraftsLoading + numberOfDraftsLoaded),
					A3(progressRow, 'Saving drafts: ', numberOfDraftsSaved, numberOfDraftsSaving + numberOfDraftsSaved),
					A3(progressRow, 'Saving solutions: ', numberOfSolutionsSaved, numberOfSolutionsSaving + numberOfSolutionsSaved)
				]))
		]);
	var draftsNeedingManualResolution = A2(
		elm$core$List$filterMap,
		function (key) {
			var _n2 = author$project$Page$Initialize$determineDraftConflict(
				{
					ae: A2(author$project$Data$Cache$get, key, model.C),
					ai: A2(elm$core$Dict$get, key, model.n),
					aj: A2(elm$core$Dict$get, key, model.j)
				});
			if (_n2.$ === 3) {
				var record = _n2.a;
				return elm$core$Maybe$Just(record);
			} else {
				return elm$core$Maybe$Nothing;
			}
		},
		elm$core$Dict$keys(model.j));
	var drafts = A2(
		elm$core$List$map,
		function (_n1) {
			var draftId = _n1.a;
			var localResult = _n1.b;
			return {
				ae: A2(author$project$Data$Cache$get, draftId, model.C),
				ai: A2(elm$core$Dict$get, draftId, model.n),
				aj: localResult
			};
		},
		A2(
			elm$core$List$sortBy,
			elm$core$Tuple$first,
			elm$core$Dict$toList(model.j)));
	var _n0 = elm$core$List$head(draftsNeedingManualResolution);
	if (!_n0.$) {
		var record = _n0.a;
		return author$project$Page$Initialize$viewResolveDraftConflict(record);
	} else {
		return author$project$Page$Initialize$viewLoading(
			{b1: elements, gn: 'Resolving unsaved data'});
	}
};
var mdgriffith$elm_ui$Element$rgb255 = F3(
	function (red, green, blue) {
		return A4(mdgriffith$elm_ui$Internal$Model$Rgba, red / 255, green / 255, blue / 255, 1);
	});
var author$project$Page$Initialize$view = function (model) {
	var element = function () {
		var _n0 = model.m;
		switch (_n0.$) {
			case 0:
				return author$project$View$ErrorScreen$layout('Missing access token');
			case 1:
				return author$project$View$Layout$layout(
					A2(author$project$Page$Initialize$viewExpiredAccessToken, model, ''));
			case 2:
				var _n1 = model.aE;
				switch (_n1.$) {
					case 0:
						return author$project$View$ErrorScreen$layout('Todo: User info not asked');
					case 1:
						return author$project$View$LoadingScreen$layout('Verifying credentials');
					case 2:
						var error = _n1.a;
						return author$project$View$Layout$layout(
							A2(author$project$Page$Initialize$viewHttpError, model, error));
					default:
						var actualUserInfo = _n1.a;
						return author$project$View$Layout$layout(
							author$project$View$Info$view(
								{
									b1: function () {
										var _n2 = model.a5;
										if (!_n2.$) {
											var expectedUserInfo = _n2.a;
											return _List_fromArray(
												[
													A2(
													mdgriffith$elm_ui$Element$paragraph,
													_List_fromArray(
														[mdgriffith$elm_ui$Element$Font$center]),
													_List_fromArray(
														[
															mdgriffith$elm_ui$Element$text('You\'re trying to sign in as '),
															A2(
															mdgriffith$elm_ui$Element$el,
															_List_fromArray(
																[
																	mdgriffith$elm_ui$Element$Font$color(
																	A3(mdgriffith$elm_ui$Element$rgb255, 163, 179, 222))
																]),
															mdgriffith$elm_ui$Element$text(actualUserInfo.dh)),
															mdgriffith$elm_ui$Element$text(' but there is unsaved data belonging to '),
															A2(
															mdgriffith$elm_ui$Element$el,
															_List_fromArray(
																[
																	mdgriffith$elm_ui$Element$Font$color(
																	A3(mdgriffith$elm_ui$Element$rgb255, 163, 179, 222))
																]),
															mdgriffith$elm_ui$Element$text(expectedUserInfo.dh)),
															mdgriffith$elm_ui$Element$text('. Either clear the local data and continue or log in to the other account.')
														])),
													A3(
													author$project$ViewComponents$textButton,
													_List_Nil,
													elm$core$Maybe$Just(author$project$Page$Initialize$ClickedDeleteLocalData),
													'Delete data'),
													A3(
													author$project$ViewComponents$textButton,
													_List_Nil,
													elm$core$Maybe$Just(author$project$Page$Initialize$ClickedSignInToOtherAccount),
													'Sign in to the other account')
												]);
										} else {
											return _List_fromArray(
												[
													A2(
													mdgriffith$elm_ui$Element$paragraph,
													_List_fromArray(
														[mdgriffith$elm_ui$Element$Font$center]),
													_List_fromArray(
														[
															mdgriffith$elm_ui$Element$text('There is unsaved data on the local storage. Either import it to this account or delete it.')
														])),
													A3(
													author$project$ViewComponents$textButton,
													_List_Nil,
													elm$core$Maybe$Just(author$project$Page$Initialize$ClickedImportLocalData),
													'Import data'),
													A3(
													author$project$ViewComponents$textButton,
													_List_Nil,
													elm$core$Maybe$Just(author$project$Page$Initialize$ClickedDeleteLocalData),
													'Delete data')
												]);
										}
									}(),
									dJ: {bq: 'Alert icon', bJ: 'assets/exception-orange.svg'},
									gn: 'New sign in detected'
								}));
				}
			default:
				return author$project$View$Layout$layout(
					author$project$Page$Initialize$viewProgress(model));
		}
	}();
	return {
		bT: _List_fromArray(
			[element]),
		gn: elm$core$String$concat(
			_List_fromArray(
				['Synchronizing', ' - ', author$project$ApplicationName$applicationName]))
	};
};
var author$project$Page$NotFound$view = function (model) {
	var content = author$project$View$Scewn$layout(
		{
			g5: elm$core$Maybe$Just(
				author$project$View$NotFound$view(
					{
						b6: elm$url$Url$toString(model.f.jw),
						$8: 'page'
					})),
			hp: elm$core$Maybe$Nothing,
			h9: elm$core$Maybe$Nothing,
			ie: elm$core$Maybe$Just(
				author$project$View$Header$view(model.f)),
			iZ: elm$core$Maybe$Nothing,
			jz: elm$core$Maybe$Nothing
		});
	return {
		bT: _List_fromArray(
			[content]),
		gn: elm$core$String$concat(
			_List_fromArray(
				['Not Found', ' - ', author$project$ApplicationName$applicationName]))
	};
};
var author$project$Main$view = function (model) {
	var msgMap = F2(
		function (_function, document) {
			return {
				bT: A2(
					elm$core$List$map,
					elm$html$Html$map(_function),
					document.bT),
				gn: document.gn
			};
		});
	switch (model.$) {
		case 0:
			var mdl = model.a;
			return A2(
				msgMap,
				author$project$Main$HomeMsg,
				author$project$Page$Home$view(mdl));
		case 1:
			var mdl = model.a;
			return A2(
				msgMap,
				author$project$Main$CampaignMsg,
				author$project$Page$Campaign$view(mdl));
		case 2:
			var mdl = model.a;
			return A2(
				msgMap,
				author$project$Main$CampaignsMsg,
				author$project$Page$Campaigns$view(mdl));
		case 3:
			var mdl = model.a;
			return A2(
				msgMap,
				author$project$Main$CreditsMsg,
				author$project$Page$Credits$view(mdl));
		case 4:
			var mdl = model.a;
			return A2(
				msgMap,
				author$project$Main$ExecutionMsg,
				author$project$Page$Execution$view(mdl));
		case 5:
			var mdl = model.a;
			return A2(
				msgMap,
				author$project$Main$DraftMsg,
				author$project$Page$Draft$view(mdl));
		case 6:
			var mdl = model.a;
			return A2(
				msgMap,
				author$project$Main$BlueprintMsg,
				author$project$Page$Blueprint$view(mdl));
		case 7:
			var mdl = model.a;
			return A2(
				msgMap,
				author$project$Main$BlueprintsMsg,
				author$project$Page$Blueprints$view(mdl));
		case 8:
			var mdl = model.a;
			return A2(
				msgMap,
				author$project$Main$InitializeMsg,
				author$project$Page$Initialize$view(mdl));
		default:
			var mdl = model.a;
			return A2(
				msgMap,
				author$project$Main$NotFoundMsg,
				author$project$Page$NotFound$view(mdl));
	}
};
var elm$browser$Browser$application = _Browser_application;
var author$project$Main$main = elm$browser$Browser$application(
	{hS: author$project$Main$init, ip: author$project$Main$ChangedUrl, iq: author$project$Main$ClickedLink, i7: author$project$Main$subscriptions, jv: author$project$Main$update, jx: author$project$Main$view});
_Platform_export({'Main':{'init':author$project$Main$main(
	A2(
		elm$json$Json$Decode$andThen,
		function (width) {
			return A2(
				elm$json$Json$Decode$andThen,
				function (localStorageEntries) {
					return A2(
						elm$json$Json$Decode$andThen,
						function (height) {
							return A2(
								elm$json$Json$Decode$andThen,
								function (currentTimeMillis) {
									return elm$json$Json$Decode$succeed(
										{e9: currentTimeMillis, fp: height, c$: localStorageEntries, gy: width});
								},
								A2(elm$json$Json$Decode$field, 'currentTimeMillis', elm$json$Json$Decode$int));
						},
						A2(elm$json$Json$Decode$field, 'height', elm$json$Json$Decode$int));
				},
				A2(
					elm$json$Json$Decode$field,
					'localStorageEntries',
					elm$json$Json$Decode$list(
						A2(
							elm$json$Json$Decode$andThen,
							function (x0) {
								return A2(
									elm$json$Json$Decode$andThen,
									function (x1) {
										return elm$json$Json$Decode$succeed(
											_Utils_Tuple2(x0, x1));
									},
									A2(elm$json$Json$Decode$index, 1, elm$json$Json$Decode$value));
							},
							A2(elm$json$Json$Decode$index, 0, elm$json$Json$Decode$string)))));
		},
		A2(elm$json$Json$Decode$field, 'width', elm$json$Json$Decode$int)))(0)}});}(this));